import React from 'react';
import { useState, useEffect } from 'react';
import DocumentGeneratorService from 'components/helpers/DocumentGeneratorService';
import DownloadModal from 'components/application/DownloadModal';
import FilledButton from 'components/application/buttons/FilledButton';
import FailedIcon from '-!svg-react-loader?name=CalendarIcon!icons/exclamation-circle.svg';
import ProgressIcon from '-!svg-react-loader?name=LoadingIcon!icons/progress.svg';

export default function DocumentGenerator(props) {
  const {
    isGenerating,
    setIsGenerating,
    generateUrl,
    generateParams,
    modalHeader,
    modalSuccessMessage,
    modalFailureMessage,
    isInline,
  } = props;

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isReady, setIsReady] = useState(false);
  const [isFailed, setIsFailed] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [documentGeneratorService, setDocumentGeneratorService] =
    useState(null);
  const [docUrl, setDocUrl] = useState('');

  const reset = () => {
    if (documentGeneratorService) {
      documentGeneratorService.end();
    }

    setIsModalOpen(false);
    setIsReady(false);
    setIsFailed(false);
    setIsButtonDisabled(false);
    setDocumentGeneratorService(null);
    setDocUrl('');
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  const successCallback = (url) => {
    setDocUrl(url);
    setIsButtonDisabled(false);
    setIsReady(true);
  };

  const failureCallback = () => {
    setIsFailed(true);
    setIsButtonDisabled(false);
  };

  const startGenerating = () => {
    const newDocumentGeneratorService = new DocumentGeneratorService({
      generateUrl,
      generateParams,
      successCallback,
      failureCallback,
      isInline,
    });
    newDocumentGeneratorService.start();
    setIsButtonDisabled(true);
    setIsFailed(false);
    setDocumentGeneratorService(newDocumentGeneratorService);
  };

  const openDocument = () => {
    window.location.href = docUrl;
    reset();
  };

  const modalBody =
    isReady ? modalSuccessMessage
    : isFailed ? modalFailureMessage
    : 'Downloading...';

  const modalBodyIcon =
    isFailed ? <FailedIcon className='.icon--within-text' /> : '';

  const modalButton = (
    <FilledButton
      color='mint'
      disabled={isButtonDisabled}
      className={`modal_download_button${isButtonDisabled ? '--disabled' : ''}`}
      onClick={isFailed ? startGenerating : openDocument}
    >
      <ProgressIcon
        width={18}
        height={18}
        className={`${isReady || isFailed ? 'hide' : 'tw-mr-1'}`}
      />
      {isReady ?
        'Download now'
      : isFailed ?
        'Retry'
      : 'Please wait...'}
    </FilledButton>
  );

  useEffect(() => {
    if (isGenerating) {
      openModal();
      startGenerating();
    }
    return () => {
      setIsGenerating(false);
    };
  }, [isGenerating]);

  return (
    <DownloadModal
      header={modalHeader}
      body={modalBody}
      bodyIcon={modalBodyIcon}
      isOpen={isModalOpen}
      onClose={reset}
      actionButton={modalButton}
      isLandscape={true}
      isFailed={isFailed}
    />
  );
}
