import React from 'react';
import PropTypes from 'prop-types';
import voca from 'voca';

import { useCurrentActor } from 'components/contexts/CurrentActor';
import {
  defaultCourse,
  asCourseObject,
} from 'components/helpers/resources/courses';

import RowBar from 'components/application/RowBar';
import ToggleableSelect from 'components/selectors/ToggleableSelect';
import SearchField from 'components/application/SearchField';

import LogoIcon from '-!svg-react-loader?name=LogoIcon!icons/ihasco-logo-mark.svg';

export const isValidNewOption = (courseOption) =>
  !!courseOption && courseOption.trim().length !== 0;

export default function CoursesBar(props) {
  const {
    actionButtonsVisible,
    setSidePanelContext,
    setCurrentCourse,
    openSidePanel,
    coursesSearch,
    coursesCount,
    onSearchInputChange,
    onSearchReset,
    resetRequestError,
  } = props;

  const currentActor = useCurrentActor();
  const currencyCode = currentActor.division.attributes.currencyCode;

  const handleChange = (selected) => {
    let newCurrentCourse;

    if (selected.value.resource) {
      const course = selected.value.resource;
      newCurrentCourse = {
        ...asCourseObject({
          course,
          eLearningAllowed: currentActor.isAllowedFeature('e_learning'),
          currencyCode,
        }),
        id: null,
        courseCompanyRoles: [],
        originalId: course.id,
      };
    } else {
      newCurrentCourse = {
        ...defaultCourse,
        name: voca.capitalize(selected.value),
      };
    }

    setSidePanelContext('new');
    resetRequestError();
    setCurrentCourse(newCurrentCourse);
    openSidePanel();
  };

  const handleFocus = () => {
    setCurrentCourse(defaultCourse);
  };

  const wasCopiedToAccount = (courseOption) =>
    courseOption.attributes.wasCopiedToAccount;

  const renderSublabel = (courseOption) => {
    return (
        currentActor.isAllowedFeature('e_learning') &&
          !wasCopiedToAccount(courseOption) &&
          !!courseOption.relationships.eLearningCourse.data
      ) ?
        <div className='flex flex--vertically-centered fh-16'>
          <LogoIcon className='m-r-4' width={16} height={16} />
          <span className='tw-text-grey-600 tw-text-s tw-font-normal tw-tracking-wide'>
            eLearning available
          </span>
        </div>
      : null;
  };

  return (
    <RowBar
      modifiers={[
        'border-top-curved',
        'border-bottom-none',
        'flex-align-items-ie-fix',
      ]}
      content={
        coursesCount > 0 && (
          <SearchField
            value={coursesSearch}
            onChange={onSearchInputChange}
            onReset={onSearchReset}
            name='coursesSearch'
            placeholder='Search your courses...'
            additionalClasses='search-field__lg'
          />
        )
      }
      actions={
        actionButtonsVisible && (
          <ToggleableSelect
            name='course'
            buttonText='Add new course'
            url='/standard_courses'
            placeholder='Search all available courses'
            isCreatable={true}
            createOptionPosition='first'
            onChange={handleChange}
            onFocus={handleFocus}
            renderSublabel={renderSublabel}
            isOptionDisabled={wasCopiedToAccount}
            isValidNewOption={isValidNewOption}
          />
        )
      }
    />
  );
}

CoursesBar.propTypes = {
  actionButtonsVisible: PropTypes.bool.isRequired,
  setSidePanelContext: PropTypes.func.isRequired,
  setCurrentCourse: PropTypes.func.isRequired,
  openSidePanel: PropTypes.func.isRequired,
  coursesSearch: PropTypes.string.isRequired,
  coursesCount: PropTypes.number.isRequired,
  onSearchInputChange: PropTypes.func.isRequired,
  onSearchReset: PropTypes.func.isRequired,
  resetRequestError: PropTypes.func.isRequired,
};
