import React from 'react';
import PropTypes from 'prop-types';

export default function Icon({
  disabled,
  onCheckboxChange,
  iconImageAssetPath,
  iconName,
  iconId,
  checkboxSelected,
}) {
  return (
    <label className='method_icon'>
      <img
        className='tw-border-grey-100'
        src={iconImageAssetPath}
        alt={iconName}
      />
      <input
        type='checkbox'
        className='icon_checkbox'
        id={iconId}
        disabled={disabled}
        checked={checkboxSelected}
        onChange={onCheckboxChange}
      />
      <span className='tw-font-normal'>{iconName}</span>
    </label>
  );
}

Icon.propTypes = {
  iconImageAssetPath: PropTypes.string.isRequired,
  iconName: PropTypes.string.isRequired,
  iconId: PropTypes.number.isRequired,
  checkboxSelected: PropTypes.bool,
  onCheckboxChange: PropTypes.func,
};

Icon.defaultProps = {
  checkboxSelected: false,
};
