import React, { Fragment, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDropzone } from 'react-dropzone';
import DropIcon from '-!svg-react-loader?name=DropIcon!icons/ic-drop.svg';
import FilesIcon from '-!svg-react-loader?name=FilesIcon!icons/ic-files.svg';
import ValidationErrorMessage from 'components/uploader/components/ValidationErrorMessage';

const isCancellable = { default: true, compact: false, solo: false };
const supportedFileTypes = {
  pdf: 'application/pdf',
  png: 'image/jpeg',
  jpg: 'image/png',
};

export default function Uploader(props) {
  const { mode, uploadsCount, onError, onCancel, onUpload, fieldInvalid } =
    props;
  const acceptedFileTypes = props.fileTypes.filter(
    (fileType) => supportedFileTypes[fileType],
  );
  const acceptedMimeTypes = acceptedFileTypes
    .map((fileType) => supportedFileTypes[fileType])
    .join(', ');

  const minSize = 0;
  const maxSize = 10000000;
  const maxUploads = props.maxUploads || 9;

  const onDrop = useCallback(
    (acceptedFiles) => {
      const maxCountReached = uploadsCount + acceptedFiles.length > maxUploads;

      if (maxCountReached) {
        onError({
          fullMessage: (
            <Fragment>
              There is a maximum of{' '}
              <span className='tw-font-medium'>{`${maxUploads} files`}</span>{' '}
              per item
            </Fragment>
          ),
        });
      } else {
        acceptedFiles.forEach((file) => onUpload(file));
      }
    },
    [uploadsCount],
  );

  const { getRootProps, getInputProps, isDragActive, open, rejectedFiles } =
    useDropzone({
      onDrop: onDrop,
      accept: acceptedMimeTypes,
      minSize: minSize,
      maxSize: maxSize,
      noClick: true,
      noKeyboard: true,
    });

  useEffect(() => {
    rejectedFiles.forEach((file) => {
      onError({
        fullMessage: (
          <ValidationErrorMessage
            filename={file.name}
            typeNotAccepted={
              !file.type || !acceptedMimeTypes.includes(file.type)
            }
            maxSizeReached={file.size > maxSize}
          />
        ),
      });
    });
  }, [rejectedFiles]);

  // initialValue is provided though not used, in order to not skip the first iteration
  const formattedFileTypes = acceptedFileTypes.reduce(
    (accumulator, currentValue, index, collection) => {
      const formattedFileType = currentValue.toUpperCase();
      if (index === 0) return formattedFileType;
      if (index === collection.length - 1)
        return `${accumulator} or ${formattedFileType}`;
      return `${accumulator}, ${formattedFileType}`;
    },
    true,
  );

  return (
    <div
      {...getRootProps({
        className: `attachments__item attachments__item--${mode}-mode${props.mode !== 'solo' ? ' attachments__item--static' : ''}`,
      })}
    >
      <div
        className={`attachments__uploader tw-border-grey-300${isDragActive ? ' tw-border-solid tw-border-grey-400' : ''}${fieldInvalid ? ' tw-border-red-600' : ''}`}
      >
        <input {...getInputProps(props.externalInputProps)} />
        {isDragActive ?
          <Fragment>
            <div className='attachments__uploader-instructions'>
              <DropIcon
                className='attachments__drop-icon [&_circle]:tw-fill-blue-500'
                height={40}
                width={40}
              />
              Drop files here to
              <br />
              upload them
            </div>
            <div className='attachments__uploader-information tw-text-s tw-text-grey-500 tw-tracking-wide'>
              You can drop multiple files
              <br />
              at the same time
            </div>
          </Fragment>
        : <Fragment>
            <div className='attachments__uploader-instructions'>
              {props.showIcon && (
                <FilesIcon
                  className='attachments__files-icon'
                  height={32}
                  width={38}
                />
              )}
              Drag & drop files here or
              <br />
              <a
                className='attachments__uploader-open tw-text-blue-500 hover:tw-text-blue-300 active:tw-text-blue-300 tw-font-medium'
                onClick={open}
              >
                upload from device
              </a>
            </div>
            <div className='attachments__uploader-information tw-text-s tw-text-grey-500 tw-tracking-wide'>
              {formattedFileTypes}
              <br />
              Max file size 10MB
            </div>
          </Fragment>
        }

        {isCancellable[mode] && uploadsCount === 0 && (
          <div className='attachments__uploader-cancellation'>
            <a
              className='attachments__uploader-cancel tw-text-blue-500 hover:tw-text-blue-300 active:tw-text-blue-300 tw-font-medium'
              onClick={onCancel}
            >
              Cancel
            </a>
          </div>
        )}
      </div>
    </div>
  );
}

Uploader.propTypes = {
  externalInputProps: PropTypes.object,
  mode: PropTypes.string,
  uploadsCount: PropTypes.number.isRequired,
  onError: PropTypes.func.isRequired,
  onCancel: PropTypes.func,
  onUpload: PropTypes.func.isRequired,
  maxUploads: PropTypes.number,
};

Uploader.defaultProps = {
  mode: 'default',
  fileTypes: ['png', 'jpg', 'pdf'],
  static: true,
  showIcon: true,
  externalInputProps: {},
};
