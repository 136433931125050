import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import Tooltip from 'components/application/Tooltip';
import SmallStubPill from 'components/application/pills/SmallStubPill';

const colorClassName = {
  green: 'tw-text-green-800 tw-bg-green-025',
  amber: 'tw-text-amber-800 tw-bg-amber-025',
};
export default function ELearningCourseAutoEnrolsPill({ color, tooltipText }) {
  return (
    <span className='m-l-8'>
      <Tooltip placement='top' trigger='hover' tooltip={tooltipText}>
        <SmallStubPill className={classNames(colorClassName[color])}>
          Auto-enrols
        </SmallStubPill>
      </Tooltip>
    </span>
  );
}

ELearningCourseAutoEnrolsPill.propTypes = {
  color: PropTypes.string,
  tooltipText: PropTypes.string,
};
