import React from 'react';
import PropTypes from 'prop-types';
import voca from 'voca';

import ModalBox from 'components/application/ModalBox';
import TextButton from 'components/application/buttons/TextButton';
import FilledButton from 'components/application/buttons/FilledButton';

import TickIcon from '-!svg-react-loader?name=TickIcon!icons/ic-tick.svg';
import CrossIcon from '-!svg-react-loader?name=CrossIcon!icons/ic-close.svg';
import ProgressIcon from '-!svg-react-loader?name=LoadingIcon!icons/progress.svg';

function HeadingText({ progress, type }) {
  switch (progress) {
    case 'generating':
      return (
        <div className='tw-flex tw-items-center tw-h-7'>
          Generating {voca.kebabCase(type).replace(/-/g, ' ')} report...
        </div>
      );
    case 'downloadReady':
      return (
        <div className='tw-flex tw-items-center tw-h-7'>
          <div className='tw-flex tw-justify-center tw-items-center tw-bg-green-500 tw-rounded-full tw-w-7 tw-h-7 tw-mr-3'>
            <TickIcon
              className='[&_polygon]:tw-fill-white'
              width={20}
              height={20}
            />
          </div>
          <div>Report ready to download</div>
        </div>
      );
    case 'noResults':
      return (
        <div className='tw-flex tw-items-center tw-h-7'>
          <div className='tw-flex tw-justify-center tw-items-center tw-bg-red-600 tw-rounded-full tw-w-7 tw-h-7 tw-mr-3'>
            <CrossIcon
              className='[&_polygon]:tw-fill-white'
              width={24}
              height={24}
            />
          </div>
          <div>Report contains no data</div>
        </div>
      );
    case 'failed':
      return (
        <div className='tw-flex tw-items-center tw-h-7'>
          <div className='tw-flex tw-justify-center tw-items-center tw-bg-red-600 tw-rounded-full tw-w-7 tw-h-7 tw-mr-3'>
            <CrossIcon
              className='[&_polygon]:tw-fill-white'
              width={24}
              height={24}
            />
          </div>
          <div>Report could not be generated</div>
        </div>
      );
    default:
      return null;
  }
}

const bodyText = ({ progress }) => {
  switch (progress) {
    case 'generating':
      return 'Your report is being generated and can take a few minutes depending on the size of the report.';
    case 'downloadReady':
      return 'After downloading the report, the file can be opened in any spreadsheet software such as Excel or Google Sheets.';
    case 'noResults':
      return 'The report doesn’t contain any data. Please check your filters and try generating the report again.';
    case 'failed':
      return 'There was a problem generating your report. Please try again or contact support for help.';
    default:
      return null;
  }
};

const buttonText = ({ progress }) => {
  switch (progress) {
    case 'generating':
      return 'Please wait...';
    case 'downloadReady':
      return 'Download report';
    case 'failed':
      return 'Try again';
    default:
      return null;
  }
};

const onButtonClick = ({ progress, onDownload, onTryAgain }) => {
  switch (progress) {
    case 'generating':
      return () => {};
    case 'downloadReady':
      return onDownload;
    case 'failed':
      return onTryAgain;
    default:
      return null;
  }
};

export default function ReportGeneratorModal(props) {
  const { type, isOpen, progress, onCancel, onDownload, onTryAgain } = props;

  const customFooter = (
    <div className='modalbox__footer-actions modalbox__footer-actions--right'>
      <TextButton size='md' color='blue' className='m-r-12' onClick={onCancel}>
        Cancel
      </TextButton>
      {!(progress == 'noResults') && (
        <FilledButton
          color='mint'
          disabled={['initial', 'generating'].includes(progress)}
          onClick={onButtonClick({ progress, onDownload, onTryAgain })}
        >
          <ProgressIcon
            width={18}
            height={18}
            className={`${progress === 'generating' ? 'tw-mr-1' : 'hide'}`}
          />
          {buttonText({ progress })}
        </FilledButton>
      )}
    </div>
  );

  return (
    <ModalBox
      mode='flexible'
      isOpen={isOpen}
      onClose={onCancel}
      customFooter={customFooter}
    >
      <div className='p-32'>
        <h2 className='tw-text-xl tw-font-semibold tw-tracking-tight tw-text-grey-900 tw-m-0 tw-p-0 tw-mb-6'>
          <HeadingText progress={progress} type={type} />
        </h2>
        <p className='tw-m-0 tw-p-0'>{bodyText({ progress })}</p>
      </div>
    </ModalBox>
  );
}

ReportGeneratorModal.propTypes = {
  type: PropTypes.oneOf([
    'completedTraining',
    'currentTrainingStatus',
    'expiringTraining',
  ]).isRequired,
  isOpen: PropTypes.bool.isRequired,
  progress: PropTypes.oneOf([
    'initial',
    'generating',
    'downloadReady',
    'noResults',
    'failed',
  ]).isRequired,
  onCancel: PropTypes.func.isRequired,
  onDownload: PropTypes.func.isRequired,
  onTryAgain: PropTypes.func.isRequired,
};
