import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import CopyIcon from '-!svg-react-loader?name=CopyIcon!icons/copy.svg';
import TickIcon from '-!svg-react-loader?name=TickIcon!icons/ic-tick.svg';

export default function PersonalInfo({
  label,
  text,
  copyable,
  isTruncated,
  isCompact,
  hideIcon,
  preserveWhitespace,
}) {
  const [copied, setCopied] = useState(false);

  function handleMouseEnter() {
    setCopied(false);
  }

  return (
    <div
      className={`personal-info${isCompact ? ' personal-info--compact' : ''}`}
    >
      <div className='personal-info__info-container'>
        <div className='personal-info__label tw-text-grey-900 tw-font-medium'>
          {label}
        </div>
        <div
          className={`personal-info__text${text ? '' : ' tw-text-grey-500 personal-info__text--not-added'}${isCompact ? ' personal-info__text--sm' : ''}${`${preserveWhitespace ? ' personal-info__text--whitespace' : ''}`}`}
        >
          <span className={`${isTruncated ? 'truncated-text-container' : ''}`}>
            {text || 'Not added'}
          </span>
        </div>
      </div>
      {text && copyable && (
        <div
          className={`copyable copyable__hit-area tw-group/hit m-t-20 m-l-4 tw-text-s tw-font-medium tw-tracking-wide${hideIcon ? ' copyable--hidden' : ''}`}
          onMouseEnter={handleMouseEnter}
        >
          <div className='copyable__copy-icon-wrapper'>
            <CopyToClipboard text={text} onCopy={() => setCopied(true)}>
              <CopyIcon
                className='[&_path]:tw-fill-grey-500 group-hover/hit:[&_path]:tw-fill-blue-500'
                width={20}
                height={20}
              />
            </CopyToClipboard>
          </div>
          <div
            className={`copyable__tooltip tw-text-white tw-bg-grey-900 copyable__tooltip--right${copied ? ' copyable__tooltip--ease-out' : ''}`}
          >
            {copied ?
              <div className='copyable__tooltip-content'>
                <TickIcon className='copyable__tick-icon [&_polygon]:tw-fill-white' />
                <span>Copied to clipboard</span>
              </div>
            : <span className='copyable__tooltip-content'>
                <span>Click to copy to clipboard</span>
              </span>
            }
          </div>
        </div>
      )}
    </div>
  );
}

PersonalInfo.propTypes = {
  label: PropTypes.string.isRequired,
  text: PropTypes.node,
  copyable: PropTypes.bool,
  isTruncated: PropTypes.bool,
};

PersonalInfo.defaultProps = {
  copyable: false,
  isCompact: false,
  isTruncated: true,
};
