import React from 'react';
import PropTypes from 'prop-types';
import TableCell from 'components/application/TableCell';
import PersonnelTrainingStatusIndicator from 'components/personnel/PersonnelTrainingStatusIndicator';
import { useCurrentActor } from 'components/contexts/CurrentActor';

export default function RolesRow(props) {
  const currentActor = useCurrentActor();

  const resetCurrentRole = function () {
    const attributes = props.role.attributes;

    props.setCurrentRole({
      id: props.role.id,
      position: attributes.position || '',
      personnelCount: attributes.personnelCount || 0,
      courseCount: attributes.courseCount,
      isDeleteProhibited: attributes.isDeleteProhibited,
      courseCompanyRoles: [],
    });

    props.resetRequestError();
  };

  const handleShowRole = () => {
    resetCurrentRole();
    props.setSidePanelContext('show');
    props.openSidePanel();
  };

  const roleAttributes = props.role.attributes;

  return (
    <tr
      className='handshq__tr--clickable fh-49 hover:tw-bg-grey-025'
      onClick={handleShowRole}
    >
      {currentActor.isAllowedFeature('training_register') && (
        <TableCell justifyContent='center'>
          <PersonnelTrainingStatusIndicator
            status={roleAttributes.trainingStatus}
          />
        </TableCell>
      )}
      <TableCell>
        <span className='truncated-text-container tw-text-grey-900 tw-text-m tw-font-medium'>
          {roleAttributes.position}
        </span>
      </TableCell>
      {currentActor.isAllowedFeature('training_register') && (
        <TableCell justifyContent='center'>
          <div className='tw-text-m'>{roleAttributes.courseCount}</div>
        </TableCell>
      )}
      <TableCell justifyContent='center'>
        <div className='tw-text-m'>{roleAttributes.personnelCount}</div>
      </TableCell>
    </tr>
  );
}

RolesRow.propTypes = {
  role: PropTypes.object.isRequired,
  setSidePanelContext: PropTypes.func.isRequired,
  setCurrentRole: PropTypes.func.isRequired,
  openSidePanel: PropTypes.func.isRequired,
};
