import React from 'react';
import PropTypes from 'prop-types';

import ELearningCourseEnrolledPill from 'components/courses/pills/ELearningCourseEnrolledPill';
import ELearningCourseInProgressPill from 'components/courses/pills/ELearningCourseInProgressPill';
import RegularCourseBookedPill from 'components/courses/pills/RegularCourseBookedPill';

export default function CourseBookedProgressPill(props) {
  const { isELearningProviderSelected, booking, registration } = props;

  return (
    <span className='m-l-8'>
      {isELearningProviderSelected ?
        registration ?
          <ELearningCourseInProgressPill />
        : <ELearningCourseEnrolledPill />
      : <RegularCourseBookedPill booking={booking} />}
    </span>
  );
}

CourseBookedProgressPill.propTypes = {
  isELearningProviderSelected: PropTypes.bool.isRequired,
  booking: PropTypes.object.isRequired,
  registration: PropTypes.object,
};
