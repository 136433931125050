import React from 'react';
import PropTypes from 'prop-types';
import RequestErrorMessage from 'components/application/RequestErrorMessage';

export default function ErrorMessage({
  wrapperClassName,
  modifiers = '',
  isFallback = false,
  validationErrors,
  withSeparateNestedErrors = false,
}) {
  return (
    (isFallback || Object.keys(validationErrors).length > 0) && (
      <div className={wrapperClassName}>
        <div
          className={`tw-border-0 tw-rounded-lg tw-text-red-800 tw-bg-red-025 tw-p-3${modifiers ? ` ${modifiers}` : ''}`}
        >
          <RequestErrorMessage
            isFallback={isFallback}
            validationErrors={validationErrors}
            withSeparateNestedErrors={withSeparateNestedErrors}
          />
        </div>
      </div>
    )
  );
}

ErrorMessage.propTypes = {
  wrapperClassName: PropTypes.string,
  modifiers: PropTypes.string,
  isFallback: PropTypes.bool,
  validationErrors: PropTypes.object,
  withSeparateNestedErrors: PropTypes.bool,
};
