import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { isHrefOrOnClickRequired } from './propTypes';

export default function TextButton({
  children,
  className,
  color,
  size,
  disabled = false,
  type = 'button',
  ...restProps
}) {
  const attributes = {
    className: classNames(
      'tw-apply-text-button',
      {
        [`tw-apply-text-button--${size}`]: !!size,
        [`tw-apply-text-button--${color}`]: !!color,
      },
      className,
    ),
    ...restProps,
  };

  return restProps.href ?
      <a {...attributes} data-disabled={disabled}>
        {children}
      </a>
    : <button {...attributes} disabled={disabled} type={type}>
        {children}
      </button>;
}

TextButton.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  color: PropTypes.oneOf(['blue', 'red']),
  disabled: PropTypes.bool,
  href: isHrefOrOnClickRequired,
  onClick: isHrefOrOnClickRequired,
  size: PropTypes.oneOf(['sm', 'md', 'full']),
  type: PropTypes.string,
};
