import React from 'react';
import PropTypes from 'prop-types';
import Table from 'components/application/Table';
import BookingsRow from 'components/bookings/BookingsRow';
import {
  resourcesAsResourceMap,
  resourceShape,
} from 'components/helpers/serialisableResources';
import { useTabContext } from 'components/contexts/TabContext';

export default function BookingsTable({ bookingConnections, onRowClick }) {
  const tabContext = useTabContext();

  // TODO decide on mutate vs copy
  const resourceMap = resourcesAsResourceMap([
    'course',
    'booking',
    'bookingGroup',
    'personnel',
  ]);

  bookingConnections.included.forEach((inclusion) => {
    if (resourceMap[inclusion.type])
      resourceMap[inclusion.type][inclusion.id] = inclusion;
  });

  const bookingConnectionsWithAssociatedRecords = bookingConnections.data.map(
    (bookingConnection) => {
      const bookingSourceReference =
        bookingConnection.relationships.source.data;
      const bookingSource =
        resourceMap[bookingSourceReference.type][bookingSourceReference.id];
      const course =
        resourceMap['course'][bookingSource.relationships.course.data.id];

      const personnel =
        bookingSource.type == 'bookingGroup' ?
          bookingSource.relationships.limitedPersonnel.data.map(
            (personnel) => resourceMap['personnel'][personnel.id],
          )
        : [
            resourceMap['personnel'][
              bookingSource.relationships.personnel.data.id
            ],
          ];

      return {
        bookingConnection,
        bookingSource,
        course,
        personnel,
      };
    },
  );

  const columnHeaderName = {
    'Past bookings': 'Method',
    eLearning: 'Provider',
    'Upcoming bookings': '',
  }[tabContext.selectedTabName];

  return (
    <Table
      headers={
        <tr>
          <th className='tw-text-s tw-text-grey-900 tw-font-medium tw-tracking-wide tw-w-auto'>
            Course
          </th>
          <th className='tw-text-s tw-text-grey-900 tw-font-medium tw-tracking-wide tw-w-auto'>
            Personnel
          </th>
          <th className='tw-text-s tw-text-grey-900 tw-font-medium tw-tracking-wide tw-w-30'>
            {columnHeaderName}
          </th>
          <th className='tw-text-s tw-text-grey-900 tw-font-medium tw-tracking-wide tw-w-50'>
            {tabContext.selectedTabName === 'eLearning' ?
              'Status'
            : 'Training date'}
          </th>
        </tr>
      }
      rows={bookingConnectionsWithAssociatedRecords.map(
        (bookingConnectionDetails) => (
          <BookingsRow
            key={bookingConnectionDetails.bookingConnection.id}
            bookingConnection={bookingConnectionDetails.bookingConnection}
            bookingSource={bookingConnectionDetails.bookingSource}
            course={bookingConnectionDetails.course}
            personnel={bookingConnectionDetails.personnel}
            onRowClick={onRowClick}
          />
        ),
      )}
    />
  );
}

BookingsTable.propTypes = {
  bookingConnections: PropTypes.shape({
    data: PropTypes.arrayOf(resourceShape('bookingConnection')),
  }),
  onRowClick: PropTypes.func.isRequired,
};
