import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import Tabs from 'components/application/Tabs';
import SubcontractorSidePanelPersonnelTab from './SubcontractorSidePanelPersonnelTab';

import { useCurrentActor } from 'components/contexts/CurrentActor';
import { useBreadBoard } from 'components/contexts/Toaster';

import { useInView } from 'react-intersection-observer';
import 'intersection-observer';

const defaultPersonnel = { loaded: false, collection: [] };
const defaultPersonnelMeta = { currentPage: 0, isLastPage: false };

export default function SubcontractorsShow(props) {
  const { currentSubcontractor, sidePanelIsOpen } = props;

  const currentActor = useCurrentActor();
  const breadBoard = useBreadBoard();

  const [personnel, setPersonnel] = useState(defaultPersonnel);
  const [personnelMeta, setPersonnelMeta] = useState(defaultPersonnelMeta);

  const [loadMoreRef, loadMoreInView] = useInView();
  const [isLoadMorePersonnelActive, setIsLoadMorePersonnelActive] =
    useState(false);

  const fetchPersonnel = () => {
    setIsLoadMorePersonnelActive(false);
    axios
      .get(`/subcontractors/${currentSubcontractor.id}/personnel`, {
        params: { page: parseInt(personnelMeta.currentPage) + 1 },
      })
      .then((response) => {
        setPersonnel((prevState) => ({
          loaded: true,
          collection: prevState.collection.concat(response.data.data),
        }));
        setPersonnelMeta(response.data.meta);
        setIsLoadMorePersonnelActive(true);
      })
      .catch(breadBoard.addInedibleToast);
  };

  const resetPersonnel = () => {
    setPersonnel(defaultPersonnel);
    setPersonnelMeta(defaultPersonnelMeta);
  };

  useEffect(() => {
    if (sidePanelIsOpen) {
      resetPersonnel();
      setIsLoadMorePersonnelActive(true);
    }
  }, [sidePanelIsOpen]);

  useEffect(() => {
    if (currentSubcontractor.id && !personnelMeta.isLastPage && loadMoreInView)
      fetchPersonnel();
  }, [currentSubcontractor.id, personnelMeta.isLastPage, loadMoreInView]);

  return (
    <div className='popup__body-show'>
      <h2
        className={`popup__body-header popup__body-header--height-unset tw-text-grey-900 tw-text-xl tw-font-semibold tw-tracking-tight truncated-text-container--wrapped truncated-text-container--five-lines mh-140`}
      >
        {currentSubcontractor.name}
      </h2>
      <hr className='tw-h-px tw-bg-grey-100 tw-border-0 tw-mt-8 tw-mb-0' />
      {currentActor.isAllowedFeature('training_register') ?
        <div className='popup__body-content p-0'>
          <Tabs
            modifiers={['panelled']}
            navClassName='tw-bg-grey-050'
            activeTabIsReset={sidePanelIsOpen}
          >
            <SubcontractorSidePanelPersonnelTab
              key='personnelTab'
              label='personnel'
              personnel={personnel}
              personnelMeta={personnelMeta}
              loadMoreRef={loadMoreRef}
              isLoadMorePersonnelActive={isLoadMorePersonnelActive}
            />
          </Tabs>
        </div>
      : <div className='popup__body-content p-b-0'>
          <h3 className='popup__body--sub tw-text-l tw-font-semibold'>
            Personnel
          </h3>
          {personnel.loaded && personnel.collection.length === 0 ?
            <span>No personnel are assigned to this sub-contractor</span>
          : <ul className='popup__body--ul'>
              {personnel.collection.map((person, index) => {
                return (
                  <li className='popup__body--li' key={index}>
                    {`${person.attributes.firstName} ${person.attributes.lastName}`}
                  </li>
                );
              })}
              {isLoadMorePersonnelActive && !personnelMeta.isLastPage && (
                <li ref={loadMoreRef} className='popup__body--li'>
                  Loading personnel...
                </li>
              )}
            </ul>
          }
        </div>
      }
    </div>
  );
}

SubcontractorsShow.propTypes = {
  currentSubcontractor: PropTypes.object.isRequired,
  sidePanelIsOpen: PropTypes.bool,
};
