import PropTypes from 'prop-types';
import React from 'react';
import TextArea from 'components/application/TextArea';
import Tooltip from 'components/application/Tooltip';
import TextButton from 'components/application/buttons/TextButton';
import OutlinedButton from 'components/application/buttons/OutlinedButton';

export default function SubmittableField(props) {
  const {
    autoFocus,
    isSubmitDisabled,
    onCancel,
    onChange,
    onSubmit,
    placeholderText,
    submitButtonText,
    value,
  } = props;

  const isSubmittable = Boolean(value) && value.trim().length > 0;

  const handleSubmit = (e) => {
    isSubmittable && onSubmit(e, value);
  };

  return (
    <React.Fragment>
      <TextArea
        autoFocus={autoFocus}
        autoHeight
        labelVisible={false}
        name='submittableFieldValue'
        onChange={onChange}
        placeholderText={placeholderText}
        padded
        smallFontSize
        value={value}
      />
      <TextButton className='tw-mr-3' color='blue' onClick={onCancel} size='sm'>
        Cancel
      </TextButton>
      <Tooltip
        placement='top'
        tooltip="Text field can't be blank"
        trigger={isSubmittable ? 'none' : 'hover'}
      >
        <OutlinedButton
          color='grey'
          disabled={!!(!isSubmittable || isSubmitDisabled)}
          onClick={handleSubmit}
          size='sm'
        >
          {submitButtonText}
        </OutlinedButton>
      </Tooltip>
    </React.Fragment>
  );
}

SubmittableField.propTypes = {
  autoFocus: PropTypes.bool,
  isSubmitDisabled: PropTypes.bool,
  onCancel: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  placeholderText: PropTypes.string,
  submitButtonText: PropTypes.string.isRequired,
  value: PropTypes.string,
};

SubmittableField.defaultProps = {
  autoFocus: false,
  placeholderText: null,
  value: '',
};
