import React, { useState } from 'react';
import PropTypes from 'prop-types';

import PersonalInfo from 'components/application/PersonalInfo';
import {
  findCurrentFieldValue,
  teamNamesForCurrentPersonnel,
} from 'components/helpers/personnel';

const moreBreakpoint = 3;

export default function PersonnelDetails(props) {
  const {
    isAllVisible = false,
    isCompact = false,
    availableFields,
    availableFieldOptions,
    currentPersonnel,
    defaultDetails,
    teams = {},
  } = props;

  const [showAllFields, setShowAllFields] = useState(isAllVisible);
  const [showAllTeams, setShowAllTeams] = useState(isAllVisible);

  function findCustomDetails() {
    return availableFields.collection.reduce((list, field) => {
      const text = findCurrentFieldValue(
        field,
        currentPersonnel,
        availableFieldOptions,
      );
      if (text) {
        list.push({ label: field.attributes.name, text: text });
      }
      return list;
    }, []);
  }

  const customDetails = findCustomDetails();
  const allDetails = defaultDetails
    .filter((field) => field.text)
    .concat(customDetails);
  const visibleDetails =
    allDetails.length < moreBreakpoint || showAllFields ?
      allDetails
    : allDetails.slice(0, moreBreakpoint);
  const teamNames = teamNamesForCurrentPersonnel(props.currentPersonnel, teams);
  const visibleTeamNames =
    showAllTeams ? teamNames : teamNames.slice(0, moreBreakpoint);
  const hasNoDetails = allDetails.length === 0 && teamNames.length == 0;

  return (
    <div className={isCompact ? '' : 'tw-py-6 tw-px-5'}>
      {allDetails.length > 0 && (
        <>
          {availableFields.loaded &&
            visibleDetails.map((dataPoint, index) => (
              <PersonalInfo
                key={index}
                preserveWhitespace={true}
                isCompact={isCompact}
                label={dataPoint.label}
                text={dataPoint.text}
                copyable={dataPoint.label === 'Email'}
                hideIcon={true}
                className='tw-mb-2'
              />
            ))}
          {allDetails.length > moreBreakpoint && !showAllFields && (
            <div className='flex flex--vertically-centered tw-mb-2'>
              <button
                className='app-link tw-bg-transparent tw-text-blue-500 hover:tw-text-blue-300 active:tw-text-blue-300 tw-mb-2'
                onClick={() => setShowAllFields(true)}
              >
                Show all ({allDetails.length - moreBreakpoint} more)
              </button>
            </div>
          )}
        </>
      )}
      {teamNames.length > 0 && (
        <PersonalInfo
          preserveWhitespace={true}
          isCompact={isCompact}
          label='Teams'
          text={visibleTeamNames.join('\n')}
          copyable={false}
          hideIcon={true}
          className='tw-mb-2'
        />
      )}
      {teamNames.length > moreBreakpoint && !showAllTeams && (
        <div className='tw-mb-2'>
          <button
            onClick={() => setShowAllTeams(true)}
            className='app-link tw-bg-transparent tw-text-blue-500 hover:tw-text-blue-300 active:tw-text-blue-300 tw-mb-2'
          >
            Show all ({teamNames.length - moreBreakpoint} more)
          </button>
        </div>
      )}
      {hasNoDetails && (
        <div className='popup__body-message tw-text-grey-500'>
          No details added to personnel
        </div>
      )}
    </div>
  );
}

PersonnelDetails.propTypes = {
  isCompact: PropTypes.bool,
  details: PropTypes.array,
  teams: PropTypes.object,
  isAllVisible: PropTypes.bool,
  availableFields: PropTypes.object.isRequired,
  availableFieldOptions: PropTypes.object.isRequired,
  currentPersonnel: PropTypes.object.isRequired,
  defaultDetails: PropTypes.array,
};
