import React from 'react';

import TrashIcon from '-!svg-react-loader?name=TrashIcon!icons/ic-trash.svg';
import TickIcon from '-!svg-react-loader?name=TickIcon!icons/ic-tick.svg';
import OutlinedButton from 'components/application/buttons/OutlinedButton';

export default function CoshhRow({
  masterCoshhDocument,
  onAdd,
  onEdit,
  onDelete,
  addedToProject,
}) {
  return (
    <>
      <tr className='handshq__tr--taller'>
        <td className='tw-border-grey-100'>
          {addedToProject && (
            <div className='fh-32 flex flex--vertically-centered'>
              <div className='svg-circle__icon tw-bg-blue-400'>
                <TickIcon
                  className='[&_polygon]:tw-fill-white'
                  width={20}
                  height={20}
                />
              </div>
            </div>
          )}
        </td>
        <td className='tw-border-grey-100 tw-text-grey-900 py-4'>
          <div className='fh-40 flex flex--vertically-centered'>
            <span className='truncated-text-container--wrapped truncated-text-container--two-lines'>
              {masterCoshhDocument.attributes.title}
            </span>
          </div>
        </td>
        <td className='tw-border-grey-100 py-4'>
          <div className='fh-40 flex flex--vertically-centered'>
            <span className='truncated-text-container--wrapped truncated-text-container--two-lines'>
              {masterCoshhDocument.attributes.manufacturer}
            </span>
          </div>
        </td>
        <td className='tw-border-grey-100'>
          <div className='fh-32 flex flex--vertically-centered'>
            {masterCoshhDocument.attributes.reference}
          </div>
        </td>
        <td className='handshq__td-button-cell--align-right tw-border-grey-100'>
          <div className='flex flex--vertically-centered flex--justify-content__flex-end'>
            {addedToProject ?
              <div className='flex fh-32 fw-122'>
                <OutlinedButton
                  className='tw-justify-center tw-h-full tw-w-full m-r-8'
                  color='grey'
                  onClick={() => onEdit(masterCoshhDocument)}
                >
                  Edit
                </OutlinedButton>
                <button
                  className='tw-inline-flex tw-items-center tw-justify-center tw-h-8 tw-w-8 tw-p-1 tw-border-0 tw-rounded-lg tw-bg-transparent [&_svg_path]:tw-fill-grey-500 hover:tw-bg-red-025 [&_svg_path]:hover:tw-fill-red-600 active:tw-bg-red-050 [&_svg_path]:active:tw-fill-red-600'
                  onClick={() => onDelete(masterCoshhDocument)}
                >
                  <TrashIcon width={24} height={24} />
                </button>
              </div>
            : <div className='fh-32 fw-122'>
                <OutlinedButton
                  className='tw-justify-center tw-h-full tw-w-full'
                  color='grey'
                  onClick={() => onAdd(masterCoshhDocument)}
                >
                  Add COSHH
                </OutlinedButton>
              </div>
            }
          </div>
        </td>
      </tr>
    </>
  );
}
