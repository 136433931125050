import React from 'react';
import moment from 'moment';

import TableCell from 'components/application/TableCell';
import ClockIcon from '-!svg-react-loader?name=ClockIcon!icons/clock.svg';

export default function TrainingEventRow(props) {
  const date = moment
    .parseZone(props.personnelEvent.createdAt)
    .format('DD MMM YY [at] HH:mm');

  return (
    <tr>
      <TableCell>
        <ClockIcon
          width={20}
          height={20}
          className='layout--ib m-r-12 [&_path]:tw-fill-grey-500'
        />
        <span height={20} className='layout--ib tw-text-m tw-text-grey-900'>
          {date}
        </span>
      </TableCell>
      <TableCell>
        <span className='tw-text-m'>{props.personnelEvent.description}</span>
      </TableCell>
    </tr>
  );
}
