import React from 'react';
import BreadCrumbList from 'components/application/BreadCrumbList';
import ProcoreLogo from '-!svg-react-loader?name=ProcoreLogo!icons/procore_logo.svg';

export const Content = ({
  breadCrumbOptions,
  selectionOptions,
  onTraversalReset,
}) => {
  return (
    <>
      <div className='p-32'>
        <div className='flex'>
          <h4 className='tw-text-xl tw-text-grey-900 tw-font-semibold tw-tracking-tight m-t-0 m-b-16'>
            Select destination folder
          </h4>
        </div>
        <p className='m-b-0'>
          Navigate to the required Procore project and select the folder where
          you would like your HandsHQ documents to be sent
        </p>
      </div>
      <hr className='tw-h-px tw-bg-grey-100 tw-m-0 tw-border-0' />
      <div className='p-32'>
        <div className='flex flex--vertically-centered fh-50'>
          <ProcoreLogo
            className='handshq--hoverable'
            onClick={onTraversalReset}
            height='12px'
            width='12px'
          />
          <div className='m-l-8'>
            {<BreadCrumbList children={breadCrumbOptions} />}
          </div>
        </div>
        <div className='b-standard fh-244 overflow-auto tw-border-grey-100 tw-bg-grey-025'>
          {selectionOptions}
        </div>
      </div>
    </>
  );
};
