import React from 'react';
import AppWrapper from 'components/apps/AppWrapper';
import ProjectPersonnelTab from 'components/projectPersonnel/ProjectPersonnelTab';

// could potentially move more into here and portal around if necessary
export default function ProjectApp({ wrappedProps, contextProps }) {
  return (
    <AppWrapper {...contextProps}>
      <ProjectPersonnelTab {...wrappedProps} />
    </AppWrapper>
  );
}
