import React, { useState, useEffect } from 'react';
import { escapeDoubleQuotes } from 'components/helpers/strings.js';
import { asDate } from 'components/helpers/dates';
import DispatchRow from 'components/projectActivity/DispatchRow';
import ClockIcon from '-!svg-react-loader?name=ClockIcon!icons/clock.svg';
import OutlinedButton from 'components/application/buttons/OutlinedButton';
import DisableIcon from '-!svg-react-loader?name=DisableIcon!icons/ic-disable.svg';
import DisableLinkModal from 'components/projectActivity/DisableLinkModal';

export default function ProjectMessageRow(props) {
  const { history, showVersionNumberColumn, message, addInedibleToast } = props;

  const [messageStatus, setMessageStatus] = useState('');
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const openModal = () => {
    setModalIsOpen(true);
  };

  useEffect(() => {
    determineMessageStatus(message.message);
  }, []);

  const determineMessageStatus = (currentMessage) => {
    if (currentMessage.attributes['expired?']) {
      setMessageStatus('expired');
    } else if (currentMessage.attributes['disabled']) {
      setMessageStatus('disabled');
    } else {
      setMessageStatus('active');
    }
  };

  const disableLink = () => {
    axios
      .patch(`/messages/${history.messageId}`, { disabled: true })
      .then((response) => {
        determineMessageStatus(response.data.data);
      })
      .catch(addInedibleToast);
  };

  const disableLinkButton = (
    <OutlinedButton size='sm' color='grey' onClick={openModal}>
      <DisableIcon className='m-r-4 m-l--6' width={20} height={20} />
      <span>Disable link</span>
    </OutlinedButton>
  );

  return (
    <>
      <tr className='handshq__tr--top-aligned hover:tw-bg-grey-025'>
        {showVersionNumberColumn && (
          <td className='tw-font-medium tw-text-grey-900 tw-border-grey-100'>
            {history.displayNumber}
          </td>
        )}

        <td className='tw-border-grey-100'>
          <div className='handshq__cell-content--near-spaced'>
            <ClockIcon
              className='[&_path]:tw-fill-grey-500 m-r-4'
              height={20}
              width={20}
            />
            <span>{asDate(history.createdAt)}</span>
          </div>
        </td>

        <td className='tw-border-grey-100'>
          <div className='m-b-4'>
            {history.nadminUser ?
              `${history.nadminUser} on behalf of ${escapeDoubleQuotes(history.reason)}`
            : escapeDoubleQuotes(history.reason)}
          </div>
          {message &&
            message.dispatches.map((dispatch) => {
              return <DispatchRow dispatch={dispatch} key={dispatch.id} />;
            })}
        </td>
        {messageStatus === 'active' ?
          <td className='handshq__td-button-cell--align-right tw-border-grey-100'>
            {disableLinkButton}
          </td>
        : <td className='handshq__td-button-cell--align-right tw-border-grey-100'>
            <div>{`Link has ${messageStatus === 'expired' ? 'expired' : 'been disabled'}`}</div>
          </td>
        }
      </tr>
      <div>
        {modalIsOpen && (
          <DisableLinkModal
            messageId={history.messageId}
            isOpen={modalIsOpen}
            setModalIsOpen={setModalIsOpen}
            disableLink={disableLink}
          />
        )}
      </div>
    </>
  );
}
