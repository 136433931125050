import React from 'react';
import PropTypes from 'prop-types';

import CheckboxField from 'components/application/CheckboxField';
import Tooltip from 'components/application/Tooltip';
import CircleQuestion from 'components/application/CircleQuestion';

import { useTrainingRegisterResources } from 'components/contexts/TrainingRegisterResourceManagementContext';
import {
  MultiValueContainer,
  DropdownIndicator,
  Option,
} from 'components/application/CollectionSelect.jsx';
import Select from 'react-select';

const autoEnrolleeScopeMap = {
  employees: 'Employees',
  all: 'Employees and sub-contractors',
};

const courseRequirementScopeMap = {
  required: 'Required',
  all: 'Required or optional',
};

export default function CourseSidePanelAutoEnrolmentForm(props) {
  const {
    hasAutoEnrolBeenActivelySelected,
    autoEnrolEnrolleeScope,
    autoEnrolCourseRequirementScope,
    isExpiringCourse,
    onInputChange,
    onOptionChange,
  } = props;

  const trainingRegisterResourceManagementContext =
    useTrainingRegisterResources();
  const isAutoEnrolmentIncludingMissingTraining =
    trainingRegisterResourceManagementContext.isAutoEnrolmentIncludingMissingTraining;

  const isChecked =
    hasAutoEnrolBeenActivelySelected &&
    (isAutoEnrolmentIncludingMissingTraining || isExpiringCourse);

  const isDisabled = (function determineIsDisabled() {
    return !isAutoEnrolmentIncludingMissingTraining && !isExpiringCourse;
  })();

  return (
    <>
      <div className='tw-flex tw-mb-5 tw-mt-5'>
        <CheckboxField
          className='tw-w-auto'
          label='Auto-enrol personnel'
          name='hasAutoEnrolBeenActivelySelected'
          checked={isChecked}
          disabled={isDisabled}
          onChange={onInputChange}
          height='short'
          customStyling='peer-disabled/input:tw-bg-grey-050 peer-disabled/input:tw-border-grey-300 peer-disabled/input:tw-opacity-100'
          tooltipType={isDisabled ? 'hover' : null}
          tooltip={
            isDisabled ?
              "Auto-enrol can't be enabled for courses that don't expire"
            : null
          }
        />
        <div className='tw-ml-2'>
          <Tooltip
            className='tooltip-dark--max-w-xxs'
            placement='top'
            trigger='hover'
            tooltip='Eligible personnel will be automatically enrolled when their training is expiring'
          >
            <CircleQuestion />
          </Tooltip>
        </div>
      </div>
      {isChecked && (
        <>
          <div className='tw-flex tw-items-center tw-ml-7 tw-mb-3'>
            <label className='tw-font-normal tw-mb-0 tw-mr-2'>For</label>
            <div className='collection-select collection-select--inline-form'>
              <Select
                name='autoEnrolEnrolleeScope'
                options={Object.keys(autoEnrolleeScopeMap).map((option) => {
                  return { label: autoEnrolleeScopeMap[option], value: option };
                })}
                value={{
                  label: autoEnrolleeScopeMap[autoEnrolEnrolleeScope],
                  value: autoEnrolEnrolleeScope,
                }}
                onChange={onOptionChange}
                isMulti={false}
                isSearchable={false}
                isClearable={false}
                components={{ Option, MultiValueContainer, DropdownIndicator }}
                className='collection-select__select-container'
                classNamePrefix='collection-select'
                closeMenuOnSelect={true}
                hideSelectedOptions={false}
                backspaceRemovesValue={false}
                maxMenuHeight={'unset'}
                customMargin='m-b-32'
                styles={{
                  menu: (baseStyles) => ({
                    ...baseStyles,
                    width: 'max-content',
                    minWidth: '100%',
                  }),
                  /* eslint-disable no-unused-vars */
                  singleValue: ({
                    maxWidth,
                    position,
                    top,
                    transform,
                    ...otherStyles
                  }) => ({ ...otherStyles }),
                  /* eslint-enable no-unused-vars */
                }}
              />
            </div>
          </div>
          <div className='tw-flex tw-items-center tw-ml-7 tw-mb-3'>
            <label className='tw-font-normal tw-mb-0 tw-mr-2'>
              When course is
            </label>
            <div className='collection-select collection-select--inline-form'>
              <Select
                name='autoEnrolCourseRequirementScope'
                options={Object.keys(courseRequirementScopeMap).map(
                  (option) => {
                    return {
                      label: courseRequirementScopeMap[option],
                      value: option,
                    };
                  },
                )}
                value={{
                  label:
                    courseRequirementScopeMap[autoEnrolCourseRequirementScope],
                  value: autoEnrolCourseRequirementScope,
                }}
                onChange={onOptionChange}
                isMulti={false}
                isSearchable={false}
                isClearable={false}
                components={{ Option, MultiValueContainer, DropdownIndicator }}
                className='collection-select__select-container'
                classNamePrefix='collection-select'
                closeMenuOnSelect={true}
                hideSelectedOptions={false}
                backspaceRemovesValue={false}
                maxMenuHeight={'unset'}
                customMargin='m-b-32'
                styles={{
                  menu: (baseStyles) => ({
                    ...baseStyles,
                    width: 'max-content',
                    minWidth: '100%',
                  }),
                  /* eslint-disable no-unused-vars */
                  singleValue: ({
                    maxWidth,
                    position,
                    top,
                    transform,
                    ...otherStyles
                  }) => ({ ...otherStyles }),
                  /* eslint-enable no-unused-vars */
                }}
              />
            </div>
          </div>
        </>
      )}
    </>
  );
}

CourseSidePanelAutoEnrolmentForm.propTypes = {
  hasAutoEnrolBeenActivelySelected: PropTypes.bool.isRequired,
  autoEnrolEnrolleeScope: PropTypes.string.isRequired,
  autoEnrolCourseRequirementScope: PropTypes.string.isRequired,
  isExpiringCourse: PropTypes.bool.isRequired,
  onInputChange: PropTypes.func.isRequired,
  onOptionChange: PropTypes.func.isRequired,
};
