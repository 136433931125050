import React from 'react';
import PropTypes from 'prop-types';

import SubArrowIcon from '-!svg-react-loader?name=SubArrowIcon!icons/sub-arrow.svg';

export default function CountDisplay({
  countKey,
  dotClassName,
  name,
  count,
  isSub = false,
  highlightedKey,
  onKeyHighlight,
  onKeyUnhighlight,
  onClick,
}) {
  const isKeyHighlighted = countKey === highlightedKey;

  return (
    <div
      data-element-name='count-display'
      className={`tw-flex tw-items-center tw-h-8 tw-px-3 tw-py-2 tw-rounded-lg tw-transition-colors tw-duration-200 ${count ? 'tw-cursor-pointer' : 'tw-cursor-default'} ${isKeyHighlighted ? 'tw-bg-blue-025' : 'tw-bg-transparent'}`}
      onMouseEnter={() =>
        count ? onKeyHighlight(countKey) : onKeyUnhighlight()
      }
      onClick={count ? onClick : undefined}
    >
      {isSub && (
        <SubArrowIcon
          width={10}
          height={9}
          className='[&_path]:tw-fill-grey-700 tw-mr-3'
        />
      )}
      <span
        className={`tw-inline-flex tw-h-2.5 tw-w-2.5 tw-rounded-full ${dotClassName} tw-mr-3`}
      />
      <div className='tw-flex-1 tw-text-s tw-font-medium tw-tracking-wide'>
        {name}
      </div>
      <div className='tw-text-s tw-font-normal tw-tracking-wide'>{count}</div>
    </div>
  );
}

CountDisplay.propTypes = {
  countKey: PropTypes.string.isRequired,
  dotClassName: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  count: PropTypes.number.isRequired,
  isSub: PropTypes.bool,
  highlightedKey: PropTypes.string,
  onKeyHighlight: PropTypes.func.isRequired,
  onKeyUnhighlight: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired,
};
