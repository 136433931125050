import React, { useState, useEffect } from 'react';
import ModalBox from 'components/application/ModalBox';
import Download from 'components/attachments/components/Download';
import Display from 'components/attachments/components/Display';
import Stepper from 'components/application/Stepper';
import { useStepper } from 'components/hooks/useStepper';
import moment from 'moment';

export default function TrainingModalBox({ data }) {
  const [step, stepDown, stepUp, resetStep] = useStepper(
    data.attachments.length,
  );
  const [isOpen, setIsOpen] = useState(false);
  const openModalBox = () => {
    setIsOpen(true);
  };
  const closeModalBox = () => {
    setIsOpen(false);
  };
  const [timestamp, setTimestamp] = useState(null);

  useEffect(() => {
    if (data.loaded) {
      openModalBox();
    }
  }, [data]);

  useEffect(() => {
    setTimestamp(moment().unix());
    resetStep();
  }, [JSON.stringify(data.attachments)]);

  const selectedAttachment = data.attachments[step - 1];
  const attachmentType =
    selectedAttachment && selectedAttachment.attributes.recordType;
  const evidenceAttachment = () => {
    if (data.attachments.length === 0) {
      return false;
    }
    if (selectedAttachment) {
      return (
        <Display
          recordType={selectedAttachment.attributes.recordType}
          attachedUrl={selectedAttachment.attributes.attachedUrl}
          timestamp={timestamp}
        />
      );
    }
  };

  return (
    <ModalBox
      isOpen={isOpen}
      onClose={closeModalBox}
      secondaryFooter={
        data.context === 'attachments' && (
          <Stepper
            totalSteps={data.attachments.length}
            currentStep={step}
            onStepUp={stepUp}
            onStepDown={stepDown}
          />
        )
      }
    >
      {
        <React.Fragment>
          <div className='modalbox-header tw-border-grey-100'>
            <div className='flex flex--justify-content__space-between flex--vertically-centered'>
              <div className='flex-item flex-item--grow-but-truncate'>
                <h2 className='truncated-text-container tw-text-l tw-text-grey-900 tw-font-semibold m-0 p-0'>{`${data.course && data.course.attributes.name}${data.context === 'notes' ? ' notes' : ''}`}</h2>
              </div>
              {data.context === 'attachments' && selectedAttachment && (
                <Download
                  hashid={selectedAttachment.attributes.hashid}
                  filename={selectedAttachment.attributes.filename}
                  buttonText='Download evidence'
                />
              )}
            </div>
          </div>
          <div
            className={`modalbox-body${attachmentType === 'pdf' && data.context === 'attachments' ? ' p-0' : ''}`}
          >
            {data.context === 'notes' ?
              data.training && data.training.attributes.notes
            : evidenceAttachment()}
          </div>
        </React.Fragment>
      }
    </ModalBox>
  );
}
