function resourcesAsOptions({
  resourcesCollection,
  resourceIdentifier,
  companiesCollection = null,
}) {
  return resourcesCollection.map((resource) => {
    return {
      value: parseInt(resource.id),
      label: resource.attributes[resourceIdentifier],
      sublabel:
        companiesCollection &&
        companiesCollection.find(
          (company) => company.id === String(resource.attributes.companyId),
        ).attributes.name,
    };
  });
}

function selectedResources(assignableResources, assignedIds) {
  return assignableResources.filter((resource) => {
    return assignedIds.includes(parseInt(resource.id));
  });
}

export { resourcesAsOptions, selectedResources };
