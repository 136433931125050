import React, { Fragment } from 'react';

import Toast from 'components/application/Toast';
import CrossIcon from '-!svg-react-loader?name=CrossIcon!icons/ic-close.svg';

export default function ErrorList({ errorKeys, errorData, removeError }) {
  return (
    <ul className='toast-rack'>
      {errorKeys.map((errorKey) => (
        <Toast
          key={errorKey}
          toastKey={errorKey}
          burn={removeError}
          contents={
            <Fragment>
              <div className='toast__icon tw-bg-red-600'>
                <CrossIcon
                  className='[&_polygon]:tw-fill-white'
                  width={20}
                  height={20}
                />
              </div>
              <div className='toast__text'>
                {errorData[errorKey].fullMessage}
              </div>
            </Fragment>
          }
          modifiers='no-undo'
        />
      ))}
    </ul>
  );
}
