import React from 'react';
import ModalBox from 'components/application/ModalBox';
import TextButton from 'components/application/buttons/TextButton';
import FilledButton from 'components/application/buttons/FilledButton';

export default function DisableLinkModal(props) {
  const { isOpen, setModalIsOpen, disableLink } = props;

  const closeModalBox = () => {
    setModalIsOpen(false);
  };

  const handleDisableClick = () => {
    closeModalBox();
    disableLink();
  };

  const customFooter = (
    <div className='modalbox__footer-actions modalbox__footer-actions--right'>
      <TextButton
        size='md'
        color='blue'
        className='m-r-12'
        onClick={closeModalBox}
      >
        Cancel
      </TextButton>
      <FilledButton color='red' onClick={handleDisableClick}>
        Yes, disable link
      </FilledButton>
    </div>
  );

  return (
    <ModalBox
      mode='letterbox'
      isOpen={isOpen}
      onClose={closeModalBox}
      customFooter={customFooter}
      usingStandardDimensions={true}
    >
      <React.Fragment>
        <div className='modalbox-header tw-border-grey-100'>
          <h2 className='modalbox-header__title truncated-text-container tw-text-grey-900 tw-text-xl tw-font-semibold tw-tracking-tight'>
            Disable link?
          </h2>
        </div>
        <div className='modalbox-body'>
          The link will be permanently disabled and can't be undone.
        </div>
      </React.Fragment>
    </ModalBox>
  );
}
