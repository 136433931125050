import React from 'react';
import useEscapeKey from 'components/hooks/useEscapeKey';
import useBodyOverflowToggle from 'components/hooks/useBodyOverflowToggle';
import TextButton from 'components/application/buttons/TextButton';

export default function UnsavedChangesModal(props) {
  const { isOpen, onClose, header, body, actionButton } = props;

  useBodyOverflowToggle(isOpen);
  useEscapeKey(onClose, [isOpen]);

  const closeButton = (
    <TextButton
      className='unsaved-changes-modal--cancel-button m-r-12'
      size='md'
      color='blue'
      onClick={onClose}
    >
      Cancel
    </TextButton>
  );

  return (
    <div
      className={`unsaved-changes-modal unsaved-changes-modal--${isOpen ? 'open' : 'close'}`}
    >
      <div className='modalbox__scroller'>
        <div className='popup__backdrop tw-bg-grey-900 tw-opacity-75'></div>
        <div className='unsaved-changes-modal__wrapper'>
          <div className={`unsaved-changes-modal__content tw-bg-white`}>
            <div className='unsaved-changes-modal__header tw-text-l tw-text-grey-900 tw-font-semibold'>
              {header}
            </div>
            <div className={`unsaved-changes-modal__body tw-border-grey-100`}>
              {body}
            </div>
            <div className='unsaved-changes-modal__footer tw-border-grey-100'>
              {closeButton}
              {actionButton}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
