import React from 'react';
import InfoPill from 'components/application/pills/InfoPill';
import AwesomeIcon from '-!svg-react-loader?name=AwesomeIcon!icons/awesome.svg';

export default function NewInfoPill() {
  return (
    <InfoPill
      className='tw-bg-lime-050 tw-text-lime-700 [&_svg_path]:tw-fill-lime-700'
      text={<div className='tw-text-xs tw-tracking-wide'>New</div>}
      icon={<AwesomeIcon className='tw-m-0 tw-mr-[6px]' />}
    />
  );
}
