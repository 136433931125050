import React from 'react';
import PropTypes from 'prop-types';

import ChevronRightIcon from '-!svg-react-loader?height=20px&width=20px&name=ChevronRightIcon!icons/chevron-right.svg';

export default function SelectionRow({
  content,
  showTraverse,
  onSelection,
  onTraversal,
  isSelected,
}) {
  return (
    <div
      onClick={onSelection}
      className={`row-bar tw-border-grey-100 p-t-1 handshq--hoverable b-l-none b-r-none b-t-none hover:tw-bg-blue-025 tw-group/row ${isSelected ? 'tw-bg-blue-025' : 'tw-bg-white'}`}
    >
      <div className='row-bar__content'>
        <span className='trunc-standard tw-text-grey-900'>{content}</span>
      </div>
      <div className={'row-bar__actions'}>
        {showTraverse && (
          <span
            className={`tw-flex tw-border-1 tw-border-solid tw-rounded-sm tw-bg-white group-hover/row:tw-border-grey-100 ${isSelected ? 'tw-border-grey-100' : 'tw-border-white'}`}
            onClick={(e) => {
              if (!onTraversal) return undefined;
              e.stopPropagation();
              onTraversal();
            }}
          >
            <ChevronRightIcon className='[&_polygon]:tw-fill-grey-700' />
          </span>
        )}
      </div>
    </div>
  );
}

SelectionRow.propTypes = {
  content: PropTypes.any,
  showTraverse: PropTypes.bool,
  onSelection: PropTypes.func,
  onTraversal: PropTypes.func,
  isSelected: PropTypes.bool,
};
