import React from 'react';
import PropTypes from 'prop-types';

import { useCurrentActor } from 'components/contexts/CurrentActor';

import RowBar from 'components/application/RowBar';
import SearchField from 'components/application/SearchField';
import Tooltip from 'components/application/Tooltip';
import OutlinedButton from 'components/application/buttons/OutlinedButton';
import AddIcon from '-!svg-react-loader?name=AddIcon!icons/add.svg';

const disabledTooltips = {
  teamsNotLoaded: 'Checking current teams...',
  teamsLimitReached: "You have reached your limit so can't add more teams",
};

export default function TeamsBar({
  teamsSearch,
  teams,
  onSearchInputChange,
  onSearchReset,
  actionButtonsVisible,
  onAddTeamClick,
}) {
  const currentActor = useCurrentActor();
  const teamsTotalCount = teams.loaded && teams.metaData.totalCount;
  const teamAllowance = currentActor.subscription.attributes.teamAllowance;
  const isTeamsLimitReached =
    teamAllowance !== null && teamsTotalCount >= teamAllowance;

  let disabledReason;
  if (!teams.loaded) {
    disabledReason = 'teamsNotLoaded';
  } else if (isTeamsLimitReached) {
    disabledReason = 'teamsLimitReached';
  }

  return (
    <RowBar
      content={
        <SearchField
          value={teamsSearch}
          onChange={onSearchInputChange}
          onReset={onSearchReset}
          name='teamsSearch'
          placeholder='Search teams...'
          additionalClasses='search-field__lg'
        />
      }
      actions={
        actionButtonsVisible && (
          <div className='tooltip-parent'>
            <OutlinedButton
              size='sm'
              color='grey'
              disabled={!!disabledReason}
              onClick={onAddTeamClick}
            >
              <AddIcon className='-tw-ml-2' width={24} height={24} />
              <span>Add new team</span>
            </OutlinedButton>
            {disabledReason && (
              <Tooltip
                placement='top'
                trigger='hover'
                tooltip={disabledTooltips[disabledReason]}
              />
            )}
          </div>
        )
      }
      additionalClasses='tw-p-4 tw-border-b-0'
    />
  );
}

TeamsBar.propTypes = {
  teams: PropTypes.shape({
    loaded: PropTypes.bool.isRequired,
    collection: PropTypes.array.isRequired,
    metaData: PropTypes.shape({
      totalCount: PropTypes.number.isRequired,
    }).isRequired,
  }).isRequired,
  teamsSearch: PropTypes.string.isRequired,
  onSearchInputChange: PropTypes.func.isRequired,
  onSearchReset: PropTypes.func.isRequired,
  onAddTeamClick: PropTypes.func,
  actionButtonsVisible: PropTypes.bool.isRequired,
};
