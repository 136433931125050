import React from 'react';
import AddPersonIcon from '-!svg-react-loader?name=AddPersonIcon!icons/addperson-xl.svg';
import { useCurrentActor } from 'components/contexts/CurrentActor';

export default function ProjectPersonnelBlankNotice() {
  const currentActor = useCurrentActor();
  const callToActionMessage = `Add employees and sub-contractors to include on the ${currentActor.division.attributes.singularRAMSName || 'RAMS'}.`;

  return (
    <section className='section section--notice section--notice--no-top-border-radius tw-bg-white tw-border-grey-100'>
      <AddPersonIcon
        width={64}
        height={64}
        className='[&_path]:tw-fill-grey-500 m-b--8'
      />
      <h2 className='section__header tw-text-grey-900 tw-text-l tw-font-semibold'>
        Add personnel
      </h2>
      <p className='section__content'>{callToActionMessage}</p>
    </section>
  );
}
