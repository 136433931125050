import React, { Fragment, useReducer, useEffect } from 'react';
import PropTypes from 'prop-types';
import { produce } from 'immer';
import moment from 'moment';

import {
  emailProjectPageReducer,
  emailProjectPageInitialStore,
} from 'reducers/emailProjectPageReducer';
import { populateResources } from 'components/helpers/storeHelpers';
import { validEmailRegex } from 'constants/regex';

import ListField from 'components/application/ListField';
import ListSelect from 'components/application/ListSelect';
import CheckboxField from 'components/application/CheckboxField';
import LimitedTextField from 'components/application/LimitedTextField';
import RichTextEditor from 'components/application/RichTextEditor';
import MessageFooter from 'components/MessageFooter';
import useRequestError from 'components/hooks/useRequestError';
import ErrorMessage from 'components/application/ErrorMessage';

const subjectMaxLength = 75;
const removeBrandingHref =
  'https://intercom.help/hands-hq/en/articles/5058888-customise-the-look-branding-of-your-emails-remove-powered-by-handshq';

export default function EmailProjectPage({
  message,
  projectDisplayName,
  trainingRegisterAllowed,
  projectPersonnelExist,
  showPoweredBy,
  previouslyUsedEmailAddresses,
  projectId,
  projectName,
  projectPersonnelTabHidden,
}) {
  const initialStore = produce(emailProjectPageInitialStore, (draftState) => {
    draftState.domain.message.data = message.data;
    populateResources(draftState, message.included);

    const user =
      draftState.domain.userCollection.data[
        draftState.domain.message.data.relationships.user.data.id
      ];

    draftState.application.pending.message = false;
    draftState.application.form.previouslyUsed =
      previouslyUsedEmailAddresses.map((email) => {
        return { value: email };
      });
    draftState.application.form.sendToSelf = true;
    draftState.application.form.subject =
      message.data.attributes.subject.substring(0, subjectMaxLength);
    draftState.application.form.ramsIncluded =
      message.data.attributes.ramsIncluded ||
      !message.data.attributes.trainingReportIncluded;
    draftState.application.form.trainingReportIncluded =
      message.data.attributes.trainingReportIncluded;
    draftState.application.form.evidenceIncluded =
      message.data.attributes.trainingReportIncluded;
    draftState.application.form.message = `<p>Hi,</p><p>Please use the link below to download the documents for ${projectName}.</p><p>Thanks,</p><p>${user.attributes.firstName}</p>`;
  });

  const [
    requestError,
    submitDisabled,
    removeErrorStyling,
    resetRequestError,
    handleRequestError,
  ] = useRequestError();
  const [store, dispatch] = useReducer(emailProjectPageReducer, initialStore);
  const bodyRef = React.useRef();

  const user =
    !store.application.pending.message &&
    store.domain.userCollection.data[
      store.domain.message.data.relationships.user.data.id
    ];
  const version =
    !store.application.pending.message &&
    store.domain.versionCollection.data[
      store.domain.message.data.relationships.version.data.id
    ];
  const formToValues = store.application.form.to.map((to) => to.value);
  const selectableItems = store.application.form.previouslyUsed.filter(
    (pu) => !formToValues.includes(pu.value),
  );
  const sevenDaysTime = moment().add(7, 'days').format('D MMM YYYY');

  const handleInputChange = ({ target }) => {
    const value = target.type === 'checkbox' ? target.checked : target.value;

    dispatch({
      type: 'UPDATE_FORM_FIELD',
      payload: { name: target.name, value: value },
    });
  };

  const handlePasswordRequiredInputChange = (event) => {
    removeErrorStyling({ target: { name: 'password' } });

    handleInputChange(event);
  };

  const handleListChange = ({ newItems }, { target }) => {
    dispatch({
      type: 'UPDATE_FORM_FIELD',
      payload: { name: target.name, value: newItems },
    });
  };

  const handleEditorChange = ({ content, target }) => {
    dispatch({
      type: 'UPDATE_FORM_FIELD',
      payload: { name: target.name, value: content },
    });
  };

  const isValidListValue = ({ value }) => {
    return validEmailRegex.test(value.toLowerCase());
  };

  useEffect(() => {
    if (submitDisabled) {
      bodyRef.current.scrollIntoView();
    }
  }, [submitDisabled]);

  return (
    !store.application.pending.message && (
      <>
        <div className='app-form__container'>
          <div
            className='app-form__header app-form__header--margin'
            ref={bodyRef}
          >
            <ErrorMessage
              wrapperClassName='app-form__error'
              validationErrors={requestError.validationErrors}
              isFallback={requestError.isFallback}
            />
            <h2 className='tw-text-xl tw-text-grey-900 tw-font-semibold tw-tracking-tight'>
              Email {projectDisplayName}
            </h2>
          </div>
          <div className='app-form tw-border-grey-100 tw-bg-white'>
            <label className='app-form__label m-b-8 tw-text-m tw-font-medium'>
              From
            </label>
            <div className='m-b-40'>
              <span className='app-form__text'>{user.attributes.email}</span>
            </div>
            <label
              className={`app-form__label m-b-8 tw-text-m tw-font-medium after:tw-absolute after:tw-text-grey-500 after:tw-content-['Required'] after:tw-right-0 after:tw-font-normal${requestError.validationErrors.to && requestError.validationErrors.to.fieldHighlighted ? ' after:tw-text-red-600' : ''}`}
            >
              To
            </label>
            <div className='m-b-40'>
              <ListField
                name='to'
                items={store.application.form.to}
                onChange={handleListChange}
                isValidValue={isValidListValue}
                fieldError={requestError.validationErrors.to}
                removeErrorStyling={removeErrorStyling}
              />
              {selectableItems.length > 0 && (
                <div className='m-t-16'>
                  <label className='app-form__label m-b-8 tw-text-m tw-font-medium'>
                    Previously used
                  </label>
                  <div>
                    <ListSelect
                      field='to'
                      items={store.application.form.to}
                      selectableItems={store.application.form.previouslyUsed}
                      onChange={handleListChange}
                      removeErrorStyling={removeErrorStyling}
                    />
                  </div>
                </div>
              )}
            </div>
            <div className='m-b-40'>
              <div className='layout--ib'>
                <CheckboxField
                  label={`Send me a copy (${user.attributes.email})`}
                  value={'sendToSelf'}
                  name='sendToSelf'
                  checked={store.application.form.sendToSelf}
                  onChange={handleInputChange}
                />
              </div>
            </div>
            <label
              className={`app-form__label m-b-8 tw-text-m tw-font-medium after:tw-absolute after:tw-text-grey-500 after:tw-content-['Required'] after:tw-right-0 after:tw-font-normal${requestError.validationErrors.subject && requestError.validationErrors.subject.fieldHighlighted ? ' after:tw-text-red-600' : ''}`}
            >
              Subject
            </label>
            <div className='m-b-40'>
              <LimitedTextField
                name='subject'
                value={store.application.form.subject}
                maxLength={subjectMaxLength}
                onChange={handleInputChange}
                fieldError={requestError.validationErrors.subject}
                removeErrorStyling={removeErrorStyling}
              />
            </div>
            <label className='app-form__label m-b-16 tw-text-m tw-font-medium'>
              Documents
            </label>
            <div className='m-b-40'>
              <div className='layout--ib'>
                <CheckboxField
                  label={`RAMS ${version.attributes.display_number === undefined ? '' : `- version ${version.attributes.display_number}`}`}
                  disabled={!store.application.form.trainingReportIncluded}
                  labelProps={{
                    className: 'hhq-checkbox__label--no-disabled-state',
                  }}
                  value={'ramsIncluded'}
                  name='ramsIncluded'
                  checked={store.application.form.ramsIncluded}
                  onChange={handleInputChange}
                  tooltipType={'hover'}
                  tooltip={
                    !store.application.form.trainingReportIncluded &&
                    'At least 1 document must be included'
                  }
                />
              </div>
              {trainingRegisterAllowed && (
                <div className='m-t-20'>
                  <div className='layout--ib'>
                    <CheckboxField
                      label='Training report'
                      disabled={
                        !store.application.form.ramsIncluded ||
                        !projectPersonnelExist ||
                        projectPersonnelTabHidden
                      }
                      labelProps={
                        !projectPersonnelExist || projectPersonnelTabHidden ?
                          {
                            className:
                              'hhq-checkbox__container--disabled-inactive',
                          }
                        : {
                            className: 'hhq-checkbox__label--no-disabled-state',
                          }
                      }
                      value={'trainingReportIncluded'}
                      name='trainingReportIncluded'
                      checked={store.application.form.trainingReportIncluded}
                      onChange={handleInputChange}
                      tooltipType={'hover'}
                      tooltip={
                        !store.application.form.ramsIncluded ?
                          'At least 1 document must be included'
                        : !projectPersonnelExist || projectPersonnelTabHidden ?
                          `There are no personnel on this ${projectDisplayName}`
                        : undefined
                      }
                    />
                    {store.application.form.trainingReportIncluded && (
                      <div className='layout--ib m-t-20 m-l-32'>
                        <CheckboxField
                          label='Include evidence'
                          value={'evidenceIncluded'}
                          name='evidenceIncluded'
                          checked={store.application.form.evidenceIncluded}
                          onChange={handleInputChange}
                        />
                      </div>
                    )}
                  </div>
                </div>
              )}
            </div>
            <label className='app-form__label m-b-16 tw-text-m tw-font-medium'>
              Message
            </label>
            <div className='m-b-40'>
              <RichTextEditor
                name='message'
                onChange={handleEditorChange}
                options={{ minHeight: 230, toolbar: 'bold italic | bullist' }}
                value={store.application.form.message}
              />
            </div>
            <label className='app-form__label m-b-16 tw-text-m tw-font-medium'>
              Security
            </label>
            <div className='m-b-40'>
              <div className='layout--ib'>
                <CheckboxField
                  label='Require password?'
                  value='passwordRequired'
                  name='passwordRequired'
                  checked={store.application.form.passwordRequired}
                  onChange={handlePasswordRequiredInputChange}
                />
              </div>
              {store.application.form.passwordRequired && (
                <div
                  className={`m-t-16 m-l-32 mw-300 ${
                    requestError.validationErrors.password ?
                      requestError.validationErrors.password.fieldHighlighted ?
                        'field__invalid'
                      : ''
                    : ''
                  }`}
                >
                  <input
                    type='text'
                    className='tw-border-grey-300 hover:tw-border-grey-400 focus-within:tw-border-blue-300 hover:focus-within:tw-border-blue-300 app-form__input-text m-b-8'
                    name='password'
                    value={store.application.form.password}
                    onChange={handleInputChange}
                    onFocus={removeErrorStyling}
                  />
                  <span className='app-form__text--sub tw-text-s tw-font-normal tw-tracking-wide'>
                    The password{' '}
                    <span className='tw-text-s tw-font-medium tw-tracking-wide'>
                      will not
                    </span>{' '}
                    be sent with the email
                  </span>
                </div>
              )}
            </div>
            <label className='app-form__label m-b-8 tw-text-m tw-font-medium'>
              Expiry
            </label>
            <div>
              <span className='app-form__text'>
                The download link will expire on{' '}
                <span className='tw-font-semibold'>{sevenDaysTime}</span> (7
                days time)
              </span>
            </div>
            {showPoweredBy && (
              <Fragment>
                <hr className='m-t-40 m-b-40' />
                <label className='app-form__label m-b-16 tw-text-m tw-font-medium'>
                  Powered by HandsHQ
                </label>
                <div className='m-b-8'>
                  <div className='layout--ib'>
                    <CheckboxField
                      label="Show 'Powered by HandsHQ' on emails and download pages"
                      labelProps={{
                        className: 'hhq-checkbox__label--no-disabled-state',
                      }}
                      value='poweredByHandshq'
                      name='poweredByHandshq'
                      disabled={true}
                      checked={true}
                      onChange={() => {}}
                    />
                  </div>
                </div>
                <div className='m-l-32'>
                  <span className='app-form__text--sub tw-text-s tw-font-normal tw-tracking-wide'>
                    This can be removed by upgrading your plan.{' '}
                  </span>
                  <a
                    className='app-link tw-text-s tw-font-medium tw-tracking-wide tw-align-baseline tw-text-blue-500 hover:tw-text-blue-300 active:tw-text-blue-300'
                    target='blank'
                    href={removeBrandingHref}
                  >
                    Contact support
                  </a>
                  <span className='app-form__text--sub tw-text-s tw-font-normal tw-tracking-wide'>
                    {' '}
                    to find out more.
                  </span>
                </div>
              </Fragment>
            )}
          </div>
        </div>
        <MessageFooter
          data={store.application}
          resetRequestError={resetRequestError}
          submitDisabled={submitDisabled}
          onRequestError={handleRequestError}
          currentUser={user}
          projectId={projectId}
          versionId={message.data.relationships.version.data.id}
        />
      </>
    )
  );
}

EmailProjectPage.propTypes = {
  message: PropTypes.object.isRequired,
  projectDisplayName: PropTypes.string,
  trainingRegisterAllowed: PropTypes.bool.isRequired,
  projectPersonnelExist: PropTypes.bool.isRequired,
  previouslyUsedEmailAddresses: PropTypes.array,
};

EmailProjectPage.defaultProps = {
  projectDisplayName: 'Project',
  previouslyUsedEmailAddresses: [],
};
