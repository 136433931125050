import React from 'react';
import PropTypes from 'prop-types';
import voca from 'voca';

import LifecycleStatusIndicator from 'components/projects/components/LifecycleStatusIndicator';
import RiskAssessmentsStatusIndicator from 'components/projects/components/RiskAssessmentsStatusIndicator';
import ReviewStatusIndicator from 'components/projects/components/ReviewStatusIndicator';
import ApprovalStatusIndicator from 'components/projects/components/ApprovalStatusIndicator';

import TruncatableText from 'components/application/TruncatableText';
import OutlinedButton from 'components/application/buttons/OutlinedButton';

import LoadingIcon from '-!svg-react-loader?name=LoadingIcon!icons/loading.svg';

export default function ProjectsTable({
  projects,
  isResizing,
  bodySize,
  ramsSingularName,
  withClient,
  withRiskAssessments,
  withReviewDate,
  withApproval,
  onProjectClick,
}) {
  const withColumns = {
    withClient: withClient && bodySize.width > 1100,
    withDivision: bodySize.width > 1200,
    withCreator: bodySize.width > 1300,
    withRiskAssessments: withRiskAssessments && bodySize.width > 1000,
    withReviewDate: withReviewDate && bodySize.width > 900,
    withApproval: withApproval && bodySize.width > 800,
  };

  const numDynamicColumns = Object.values({ ...withColumns }).reduce(
    (a, item) => a + item,
    0,
  );

  return (
    <div className='tw-relative tw-w-full tw-h-full'>
      <table className='tw-table-fixed tw-w-full tw-border-separate tw-border-spacing-0'>
        <thead className='tw-sticky tw-top-0 tw-z-[1]'>
          <ProjectsTableHeader
            ramsSingularName={ramsSingularName}
            {...withColumns}
          />
        </thead>
        <tbody>
          {projects.map((project) => (
            <ProjectsTableRow
              key={project.id}
              project={project}
              numDynamicColumns={numDynamicColumns}
              isResizing={isResizing}
              ramsSingularName={ramsSingularName}
              {...withColumns}
              onProjectClick={onProjectClick}
            />
          ))}
        </tbody>
      </table>
      {projects.length === 0 && (
        <div className='tw-absolute tw-top-0 tw-right-0 tw-bottom-0 tw-left-0 tw-flex tw-justify-center tw-items-center'>
          <div className='tw-apply-loading-spinner--blue-light'>
            <LoadingIcon width={36} height={36} />
          </div>
        </div>
      )}
    </div>
  );
}

ProjectsTable.propTypes = {
  projects: PropTypes.array.isRequired,
  isResizing: PropTypes.bool.isRequired,
  bodySize: PropTypes.shape({
    height: PropTypes.number.isRequired,
    width: PropTypes.number.isRequired,
  }),
  ramsSingularName: PropTypes.string.isRequired,
  withClient: PropTypes.bool.isRequired,
  withRiskAssessments: PropTypes.bool.isRequired,
  withReviewDate: PropTypes.bool.isRequired,
  withApproval: PropTypes.bool.isRequired,
  onProjectClick: PropTypes.func,
};

export function ProjectsTableHeader({
  ramsSingularName,
  withClient,
  withDivision,
  withCreator,
  withRiskAssessments,
  withReviewDate,
  withApproval,
}) {
  const tableProjectHeaderClassName =
    withClient || withDivision || withCreator ? 'tw-w-[196px]' : 'tw-auto';

  return (
    <tr className='tw-bg-grey-050'>
      <th className='tw-w-[52px] tw-pl-5 tw-px-2 tw-py-[7px] tw-border-solid tw-border-0 tw-border-t-1 tw-border-b-1 tw-border-grey-100 tw-text-s tw-font-medium tw-tracking-wide tw-text-grey-900'>
        Live
      </th>
      <th
        className={`${tableProjectHeaderClassName} tw-px-2 tw-py-[7px] tw-border-solid tw-border-0 tw-border-t-1 tw-border-b-1 tw-border-grey-100 tw-text-s tw-font-medium tw-tracking-wide tw-text-grey-900`}
      >
        {voca.capitalize(ramsSingularName)}
      </th>
      {withClient && (
        <th className='tw-w-auto tw-px-2 tw-py-[7px] tw-border-solid tw-border-0 tw-border-t-1 tw-border-b-1 tw-border-grey-100 tw-text-s tw-font-medium tw-tracking-wide tw-text-grey-900'>
          Client
        </th>
      )}
      {withDivision && (
        <th className='tw-w-auto tw-px-2 tw-py-[7px] tw-border-solid tw-border-0 tw-border-t-1 tw-border-b-1 tw-border-grey-100 tw-text-s tw-font-medium tw-tracking-wide tw-text-grey-900'>
          Division
        </th>
      )}
      {withCreator && (
        <th className='tw-w-auto tw-px-2 tw-py-[7px] tw-border-solid tw-border-0 tw-border-t-1 tw-border-b-1 tw-border-grey-100 tw-text-s tw-font-medium tw-tracking-wide tw-text-grey-900'>
          Creator
        </th>
      )}
      {withRiskAssessments && (
        <th className='tw-w-[124px] tw-px-2 tw-py-[7px] tw-border-solid tw-border-0 tw-border-t-1 tw-border-b-1 tw-border-grey-100 tw-text-s tw-font-medium tw-tracking-wide tw-text-grey-900'>
          Risk assessments
        </th>
      )}
      {withReviewDate && (
        <th className='tw-w-[96px] tw-px-2 tw-py-[7px] tw-border-solid tw-border-0 tw-border-t-1 tw-border-b-1 tw-border-grey-100 tw-text-s tw-font-medium tw-tracking-wide tw-text-grey-900'>
          Review date
        </th>
      )}
      {withApproval && (
        <th className='tw-w-[116px] tw-px-2 tw-py-[7px] tw-border-solid tw-border-0 tw-border-t-1 tw-border-b-1 tw-border-grey-100 tw-text-s tw-font-medium tw-tracking-wide tw-text-grey-900'>
          Approval
        </th>
      )}
      <th className='tw-w-[148px] tw-pr-5 tw-px-2 tw-py-[7px] tw-border-solid tw-border-0 tw-border-t-1 tw-border-b-1 tw-border-grey-100 tw-text-s tw-font-medium tw-tracking-wide tw-text-grey-900'>
        Actions
      </th>
    </tr>
  );
}

ProjectsTableHeader.propTypes = {
  ramsSingularName: PropTypes.string.isRequired,
  withClient: PropTypes.bool.isRequired,
  withDivision: PropTypes.bool.isRequired,
  withCreator: PropTypes.bool.isRequired,
  withRiskAssessments: PropTypes.bool.isRequired,
  withReviewDate: PropTypes.bool.isRequired,
  withApproval: PropTypes.bool.isRequired,
};

const numFixedColumns = 3;

export function ProjectsTableRow({
  project,
  numDynamicColumns,
  isResizing,
  ramsSingularName,
  withClient,
  withDivision,
  withCreator,
  withRiskAssessments,
  withReviewDate,
  withApproval,
  onProjectClick,
}) {
  const handleProjectClick = () => {
    if (onProjectClick) onProjectClick({ project });

    const projectPath = encodeURIComponent(
      project.archivedAt ?
        `/projects/?tab=archived&search=${project.name}`
      : `/projects/${project.id}`,
    );

    window.location.assign(
      `/companies/${project.companyId}?redirect_to=${projectPath}`,
    );
  };

  return (
    <>
      <tr className='tw-group/row tw-h-12 tw-bg-white hover:tw-bg-grey-025'>
        <td className='tw-p-2 tw-pl-5'>
          {project.lifecycleStatus && (
            <div className='tw-h-8 tw-flex tw-items-center tw-justify-center'>
              <LifecycleStatusIndicator
                status={project.lifecycleStatus}
                ramsSingularName={ramsSingularName}
              />
            </div>
          )}
        </td>
        <td className='tw-p-2'>
          <div className='tw-h-8 tw-flex tw-items-center'>
            <TruncatableText
              key={`${numDynamicColumns}${isResizing}`}
              className='tw-text-m tw-font-medium tw-text-grey-900'
              text={project.name}
            />
          </div>
        </td>
        {withClient && (
          <td className='tw-p-2'>
            {project.client && (
              <div className='tw-h-8 tw-flex tw-items-center'>
                <TruncatableText
                  key={`${numDynamicColumns}${isResizing}`}
                  text={project.client}
                />
              </div>
            )}
          </td>
        )}
        {withDivision && (
          <td className='tw-p-2'>
            <div className='tw-h-8 tw-flex tw-items-center'>
              <TruncatableText
                key={`${numDynamicColumns}${isResizing}`}
                text={project.divisionName}
              />
            </div>
          </td>
        )}
        {withCreator && (
          <td className='tw-p-2'>
            <div className='tw-h-8 tw-flex tw-items-center'>
              <TruncatableText
                key={`${numDynamicColumns}${isResizing}`}
                text={project.creator}
              />
            </div>
          </td>
        )}
        {withRiskAssessments && (
          <td className='tw-p-2'>
            {project.riskAssessmentsStatus && (
              <div className='tw-h-8 tw-flex tw-items-center tw-justify-start'>
                <RiskAssessmentsStatusIndicator
                  status={project.riskAssessmentsStatus}
                />
              </div>
            )}
          </td>
        )}
        {withReviewDate && (
          <td className='tw-p-2'>
            {project.reviewStatus && (
              <div className='tw-h-8 tw-flex tw-items-center tw-justify-start'>
                <ReviewStatusIndicator
                  status={project.reviewStatus}
                  dueDate={project.reviewDueDate}
                />
              </div>
            )}
          </td>
        )}
        {withApproval && (
          <td className='tw-p-2'>
            {project.approvalStatus && (
              <div className='tw-h-8 tw-flex tw-items-center tw-justify-start'>
                <ApprovalStatusIndicator status={project.approvalStatus} />
              </div>
            )}
          </td>
        )}
        <td className='tw-p-2 tw-pr-5'>
          <OutlinedButton
            className='tw-hidden group-hover/row:tw-table-cell tw-w-full'
            size='sm'
            color='grey'
            onClick={handleProjectClick}
          >
            <div className='tw-flex tw-justify-center tw-items-center tw-h-5 tw-w-full'>
              <div className='tw-whitespace-nowrap tw-text-ellipsis tw-overflow-hidden'>
                Go to {project.archivedAt ? 'archive' : ramsSingularName}
              </div>
            </div>
          </OutlinedButton>
        </td>
      </tr>
      <tr>
        <td
          colSpan={numFixedColumns + numDynamicColumns}
          className='tw-h-px tw-border-0 tw-bg-grey-100'
        ></td>
      </tr>
    </>
  );
}

ProjectsTableRow.propTypes = {
  project: PropTypes.shape({
    id: PropTypes.string.isRequired,
    lifecycleStatus: PropTypes.string,
    name: PropTypes.string.isRequired,
    client: PropTypes.string,
    divisionName: PropTypes.string.isRequired,
    creator: PropTypes.string.isRequired,
    riskAssessmentsStatus: PropTypes.string,
    reviewDueDate: PropTypes.string,
    reviewStatus: PropTypes.string,
    approvalStatus: PropTypes.string,
    archivedAt: PropTypes.string,
    companyId: PropTypes.string.isRequired,
  }),
  numDynamicColumns: PropTypes.number.isRequired,
  isResizing: PropTypes.bool,
  ramsSingularName: PropTypes.string.isRequired,
  withClient: PropTypes.bool.isRequired,
  withDivision: PropTypes.bool.isRequired,
  withCreator: PropTypes.bool.isRequired,
  withRiskAssessments: PropTypes.bool.isRequired,
  withReviewDate: PropTypes.bool.isRequired,
  withApproval: PropTypes.bool.isRequired,
  onProjectClick: PropTypes.func,
};
