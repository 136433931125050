import React from 'react';
import PropTypes from 'prop-types';

export default function OtherOptions(props) {
  const blinkClassName = props.blink ? ' other-options--blink' : '';
  const disabledClassName = props.disabled ? ' tw-text-grey-300' : '';

  const dots = [...Array(3)].map((_, index) => {
    return (
      <span
        key={index}
        className="other-options__dot before:tw-content-['\25CF'] before:tw-text-[5px] before:tw-align-middle"
      ></span>
    );
  });

  return (
    <div
      className={`other-options${blinkClassName}${disabledClassName} tw-leading-[5px]`}
    >
      {dots}
    </div>
  );
}

OtherOptions.propTypes = {
  blink: PropTypes.bool.isRequired,
  disabled: PropTypes.bool,
};

OtherOptions.defaultProps = {
  disabled: false,
};
