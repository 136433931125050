import React from 'react';

import NoResultsIcon from '-!svg-react-loader?name=NoResultsIcon!icons/noresults-xl.svg';

export default function MasterCoshhSearchBlankNotice({ masterCoshhSearch }) {
  return (
    <section className='section section--notice section--flush p-t-80 p-b-80 tw-border-grey-100 tw-bg-white'>
      <NoResultsIcon
        width={64}
        height={64}
        className='[&_path]:tw-fill-grey-300'
      />
      <h2 className='section__header p-t-0 tw-text-grey-900 tw-text-l tw-font-semibold'>{`No results found for '${masterCoshhSearch}'`}</h2>
      <p className='section__content m-t-8 m-b-0'>
        Can't find what you're looking for?{' '}
        {
          <a
            className='app-link tw-align-baseline tw-text-blue-500 hover:tw-text-blue-300 active:tw-text-blue-300'
            href='mailto:help@handshq.com'
          >
            Email
          </a>
        }{' '}
        us and we'll add it!
      </p>
    </section>
  );
}
