import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import CloseIcon from '-!svg-react-loader?name=CloseIcon!icons/ic-close.svg';

export default function Toast(props) {
  const [visible, setVisible] = useState(false);
  let burnTimer, animationTimer;

  const startBurning = () => {
    setVisible(false);
    animationTimer = setTimeout(() => {
      props.burn(props.toastKey);
    }, 1000);
  };

  useEffect(() => {
    setVisible(true);
  }, []);

  useEffect(() => {
    if (props.burnTimer) {
      burnTimer = setTimeout(() => {
        startBurning();
      }, props.burnTimer);
    }
    return () => {
      clearTimeout(burnTimer);
      clearTimeout(animationTimer);
    };
  }, []);

  return (
    <li
      className={`toast tw-shadow-md tw-bg-white tw-border-grey-100 toast--${props.modifiers} ${visible ? 'toast--visible' : 'toast--hidden'}`}
    >
      <div className='toast__content'>{props.contents}</div>
      <CloseIcon
        className='toast__close tw-bg-white tw-border-white hover:tw-border-grey-100'
        width={24}
        height={24}
        onClick={startBurning}
      />
    </li>
  );
}

Toast.propTypes = {
  contents: PropTypes.any.isRequired,
  burnTimer: PropTypes.number,
  burn: PropTypes.func,
};

Toast.defaultProps = {
  burnTimer: 20000,
};
