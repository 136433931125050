import React from 'react';
import InfoBanner from 'components/application/InfoBanner';
import ExpandableList from 'components/application/ExpandableList';
import DivisionItem from 'components/riskRegister/sharedResourceNotices/shared/DivisionItem';
import PropTypes from 'prop-types';
import { resourceShape } from 'components/helpers/serialisableResources';

export default function MultiDivisionCategoryNotice({ category, divisions }) {
  return (
    <InfoBanner title={'Editing this category will affect other divisions'}>
      {category.attributes.name} is a category shared with:
      <ExpandableList
        collection={divisions}
        renderItem={(division) => (
          <li key={`li--${division.id}`}>
            <DivisionItem
              division={division}
              scopedCategories={[]}
              riskAssessment={null}
            />
          </li>
        )}
      />
    </InfoBanner>
  );
}

MultiDivisionCategoryNotice.propTypes = {
  category: resourceShape('trade').isRequired,
  divisions: PropTypes.arrayOf(resourceShape('company')).isRequired,
};
