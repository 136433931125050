import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import Select, { components } from 'react-select';
import { useInfiniteQuery } from '@tanstack/react-query';
import _ from 'lodash';

import { useBreadBoard } from 'components/contexts/Toaster';
import CircleQuestion from 'components/application/CircleQuestion';
import Tooltip from 'components/application/Tooltip';
import { DropdownIndicator } from 'components/application/CollectionSelect';

function ManagerOnlySublabel({ name }) {
  return (
    <div className='flex items-center'>
      <span>Will be added as a manager only</span>
      <div className='tw-ml-2'>
        <Tooltip
          placement='top'
          trigger='hover'
          tooltip={`${name} can't be made a member as they do not have a personnel profile in this division`}
        >
          <CircleQuestion size='sm' />
        </Tooltip>
      </div>
    </div>
  );
}

const getNextPageParam = (lastPage, allPages) => {
  const { currentPage, totalPages } = lastPage.meta;
  return currentPage !== totalPages ? allPages.length + 1 : undefined;
};

export default function SelectMembers({ team, onTeamMemberSelect }) {
  const breadBoard = useBreadBoard();

  const [searchText, setSearchText] = useState('');
  const [valueMenuOpen, setValueMenuOpen] = useState(false);
  const [isDebouncing, setIsDebouncing] = useState(false);

  const handleDebounce = (searchText) => {
    setSearchText(searchText);
    setIsDebouncing(false);
  };
  const debounceInput = useRef(
    _.debounce((searchText) => handleDebounce(searchText), 500),
  ).current;

  const setSearchTextDebounced = (searchText) => {
    if (searchText && searchText.length > 0) {
      setIsDebouncing(true);
    }
    debounceInput(searchText);
  };

  const assignableMembersParams = {
    company_id: team.companyId,
    search: searchText,
    nin: team.members.map((member) => member.id),
  };

  const handleMenuOpen = () => setValueMenuOpen(true);
  const handleMenuClose = () => setValueMenuOpen(false);

  const fetchAssignableMembers = (page) =>
    axios.post('/assignable_team_member_searches', {
      ...assignableMembersParams,
      page: page,
    });

  const {
    data: assignableMembers,
    fetchNextPage: fetchNextAssignableMembersPage,
    hasNextPage: assignableMembersHasNextPage,
    isFetching: assignableMembersIsFetching,
  } = useInfiniteQuery({
    queryKey: ['assignableMembers', assignableMembersParams],
    queryFn: async ({ pageParam = 1 }) => {
      const assignableMembersResponse = await fetchAssignableMembers(pageParam);
      return assignableMembersResponse.data;
    },
    getNextPageParam: getNextPageParam,
    enabled: valueMenuOpen,
    onError: breadBoard.addInedibleToast,
  });

  const assignableOptions = (resourceData) => {
    const fullAssignableMembers = resourceData.pages
      .map((page) => page.data)
      .flat();
    const membersList = fullAssignableMembers.map((member) => {
      const managerOnly = member.relationships.personnel.data === null;
      return {
        value: {
          id: member.id,
          userId:
            member.relationships.user.data && member.relationships.user.data.id,
          personnelId:
            member.relationships.personnel.data &&
            member.relationships.personnel.data.id,
          isManager: managerOnly,
        },
        label: `${member.attributes.firstName} ${member.attributes.lastName}`,
        sublabel:
          managerOnly ?
            <ManagerOnlySublabel name={member.attributes.firstName} />
          : '',
      };
    });
    return membersList;
  };

  return (
    <>
      <label
        className='collection-select__label tw-font-medium'
        htmlFor='memberIds'
      >
        Team members
      </label>
      <Select
        className='collection-select__select-container tw-mb-4'
        classNamePrefix='collection-select'
        name='memberIds'
        id='memberIds'
        components={{ DropdownIndicator, Option }}
        value={null}
        placeholder='Search...'
        onChange={(e) => onTeamMemberSelect(e.value)}
        onMenuOpen={handleMenuOpen}
        onMenuClose={handleMenuClose}
        options={
          assignableMembers && !isDebouncing ?
            assignableOptions(assignableMembers)
          : []
        }
        isLoading={isDebouncing || assignableMembersIsFetching}
        loadingMessage={() => 'Loading...'}
        noOptionsMessage={() => (!!searchText ? 'No matching results' : null)}
        onInputChange={setSearchTextDebounced}
        onMenuScrollToBottom={() => {
          if (assignableMembersHasNextPage) fetchNextAssignableMembersPage();
        }}
        openMenuOnFocus={true}
      />
    </>
  );
}

export const Option = (props) => {
  return (
    <components.Option {...props}>
      <div className='collection-select__option_container'>
        <span>{props.children}</span>
        {props.data.sublabel && (
          <div className='truncated-text-container collection-select-option__subtext'>
            {props.data.sublabel}
          </div>
        )}
      </div>
    </components.Option>
  );
};

ManagerOnlySublabel.propTypes = {
  name: PropTypes.string.isRequired,
};

SelectMembers.propTypes = {
  team: PropTypes.object.isRequired,
  onTeamMemberSelect: PropTypes.func.isRequired,
};
