import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { useCurrentActor } from 'components/contexts/CurrentActor';
import { useBreadBoard } from 'components/contexts/Toaster';
import { personDisplayName } from 'components/helpers/users';
import { resourceShape } from 'components/helpers/serialisableResources';

import useOutsideClick from 'components/hooks/useOutsideClick';
import useModal from 'components/hooks/useModal';

import PersonnelSupportModal from 'components/training/PersonnelSupportModal';

import ChevronUpIcon from '-!svg-react-loader?name=ChevronUpIcon!icons/chevron-up.svg';
import ChevronDownIcon from '-!svg-react-loader?name=ChevronDownIcon!icons/chevron-down.svg';
import TrainingProfileIcon from '-!svg-react-loader?name=TrainingProfileIcon!icons/icon-training-profile.svg';
import SupportIcon from '-!svg-react-loader?name=SupportIcon!icons/icon-support.svg';
import LogOutIcon from '-!svg-react-loader?name=LogOutIcon!icons/icon-log-out.svg';

export default function UserMenu({
  isProcoreSession,
  isTrainingProfileNavigationVisible,
  personnel,
  lineManager,
}) {
  const currentActor = useCurrentActor();
  const breadBoard = useBreadBoard();

  const ref = useRef();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [supportModalIsOpen, , openSupportModal, closeSupportModal] =
    useModal(false);

  const { firstName, lastName } = currentActor.user.attributes;
  const initials = `${firstName[0].toUpperCase()}${lastName[0].toUpperCase()}`;

  const handleTrainingProfileClick = () => {
    window.location.href = `/personnel/${personnel.id}`;
  };

  const handleSupportClick = () => {
    if (currentActor.user.attributes.accessType === 'personnel') {
      openSupportModal();
    } else {
      window.open('https://intercom.help/hands-hq', '_blank');
    }
  };

  const handleLogOutClick = () => {
    axios
      .delete(
        isProcoreSession ?
          '/users/integration_sessions/destroy'
        : '/users/sign_out',
      )
      .then(() => (window.location.href = '/users/sign_in'))
      .catch(breadBoard.addInedibleToast);
  };

  const toggleMenu = () => setIsDropdownOpen((prevState) => !prevState);

  useOutsideClick(() => {
    setIsDropdownOpen(false);
  }, ref);

  return (
    <>
      <div ref={ref} className='tw-relative'>
        <div
          data-element-name='user-menu-toggle'
          className={classNames(
            'tw-flex tw-items-center tw-cursor-pointer tw-w-14 tw-h-8 tw-rounded-[20px] hover:tw-bg-grey-800',
            isDropdownOpen && 'tw-bg-grey-800',
          )}
          onClick={toggleMenu}
        >
          <div className='tw-flex tw-items-center tw-justify-center tw-text-s tw-font-semibold tw-text-white tw-bg-grey-500 tw-rounded-full tw-w-7 tw-h-7 tw-ml-0.5'>
            {initials}
          </div>
          <div className='tw-flex tw-items-center tw-justify-center tw-w-5 tw-h-7'>
            {isDropdownOpen ?
              <ChevronUpIcon
                className='[&_polygon]:tw-fill-white'
                width={20}
                height={20}
              />
            : <ChevronDownIcon
                className='[&_polygon]:tw-fill-white'
                width={20}
                height={20}
              />
            }
          </div>
        </div>
        {isDropdownOpen && (
          <div
            data-element-name='user-menu-dropdown'
            className='tw-absolute tw-right-0 tw-top-9 tw-rounded-lg tw-border-1 tw-border-solid tw-bg-white tw-border-grey-100 tw-shadow-lg tw-tw-w-60 tw-z-[9999]'
          >
            <div className='tw-flex tw-items-center tw-justify-center tw-flex-col tw-w-60 tw-h-32 tw-p-3'>
              <div className='tw-flex tw-items-center tw-justify-center tw-text-l tw-font-semibold tw-text-white tw-bg-grey-500 tw-rounded-full tw-w-12 tw-h-12 tw-mb-2'>
                {initials}
              </div>
              <div className='tw-text-l tw-font-semibold tw-text-grey-900'>
                {personDisplayName(currentActor.user.attributes)}
              </div>
            </div>
            <div className='tw-flex tw-flex-col tw-w-60' onClick={toggleMenu}>
              {isTrainingProfileNavigationVisible && (
                <div
                  className='tw-flex tw-items-center tw-cursor-pointer tw-border-0 tw-border-t-1 tw-border-solid tw-font-medium tw-text-grey-900 tw-border-grey-100 hover:tw-bg-blue-025 tw-p-3'
                  onClick={
                    isTrainingProfileNavigationVisible ?
                      handleTrainingProfileClick
                    : undefined
                  }
                >
                  <TrainingProfileIcon
                    className='[&_polygon]:tw-fill-grey-900 tw-mr-2'
                    width={20}
                    height={20}
                  />
                  Training profile
                </div>
              )}
              <div
                className='tw-flex tw-items-center tw-cursor-pointer tw-border-0 tw-border-t-1 tw-border-solid tw-font-medium tw-text-grey-900 tw-border-grey-100 hover:tw-bg-blue-025 tw-p-3'
                onClick={handleSupportClick}
              >
                <SupportIcon
                  className='[&_polygon]:tw-fill-grey-900 tw-mr-2'
                  width={20}
                  height={20}
                />
                Support
              </div>
              <div
                className='tw-flex tw-items-center tw-cursor-pointer tw-border-0 tw-border-t-1 tw-border-solid tw-font-medium tw-text-grey-900 tw-border-grey-100 hover:tw-bg-blue-025 tw-p-3'
                onClick={handleLogOutClick}
              >
                <LogOutIcon
                  className='[&_polygon]:tw-fill-grey-900 tw-mr-2'
                  width={20}
                  height={20}
                />
                Log out
              </div>
            </div>
          </div>
        )}
      </div>
      {currentActor.user.attributes.accessType === 'personnel' && (
        <PersonnelSupportModal
          isOpen={supportModalIsOpen}
          onClose={closeSupportModal}
          lineManager={lineManager}
        />
      )}
    </>
  );
}

UserMenu.propTypes = {
  isProcoreSession: PropTypes.bool.isRequired,
  isTrainingProfileNavigationVisible: PropTypes.bool.isRequired,
  personnel: resourceShape('simplePersonnel'),
  lineManager: resourceShape('simplePersonnel'),
};
