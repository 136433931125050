import React from 'react';
import PropTypes from 'prop-types';

export default function InfoPill({ className, text, icon = null }) {
  return (
    <div className={`info-pill${className ? ` ${className}` : ''}`}>
      {icon}
      <span>{text}</span>
    </div>
  );
}

InfoPill.propTypes = {
  className: PropTypes.string,
  icon: PropTypes.node,
  text: PropTypes.node.isRequired,
};
