import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { sendAnalytics } from 'components/helpers/analytics';

import useToasts from 'components/hooks/useToasts';

import ELearningLauncher from 'components/eLearning/ELearningLauncher';
import ToastRack from 'components/application/ToastRack';

import CourseCongratsSvg from '-!svg-react-loader?name=CourseCongratsSvg!icons/course-congrats.svg';
import CourseFallBackSvg from '-!svg-react-loader?name=CourseFallBackSvg!icons/course-fall-back.svg';
import ClockIcon from '-!svg-react-loader?name=ClockIcon!icons/clock.svg';
import IHascoBrandIcon from '-!svg-react-loader?name=IHascoBrandIcon!icons/ihasco-brand.svg';

const defaultSeedRegistrationAttributes = {
  uuid: null,
  completionStatus: null,
  successStatus: null,
  score: null,
  completedAndPassed: false,
  completedAndPassedWithTest: false,
};

export default function ELearningPage({
  personnelFirstName,
  bookingHashid,
  booking,
  courseProvider,
  eLearningCourse,
  seedRegistration,
}) {
  const {
    name: eLearningCourseName,
    duration: eLearningCourseDuration,
    description: eLearningCourseDescription,
    coverImageUrl: eLearningCourseCoverImageUrl,
    custom: eLearningCourseCustom,
  } = eLearningCourse.data.attributes;

  const {
    uuid: seedRegistrationUuid,
    completionStatus: seedRegistrationCompletionStatus,
    successStatus: seedRegistrationSuccessStatus,
    score: seedRegistrationScore,
    completedAndPassed: seedCompletedAndPassed,
    completedAndPassedWithTest: seedCompletedAndPassedWithTest,
  } =
    seedRegistration ?
      seedRegistration.data.attributes
    : defaultSeedRegistrationAttributes;

  const [toasts, , , , addInedibleToast] = useToasts();
  const [registration, setRegistration] = useState({
    uuid: seedRegistrationUuid,
    completionStatus: seedRegistrationCompletionStatus,
    successStatus: seedRegistrationSuccessStatus,
    score: seedRegistrationScore,
    completedAndPassed: seedCompletedAndPassed,
    completedAndPassedWithTest: seedCompletedAndPassedWithTest,
  });

  useEffect(() => {
    const personnelId = booking.data.relationships.personnel.data.id;
    const courseId = booking.data.relationships.course.data.id;

    sendAnalytics('eLearning page viewed', {
      personnelId,
      courseId,
      registrationUuid: registration.uuid,
    });
  }, []);

  return (
    <>
      <div className='flex flex--justify-content__center tw-bg-grey-025'>
        <div className='e-learning__main-content tw-bg-white tw-border-grey-100'>
          {registration.completedAndPassed ?
            <>
              <div className='e-learning__column e-learning__column--left'>
                <h2 className='tw-text-grey-900 tw-text-2xl tw-font-semibold tw-tracking-tighter m-0 m-b-24'>
                  Congratulations {personnelFirstName}!
                </h2>
                <div className='m-b-24'>
                  {registration.completedAndPassedWithTest ?
                    <>
                      You passed with a score of{' '}
                      <span className='tw-font-medium'>
                        {registration.score}
                      </span>
                      %
                    </>
                  : <>You successfully completed the course.</>}
                </div>
                <div className='m-b-24'>
                  Your training records have been updated and a<br />
                  certificate has been sent to you and your manager.
                </div>
                <div>You can now close this window.</div>
              </div>
              <div className='e-learning__column e-learning__column--right flex flex--justify-content__center'>
                <CourseCongratsSvg className='w-100 h-auto' />
              </div>
            </>
          : <>
              <div className='e-learning__column e-learning__column--left'>
                <div className='m-b-24'>
                  <h2 className='tw-text-grey-900 tw-text-2xl tw-font-semibold tw-tracking-tighter m-0'>
                    {eLearningCourseName}
                  </h2>
                  {eLearningCourseDuration && (
                    <div className='flex m-t-4'>
                      <ClockIcon
                        className='[&_path]:tw-fill-grey-500 m-r-4'
                        width='20px'
                        height='20px'
                      />
                      <span>Approximately {eLearningCourseDuration}</span>
                    </div>
                  )}
                </div>
                <div className='e-learning__state tw-border-0 tw-text-cyan-800 tw-bg-cyan-025 m-b-24'>
                  <div className='tw-text-xs tw-font-semibold tw-tracking-wide'>
                    {registration.uuid ? 'In progress' : 'Not started'}
                  </div>
                </div>
                {eLearningCourseDescription && (
                  <div className='ws-pre-wrap m-b-24'>
                    {eLearningCourseDescription}
                  </div>
                )}
                {(!eLearningCourseCustom || courseProvider) && (
                  <div className='flex flex--vertically-centered tw-relative m-b-24'>
                    <span>This course is provided by&nbsp;</span>
                    {!eLearningCourseCustom ?
                      <IHascoBrandIcon
                        width='82px'
                        height='24px'
                        className='tw-relative tw-bottom-0.5 m-l-4'
                      />
                    : <span className='tw-font-medium'>{courseProvider}</span>}
                  </div>
                )}
                <div className='content-box p-20 tw-border-grey-100 tw-bg-white'>
                  <div className='m-b-20'>
                    <span>
                      To save your progress and receive credit for completing
                      the course, you{' '}
                    </span>
                    <span className='tw-font-semibold'>
                      must close the course window or tab
                    </span>
                    <span> at the end of your session.</span>
                  </div>
                  <div className='m-b-20'>
                    You should only {registration.uuid ? 'continue' : 'take'}{' '}
                    this course when you have a stable internet connection.
                  </div>
                  <ELearningLauncher
                    bookingHashid={bookingHashid}
                    booking={booking}
                    registrationUuid={registration.uuid}
                    onRegistrationUpdate={(updateProps) =>
                      setRegistration({ ...registration, ...updateProps })
                    }
                    onError={addInedibleToast}
                  />
                </div>
                <div className='m-t-24'>
                  <span className='tw-font-semibold'>Link not working?</span>
                  <span className='tw-text-grey-600'>
                    {' '}
                    You may need to allow pop-ups for your browser. For help
                    enabling pop-ups, read our{' '}
                  </span>
                  <a
                    className='app-link va-baseline tw-font-semibold tw-text-blue-500 hover:tw-text-blue-300'
                    href='https://intercom.help/hands-hq/en/articles/6563616-elearning-course-not-launching-troubleshooting-guide'
                  >
                    help guide
                  </a>
                  <span className='tw-text-grey-600'>.</span>
                </div>
              </div>
              <div className='e-learning__column e-learning__column--right'>
                {eLearningCourseCoverImageUrl ?
                  <img
                    className='w-100 h-auto'
                    src={eLearningCourseCoverImageUrl}
                  />
                : <CourseFallBackSvg className='w-100 h-auto' />}
              </div>
            </>
          }
        </div>
      </div>
      <ToastRack toasts={toasts} />
    </>
  );
}

ELearningPage.propTypes = {
  personnelFirstName: PropTypes.string.isRequired,
  bookingHashid: PropTypes.string.isRequired,
  booking: PropTypes.shape({
    data: PropTypes.shape({
      relationships: PropTypes.shape({
        personnel: PropTypes.object.isRequired,
        course: PropTypes.object.isRequired,
      }),
    }),
  }),
  courseProvider: PropTypes.string,
  eLearningCourse: PropTypes.shape({
    data: PropTypes.shape({
      attributes: PropTypes.shape({
        name: PropTypes.string.isRequired,
        duration: PropTypes.string,
        description: PropTypes.string,
        coverImageUrl: PropTypes.string,
        custom: PropTypes.bool.isRequired,
      }),
    }),
  }),
  seedRegistration: PropTypes.shape({
    data: PropTypes.shape({
      attributes: PropTypes.shape({
        uuid: PropTypes.string,
        completionStatus: PropTypes.string,
        successStatus: PropTypes.string,
        score: PropTypes.number,
        completedAndPassed: PropTypes.bool,
        completedAndPassedWithTest: PropTypes.bool,
      }),
    }),
  }),
};
