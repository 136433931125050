import React from 'react';
import PropTypes from 'prop-types';

import CheckboxField from 'components/application/CheckboxField';

export default function Estimates(props) {
  const { estimates, onChange } = props;

  return (
    <>
      <label className='tw-font-medium tw-mb-3'>Estimates</label>
      <div>
        <CheckboxField
          label='Cost and time'
          name='estimates_cost_and_time'
          value='estimates_cost_and_time'
          checked={estimates.costAndTime}
          onChange={(event) => onChange('costAndTime', event.target.checked)}
        />
      </div>
    </>
  );
}

Estimates.propTypes = {
  estimates: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
};
