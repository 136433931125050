import React from 'react';
import PropTypes from 'prop-types';

import { resourceShape } from 'components/helpers/serialisableResources';
import { useTrainingRegisterResources } from 'components/contexts/TrainingRegisterResourceManagementContext';
import InfoIcon from '-!svg-react-loader?name=InfoIcon!icons/info.svg';
import ActionsPill from 'components/application/ActionsPill';
import BookingCourseDetails from 'components/bookings/BookingCourseDetails';
import BookingDetailsForm from 'components/bookings/BookingDetailsForm';
import ErrorMessage from 'components/application/ErrorMessage';
import SelectedMembersList from 'components/bookings/SelectedMembersList';
import SelectMembers from 'components/bookings/SelectMembers';

export default function BookingConnectionsSidePanelForm({
  currentBooking,
  domainBookingSource,
  domainPersonnelCollection,
  domainCourse,
  remainingCredits,
  onBookingGroupMemberDelete,
  onBookingGroupMemberSelect,
  onDateChange,
  onInputChange,
  removeErrorStyling,
  requestError,
  showBookingGroupView,
  isFetching,
  isELearningAllowanceError,
}) {
  const trainingRegisterResourceManagementContext =
    useTrainingRegisterResources();

  const courseHasELearningCourse =
    !!domainCourse?.relationships?.eLearningCourse?.data?.id;

  return (
    <>
      {showBookingGroupView && (
        <>
          <div className='popup__body-content'>
            <div className='tw-text-l tw-font-semibold tw-text-grey-700 tw-mb-4'>
              Course details
            </div>
            <BookingCourseDetails course={domainCourse} />
          </div>
          <hr className='tw-h-px tw-bg-grey-100 tw-my-0 tw-border-0' />
        </>
      )}
      <div className='popup__body-form--light'>
        {showBookingGroupView && (
          <div className='tw-text-l tw-font-semibold tw-text-grey-700 tw-mb-4'>
            {courseHasELearningCourse ? 'Enrolment' : 'Booking'} details
          </div>
        )}
        <div className='tw-flex tw-border-0 tw-rounded-lg tw-text-cyan-800 tw-bg-cyan-025 tw-p-3 tw-m-0 tw-mb-5'>
          <div className='tw-h-5 tw-mr-3'>
            <InfoIcon
              width={20}
              height={20}
              className='[&_path]:tw-fill-cyan-800'
            />
          </div>
          <p className='tw-m-0'>
            Email notifications will be sent to the personnel and their line
            manager.
          </p>
        </div>
        <div className='tw-mb-6'>
          <ErrorMessage
            validationErrors={requestError.validationErrors}
            isFallback={requestError.isFallback}
            modifiers='side-panel__alert side-panel__alert--danger side-panel__alert--wide'
          />
        </div>
        {!courseHasELearningCourse && (
          <BookingDetailsForm
            currentBooking={currentBooking}
            dateLabel='Training date'
            onDateChange={onDateChange}
            onInputChange={onInputChange}
            removeErrorStyling={removeErrorStyling}
            requestError={requestError}
          />
        )}
        {trainingRegisterResourceManagementContext.hasBookingGroupEditableAccess &&
          domainBookingSource?.type === 'booking' &&
          !showBookingGroupView && (
            <div className='tw-bg-cyan-025 tw-flex tw-gap-3 tw-mt-4 tw-p-3 tw-rounded-lg tw-text-cyan-800'>
              <div className='tw-h-5'>
                <InfoIcon
                  className='[&_path]:tw-fill-cyan-800'
                  height={20}
                  width={20}
                />
              </div>
              <p className='tw-mb-0'>
                To add more personnel on this booking, edit through the{' '}
                <a
                  className='app-link tw-align-baseline tw-text-blue-500 hover:tw-text-blue-300 active:tw-text-blue-300'
                  href='/personnel?tab=bookings'
                >
                  bookings
                </a>{' '}
                section.
              </p>
            </div>
          )}
      </div>
      {showBookingGroupView && (
        <>
          <hr className='tw-h-px tw-bg-grey-100 tw-my-0 tw-border-0' />
          <div className='popup__body-form--light'>
            <div className='tw-flex tw-mb-4 tw-justify-between'>
              <div className='tw-flex tw-gap-2 tw-items-center'>
                <div className='tw-text-l tw-font-semibold tw-text-grey-700'>
                  Personnel
                </div>
                {currentBooking.personnelIds.length > 0 && (
                  <div className='tw-bg-grey-050 tw-h-5 tw-px-1.5 tw-rounded-lg tw-flex tw-text-center tw-items-center tw-justify-center'>
                    <p className='tw-text-xs tw-font-semibold tw-font-inter tw-m-0'>
                      {currentBooking.personnelIds.length}
                    </p>
                  </div>
                )}
              </div>
              <div className='tw-flex'>
                {remainingCredits != null && remainingCredits <= 25 && (
                  <ActionsPill
                    color={
                      remainingCredits > 0 ? 'grey'
                      : remainingCredits === 0 ?
                        'amber'
                      : 'red'
                    }
                    displayText={`Remaining credits: ${remainingCredits}`}
                  />
                )}
              </div>
            </div>
            <SelectMembers
              selectedMemberIDs={currentBooking.personnelIds}
              domainBookingSource={domainBookingSource}
              domainPersonnelCollection={domainPersonnelCollection}
              isFetching={isFetching}
              domainCourse={domainCourse}
              onBookingGroupMemberSelect={onBookingGroupMemberSelect}
              isELearningAllowanceError={isELearningAllowanceError}
            />
          </div>
          <div className='popup__body-form tw-pt-0 tw-mb-8 tw-mt-[-20px]'>
            <SelectedMembersList
              selectedMemberIDs={currentBooking.personnelIds}
              domainPersonnelCollection={domainPersonnelCollection}
              isFetching={isFetching}
              onBookingGroupMemberDelete={onBookingGroupMemberDelete}
            />
          </div>
        </>
      )}
    </>
  );
}

BookingConnectionsSidePanelForm.propTypes = {
  currentBooking: PropTypes.shape({
    date: PropTypes.instanceOf(Date),
    notes: PropTypes.string,
    personnelIds: PropTypes.array,
  }).isRequired,
  domainBookingSource: resourceShape(['booking', 'bookingGroup']),
  domainPersonnelCollection: PropTypes.arrayOf(resourceShape('personnel')),
  domainCourse: resourceShape('course').isRequired,
  isBooking: PropTypes.bool,
  isFetching: PropTypes.bool,
  onBookingGroupMemberSelect: PropTypes.func,
  onBookingGroupMemberDelete: PropTypes.func,
  onDateChange: PropTypes.func.isRequired,
  onInputChange: PropTypes.func.isRequired,
  remainingCredits: PropTypes.number,
  removeErrorStyling: PropTypes.func.isRequired,
  requestError: PropTypes.object,
  showBookingGroupView: PropTypes.bool,
  isELearningAllowanceError: PropTypes.bool,
};
