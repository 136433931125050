import React, { Fragment } from 'react';
import Table from 'components/application/Table';
import TrainingEventRow from 'components/trainingEvents/TrainingEventRow';
import Paginator from 'components/application/Paginator';

export default function TrainingEventsTab(props) {
  const handlePageChange = (event) => {
    const page = event.currentTarget.getAttribute('data-page');
    props.fetchPersonnelEvents(page);
  };

  return (
    <>
      <Table
        headers={
          <tr>
            <th className='tw-text-s tw-text-grey-900 tw-font-medium tw-tracking-wide w-30'>
              Date
            </th>
            <th className='tw-text-s tw-text-grey-900 tw-font-medium tw-tracking-wide w-70'>
              Change
            </th>
          </tr>
        }
        rows={props.personnelEvents.map((event, index) => (
          <TrainingEventRow key={index} personnelEvent={event.attributes} />
        ))}
      />
      {props.metaData.totalPages > 1 && (
        <div className='m-t-80 text-center'>
          <Paginator
            currentPage={props.metaData.currentPage}
            totalPages={props.metaData.totalPages}
            onClick={handlePageChange}
          />
        </div>
      )}
    </>
  );
}
