import React from 'react';
import PropTypes from 'prop-types';
import Tooltip from 'components/application/Tooltip';
import TextButton from 'components/application/buttons/TextButton';

export default function ArchiveButton(props) {
  const {
    disabled,
    disabledTooltip,
    isCompact,
    isArchived,
    onClick,
    onMouseEnter,
  } = props;

  return (
    <div
      className={`tooltip-parent${disabled ? ' tooltip-parent--disallowed' : ''}`}
      onMouseEnter={onMouseEnter}
    >
      <div
        className={`tw-border-solid tw-border-0 tw-border-t-1 tw-border-grey-100 ${isCompact ? 'fh-48' : 'fh-58'}`}
      >
        <TextButton
          size='full'
          color={isArchived ? 'blue' : 'red'}
          disabled={disabled}
          onClick={onClick}
        >
          {isArchived ? 'Unarchive' : 'Archive'}
        </TextButton>
      </div>
      {disabled && (
        <Tooltip
          className={isCompact ? 'pos-t-12' : 'pos-t-16'}
          placement='top'
          trigger='hover'
          tooltip={disabledTooltip}
        />
      )}
    </div>
  );
}

ArchiveButton.propTypes = {
  disabled: PropTypes.bool,
  disabledTooltip: PropTypes.node,
  isCompact: PropTypes.bool,
  isArchived: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  onMouseEnter: PropTypes.func,
};

ArchiveButton.defaultProps = {
  disabled: false,
  disabledTooltip: null,
  isCompact: false,
  isArchived: false,
};
