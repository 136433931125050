import React from 'react';
import PropTypes from 'prop-types';
import useEscapeKey from 'components/hooks/useEscapeKey';
import useBodyOverflowToggle from 'components/hooks/useBodyOverflowToggle';
import TextButton from 'components/application/buttons/TextButton';

export default function DownloadModal(props) {
  const { isOpen, onClose, isFailed, header, body, bodyIcon, actionButton } =
    props;

  const openCloseClassName = isOpen ? 'modalbox--open' : 'modalbox--close';

  const handleWrapperClick = (event) => {
    if (event.target.classList.contains('popup__backdrop')) {
      onClose();
    }
  };

  useBodyOverflowToggle(isOpen);
  useEscapeKey(onClose, [isOpen]);

  const closeButton = (
    <TextButton size='md' color='blue' className='m-r-12' onClick={onClose}>
      Cancel
    </TextButton>
  );

  return (
    <div
      className={`download_modal modal_report-retrieval ${openCloseClassName}`}
    >
      <div className='modalbox__scroller'>
        <div
          className='popup__backdrop tw-bg-grey-900 tw-opacity-75'
          onClick={handleWrapperClick}
        ></div>
        <div className='modalbox__box-wrapper'>
          <div
            className={`download_modal__content tw-bg-white tw-border-grey-100`}
          >
            <div className='download_modal__header tw-text-grey-900 tw-text-l tw-font-semibold'>
              {header}
            </div>
            <div
              className={`download_modal__body tw-border-t-grey-100${isFailed ? ' tw-text-red-600' : ''}`}
            >
              {bodyIcon}
              {body}
            </div>
            <div className='download_modal__footer tw-border-t-grey-100'>
              {closeButton}
              {actionButton}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

DownloadModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};
