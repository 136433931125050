import React from 'react';
import PropTypes from 'prop-types';
import ModalBox from 'components/application/ModalBox';

import { useCurrentActor } from 'components/contexts/CurrentActor';

import {
  displayCurrencyString,
  currencyNumberToCurrencyString,
} from 'components/helpers/currency';

import TextButton from 'components/application/buttons/TextButton';
import FilledButton from 'components/application/buttons/FilledButton';
import CustomELearningCourseCard from 'components/courses/CustomELearningCourseCard';

export default function BookingELearningModal(props) {
  const {
    isOpen,
    setIsClosed,
    identifier,
    course,
    eLearningCourse,
    currentCredits,
    createELearningBooking,
  } = props;

  const currentActor = useCurrentActor();

  const currencyCode = currentActor.division.attributes.currencyCode;

  const handleEnrolClick = () => {
    setIsClosed();
    createELearningBooking({ course: course });
  };

  const customFooter = (
    <div className='modalbox__footer-actions modalbox__footer-actions--right'>
      <TextButton
        size='md'
        color='blue'
        className='m-r-12'
        onClick={setIsClosed}
      >
        Cancel
      </TextButton>
      <FilledButton color='mint' onClick={handleEnrolClick}>
        Enrol {identifier}
      </FilledButton>
    </div>
  );

  const isELearningCustom =
    eLearningCourse && eLearningCourse.attributes.custom;
  const courseName = course && course.attributes.name;
  const courseProvider = course && course.attributes.provider;
  const eLearningCourseDuration =
    eLearningCourse && eLearningCourse.attributes.duration;
  const eLearningCourseCost =
    isELearningCustom &&
    currencyNumberToCurrencyString({
      number: eLearningCourse.attributes.cost,
      currencyCode,
    });

  return (
    <ModalBox
      mode='flexible'
      isOpen={isOpen}
      onClose={setIsClosed}
      customFooter={customFooter}
    >
      <div className='tw-p-8'>
        <h2 className='tw-text-l tw-text-grey-900 tw-font-semibold tw-p-0 tw-mt-0 tw-mb-5'>
          Are you sure you want to enrol {identifier} on the online {courseName}{' '}
          course?
        </h2>
        <p className='tw-mb-5'>
          {identifier} will be emailed instructions to take the course and will
          be informed of the expiry date of the current course. They will be
          reminded every 2 weeks until the course is completed.
        </p>
        {isELearningCustom ?
          <CustomELearningCourseCard
            courseName={courseName}
            courseProvider={courseProvider}
            courseDuration={eLearningCourseDuration}
            courseCost={displayCurrencyString({
              string: eLearningCourseCost,
              currencyCode,
            })}
          />
        : <div className='tw-m-0'>
            <div>
              Credit balance:{' '}
              <span className='tw-font-semibold'>{currentCredits}</span>
            </div>
            <div>1 credit will be deducted</div>
          </div>
        }
      </div>
    </ModalBox>
  );
}

BookingELearningModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  setIsClosed: PropTypes.func.isRequired,
  identifier: PropTypes.string.isRequired,
  course: PropTypes.object,
  eLearningCourse: PropTypes.object,
  currentCredits: PropTypes.number,
  createELearningBooking: PropTypes.func.isRequired,
};

BookingELearningModal.defaultProps = {
  course: null,
};
