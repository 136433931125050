import React from 'react';
import PropTypes from 'prop-types';

import useKeyHighlight from 'components/ramsAnalytics/hooks/useKeyHighlight';

import StatusChart from 'components/ramsAnalytics/components/StatusChart';
import CountDisplay from 'components/ramsAnalytics/components/CountDisplay';

export default function StatusReportBody({
  chartData,
  totalCount,
  onSegmentClick,
}) {
  const { highlightedKey, handleKeyHighlight, handleKeyUnhighlight } =
    useKeyHighlight(null);

  const handleSegmentClick = ({ entry }) => {
    handleKeyUnhighlight();
    onSegmentClick({ entry });
  };

  return (
    <div className='tw-absolute tw-right-0 tw-bottom-0 tw-left-0 tw-pb-10'>
      <div className='tw-flex tw-justify-center'>
        <div className='tw-flex tw-w-[468px] tw-h-[180px]'>
          <div className='tw-flex tw-w-full'>
            <div className='tw-w-[180px] tw-h-[180px]'>
              <StatusChart
                totalCount={totalCount}
                chartData={chartData}
                highlightedKey={highlightedKey}
                onKeyHighlight={handleKeyHighlight}
                onKeyUnhighlight={handleKeyUnhighlight}
                onSegmentClick={handleSegmentClick}
              />
            </div>
            <div
              className='tw-flex tw-flex-col tw-justify-center tw-flex-1 tw-h-[180px] tw-ml-6'
              onMouseLeave={handleKeyUnhighlight}
            >
              {chartData.map((entry) => (
                <CountDisplay
                  key={entry.countKey}
                  countKey={entry.countKey}
                  dotClassName={entry.dotClassName}
                  name={entry.name}
                  count={entry.count}
                  highlightedKey={highlightedKey}
                  onKeyHighlight={handleKeyHighlight}
                  onKeyUnhighlight={handleKeyUnhighlight}
                  onClick={() => handleSegmentClick({ entry })}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

StatusReportBody.propTypes = {
  chartData: PropTypes.array.isRequired,
  totalCount: PropTypes.number.isRequired,
  onSegmentClick: PropTypes.func.isRequired,
};
