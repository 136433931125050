import React from 'react';

export const Content = ({ folderName }) => {
  return (
    <div className='p-32'>
      <h4 className='m-t-0 m-b-16 tw-text-l tw-text-grey-900 tw-font-semibold'>
        Confirm destination folder
      </h4>
      <p>
        You are choosing <span className='tw-font-medium'>{folderName}</span> as
        the destination. This is where versions of your RAMS will be
        auto-uploaded upon approval.
      </p>
    </div>
  );
};
