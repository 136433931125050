import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import Select, { components } from 'react-select';
import { formatOpeningHours } from './helpers';
import { resourceShape } from 'components/helpers/serialisableResources';
import tailwindConfig from 'stylesheets/tailwind.config.js';

const borderWidth = tailwindConfig.theme.borderWidth;
const colors = tailwindConfig.theme.colors;
const spacing = tailwindConfig.theme.spacing;

export default function MedicalLocationSearchSelect({
  assignableMedicalLocations,
  autoFocus,
  errorMessage,
  isLoading,
  onBlur,
  onInputChange,
  onSelect,
}) {
  const options =
    assignableMedicalLocations?.map((assignableMedicalLocation) => {
      return {
        attributes: assignableMedicalLocation.attributes,
        label: assignableMedicalLocation.attributes.name,
        value: assignableMedicalLocation.id,
      };
    }) || [];

  return (
    <Select
      autoFocus={autoFocus}
      blurInputOnSelect
      className='collection-select__select-container tw-h-8 tw-w-full'
      classNamePrefix='collection-select'
      closeMenuOnSelect
      components={{
        DropdownIndicator: () => null,
        IndicatorSeparator: () => null,
        MenuList,
        Option,
      }}
      filterOption={() => true}
      id='assignableMedicalLocations'
      isLoading={isLoading}
      name='assignableMedicalLocations'
      noOptionsMessage={() =>
        errorMessage ||
        (assignableMedicalLocations?.length === 0 &&
          'There were no results for your search. Please check spelling.') ||
        null
      }
      onBlur={onBlur}
      onChange={(option) => onSelect(option.value)}
      onInputChange={onInputChange}
      openMenuOnFocus={false}
      options={options}
      placeholder='Search for hospital, street or postcode'
      styles={{
        control: (baseStyles) => ({
          ...baseStyles,
          height: `${spacing[8]} !important`,
          minHeight: `${spacing[8]} !important`,
        }),
        input: (baseStyles) => ({
          ...baseStyles,
          alignItems: 'center',
          display: 'flex',
          height: `${spacing[7.5]} !important`,
          minHeight: `${spacing[7.5]} !important`,
        }),
        option: (baseStyles) => ({
          ...baseStyles,
          background: `${colors.white} !important`,
          borderBottom: `${borderWidth[1]} solid ${colors.grey[100]} !important`,
          padding: `${spacing[3]} !important`,
        }),
        valueContainer: (baseStyles) => ({
          ...baseStyles,
          height: `${spacing[7.5]} !important`,
          minHeight: `${spacing[7.5]} !important`,
          padding: `${spacing[0]} ${spacing[3]} !important`,
          maxWidth: '522.719px',
          width: '100%',
          '@media only screen and (min-width: 768px)': {
            maxWidth: '279.906px !important',
          },
          '@media only screen and (min-width: 1200px)': {
            maxWidth: '386.578px !important',
          },
        }),
      }}
    />
  );
}

const MenuList = (props) => {
  return (
    <components.MenuList {...props}>
      <div className='tw-bg-amber-025 tw-p-3 tw-sticky tw-text-amber-800 tw-text-s tw-top-0'>
        Results provided by Google Maps. Please ensure the hospital you select
        has an A&E department.
      </div>
      {props.children}
    </components.MenuList>
  );
};

const Option = (props) => {
  const openingHours = formatOpeningHours({
    hours: props.data.attributes.openingHours,
  });

  return (
    <components.Option {...props}>
      <div className='tw-flex tw-flex-col tw-gap-1 tw-text-grey-900'>
        <p className='tw-font-bold tw-m-0 tw-text-l'>
          {props.data.attributes.name}
        </p>
        <div className='tw-text-s'>
          {!!props.data.attributes.address1 && (
            <p className='tw-m-0'>{props.data.attributes.address1},</p>
          )}
          {!!props.data.attributes.address2 && (
            <p className='tw-m-0'>{props.data.attributes.address2},</p>
          )}
          <p className='tw-m-0'>
            {!!props.data.attributes.city && `${props.data.attributes.city}, `}
            {props.data.attributes.postcode}
          </p>
        </div>
        {!!props.data.attributes.phoneNumber && (
          <p className='tw-m-0 tw-text-s'>
            {props.data.attributes.phoneNumber}
          </p>
        )}
        {!!openingHours && <p className='tw-m-0 tw-text-s'>{openingHours}</p>}
      </div>
    </components.Option>
  );
};

MedicalLocationSearchSelect.propTypes = {
  assignableMedicalLocations: PropTypes.arrayOf(
    resourceShape('assignableMedicalLocation'),
  ),
  autoFocus: PropTypes.bool,
  errorMessage: PropTypes.string,
  isLoading: PropTypes.bool,
  onBlur: PropTypes.func.isRequired,
  onInputChange: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired,
};
