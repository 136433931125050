import React, { useState } from 'react';
import PropTypes from 'prop-types';

import AutosizeInput from 'react-input-autosize';

export default function ListFieldItem(props) {
  const { index, item, onChange, onRemove, onInputFocus } = props;

  const [editingValue, setEditingValue] = useState('');
  const [isEditing, setIsEditing] = useState(false);

  const setEditing = () => {
    setEditingValue(item.value);
    setIsEditing(true);
  };

  const resetEditing = () => {
    setEditingValue('');
    setIsEditing(false);
  };

  const handleInputChange = (event) => {
    setEditingValue(event.target.value);
  };

  const handleInputKeyDown = (event) => {
    const addingKeys = [',', ' ', 'Enter', 'Tab', 'Escape'];

    if (addingKeys.includes(event.key)) {
      event.preventDefault();
      onChange({ index: index, value: event.target.value });
      resetEditing();
    }
  };

  const handleInputBlur = (event) => {
    onChange({ index: index, value: event.target.value });
    resetEditing();
  };

  const handleChipClick = (_event) => {
    setEditing();
  };

  const onRemoveClick = (event) => {
    event.stopPropagation();
    onRemove({ index: index });
  };

  const removeIconColourClassName =
    item.isInvalid ?
      'tw-bg-red-500 after:tw-bg-white before:tw-bg-white hover:tw-bg-white hover:after:tw-bg-red-500 hover:before:tw-bg-red-500'
    : 'tw-bg-transparent after:tw-bg-grey-700 before:tw-bg-grey-700 hover:tw-bg-red-500 hover:after:tw-bg-white hover:before:tw-bg-white';

  const removeIconAfterClassName =
    "after:tw-absolute after:tw-top-[3px] after:tw-left-[7px] after:tw-content-[''] after:tw-h-[10px] after:tw-w-[2px] after:tw-rotate-45";
  const removeIconBeforeClassName =
    "before:tw-absolute before:tw-top-[3px] before:tw-left-[7px] before:tw-content-[''] before:tw-h-[10px] before:tw-w-[2px] before:-tw-rotate-45";

  return isEditing ?
      <div className='app-form__input-list-item-editor app-form__input-list-item-editor--padded'>
        <AutosizeInput
          className='app-form__input-list-item-autosize-input'
          value={editingValue}
          onChange={handleInputChange}
          onKeyDown={handleInputKeyDown}
          onBlur={handleInputBlur}
          onFocus={onInputFocus}
          injectStyles={false}
          autoFocus={true}
        />
      </div>
    : <div
        className={`app-chip app-chip--with-icon ${item.isInvalid ? 'tw-text-white tw-bg-red-500' : 'tw-text-grey-700 tw-bg-grey-100'}`}
        onClick={handleChipClick}
      >
        <span>{item.value}</span>
        <span
          className={`tw-absolute tw-top-[6px] tw-right-2 tw-h-4 tw-w-4 tw-rounded-full tw-cursor-pointer ${removeIconAfterClassName} ${removeIconBeforeClassName} ${removeIconColourClassName}`}
          onClick={onRemoveClick}
        />
      </div>;
}

ListFieldItem.propTypes = {
  index: PropTypes.number.isRequired,
  item: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  onInputFocus: PropTypes.func.isRequired,
};
