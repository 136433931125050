import React from 'react';

import Background from '-!svg-react-loader?!components/brandings/svgs/downloadLanding/branding-download-bg.svg';
import DownloadButton from '-!svg-react-loader!components/brandings/svgs/downloadLanding/branding-download-buttonbg.svg';
import Powered from '-!svg-react-loader!components/brandings/svgs/downloadLanding/branding-download-powered.svg';
import Content from '-!svg-react-loader!components/brandings/svgs/downloadLanding/branding-download-content.svg';
import ButtonText from '-!svg-react-loader!components/brandings/svgs/downloadLanding/branding-download-buttontext.svg';
import Logo from '-!svg-react-loader!components/brandings/svgs/downloadLanding/branding-download-logo.svg';
import Links from '-!svg-react-loader!components/brandings/svgs/downloadLanding/branding-download-links.svg';

export default function DownloadLandingPreview({
  primaryBgColour,
  buttonBgColour,
  buttonTextColour,
  textLinkColour,
  handshqLabel,
  logoSrc,
}) {
  // pending: placeholder until decision made on scaling behaviour
  const imageContainerStyles = {
    left: '233px',
    height: '58px',
    top: '112px',
    bottom: '0px',
    width: '116px',
    position: 'relative',
  };

  return (
    <div
      className='svg-preview__document tw-bg-white'
      style={{ height: '304px', width: '413px' }}
    >
      <Background fill={primaryBgColour} />
      {handshqLabel && <Powered />}
      <Content />
      <DownloadButton fill={buttonBgColour} />
      <ButtonText fill={buttonTextColour} />
      <Logo fill={primaryBgColour} />
      <Links fill={textLinkColour} />
      {logoSrc && (
        <div style={imageContainerStyles}>
          <img
            className='image--bound-scaling'
            src={logoSrc}
            alt='Displayed logo when downloading a document'
          />
        </div>
      )}
    </div>
  );
}
