import React, { useState, useRef } from 'react';
import { focusField } from 'components/helpers/refs';
import PropTypes from 'prop-types';

import ListFieldItem from 'components/application/ListFieldItem';

export default function ListField(props) {
  const {
    name,
    items,
    isValidValue,
    onChange,
    fieldError,
    removeErrorStyling,
  } = props;

  const [editingValue, setEditingValue] = useState('');
  const [isFocused, setIsFocused] = useState(false);
  const inputRef = useRef();

  const setInputFocused = () => {
    focusField(inputRef);
  };

  const changeItems = ({ newItems }) => {
    onChange({ oldItems: items, newItems: newItems }, { target: { name } });
    setEditingValue('');
  };

  const addNewItem = ({ value }) => {
    if (value.length > 0) {
      const newItems = [...items];

      newItems.push({ value });
      changeItems({ newItems });
    }
  };

  const removeLastItem = () => {
    if (items.length > 0) {
      const newItems = [...items];

      newItems.splice(-1, 1);
      changeItems({ newItems });
    }
  };

  const removeItemByIndex = ({ index }) => {
    const newItems = [...items];

    newItems.splice(index, 1);
    changeItems({ newItems });
    setInputFocused();
  };

  const updateItemByIndex = ({ index, value }) => {
    if (value.length > 0) {
      const newItems = [...items];

      newItems[index] = { value };
      changeItems({ newItems });
      setInputFocused();
    } else {
      removeItemByIndex({ index });
    }
  };

  const handleInputChange = (event) => {
    setEditingValue(event.target.value);
  };

  const handleInputKeyDown = (event) => {
    const addingKeys = [',', ' ', 'Enter', 'Tab', 'Escape'];

    if (addingKeys.includes(event.key)) {
      event.preventDefault();
      addNewItem({ value: event.target.value });
    }
    if (event.key === 'Escape') {
      event.target.blur();
    }
    if (event.key === 'Backspace' && event.target.value.length === 0) {
      removeLastItem();
    }
  };

  const handleInputBlur = (event) => {
    addNewItem({ value: event.target.value });
    setIsFocused(false);
  };

  const handleInputFocus = (event) => {
    setIsFocused(true);
    removeErrorStyling(event);
  };

  const checkedItems = items.reduce((acc, item) => {
    const isInvalid = !isValidValue({ value: item.value });

    acc.push({ ...item, isInvalid });
    return acc;
  }, []);

  const isInvalid = !!fieldError?.fieldHighlighted;

  const inputListClassName = (() => {
    switch (true) {
      case isInvalid:
        return 'tw-border-red-600 hover:tw-border-red-600';
      case isFocused:
        return 'tw-border-blue-300';
      default:
        return 'tw-border-grey-300 hover:tw-border-grey-400';
    }
  })();

  return (
    <div className={`app-form__input-list ${inputListClassName}`}>
      {checkedItems.map((item, index) => (
        <ListFieldItem
          key={index}
          index={index}
          item={item}
          onChange={updateItemByIndex}
          onRemove={removeItemByIndex}
          onInputFocus={handleInputFocus}
        />
      ))}
      <div className='app-form__input-list-item-editor app-form__input-list-item-editor--flexible'>
        <input
          ref={inputRef}
          className='app-form__input-list-item-input'
          value={editingValue}
          onChange={handleInputChange}
          onKeyDown={handleInputKeyDown}
          onBlur={handleInputBlur}
          onFocus={handleInputFocus}
          name={name}
        />
      </div>
    </div>
  );
}

ListField.propTypes = {
  name: PropTypes.string.isRequired,
  items: PropTypes.array.isRequired,
  isValidValue: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
};

ListField.defaultProps = {
  fieldError: {},
};
