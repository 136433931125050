import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { SortableContainer, SortableElement } from 'react-sortable-hoc';

import { toggleGrabbing } from 'components/helpers/document';

import FieldOption from 'components/settings/personnelInformation/FieldOption';

const minimumNumber = 2;

const SortableItem = SortableElement(
  ({
    option,
    isSorting,
    isInvalid,
    removeErrorStyling,
    isAutoFocused,
    isDeleteAllowed,
    onOptionValueChange,
    onOptionDelete,
  }) => (
    <FieldOption
      key={option.id}
      option={option}
      isSorting={isSorting}
      isInvalid={isInvalid}
      removeErrorStyling={removeErrorStyling}
      isAutoFocused={isAutoFocused}
      isDeleteAllowed={isDeleteAllowed}
      onOptionValueChange={onOptionValueChange}
      onOptionDelete={onOptionDelete}
    />
  ),
);

const SortableList = SortableContainer(
  ({
    options,
    isSorting,
    fieldError,
    removeErrorStyling,
    onOptionValueChange,
    onOptionDelete,
  }) => {
    const numberOfOptions = options.allDataIds.length;
    const isGreaterThanMinimumNumberOfOptions = numberOfOptions > minimumNumber;
    const isFieldErrorHighlighted = !!fieldError.fieldHighlighted;
    const optionValueCount =
      isFieldErrorHighlighted &&
      Object.values(options.data).reduce((accumulator, option) => {
        accumulator[option.value] =
          accumulator[option.value] ? accumulator[option.value] + 1 : 1;
        return accumulator;
      }, {});

    return (
      <ul className='sortable-collection'>
        {options.allDataIds.map((id, index) => {
          const option = options.data[id];
          const isOptionValueBlank =
            isFieldErrorHighlighted && !option.value.trim();
          const isOptionValueNotUnique =
            isFieldErrorHighlighted && optionValueCount[option.value] > 1;

          return (
            <SortableItem
              key={`option-${option.id}`}
              index={index}
              option={option}
              isSorting={isSorting}
              isInvalid={isOptionValueBlank || isOptionValueNotUnique}
              removeErrorStyling={removeErrorStyling}
              isAutoFocused={
                isGreaterThanMinimumNumberOfOptions ?
                  index === numberOfOptions - 1
                : index === 0
              }
              isDeleteAllowed={isGreaterThanMinimumNumberOfOptions}
              onOptionValueChange={onOptionValueChange}
              onOptionDelete={onOptionDelete}
            />
          );
        })}
      </ul>
    );
  },
);

export default function FieldOptions({
  options,
  fieldError,
  removeErrorStyling,
  onOptionValueChange,
  onOptionDelete,
  onOptionOrderChange,
}) {
  const [isSorting, setIsSorting] = useState(false);

  function handleSortStart({ _node, _helper }) {
    setIsSorting(true);
  }

  function handleSortEnd({ oldIndex, newIndex }) {
    onOptionOrderChange({ oldIndex, newIndex });
    setIsSorting(false);
  }

  useEffect(() => {
    toggleGrabbing(isSorting);
  }, [isSorting]);

  return (
    <SortableList
      lockAxis='y'
      axis='y'
      useDragHandle={true}
      lockToContainerEdges={true}
      helperClass='sortable-collection__member-wrapper--active'
      onSortStart={handleSortStart}
      onSortEnd={handleSortEnd}
      options={options}
      isSorting={isSorting}
      fieldError={fieldError}
      removeErrorStyling={removeErrorStyling}
      onOptionValueChange={onOptionValueChange}
      onOptionDelete={onOptionDelete}
    />
  );
}

FieldOptions.propTypes = {
  options: PropTypes.exact({
    allDataIds: PropTypes.array.isRequired,
    data: PropTypes.object.isRequired,
  }).isRequired,
  fieldError: PropTypes.exact({
    error: PropTypes.object,
    fieldHighlighted: PropTypes.bool,
  }),
  removeErrorStyling: PropTypes.func.isRequired,
  onOptionValueChange: PropTypes.func.isRequired,
  onOptionDelete: PropTypes.func.isRequired,
  onOptionOrderChange: PropTypes.func.isRequired,
};

FieldOptions.defaultProps = {
  fieldError: {},
};
