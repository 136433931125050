import voca from 'voca';
import _ from 'lodash';

const fetchPersonnel = ({ personnelId }) => {
  return axios.get(`/personnel/${personnelId}?with_course_connections=true`);
};

const fetchPersonnelTraining = ({ personnelId }) => {
  return axios.get(`/personnel/${personnelId}/trainings`);
};

const fetchPersonnelSubordinates = ({ personnelId, pageParam }) => {
  return axios.get(`/personnel/${personnelId}/subordinates`, {
    params: { page: pageParam },
  });
};

const fetchAvailableFields = () => {
  return axios.get('/custom_fields/available_field_attributes');
};

const indexResources = ({ resources, path }) => {
  return resources.reduce((acc, resource) => {
    const index = _.get(resource, path);

    if (index) acc[index] = resource;
    return acc;
  }, {});
};

const groupResources = ({ resources, path }) => {
  return resources.reduce((acc, resource) => {
    const group = _.get(resource, path);

    if (group) {
      if (!acc.hasOwnProperty(group)) acc[group] = [];
      acc[group].push(resource);
    }
    return acc;
  }, {});
};

const formatResources = ({ resources, options = {} }) => {
  const formattedResources = {};

  if (options['resources']) formattedResources['resources'] = resources;
  if (options['ids'])
    formattedResources['ids'] = resources.map((resource) => resource.id);

  Object.keys(options)
    .filter((key) => voca.startsWith(key, 'indexedBy'))
    .forEach((key) => {
      formattedResources[key] = indexResources({
        resources,
        path: options[key].path,
      });
    });

  Object.keys(options)
    .filter((key) => voca.startsWith(key, 'groupedBy'))
    .forEach((key) => {
      formattedResources[key] = groupResources({
        resources,
        path: options[key].path,
      });
    });

  return formattedResources;
};

const filterIncluded = ({ included, type }) => {
  return included.filter((inclusion) => inclusion.type === type);
};

export {
  fetchPersonnel,
  fetchPersonnelTraining,
  fetchPersonnelSubordinates,
  fetchAvailableFields,
  indexResources,
  groupResources,
  formatResources,
  filterIncluded,
};
