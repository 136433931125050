import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { resourceShape } from 'components/helpers/serialisableResources';
import ErrorMessage from 'components/application/ErrorMessage';
import FormFooter from 'components/sidepanels/FormFooter';
import SelectedMembersList from 'components/bookings/SelectedMembersList';
import SidePanel from 'components/application/SidePanel';
import TrainingFormFields from 'components/training/TrainingFormFields';

export default function BookingConnectionTrainingSidePanel({
  currentBookingConnectionTraining,
  domainPersonnelCollection,
  domainCourse,
  onBookingGroupMemberDelete,
  onCalendarClose,
  onCancel,
  onDateChange,
  onInputChange,
  onSubmit,
  removeErrorStyling,
  requestError,
  sidePanelContext,
  sidePanelIsOpen,
  submitDisabled,
}) {
  const [scrolledAmount, setScrolledAmount] = useState(0);

  return (
    <SidePanel
      closeCallback={onCancel}
      color='tertiary'
      contentContext={sidePanelContext}
      headerContent={
        <div className='popup__title popup__title--tertiary-split'>
          <h1 className='tw-text-s tw-font-medium tw-tracking-tight m-b-8'>
            Record new training
          </h1>
          <h2 className='truncated-text-container--wrapped truncated-text-container--five-lines tw-text-xl tw-text-grey-900 tw-font-semibold tw-tracking-tight'>
            {domainCourse.attributes.name}
          </h2>
        </div>
      }
      bodyContent={
        <>
          <div className='popup__body-form--light'>
            <div className='tw-mb-6'>
              <ErrorMessage
                validationErrors={requestError.validationErrors}
                isFallback={requestError.isFallback}
                modifiers='side-panel__alert side-panel__alert--danger side-panel__alert--wide'
              />
            </div>
            <TrainingFormFields
              currentTraining={currentBookingConnectionTraining}
              domainCourse={domainCourse}
              requestError={requestError}
              onInputChange={onInputChange}
              removeErrorStyling={removeErrorStyling}
              submitDisabled={submitDisabled}
              onTrainingDateChange={onDateChange}
              onTrainingCalendarClose={onCalendarClose}
              notesExplanatoryMessage={
                'These will be applied to all personnel in the list below. They may be overridden individually through their profiles.'
              }
            />
          </div>
          <hr className='tw-h-px tw-bg-grey-100 tw-my-0 tw-border-0' />
          <div className='popup__body-form--light'>
            <div className='tw-flex tw-gap-2 tw-mb-2'>
              <div className='personal-info__label tw-text-grey-900 tw-font-medium'>
                Personnel
              </div>
              <div className='tw-bg-grey-050 tw-h-5 tw-px-1.5 tw-rounded-lg tw-flex tw-text-center tw-items-center tw-justify-center'>
                <p className='tw-font-inter tw-font-semibold tw-mb-0 tw-text-xs'>
                  {currentBookingConnectionTraining.personnelIds.length}
                </p>
              </div>
            </div>
            <p className='tw-mb-4 tw-text-grey-500 tw-text-s'>
              Evidence must be uploaded from individual personnel profiles.
            </p>
            <SelectedMembersList
              selectedMemberIDs={currentBookingConnectionTraining.personnelIds}
              domainPersonnelCollection={domainPersonnelCollection}
              onBookingGroupMemberDelete={onBookingGroupMemberDelete}
              shouldBlockFullRemoval={true}
            />
          </div>
        </>
      }
      footerContent={
        <FormFooter
          onCancel={onCancel}
          onSubmit={onSubmit}
          submitButtonDisabled={submitDisabled}
          text='Record training'
        />
      }
      isOpen={sidePanelIsOpen}
      onBackdropClick={onCancel}
      scrolledAmount={scrolledAmount}
      setScrolledAmount={setScrolledAmount}
      submitDisabled={submitDisabled}
    />
  );
}

BookingConnectionTrainingSidePanel.propTypes = {
  currentBookingConnectionTraining: PropTypes.shape({
    expiryDate: PropTypes.instanceOf(Date),
    notes: PropTypes.string,
    personnelIds: PropTypes.array,
    startDate: PropTypes.instanceOf(Date),
  }).isRequired,
  domainPersonnelCollection: PropTypes.arrayOf(resourceShape('personnel')),
  domainCourse: resourceShape('course').isRequired,
  onBookingGroupMemberDelete: PropTypes.func.isRequired,
  onCalendarClose: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  onDateChange: PropTypes.func.isRequired,
  onInputChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  removeErrorStyling: PropTypes.func,
  requestError: PropTypes.object,
  sidePanelContext: PropTypes.string,
  sidePanelIsOpen: PropTypes.bool,
  submitDisabled: PropTypes.bool,
};
