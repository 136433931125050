import React from 'react';

export default function InfoBlock(props) {
  const { status, value, isDisabled } = props;

  const statusClassName = {
    high: 'tw-text-white tw-bg-red-500',
    medium: 'tw-text-grey-900 tw-bg-amber-300',
    low: 'tw-text-white tw-bg-green-400',
    grey: 'tw-text-grey-600 tw-bg-grey-200',
  };

  return (
    <div
      className={`info-block tw-text-xs tw-font-semibold tw-tracking-wide ${statusClassName[status] || ''} ${isDisabled ? 'tw-opacity-30' : ''}`}
    >
      {value}
    </div>
  );
}
