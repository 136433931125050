import React from 'react';
import PropTypes from 'prop-types';
import qs from 'qs';

import Options from 'components/application/Options';
import OptionChoice from 'components/application/OptionChoice';
import OutlinedButton from 'components/application/buttons/OutlinedButton';

import ChevronUpIcon from '-!svg-react-loader?name=ChevronUpIcon!icons/chevron-up.svg';
import ChevronDownIcon from '-!svg-react-loader?name=ChevronDownIcon!icons/chevron-down.svg';

const selectableReports = [
  { label: 'Expiring training', type: 'expiringTraining' },
  { label: 'Completed training', type: 'completedTraining' },
  { label: 'Current training status', type: 'currentTrainingStatus' },
];

export default function TrainingRegisterReportsSelector({ filter }) {
  const handleReportSelection = (type) => {
    const queryString = qs.stringify({
      tab: 'reports',
      type: type,
      filter: filter,
    });

    window.open(`/personnel?${queryString}`, '_blank');
  };

  return (
    <Options
      align='center'
      toggleStyle='tw-inline-block'
      listStyle='tw-w-52 tw-z-[99999] [&_li]:tw-border-none'
      btnToggle={(isOpen) => {
        return (
          <div className='tooltip-parent'>
            <OutlinedButton size='sm' color='grey' onClick={() => {}}>
              <span>Create report</span>
              <ChevronUpIcon
                className='-tw-mr-[6px] -tw-mb-[1px]'
                width={24}
                height={24}
                style={{ display: isOpen ? 'inline-block' : 'none' }}
              />
              <ChevronDownIcon
                className='-tw-mr-[6px] -tw-mb-[1px]'
                width={24}
                height={24}
                style={{ display: isOpen ? 'none' : 'inline-block' }}
              />
            </OutlinedButton>
          </div>
        );
      }}
    >
      {selectableReports.map((report) => {
        return (
          <OptionChoice
            key={report.type}
            onClick={(setOptionsOpen) => {
              setOptionsOpen(false);
              handleReportSelection(report.type);
            }}
          >
            <a className='tw-text-m tw-text-grey-700 hover:tw-text-grey-700 tw-font-medium tw-tracking-auto'>
              {report.label}
            </a>
          </OptionChoice>
        );
      })}
    </Options>
  );
}

const resourceIdShape = PropTypes.shape({
  eq: PropTypes.string.isRequired,
});

TrainingRegisterReportsSelector.propTypes = {
  filter: PropTypes.shape({
    courseId: resourceIdShape,
    roleId: resourceIdShape,
  }),
};
