import React from 'react';
import PropTypes from 'prop-types';
import { resourceShape } from 'components/helpers/serialisableResources';
import ModalBox from 'components/application/ModalBox';

import TextButton from 'components/application/buttons/TextButton';
import FilledButton from 'components/application/buttons/FilledButton';

export default function BookingReminderModal({
  isOpen,
  onClose,
  booking,
  personnel,
  course,
  onSendReminderConfirmation,
}) {
  const onSendReminder = () => {
    onClose();
    onSendReminderConfirmation({ booking, course });
  };

  const customFooter = (
    <div className='modalbox__footer-actions modalbox__footer-actions--right'>
      <TextButton size='md' color='blue' className='m-r-12' onClick={onClose}>
        Cancel
      </TextButton>
      <FilledButton color='mint' onClick={onSendReminder}>
        Send reminder
      </FilledButton>
    </div>
  );

  return (
    <ModalBox
      mode='flexible'
      isOpen={isOpen}
      onClose={onClose}
      customFooter={customFooter}
    >
      <div className='p-32'>
        <h2 className='tw-text-l tw-text-grey-900 tw-font-semibold m-0 p-0'>
          Are you sure you want to send a reminder to{' '}
          {personnel && personnel.firstName} to take{' '}
          {course && course.attributes.name}?
        </h2>
        <p className='tw-font-normal tw-text-m tw-text-grey-700 tw-mt-6 tw-mb-0 tw-ml-0 tw-mr-0 tw-p-0'>
          An email notification will be sent to personnel with email addresses.
        </p>
      </div>
    </ModalBox>
  );
}

BookingReminderModal.propTypes = {
  personnel: PropTypes.shape({
    firstName: PropTypes.string.isRequired,
  }),
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  booking: resourceShape('booking'),
  course: resourceShape('course'),
  onSendReminderConfirmation: PropTypes.func.isRequired,
};
