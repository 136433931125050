import React from 'react';
import DownloadPdfIcon from '-!svg-react-loader?name=DownloadPdfIcon!icons/ic-download-pdf.svg';

export default function PdfDownloadContainer(props) {
  const { title, createdAt, fileSize, viewHref, downloadHref, BrandedLink } =
    props;

  return (
    <div
      className={`document-container tw-bg-grey-050${title === 'Training report' ? ' m-t-16' : ''}`}
    >
      <DownloadPdfIcon />
      <div className={'m-l-12'}>
        <div className='download-container__title tw-text-grey-900 tw-font-semibold'>
          {title}
        </div>
        <div className='m-b-8 download-container__sub-text'>
          Created {createdAt} • {fileSize}
        </div>
        <span className={'m-r-20'}>
          <BrandedLink
            url={viewHref}
            linkText={'View'}
            target={'_blank'}
            styleClasses={'tw-font-semibold'}
          />
        </span>
        <BrandedLink
          url={downloadHref}
          linkText={'Download'}
          styleClasses={'tw-font-semibold'}
        />
      </div>
    </div>
  );
}
