import React from 'react';
import PropTypes from 'prop-types';

const reviewStatus = {
  pastReviewDueDate: {
    indicatorClassName: 'tw-bg-red-500',
    textClassName: 'tw-text-red-500',
  },
  beforeReminderDate: {
    indicatorClassName: 'tw-bg-green-400',
    textClassName: 'tw-text-green-400',
  },
  pastReminderDate: {
    indicatorClassName: 'tw-bg-amber-400',
    textClassName: 'tw-text-amber-600',
  },
};

export default function ReviewStatusIndicator({ status, dueDate }) {
  const displayableStatus = reviewStatus[status];

  return displayableStatus ?
      <div className='tw-flex tw-items-center'>
        <div
          className={`tw-inline-flex tw-h-2.5 tw-w-2.5 tw-rounded-full ${displayableStatus.indicatorClassName}`}
        />
        <div
          className={`tw-text-s tw-font-medium tw-tracking-wide tw-ml-1 ${displayableStatus.textClassName}`}
        >
          {dueDate}
        </div>
      </div>
    : null;
}

ReviewStatusIndicator.propTypes = {
  status: PropTypes.oneOf(Object.keys(reviewStatus)),
  dueDate: PropTypes.string,
};
