import React from 'react';
import PropTypes from 'prop-types';

import { useBreadBoard } from 'components/contexts/Toaster';
import {
  usePersonnel,
  useAvailableFields,
} from 'components/mobileTrainingProfile/hooks/reactQuery';
import {
  formatResources,
  filterIncluded,
} from 'components/mobileTrainingProfile/helpers/requests';
import { personDisplayName } from 'components/helpers/users';
import { sortRolesByPrimaryAndPosition } from 'components/helpers/personnel';

import LeftArrowIcon from '-!svg-react-loader?name=LeftArrowIcon!icons/left-arrow-lg.svg';
import PersonIcon from '-!svg-react-loader?name=PersonIcon!icons/person-lg.svg';

import Tabs from 'components/application/Tabs';
import PersonnelDetailsTab from 'components/mobileTrainingProfile/components/personnelDetailsScreen/PersonnelDetailsTab';
import TeamsTab from 'components/mobileTrainingProfile/components/personnelDetailsScreen/TeamsTab';
import ManagementTab from 'components/mobileTrainingProfile/components/personnelDetailsScreen/ManagementTab';

export default function PersonnelDetailsScreen({
  personnelId,
  onViewHomeClick,
}) {
  const breadBoard = useBreadBoard();

  const {
    data: personnelQuery,
    isLoading: isPersonnelQueryLoading,
    isError: isPersonnelQueryError,
  } = usePersonnel({
    personnelId,
    select: personnelQuerySelector,
    onError: breadBoard.addInedibleToast,
  });

  const {
    data: availableFieldsQuery,
    isLoading: isAvailableFieldsQueryLoading,
    isError: isAvailableFieldsQueryError,
  } = useAvailableFields({
    select: availableFieldsSelector,
    onError: breadBoard.addInedibleToast,
  });

  if (
    isPersonnelQueryLoading ||
    isPersonnelQueryError ||
    isAvailableFieldsQueryLoading ||
    isAvailableFieldsQueryError
  )
    return null;

  const personnel = personnelQuery.personnel.indexedById[personnelId];
  const lineManager =
    personnelQuery.lineManagers.indexedById[
      personnel.relationships.lineManager.data?.id
    ];
  const company =
    personnelQuery.companies.indexedById[
      personnel.relationships.company.data.id
    ];
  const personnelCompanyRoles = sortRolesByPrimaryAndPosition(
    personnelQuery.userCompanyRoles.resources,
    personnelQuery.companyRoles.resources,
  );

  return (
    <>
      <div className='tw-sticky tw-top-0 tw-z-[999] tw-flex tw-items-center tw-bg-grey-900 tw-h-20 tw-w-full tw-p-3'>
        <div className='tw-flex-none tw-flex tw-items-center tw-border-solid tw-border-0 tw-border-r-1 tw-border-grey-500 tw-w-13 tw-h-full'>
          <LeftArrowIcon
            data-element-name='navigate-home-icon'
            className='tw-ml-0.5 tw-cursor-pointer'
            width={40}
            height={40}
            onClick={onViewHomeClick}
          />
        </div>
        <div className='tw-flex-auto tw-text-grey-025 tw-ml-4 tw-mr-4'>
          <h1 className='tw-font-mark-pro tw-text-xl tw-font-bold tw-tracking-tight tw-m-0'>
            Personnel details
          </h1>
        </div>
      </div>
      <div className='tw-p-3'>
        <div className='tw-flex tw-items-center tw-flex-col tw-border-solid tw-border-1 tw-rounded-lg tw-border-grey-100 tw-bg-white tw-px-4 tw-py-8'>
          <div className='tw-flex tw-justify-center tw-items-center'>
            <div className='tw-flex tw-justify-center tw-items-center tw-h-20 tw-w-20 tw-rounded-full tw-bg-grey-600'>
              <PersonIcon
                className='[&_path]:tw-fill-white'
                width={32}
                height={32}
              />
            </div>
          </div>
          <h2 className='tw-text-grey-900 tw-text-2xl tw-font-semibold tw-tracking-tighter tw-m-0 tw-mt-6'>
            {personDisplayName(personnel.attributes)}
          </h2>
          <p className='tw-text-l tw-m-0'>{company.attributes.name}</p>
          {personnelCompanyRoles.length > 0 && (
            <ul className='tw-p-0 tw-list-none tw-m-0 tw-text-center tw-mt-6'>
              {personnelCompanyRoles.map((companyRole) => (
                <li className='tw-mb-2 last:tw-mb-0' key={companyRole.id}>
                  <span className='tw-inline-block tw-py-1.5 tw-px-4 tw-rounded-[16px] tw-text-center tw-break-words tw-bg-grey-050'>
                    {companyRole.attributes.position}
                  </span>
                </li>
              ))}
            </ul>
          )}
        </div>
        <div className='tw-mt-6'>
          <Tabs
            modifiers={['truncated']}
            navClassName='tw-overflow-y-hidden tw-whitespace-nowrap [&::-webkit-scrollbar]:hidden [-ms-overflow-style:none] [scrollbar-width:none] tw-h-[22px] tw-mb-3'
          >
            <PersonnelDetailsTab
              key='personnelDetailsTab'
              label='personnel details'
              fieldValues={personnelQuery.fieldValues.resources}
              fieldAttributes={availableFieldsQuery.fieldAttributes.resources}
              fieldOptions={availableFieldsQuery.fieldOptions.resources}
              defaultDetails={[
                {
                  label: 'Personnel ID',
                  text: personnel.attributes.externalId,
                },
                { label: 'Email', text: personnel.attributes.email },
              ]}
            />
            <TeamsTab
              key='teamsTab'
              label='teams'
              teams={personnelQuery.teams.resources}
            />
            <ManagementTab
              key='managementTab'
              label='management'
              personnelId={personnelId}
              lineManager={lineManager}
            />
          </Tabs>
        </div>
      </div>
    </>
  );
}

PersonnelDetailsScreen.propTypes = {
  personnelId: PropTypes.string.isRequired,
  onViewHomeClick: PropTypes.func.isRequired,
};

const personnelQuerySelector = (response) => {
  const data = response.data;
  const included = response.included;

  return {
    personnel: formatResources({
      resources: [data],
      options: { indexedById: { path: 'id' } },
    }),
    companies: formatResources({
      resources: filterIncluded({ included, type: 'company' }),
      options: { indexedById: { path: 'id' } },
    }),
    lineManagers: formatResources({
      resources: filterIncluded({ included, type: 'lineManager' }),
      options: { indexedById: { path: 'id' } },
    }),
    userCompanyRoles: formatResources({
      resources: filterIncluded({ included, type: 'userCompanyRole' }),
      options: { resources: true },
    }),
    companyRoles: formatResources({
      resources: filterIncluded({ included, type: 'companyRole' }),
      options: { resources: true },
    }),
    fieldValues: formatResources({
      resources: filterIncluded({ included, type: 'fieldValue' }),
      options: { resources: true },
    }),
    teams: formatResources({
      resources: filterIncluded({ included, type: 'team' }),
      options: { resources: true },
    }),
  };
};

const availableFieldsSelector = (response) => {
  const data = response.data;
  const included = response.included;

  return {
    fieldAttributes: formatResources({
      resources: data,
      options: { resources: true },
    }),
    fieldOptions: formatResources({
      resources: filterIncluded({ included, type: 'fieldOption' }),
      options: { resources: true },
    }),
  };
};
