import useForm from 'components/hooks/useForm';

export default function usePersonnelForm(initialState) {
  const [
    formPersonnel,
    setFormPersonnel,
    handlePersonnelInputChange,
    handlePersonnelOptionChange,
    ,
    handlePersonnelDateChange,
  ] = useForm(initialState);

  const handleFieldValueInputChange = (event) => {
    const target = event.target;
    const name = target.name;
    const newValue = target.value;

    setFormPersonnel({
      ...formPersonnel,
      fieldValues: {
        ...formPersonnel.fieldValues,
        [name]: {
          ...formPersonnel.fieldValues[name],
          value: newValue,
          valueType: 'text',
        },
      },
    });
  };

  const handleDateFieldChange = (name, date) => {
    setFormPersonnel({
      ...formPersonnel,
      fieldValues: {
        ...formPersonnel.fieldValues,
        [name]: {
          ...formPersonnel.fieldValues[name],
          value: date,
          valueType: 'date',
        },
      },
    });
  };

  const handleFieldOptionChange = (selected, actionMeta) => {
    const name = actionMeta.name;
    const newValue =
      actionMeta.action === 'clear' ? '' : selected.value.optionId;
    setFormPersonnel({
      ...formPersonnel,
      fieldValues: {
        ...formPersonnel.fieldValues,
        [name]: { ...formPersonnel.fieldValues[name], fieldOptionId: newValue },
      },
    });
  };

  const handleToggle = (name) => {
    setFormPersonnel((prevState) => ({
      ...prevState,
      [name]: !prevState[name],
    }));
  };

  return [
    formPersonnel,
    setFormPersonnel,
    handlePersonnelInputChange,
    handlePersonnelOptionChange,
    handlePersonnelDateChange,
    handleFieldValueInputChange,
    handleDateFieldChange,
    handleFieldOptionChange,
    handleToggle,
  ];
}
