import React from 'react';
import PropTypes from 'prop-types';
import { personDisplayName } from 'components/helpers/users';
import { resourceShape } from 'components/helpers/serialisableResources';
import Tooltip from 'components/application/Tooltip';

export default function PersonnelShow({
  personnel,
  isCompact = false,
  roles = [],
  division,
  subcontractor,
}) {
  return (
    <div className={`popup__body-show${isCompact ? ' p-b-0' : ''}`}>
      <h2
        className={`popup__body-header${isCompact ? '' : ' popup__body-header--height-unset'} tw-text-grey-900 tw-text-xl tw-font-semibold tw-tracking-tight ${isCompact ? 'h-auto m-b-0' : 'truncated-text-container--wrapped truncated-text-container--five-lines truncated-text-container--block mh-140'}`}
      >
        {personDisplayName(personnel.attributes)}
      </h2>
      <h3 className='popup__body-header popup__body-header--sub tw-text-m tw-font-normal tw-tracking-auto truncated-text-container truncated-text-container--block'>
        {(subcontractor || division).attributes.name}
      </h3>
      <div className={`popup__body-content ${isCompact ? 'p-t-4' : 'p-t-0'}`}>
        {roles.length > 0 && (
          <ul className={`pill-list${isCompact ? ' m-t-16' : ' m-t-24'}`}>
            {roles.map((role) => (
              <li key={role.id} className={'tooltip-parent'}>
                <span
                  className={`pill tw-bg-grey-050${role.attributes.position.length > 30 ? ' truncated-text-container' : ''}`}
                >
                  {role.attributes.position}
                </span>
                {role.attributes.position.length > 30 ?
                  <Tooltip
                    type='dark'
                    placement='top'
                    trigger={'hover'}
                    tooltip={role.attributes.position}
                  />
                : ''}
              </li>
            ))}
          </ul>
        )}
      </div>
    </div>
  );
}

PersonnelShow.propTypes = {
  personnel: resourceShape('personnel').isRequired,
  division: resourceShape(['assignableCompany', 'company']),
  subcontractor: resourceShape(['assignableCompany', 'subcontractor']),
  roles: PropTypes.arrayOf(resourceShape('companyRole')),
  isCompact: PropTypes.bool,
};
