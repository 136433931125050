import React, { useState, useRef } from 'react';
import Select, { components } from 'react-select';
import _ from 'lodash';

import { useCurrentActor } from 'components/contexts/CurrentActor';
import { useInfiniteQuery } from '@tanstack/react-query';
import { useBreadBoard } from 'components/contexts/Toaster';

import { getNextPageParam } from 'components/helpers/reactQuery';

import AddedIcon from '-!svg-react-loader?name=AddedIcon!icons/ic-added.svg';
import DashboardIcon from '-!svg-react-loader?name=HomeIcon!icons/dashboard-sm.svg';
import HomeIcon from '-!svg-react-loader?name=HomeIcon!icons/home-sm.svg';
import ChevronUpIcon from '-!svg-react-loader?name=ChevronUpIcon!icons/chevron-up.svg';
import ChevronDownIcon from '-!svg-react-loader?name=ChevronDownIcon!icons/chevron-down.svg';

const optionIconProps = {
  width: 20,
  height: 20,
  className: '[&_path]:tw-fill-grey-900',
};
const dashboardPath = '/dashboard';

export default function ContextSwitcher({
  currentPath,
  isDashboardAccessible,
}) {
  const currentActor = useCurrentActor();
  const breadBoard = useBreadBoard();

  const isDashboardPath = currentPath === dashboardPath;

  const [
    isFetchingAccessibleDivisionsEnabled,
    setIsFetchingAccessibleDivisionsEnabled,
  ] = useState(false);
  const [searchParam, setSearchParam] = useState('');

  const fetchAccessibleDivisions = ({ pageParam }) => {
    return axios.get('/accessible_divisions', {
      params: { search: searchParam, page: pageParam },
    });
  };

  const {
    data: accessibleDivisions,
    isFetching: isFetchingAccessibleDivisions,
    fetchNextPage: fetchNextPageOfAccessibleDivisions,
    hasNextPage: hasNextPageOfAccessibleDivisions,
  } = useInfiniteQuery({
    queryKey: ['accessibleDivisions', { search: searchParam }],
    queryFn: async ({ pageParam = 1 }) => {
      const accessibleDivisionsResponse = await fetchAccessibleDivisions({
        pageParam,
      });
      return accessibleDivisionsResponse.data;
    },
    getNextPageParam: getNextPageParam,
    enabled: isFetchingAccessibleDivisionsEnabled,
    onError: breadBoard.addInedibleToast,
  });

  const dashboardOption = {
    path: dashboardPath,
    icon: <DashboardIcon {...optionIconProps} />,
    isCurrent: isDashboardPath,
    value: 'dashboard',
    label: 'Dashboard',
  };

  const divisionOption = (option) => {
    return {
      path: `/companies/${option.id}`,
      icon:
        option.attributes.primary ? <HomeIcon {...optionIconProps} /> : null,
      isCurrent: !isDashboardPath && option.id === currentActor.division.id,
      value: `company-${option.id}`,
      label: option.attributes.name,
    };
  };

  const options = (() => {
    if (!accessibleDivisions) return [];

    const withDashboardOption =
      isDashboardAccessible && new RegExp(searchParam, 'i').test('dashboard');
    const divisionOptions = accessibleDivisions.pages
      .map((page) => page.data.map((division) => divisionOption(division)))
      .flat();

    return withDashboardOption ?
        [dashboardOption, ...divisionOptions]
      : divisionOptions;
  })();

  const Option = (props) => {
    return (
      <components.Option {...props}>
        <div className='context-switcher__option-item'>
          {props.data.icon && (
            <div className='context-switcher__option-icon'>
              {props.data.icon}
            </div>
          )}
          <span className='context-switcher__option-text tw-text-m'>
            {props.data.label}
          </span>
        </div>
        {props.data.isCurrent && (
          <div className='context-switcher__option-item'>
            <span className='context-switcher__current-icon'>
              <AddedIcon
                width={16}
                height={16}
                className='[&_circle]:tw-fill-cyan-400'
              />
            </span>
          </div>
        )}
      </components.Option>
    );
  };

  const Placeholder = (props) => {
    return (
      <components.Placeholder {...props}>
        {props.selectProps.menuIsOpen ?
          <div>Search</div>
        : <div>
            {isDashboardPath ?
              'Dashboard'
            : currentActor.division.attributes.name}
          </div>
        }
      </components.Placeholder>
    );
  };

  const DropdownIndicator = (props) => {
    return (
      components.DropdownIndicator && (
        <components.DropdownIndicator {...props}>
          {props.selectProps.menuIsOpen ?
            <ChevronUpIcon
              className='[&_polygon]:tw-fill-grey-100'
              width={20}
              height={20}
            />
          : <ChevronDownIcon
              className='[&_polygon]:tw-fill-grey-100'
              width={20}
              height={20}
            />
          }
        </components.DropdownIndicator>
      )
    );
  };

  const handleInputChange = useRef(
    _.debounce((searchText) => setSearchParam(searchText), 500),
  ).current;

  const handleChange = (option) => {
    window.location.href = option.path;
  };

  return (
    <div className='pos-rel'>
      <Select
        name='contextSwitcher'
        className='context-switcher'
        classNamePrefix='context-switcher'
        options={options}
        controlShouldRenderValue={true}
        autoFocus={false}
        maxMenuHeight={250}
        styles={{
          input: (provided, _state) => ({
            ...provided,
            margin: 0,
            paddingBottom: 0,
            paddingTop: 0,
          }),
        }}
        components={{ Option, Placeholder, DropdownIndicator }}
        isLoading={isFetchingAccessibleDivisions}
        loadingMessage={() => null}
        noOptionsMessage={() => (!!searchParam ? 'No matching results' : null)}
        filterOption={() => true}
        onInputChange={handleInputChange}
        onChange={handleChange}
        onFocus={() => setIsFetchingAccessibleDivisionsEnabled(true)}
        onMenuClose={() => setIsFetchingAccessibleDivisionsEnabled(false)}
        onMenuScrollToBottom={() => {
          if (hasNextPageOfAccessibleDivisions)
            fetchNextPageOfAccessibleDivisions();
        }}
      />
    </div>
  );
}
