import React from 'react';
import ProjectActivityTable from 'components/projectActivity/ProjectActivityTable';
import ProjectActivityBlankNotice from 'components/projectActivity/ProjectActivityBlankNotice';
import useToasts from 'components/hooks/useToasts';
import ToastRack from 'components/application/ToastRack';
import { useInfiniteQuery } from '@tanstack/react-query';
import { collateMessagesAndDispatches } from 'components/helpers/resources/messages';

export default function ProjectActivityTab({
  projectId,
  editProject,
  projectIncomplete,
  downloadDisabled,
  isVersionPdfsEnabled,
  currentUser,
}) {
  const [toasts, , , , addInedibleToast] = useToasts();
  const {
    data: histories,
    fetchNextPage: fetchNextHistoriesPage,
    hasNextPage: hasNextHistoriesPage,
    isFetchingNextPage: isFetchingNextHistoriesPage,
  } = useInfiniteQuery({
    queryKey: ['projectHistories', projectId],
    queryFn: async ({ pageParam = 1 }) =>
      (await getProjectHistories(projectId, pageParam)).data,
    getNextPageParam: (lastPage, _allPages) =>
      lastPage.meta.is_last_page ?
        undefined
      : parseInt(lastPage.meta.loaded_page_no) + 1,
    onError: addInedibleToast,
  });

  const getProjectHistories = (projectId, pageParam) =>
    axios.get(`/projects/${projectId}/histories`, {
      params: { page_no: pageParam },
    });

  const formatMessageInfo = (histories) => {
    if (!histories) {
      return {};
    }

    const allIncludedData = histories.pages.flatMap((page) => page.included);
    const allCollatedData = collateMessagesAndDispatches(allIncludedData);
    return allCollatedData.reduce((accumulator, object) => {
      const id = Object.keys(object)[0];
      accumulator[id] = object[id];
      return accumulator;
    }, {});
  };

  const formatHistories = (histories) => {
    if (!histories) {
      return [];
    }

    const allHistoriesData = histories.pages.flatMap((page) => page.data);
    return allHistoriesData.map((history) => {
      return {
        id: history.id,
        reason: history.attributes.reason,
        createdAt: history.attributes.createdAt,
        displayNumber: history.attributes.displayNumber,
        nadminUser: history.attributes.nadminUserShortName,
        messageId:
          history.relationships.message.data &&
          history.relationships.message.data.id,
        versionId:
          history.relationships.version.data &&
          parseInt(history.relationships.version.data.id),
      };
    });
  };

  return (
    <React.Fragment>
      {projectIncomplete ?
        <ProjectActivityBlankNotice
          editProject={editProject}
          isVersionPdfsEnabled={isVersionPdfsEnabled}
        />
      : <ProjectActivityTable
          projectId={projectId}
          currentUser={currentUser}
          isActive={!isFetchingNextHistoriesPage}
          projectHistories={formatHistories(histories)}
          messageInfo={formatMessageInfo(histories)}
          loadMore={fetchNextHistoriesPage}
          hasMore={hasNextHistoriesPage}
          isVersionPdfsEnabled={isVersionPdfsEnabled}
          downloadDisabled={downloadDisabled}
          addInedibleToast={addInedibleToast}
        />
      }
      <ToastRack toasts={toasts} />
    </React.Fragment>
  );
}
