import React, { useState, useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';
import useDebounce from 'components/hooks/useDebounce';
import Toggleable from 'components/application/Toggleable';
import CollectionSelect from 'components/application/CollectionSelect';
import SearchIcon from '-!svg-react-loader?name=SearchIcon!icons/search.svg';
import AddButton from 'components/buttons/AddButton';

export default function ToggleableSelect(props) {
  const [isToggled, setIsToggled] = useState(false);
  const [select, setSelect] = useState({ inputValue: null, callback: null });
  const [debouncedInputValue, resetDebouncedInputValue] = useDebounce(
    select.inputValue,
    250,
  );

  const resourceOption = (resource) => {
    return {
      label: resource.attributes.name,
      value: { resource: resource },
      disabled:
        props.isOptionDisabled ? props.isOptionDisabled(resource) : false,
      sublabel: props.renderSublabel ? props.renderSublabel(resource) : null,
    };
  };

  const fetchOptions = () => {
    if (debouncedInputValue && debouncedInputValue.length > 0) {
      axios
        .get(props.url, { params: { search: debouncedInputValue } })
        .then((response) =>
          select.callback(
            response.data.data.map((resource) => resourceOption(resource)),
          ),
        )
        .catch((_error) => props.onError && props.onError());
    } else {
      select.callback([]);
    }
  };

  const loadOptions = (inputValue, callback) =>
    setSelect({ inputValue: inputValue, callback: callback });

  const handleFocus = () => {
    props.onFocus && props.onFocus();
    resetDebouncedInputValue();
  };

  const handleBlur = () => {
    setIsToggled(false);
  };

  const handleChange = (selected, actionMeta) => {
    props.onChange(selected, actionMeta);
    setIsToggled(false);
  };

  const isOptionDisabled = (option) => option.disabled;

  useEffect(() => {
    if (select.callback) {
      fetchOptions();
    }
  }, [debouncedInputValue]);

  return (
    <Fragment>
      <Toggleable
        isToggled={isToggled}
        setIsToggled={setIsToggled}
        primary={
          <AddButton
            text={props.buttonText}
            onClick={props.onToggleablePrimaryClick}
          />
        }
        secondary={
          <div className='pos-rel'>
            <SearchIcon
              width={30}
              height={24}
              className='[&_path]:tw-fill-grey-700 zi-1 m-t-5 m-l-5 search-field__toggleable-icon'
            />
            <div className='fw-460'>
              <CollectionSelect
                name={props.name}
                placeholder={props.placeholder}
                modifiers={['inline-form', 'inline-search', 'large-menu']}
                isAsync={true}
                noDropdown={true}
                isCreatable={props.isCreatable}
                autoFocus={true}
                options={loadOptions}
                onChange={handleChange}
                externalProps={{
                  onFocus: handleFocus,
                  onBlur: handleBlur,
                  isOptionDisabled: isOptionDisabled,
                  isValidNewOption: props.isValidNewOption,
                  createOptionPosition: props.createOptionPosition,
                  additionalNoOptionsMessage: props.additionalNoOptionsMessage,
                }}
              />
            </div>
          </div>
        }
      />
    </Fragment>
  );
}

ToggleableSelect.defaultProps = {
  isCreatable: false,
  createOptionPosition: 'last',
};

ToggleableSelect.propTypes = {
  createOptionPosition: PropTypes.oneOf(['first', 'last']),
};
