import React, { useState, useRef } from 'react';
import moment from 'moment';
import enGb from 'date-fns/locale/en-GB';
import ReactDatePicker, { registerLocale } from 'react-datepicker';
import 'style-loader!react-datepicker/dist/react-datepicker.min.css';

export function DatePicker(props) {
  const datePickerRef = props.inputRef || useRef();

  return <AppDatePicker {...props} datePickerRef={datePickerRef} />;
}

function AppDatePicker(props) {
  registerLocale('en-gb', enGb);

  const {
    datePickerRef,
    className,
    name,
    value,
    placement,
    earliestDate,
    latestDate,
    placeholderText,
    isDisabled,
    disabledPlaceholderText,
    fieldError,
    removeErrorStyling,
    onCalendarClose,
    onChange,
  } = props;

  const [picker, setPicker] = useState('day');

  const isDayPickerActive = picker === 'day';
  const isMonthPickerActive = picker === 'month';

  const handleDatePickerFocus = (event) => {
    setPicker('day');
    if (removeErrorStyling) removeErrorStyling(event);
  };

  const handleDatePickerChange = (date, event) => {
    if (isDayPickerActive) {
      onChange(name, date);
      // internal open state not available via prop, used class component API
      // would break if internals changed from class component to functional component
      if (event.type === 'click') datePickerRef.current.setOpen(false);
    } else {
      setPicker('day');
    }
  };

  const handleDatePickerHeaderButtonClick = () => {
    if (isDayPickerActive) setPicker('month');
  };

  const isInvalid = !!fieldError?.fieldHighlighted;

  const inputClassName = (() => {
    switch (true) {
      case isDisabled:
        return 'tw-bg-grey-025 tw-text-grey-400 tw-border-grey-200 hover:tw-border-grey-200 focus-within:tw-border-grey-200 hover:focus-within:tw-border-grey-200';
      case isInvalid:
        return 'tw-border-red-600 hover:tw-border-red-600 focus-within:tw-border-red-600 hover:focus-within:tw-border-red-600';
      default:
        return 'tw-border-grey-300 hover:tw-border-grey-400 focus-within:tw-border-blue-300 hover:focus-within:tw-border-blue-300 placeholder:tw-text-grey-300';
    }
  })();

  const handleDatePickerCalenderClose = () => {
    if (onCalendarClose) {
      onCalendarClose(name);
    }
  };

  return (
    <ReactDatePicker
      ref={datePickerRef}
      locale='en-gb'
      className={`datepicker-input field__input tw-font-inter tw-text-m tw-font-normal tw-tracking-auto ${inputClassName}${className ? ` ${className}` : ''}`}
      calendarClassName='datepicker-calendar'
      selected={value}
      id={name}
      name={name}
      autoComplete='off'
      dateFormat={['dd/MM/yyyy', 'dd-MM-yyyy']}
      disabled={isDisabled}
      popperPlacement={placement}
      popperModifiers={{
        preventOverflow: { enabled: false },
        hide: { enabled: false },
      }}
      placeholderText={isDisabled ? disabledPlaceholderText : placeholderText}
      minDate={earliestDate}
      maxDate={latestDate}
      shouldCloseOnSelect={false}
      renderCustomHeader={(props) => {
        return CustomHeader({
          ...props,
          picker,
          onHeaderButtonClick:
            isDayPickerActive ? handleDatePickerHeaderButtonClick : undefined,
        });
      }}
      showMonthYearPicker={isMonthPickerActive}
      showFullMonthYearPicker={isMonthPickerActive}
      onChange={handleDatePickerChange}
      onFocus={handleDatePickerFocus}
      onCalendarClose={handleDatePickerCalenderClose}
    />
  );
}

export default function DatePickerFieldWrapper(props) {
  const datePickerRef = props.inputRef || useRef();

  return <DatePickerField {...props} datePickerRef={datePickerRef} />;
}

function DatePickerField(props) {
  const { datePickerRef, name, label, isRequired, fieldError } = props;

  const isInvalid = !!fieldError?.fieldHighlighted;

  return (
    <div className='datepicker-field'>
      <div className='form-group'>
        <label
          className={`field__label tw-font-medium${isRequired ? " after:tw-absolute after:tw-text-grey-500 after:tw-content-['Required'] after:tw-right-0 after:tw-font-normal" : ''}${isInvalid ? ' after:tw-text-red-600' : ''}`}
          htmlFor={name}
        >
          {label}
        </label>
        <div className='flex flex--vertically-centered pos-rel'>
          <AppDatePicker
            datePickerRef={datePickerRef}
            className='tw-max-w-[192px] tw-min-w-[148px]'
            name={name}
            value={props.value}
            placement={props.placement}
            earliestDate={props.earliestDate}
            latestDate={props.latestDate}
            placeholderText={props.placeholderText}
            isDisabled={props.isDisabled}
            disabledPlaceholderText={props.disabledPlaceholderText}
            fieldError={fieldError}
            removeErrorStyling={props.removeErrorStyling}
            onCalendarClose={props.onCalendarClose}
            onChange={props.onChange}
          />
        </div>
      </div>
    </div>
  );
}

DatePickerField.defaultProps = {
  isRequired: false,
  isDisabled: false,
  disabledPlaceholderText: '',
  placeholderText: '',
  placement: 'bottom-start',
  sumbitDisabled: false,
  fieldError: undefined,
};

const CustomHeader = function (props) {
  const {
    date,
    increaseMonth,
    increaseYear,
    decreaseMonth,
    decreaseYear,
    nextMonthButtonDisabled,
    nextYearButtonDisabled,
    prevMonthButtonDisabled,
    prevYearButtonDisabled,
    picker,
    onHeaderButtonClick,
  } = props;

  const pickerMap = {
    day: {
      dateFormat: 'MMMM YYYY',
      prevLabel: 'Previous Month',
      prevOnClick: decreaseMonth,
      prevDisabled: prevMonthButtonDisabled,
      nextLabel: 'Next Month',
      nextOnClick: increaseMonth,
      nextDisabled: nextMonthButtonDisabled,
    },
    month: {
      dateFormat: 'YYYY',
      prevLabel: 'Previous Year',
      prevOnClick: decreaseYear,
      prevDisabled: prevYearButtonDisabled,
      nextLabel: 'Next Year',
      nextOnClick: increaseYear,
      nextDisabled: nextYearButtonDisabled,
    },
  };

  return (
    <>
      {!pickerMap[picker].prevDisabled && (
        <button
          type='button'
          className='react-datepicker__navigation react-datepicker__navigation--previous'
          aria-label={pickerMap[picker].prevLabel}
          onClick={pickerMap[picker].prevOnClick}
        />
      )}
      <div className='react-datepicker__header'>
        <button
          className={`react-datepicker__header-button${onHeaderButtonClick ? ' react-datepicker__header-button--clickable' : ''}`}
          onClick={onHeaderButtonClick}
        >
          {moment.parseZone(date).format(pickerMap[picker].dateFormat)}
        </button>
      </div>
      {!pickerMap[picker].nextDisabled && (
        <button
          type='button'
          className='react-datepicker__navigation react-datepicker__navigation--next'
          aria-label={pickerMap[picker].nextLabel}
          onClick={pickerMap[picker].nextOnClick}
        />
      )}
    </>
  );
};
