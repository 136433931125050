import React from 'react';
import PropTypes from 'prop-types';
import Tooltip from 'components/application/Tooltip';

export default function RadioField({
  className,
  checked,
  onChange,
  value,
  name,
  label,
  labelId,
  disabled,
  tooltip,
}) {
  return (
    <div
      className={`radio-container tooltip-parent${className ? ` ${className}` : ''}`}
    >
      <label
        className={`radio-container__label tw-font-normal${checked ? ' radio-container__label--active' : ''}`}
        id={labelId}
      >
        <input
          type='radio'
          checked={checked}
          onChange={onChange}
          value={value}
          name={name}
          disabled={disabled}
        />
        <div
          className={`radio-container__outer-circle tw-peer/outer-circle tw-cursor-pointer tw-bg-white ${checked ? 'tw-border-blue-400 hover:tw-border-blue-500 hover:tw-bg-blue-025' : 'tw-border-grey-300 hover:tw-border-blue-300'}${disabled ? ' tw-opacity-30' : ''}`}
        ></div>
        <span
          className={`radio-container__checkmark after:tw-cursor-pointer after:tw-absolute after:tw-m-[6px] after:tw-bg-blue-400 peer-hover/outer-circle:after:tw-bg-blue-500 after:tw-rounded-full after:tw-w-2 after:tw-h-2 after:tw-content-[""] ${checked ? 'after:tw-block' : 'after:tw-hidden'}${disabled ? ' tw-opacity-30' : ''}`}
        ></span>
        <div className='m-l-28 radio-container__text'>{label}</div>
      </label>
      {disabled && (
        <Tooltip
          placement='left'
          trigger='hover'
          tooltip={tooltip}
          className='tooltip-dark--max-w-xxs'
        />
      )}
    </div>
  );
}

RadioField.propTypes = {
  className: PropTypes.string,
  checked: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.node.isRequired,
  onChange: PropTypes.func.isRequired,
};
