import React from 'react';
import useForm from 'components/hooks/useForm';
import useRequestError from 'components/hooks/useRequestError';
import PasswordField from 'components/messages/PasswordField';
import ClockIcon from '-!svg-react-loader?name=ClockIcon!icons/clock.svg';
import ActionButton from 'components/buttons/ActionButton';
import { withBranding } from 'components/messages/DownloadContainer';
import axios from 'axios';

export default function MessageAccessContainer({
  messageInfo,
  companyBranding,
  accessibilityStatus,
}) {
  const [requestError, , , , handleRequestError] = useRequestError();
  const [passwordInput, , handlePasswordInputChange] = useForm({
    password: '',
  });

  const branding = companyBranding.data;

  const BrandedButton = withBranding(ActionButton, {
    backgroundColor: branding.attributes.buttonBgColour,
    color: branding.attributes.buttonTextColour,
  });

  const submitPassword = () => {
    axios
      .post(`/messages/${messageInfo.hashid}/message_access`, passwordInput)
      .then((response) => {
        window.location.assign(
          `/messages/${messageInfo.hashid}?token=${response.data.data.attributes.token}`,
        );
      })
      .catch(handleRequestError);
  };

  const handleInputKeyDown = (event) => {
    if (event.key === 'Enter') {
      submitPassword();
    }
  };

  const renderComponent = () => {
    switch (accessibilityStatus) {
      case 'expired':
        return <DownloadUnavailableComponent reason={'expired'} icon={true} />;
      case 'disabled':
        return <DownloadUnavailableComponent reason={'been disabled'} />;
      case 'protected':
        return (
          <PasswordField
            validationError={requestError.validationErrors.password}
            errorMessage={'Password was incorrect. Please try again.'}
            instructionMessage={
              'Please enter the password to view these documents'
            }
            passwordInput={passwordInput}
            onPasswordInputChange={handlePasswordInputChange}
            onInputKeyDown={handleInputKeyDown}
          >
            <BrandedButton buttonText='Continue' onClick={submitPassword} />
          </PasswordField>
        );
    }
  };

  return (
    <div className='download-container tw-bg-white'>
      <div className='download-container__title tw-font-medium'>
        {messageInfo.companyName}
      </div>
      <div className='download-container__name tw-text-xl tw-text-grey-900 tw-font-semibold tw-tracking-tight'>
        {messageInfo.projectName}
      </div>
      {renderComponent()}
    </div>
  );
}

function DownloadUnavailableComponent(props) {
  return (
    <div className='download-container__sub-text tw-text-red-600 m-t-20'>
      {props.icon && (
        <ClockIcon
          className='[&_path]:tw-fill-red-600 download-container__svg m-b-2 m-r-2'
          height={18}
          width={18}
        />
      )}
      {`Sorry, this download has ${props.reason} and is no longer available`}
    </div>
  );
}
