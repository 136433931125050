export const baseStoreState = {
  domain: {},
  application: {
    pending: {},
  },
  ui: {},
};

// "Selectors"

function relationCollection(state, relationType) {
  return state.domain[`${relationType}Collection`];
}

export function resourcesAsDomainData(resources) {
  return resources.reduce((accumulator, resourceName) => {
    accumulator[`${resourceName}Collection`] = { data: {}, allDataIds: [] };
    return accumulator;
  }, {});
}

export function findBelongsToRelation(state, resource, relationType) {
  const resourceRelation =
    resource.relationships[relationType] &&
    resource.relationships[relationType].data;
  const relationData =
    resourceRelation &&
    relationCollection(state, relationType).data[resourceRelation.id];
  return relationData || null;
}

// note both has many/one are using state data to find related resources rather than relying on relationships on the resource

export function findHasManyRelations(state, resource, relationType) {
  const relationIds = relationCollection(state, relationType).allDataIds.filter(
    (relationId) => {
      const relationMember = relationCollection(state, relationType).data[
        relationId
      ];
      const memberRelationshipData =
        relationMember.relationships[resource.type].data;
      return (
        memberRelationshipData && memberRelationshipData.id === resource.id
      );
    },
  );
  return relationIds.map(
    (relationId) => relationCollection(state, relationType).data[relationId],
  );
}

export function findRelationalHasManyRelations(
  store,
  resource,
  relationTypeSingular,
  relationTypePlural = `${relationTypeSingular}s`,
) {
  return resource.relationships[relationTypePlural].data.map(
    (relationship) =>
      relationCollection(store, relationTypeSingular).data[relationship.id],
  );
}

export function findHasOneRelation(state, resource, relationType) {
  const relationId = relationCollection(state, relationType).allDataIds.find(
    (relationId) => {
      const relationMember = relationCollection(state, relationType).data[
        relationId
      ];
      const memberRelationshipData =
        relationMember.relationships[resource.type].data;
      return (
        memberRelationshipData && memberRelationshipData.id === resource.id
      );
    },
  );
  return relationCollection(state, relationType).data[relationId] || null;
}

// reducer mutators

export function populateResources(
  draftState,
  resourceCollection,
  resourceType,
) {
  resourceCollection.forEach((currentValue) => {
    const resourceName = resourceType || currentValue.type;
    const collectionStore = relationCollection(draftState, resourceName);

    collectionStore.data[currentValue.id] = currentValue;
    if (!collectionStore.allDataIds.includes(currentValue.id)) {
      collectionStore.allDataIds.push(currentValue.id);
    }
  });
  return draftState;
}

export function removeStaleResourceType(
  draftState,
  resourceCollection,
  resourceType,
) {
  const collectionIds = resourceCollection.map((resource) => resource.id);

  const newDataIds = draftState.domain[
    `${resourceType}Collection`
  ].allDataIds.filter((id) => {
    return collectionIds.includes(id);
  });

  draftState.domain[`${resourceType}Collection`].allDataIds = newDataIds;

  const filteredData = Object.keys(
    draftState.domain[`${resourceType}Collection`].data,
  )
    .filter((keyId) => {
      return newDataIds.includes(keyId);
    })
    .reduce((accumulator, keyId) => {
      accumulator[keyId] =
        draftState.domain[`${resourceType}Collection`].data[keyId];
      return accumulator;
    }, {});

  draftState.domain[`${resourceType}Collection`].data = filteredData;
  return draftState;
}

export function addResource(draftState, resourceData) {
  relationCollection(draftState, resourceData.type).data[resourceData.id] =
    resourceData;
  relationCollection(draftState, resourceData.type).allDataIds.push(
    resourceData.id,
  );
}

export function removeResource(draftState, { type, id }) {
  const indexToRemove = relationCollection(
    draftState,
    type,
  ).allDataIds.findIndex((resourceId) => resourceId === id);
  if (indexToRemove > -1) {
    relationCollection(draftState, type).allDataIds.splice(indexToRemove, 1);
  }

  delete relationCollection(draftState, type).data[id];
  return draftState;
}

export function replaceResource(draftState, resourceData) {
  relationCollection(draftState, resourceData.type).data[resourceData.id] =
    resourceData;
  return draftState;
}

// TODO decide on API
export function assignRelationship(draftState, resources, relationship) {
  const existingRelationships = relationCollection(
    draftState,
    relationship.resource.type,
  ).data[relationship.resource.id].relationships;
  if (!existingRelationships) {
    relationCollection(draftState, relationship.resource.type).data[
      relationship.resource.id
    ].relationships = {};
  }
  relationCollection(draftState, relationship.resource.type).data[
    relationship.resource.id
  ].relationships[relationship.name] = {
    data: resources.map((resource) => {
      return (({ id, type }) => ({ id, type }))(resource);
    }),
  };
}
