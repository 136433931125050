import React from 'react';
import PropTypes from 'prop-types';

import ErrorMessage from 'components/application/ErrorMessage';
import TextField from 'components/application/TextField';
import CollectionSelect from 'components/application/CollectionSelect';

import { resourcesAsOptions } from 'components/helpers/forms';
import { useCurrentActor } from 'components/contexts/CurrentActor';

export default function RolesForm({
  currentRole,
  assignableCourses,
  onCourseCompanyRoleRequiredChange,
  onCourseCompanyRoleDelete,
  sidePanelFieldRef,
  onInputChange,
  onOptionChange,
  requestError,
  removeErrorStyling,
}) {
  const currentActor = useCurrentActor();
  // filter out already selected courses
  const selectedCourseIds =
    currentRole.courseCompanyRoles ?
      currentRole.courseCompanyRoles.map((ccr) => ccr.courseId)
    : [];
  const selectableCourses = assignableCourses.filter(
    (course) => !selectedCourseIds.includes(course.id),
  );

  // order courseCompanyRoles alphabetically
  const orderedCourseCompanyRoles = currentRole.courseCompanyRoles
    .map((courseCompanyRole) => {
      const courseId = courseCompanyRole.courseId;
      const courseName = assignableCourses.find(
        (course) => course.id == courseId,
      ).attributes.name;
      return {
        courseId: courseId,
        courseName: courseName,
        required: courseCompanyRole.required,
      };
    })
    .sort((a, b) =>
      a.courseName.localeCompare(b.courseName, 'en', { sensitivity: 'base' }),
    );

  return (
    <React.Fragment>
      <div className='popup__body-form'>
        <ErrorMessage
          wrapperClassName='form-container'
          validationErrors={requestError.validationErrors}
          isFallback={requestError.isFallback}
        />
        <div className='form-container m-b--24'>
          <TextField
            label='Role'
            name='position'
            inputRef={sidePanelFieldRef}
            value={currentRole.position}
            isRequired={true}
            fieldError={requestError.validationErrors.position}
            removeErrorStyling={removeErrorStyling}
            onChange={onInputChange}
          />
        </div>
      </div>
      {currentActor.isAllowedFeature('training_register') && (
        <>
          <hr className='tw-h-px tw-bg-grey-100 tw-m-0 tw-border-0' />
          <div className='popup__body-form p-b-0 m-b-32'>
            <div className='form-container'>
              <CollectionSelect
                label='Courses for this role'
                placeholder='Search courses...'
                name='courseIds'
                value={null}
                modifiers={['expandable']}
                options={resourcesAsOptions({
                  resourcesCollection: selectableCourses,
                  resourceIdentifier: 'name',
                })}
                externalProps={{
                  additionalNoOptionsMessage:
                    'New courses must be added from the course register.',
                }}
                onChange={(e) => onOptionChange(e.value.toString())}
              />
            </div>
          </div>
        </>
      )}

      {currentActor.isAllowedFeature('training_register') && (
        <div className='popup__body-form p-b-0 p-t-0 m-b-32'>
          <div className='form-container'>
            {orderedCourseCompanyRoles.map((courseCompanyRole) => {
              return (
                <div
                  key={courseCompanyRole.courseId}
                  className='tw-group/pill tw-rounded-md tw-bg-grey-050 chip-item-pill w-100 py-10 px-12 m-b-8 tw-flex tw-relative tw-justify-between tw-items-center'
                >
                  <div className='tw-text-s tw-font-medium tw-tracking-wide'>
                    {courseCompanyRole.courseName}
                  </div>

                  <div className='tw-flex tw-items-center'>
                    <div className='tw-w-28'>
                      <CollectionSelect
                        modifiers={['pill']}
                        label=''
                        name='requiredCCR'
                        isSearchable={false}
                        value={
                          courseCompanyRole.required ?
                            { value: true, label: 'Required' }
                          : { value: false, label: 'Optional' }
                        }
                        options={[
                          { value: true, label: 'Required' },
                          { value: false, label: 'Optional' },
                        ]}
                        onChange={(e) =>
                          onCourseCompanyRoleRequiredChange(
                            courseCompanyRole.courseId,
                            e.value,
                          )
                        }
                      />
                    </div>
                    <span
                      onClick={() =>
                        onCourseCompanyRoleDelete(courseCompanyRole.courseId)
                      }
                      className="collection-select__multivalue-remove m-l-8 circle--remove tw-bg-transparent hover:tw-bg-red-600 before:tw-content-[''] before:tw-bg-grey-700 hover:before:tw-bg-white after:tw-content-[''] after:tw-bg-grey-700 hover:after:tw-bg-white"
                    />
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      )}
    </React.Fragment>
  );
}

RolesForm.propTypes = {
  currentRole: PropTypes.object.isRequired,
  onInputChange: PropTypes.func.isRequired,
  onOptionChange: PropTypes.func.isRequired,
};
