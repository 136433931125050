import PropTypes from 'prop-types';
import React, { useState } from 'react';
import CheckboxField from '../../application/CheckboxField';

export default function NearestMedicalLocationFeatureSection({
  nearestMedicalLocationFeatureAllocationId,
  nearestMedicalLocationFeatureId,
  nhsApiLookupFeatureAllocationId,
  nhsApiLookupFeatureId,
  showNhsApiLookupFeatureCheckbox,
}) {
  const [
    isNearestMedicalLocationFeatureChecked,
    setIsNearestMedicalLocationFeatureChecked,
  ] = useState(!!nearestMedicalLocationFeatureAllocationId);
  const [isNhsApiLookupFeatureChecked, setIsNhsApiLookupFeatureChecked] =
    useState(!!nhsApiLookupFeatureAllocationId);

  const nearestMedicalLocationFeatureAllocationActionRequired =
    (
      !nearestMedicalLocationFeatureAllocationId &&
      isNearestMedicalLocationFeatureChecked
    ) ?
      'create'
    : (
      !!nearestMedicalLocationFeatureAllocationId &&
      !isNearestMedicalLocationFeatureChecked
    ) ?
      'destroy'
    : null;

  const nhsApiLookupFeatureAllocationActionRequired =
    !nhsApiLookupFeatureAllocationId && isNhsApiLookupFeatureChecked ? 'create'
    : !!nhsApiLookupFeatureAllocationId && !isNhsApiLookupFeatureChecked ?
      'destroy'
    : null;

  return (
    <>
      <h3 className='tw-font-semibold tw-mb-6 tw-mt-0 tw-text-grey-900 tw-text-l tw-tracking-auto'>
        Nearest A&E
      </h3>
      <p className='tw-mb-5'>
        You can enable this option to add nearest A&E to your documents.
      </p>
      <div className='tw-flex tw-flex-col tw-gap-y-5'>
        <CheckboxField
          checked={isNearestMedicalLocationFeatureChecked}
          label='Show the nearest A&E locator in project details'
          labelTextProps={{ className: 'tw-ml-3' }}
          name='project_configuration[nearest_a_and_e_feature]'
          onChange={(event) => {
            const isChecked = event.target.checked;
            setIsNearestMedicalLocationFeatureChecked(isChecked);
            setIsNhsApiLookupFeatureChecked(isChecked);
          }}
        />
        {(nearestMedicalLocationFeatureAllocationActionRequired ===
          'create' && (
          <input
            name='project_configuration[feature_allocations_attributes][0][feature_id]'
            type='hidden'
            value={nearestMedicalLocationFeatureId}
          />
        )) ||
          (nearestMedicalLocationFeatureAllocationActionRequired ===
            'destroy' && (
            <>
              <input
                name='project_configuration[feature_allocations_attributes][0][id]'
                type='hidden'
                value={nearestMedicalLocationFeatureAllocationId}
              />
              <input
                name='project_configuration[feature_allocations_attributes][0][_destroy]'
                type='hidden'
                value='1'
              />
            </>
          ))}
        {showNhsApiLookupFeatureCheckbox && (
          <>
            <CheckboxField
              checked={isNhsApiLookupFeatureChecked}
              disabled={!isNearestMedicalLocationFeatureChecked}
              label='Look up nearest A&E using the NHS API (note: this option is only valid within England)'
              labelTextProps={{ className: 'tw-ml-3' }}
              name='project_configuration[nhs_api_lookup_feature]'
              onChange={(event) =>
                setIsNhsApiLookupFeatureChecked(event.target.checked)
              }
            />
            {(nhsApiLookupFeatureAllocationActionRequired === 'create' && (
              <input
                name='project_configuration[feature_allocations_attributes][1][feature_id]'
                type='hidden'
                value={nhsApiLookupFeatureId}
              />
            )) ||
              (nhsApiLookupFeatureAllocationActionRequired === 'destroy' && (
                <>
                  <input
                    name='project_configuration[feature_allocations_attributes][1][id]'
                    type='hidden'
                    value={nhsApiLookupFeatureAllocationId}
                  />
                  <input
                    name='project_configuration[feature_allocations_attributes][1][_destroy]'
                    type='hidden'
                    value='1'
                  />
                </>
              ))}
          </>
        )}
      </div>
      <hr className='tw-bg-grey-100 tw-border-0 tw-h-px tw-my-10' />
    </>
  );
}

NearestMedicalLocationFeatureSection.propTypes = {
  nearestMedicalLocationFeatureAllocationId: PropTypes.number,
  nearestMedicalLocationFeatureId: PropTypes.number.isRequired,
  nhsApiLookupFeatureAllocationId: PropTypes.number,
  nhsApiLookupFeatureId: PropTypes.number,
  showNhsApiLookupFeatureCheckbox: PropTypes.bool,
};
