import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { resourceShape } from 'components/helpers/serialisableResources';
import Tooltip from 'components/application/Tooltip';
import SidePanel from 'components/application/SidePanel';
import ErrorMessage from 'components/application/ErrorMessage';
import FormFooter from 'components/sidepanels/FormFooter';
import TrainingFormFields from 'components/training/TrainingFormFields';
import ConditionalWrapper from 'components/application/ConditionalWrapper';
import { useTrackedPersonnelContext } from 'components/contexts/TrackedPersonnelContext';
import { useCurrentActor } from 'components/contexts/CurrentActor';
import { sendAnalytics } from 'components/helpers/analytics';
import AttachmentsField from 'components/attachments/AttachmentsField';

import RadioField from 'components/application/RadioField';

export default function TrainingRequirementsSidePanel(props) {
  const [scrolledAmount, setScrolledAmount] = useState(0);
  const currentActor = useCurrentActor();
  const trackedPersonnelContext = useTrackedPersonnelContext();

  const currentTrainingAttachments = useMemo(() => {
    return props.includedAttachments.filter((a) =>
      props.currentTraining.attachmentIds.includes(Number(a.id)),
    );
  }, [props.includedAttachments, props.currentTraining.attachmentIds]);

  const handleCancel = () => {
    props.closeSidePanel();
    props.resetRequestError();
  };

  const onBackdropClick = () =>
    !['new', 'edit'].includes(props.sidePanelContext) && props.closeSidePanel();

  return (
    <SidePanel
      color={props.sidePanelContext === 'show' ? 'secondary' : 'tertiary'}
      isOpen={props.sidePanelIsOpen}
      scrolledAmount={scrolledAmount}
      setScrolledAmount={setScrolledAmount}
      displayClose={false}
      onBackdropClick={onBackdropClick}
      closeCallback={props.closeSidePanel}
      headerContent={
        <div className='popup__title popup__title--tertiary-split'>
          <h1 className='tw-text-s tw-font-medium tw-tracking-tight m-b-8'>{`${props.sidePanelContext === 'new' ? 'Add' : 'Edit'} training course for`}</h1>
          <h2 className='truncated-text-container--wrapped truncated-text-container--five-lines tw-text-xl tw-text-grey-900 tw-font-semibold tw-tracking-tight'>
            {props.domainCourse?.attributes?.name}
          </h2>
        </div>
      }
      bodyContent={
        <div className='popup__body-form'>
          <ErrorMessage
            wrapperClassName='form-container'
            validationErrors={props.requestError.validationErrors}
            isFallback={props.requestError.isFallback}
          />
          <div className='form-container'>
            <label className='field__label tw-font-medium'>
              <span className='m-r-4'>Is this course required?</span>
            </label>
            <div className='tw-mb-4'>
              <RadioField
                checked={props.currentTrainingRequirement.isCourseRequired}
                onChange={() =>
                  props.onTrainingRequirementInputChange({
                    target: { name: 'isCourseRequired', value: true },
                  })
                }
                value={true}
                name='required_true'
                label={'Required'}
              />
              <div className='tw-text-s tw-font-normal tw-px-7 tw-mt-[-8px]'>
                Affects training status, email notifications will be sent
              </div>
            </div>
            <div>
              <RadioField
                checked={!props.currentTrainingRequirement.isCourseRequired}
                onChange={() =>
                  props.onTrainingRequirementInputChange({
                    target: { name: 'isCourseRequired', value: false },
                  })
                }
                value={false}
                name='required_false'
                label={'Optional'}
              />
              <div className='tw-text-s tw-font-normal tw-px-7 tw-mt-[-8px]'>
                Training status unaffected, no email notifications sent
              </div>
            </div>
            <hr className='m-t-32 m-r--20 m-b-32 m-l--20' />
            <label className='field__label tw-font-medium'>
              Has this training already taken place?
            </label>
            <RadioField
              checked={!props.currentTrainingRequirement.isRecordingTraining}
              onChange={() =>
                props.onTrainingRequirementInputChange({
                  target: { name: 'isRecordingTraining', value: false },
                })
              }
              value={false}
              name='record_training_false'
              label={'No'}
            />
            <ConditionalWrapper
              condition={
                trackedPersonnelContext.hasReachedLimit &&
                !trackedPersonnelContext.isCurrentPersonnelTracked
              }
              wrapper={(children) => (
                <Tooltip
                  placement='left'
                  trigger='hover'
                  tooltip='You have reached your training limit and can’t record training for this personnel'
                  className='tooltip-dark--max-w-xxs'
                >
                  {children}
                </Tooltip>
              )}
            >
              <RadioField
                disabled={
                  trackedPersonnelContext.hasReachedLimit &&
                  !trackedPersonnelContext.isCurrentPersonnelTracked
                }
                checked={props.currentTrainingRequirement.isRecordingTraining}
                onChange={() =>
                  props.onTrainingRequirementInputChange({
                    target: { name: 'isRecordingTraining', value: true },
                  })
                }
                value={true}
                name='record_training_true'
                label={'Yes'}
              />
            </ConditionalWrapper>
            {props.currentTrainingRequirement.isRecordingTraining && (
              <>
                <TrainingFormFields
                  currentTraining={props.currentTraining}
                  domainCourse={props.domainCourse}
                  requestError={props.requestError}
                  removeErrorStyling={props.removeErrorStyling}
                  onInputChange={props.onTrainingInputChange}
                  onTrainingDateChange={props.onTrainingDateChange}
                  onTrainingCalendarClose={props.onTrainingCalendarClose}
                />
                <AttachmentsField
                  label='Upload evidence'
                  tooltip='E.g. certificates or cards'
                  name='attachmentIds'
                  value={props.currentTraining.attachmentIds}
                  isVisible={props.sidePanelIsOpen}
                  seedAttachments={{
                    data: currentTrainingAttachments,
                    included: props.includedCoverImages,
                  }}
                  onChange={props.onTrainingInputChange}
                  onError={props.onError}
                  maxUploads={25}
                  onUploadSuccess={() =>
                    sendAnalytics('Training evidence uploaded', {
                      currentUser: currentActor.user,
                      personnelId: props.personnelId,
                    })
                  }
                />
              </>
            )}
          </div>
        </div>
      }
      footerContent={
        <FormFooter
          text={
            props.sidePanelContext === 'new' ?
              'Add training course'
            : 'Save changes'
          }
          onCancel={handleCancel}
          onSubmit={
            props.sidePanelContext === 'new' ?
              props.createTrainingRequirement
            : props.updateTraining
          }
          submitButtonDisabled={props.submitDisabled}
        />
      }
      contentContext={props.sidePanelContext}
      submitDisabled={props.submitDisabled}
    />
  );
}

TrainingRequirementsSidePanel.propTypes = {
  domainCourse: resourceShape('course'),
  currentTraining: PropTypes.object.isRequired,
  currentTrainingRequirement: PropTypes.object.isRequired,
  includedAttachments: PropTypes.array.isRequired,
  sidePanelIsOpen: PropTypes.bool.isRequired,
  sidePanelContext: PropTypes.string.isRequired,
  requestError: PropTypes.object.isRequired,
  submitDisabled: PropTypes.bool.isRequired,
  personnelId: PropTypes.string,
  includedCoverImages: PropTypes.array.isRequired,
  closeSidePanel: PropTypes.func.isRequired,
  resetRequestError: PropTypes.func.isRequired,
  onTrainingDateChange: PropTypes.func.isRequired,
  onTrainingInputChange: PropTypes.func.isRequired,
  onTrainingRequirementInputChange: PropTypes.func.isRequired,
  removeErrorStyling: PropTypes.func.isRequired,
  onTrainingCalendarClose: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,
  createTrainingRequirement: PropTypes.func.isRequired,
  updateTraining: PropTypes.func,
};
