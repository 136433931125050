import React from 'react';
import PropTypes from 'prop-types';
import { resourceShape } from 'components/helpers/serialisableResources';
import moment from 'moment';

import { useCurrentActor } from 'components/contexts/CurrentActor';

import ActionsPill from 'components/application/ActionsPill';

import DesktopMobileIcon from '-!svg-react-loader?name=DesktopMobileIcon!icons/desktop-mobile.svg';
import LaunchIcon from '-!svg-react-loader?name=LaunchIcon!icons/launch.svg';
import CalendarIcon from '-!svg-react-loader?name=CalendarIcon!icons/calendar.svg';
import AutoEnrolIcon from '-!svg-react-loader?name=AutoEnrolIcon!icons/double-arrow-rotate.svg';
import {
  hasOnlyNonCriticalBlockers,
  autoEnrolmentNonCriticalBlockersStatusText,
} from 'components/helpers/resources/autoEnrolments';

const bookingTooltipOptions = {
  expiring: 'Training is expiring soon and requires booking',
  expired: 'Training is expired and requires booking',
  missing: 'Training is missing and requires booking',
  personnelEmailUnavailable:
    'Personnel must have an email address to book eLearning',
  creditInfoUnavailable:
    'There was a problem displaying your remaining credits',
};

const enrolmentTooltipText = ({ booking, booker, registration }) => {
  let tooltipText = '';

  if (booking.attributes.autoEnrolled) {
    tooltipText = `Course was auto-enrolled on ${moment(booking.attributes.createdAt).format('D MMM YY')}`;
  } else if (booker) {
    tooltipText = `Course was enrolled by ${booker.attributes.firstName} ${booker.attributes.lastName} on ${moment(booking.attributes.createdAt).format('D MMM YY')}`;
  } else {
    tooltipText = `Course was enrolled on ${moment(booking.attributes.createdAt).format('D MMM YY')}`;
  }

  return registration ?
      `${tooltipText} and was last accessed on ${moment(registration.attributes.updatedAt).format('D MMM YY')}`
    : tooltipText;
};

export default function ELearningBookings({
  booking,
  booker,
  course,
  eLearningCourse,
  registration,
  latestTraining,
  currentTrainingStatus,
  currentPersonnel,
  isCreditInfoUnavailable,
  isEligibleForManualBooking,
  prioritisedAutoEnrolmentBlockers,
  currentAutoEnrolmentExclusionUserSelection,
  isELearningLaunchable,
  onAddELearningBooking,
}) {
  const currentActor = useCurrentActor();
  const isPersonnelEmailUnavailable = !currentPersonnel.email;
  const isELearningNonCustom =
    eLearningCourse && !eLearningCourse.attributes.custom;
  let bookingTooltipKey;

  if (isELearningNonCustom && isCreditInfoUnavailable) {
    bookingTooltipKey = 'creditInfoUnavailable';
  } else if (isPersonnelEmailUnavailable) {
    bookingTooltipKey = 'personnelEmailUnavailable';
  } else {
    bookingTooltipKey = currentTrainingStatus;
  }

  if (booking && isELearningLaunchable) {
    return (
      <ActionsPill
        color='cyan'
        displayText={`${registration ? 'Continue' : 'Start'} eLearning`}
        icon={<DesktopMobileIcon height={16} width={16} />}
        actionIcon={<LaunchIcon className='tw-m-1' height={16} width={16} />}
        onClick={() =>
          window.open(
            `/e_learning/bookings/${booking.attributes.hashid}`,
            '_blank',
          )
        }
      />
    );
  } else {
    if (registration) {
      return (
        <ActionsPill
          color='green'
          displayText='eLearning in progress'
          icon={<CalendarIcon height={16} width={16} />}
          tooltipText={enrolmentTooltipText({ booking, booker, registration })}
        />
      );
    }

    if (booking) {
      return (
        <ActionsPill
          color='green'
          displayText='eLearning enrolled'
          icon={<CalendarIcon height={16} width={16} />}
          tooltipText={enrolmentTooltipText({ booking, booker, registration })}
        />
      );
    }
  }
  if (
    currentActor.isAllowedFeature([
      'e_learning_auto_enrol',
      'e_learning',
      'training_register',
    ]) &&
    !currentAutoEnrolmentExclusionUserSelection
  ) {
    const hasNoBlockers = prioritisedAutoEnrolmentBlockers.length === 0;
    const hasNonCriticalBlockers = hasOnlyNonCriticalBlockers({
      prioritisedAutoEnrolmentBlockers,
    });

    if (hasNoBlockers || hasNonCriticalBlockers) {
      return (
        <ActionsPill
          color={`${hasNonCriticalBlockers ? 'amber' : 'green'}`}
          displayText='Auto-enrols'
          icon={<AutoEnrolIcon height={16} width={16} />}
          tooltipText={autoEnrolmentNonCriticalBlockersStatusText({
            priorityBlocker: prioritisedAutoEnrolmentBlockers[0],
            latestTraining: latestTraining,
            courseExpiringDuration: course?.attributes?.expiringDuration,
          })}
        />
      );
    }
  }

  if (isEligibleForManualBooking)
    return (
      <ActionsPill
        color='cyan'
        isDisabled={isPersonnelEmailUnavailable || isCreditInfoUnavailable}
        displayText='Enrol eLearning'
        icon={<CalendarIcon height={16} width={16} />}
        tooltipText={bookingTooltipOptions[bookingTooltipKey]}
        onClick={() => onAddELearningBooking({ course, eLearningCourse })}
      />
    );

  return null;
}

ELearningBookings.propTypes = {
  booking: PropTypes.object,
  booker: resourceShape('simpleUser'),
  course: PropTypes.object,
  eLearningCourse: resourceShape('eLearningCourse'),
  registration: PropTypes.object,
  latestTraining: PropTypes.object,
  currentTrainingStatus: PropTypes.string,
  currentPersonnel: PropTypes.object,
  isCreditInfoUnavailable: PropTypes.bool,
  isEligibleForManualBooking: PropTypes.bool,
  prioritisedAutoEnrolmentBlockers: PropTypes.array,
  currentAutoEnrolmentExclusionUserSelection: PropTypes.bool,
  isELearningLaunchable: PropTypes.bool,
  onAddELearningBooking: PropTypes.func,
};

ELearningBookings.defaultProps = {
  hasPersonnelEditableAccess: true,
};
