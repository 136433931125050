import { enableAllPlugins } from 'immer';
enableAllPlugins();
import { produce } from 'immer';

export const personnelShowPageInitialStore = {
  domain: {
    personnel: null,
    assignableCompanies: [],
    assignableRoles: [],
    fieldValues: {},
    training: { data: {}, allDataIds: [] },
    companyRoles: {},
    courseCompanyRoles: {},
    userCompanyRoles: {},
    userCourses: {},
    autoEnrolmentConfigurations: {},
    autoEnrolmentExclusions: {},
    lineManagers: {},
    companies: {},
    teams: {},
    subcontractors: {},
    courses: {},
    eLearningCourses: {},
    attachments: {},
    coverImages: {},
    personnelEvents: {},
    bookings: {},
    registrations: {},
    personnelEventsMeta: {},
    bookers: {},
    users: {},
  },
  application: {
    pending: {
      personnelFetch: true,
      trainingFetch: true,
      assignableCompaniesFetch: true,
      assignableRolesFetch: true,
      personnelEventsFetch: true,
    },
  },
};

export const personnelShowPageReducer = (state, action) => {
  return produce(state, (draftState) => {
    switch (action.type) {
      case 'LOAD_PERSONNEL':
        draftState.domain.personnel = action.payload.member;
        draftState.domain.bookings = {};
        draftState.domain.fieldValues = {};
        draftState.domain.bookers = {};

        Object.keys(action.payload.included).forEach((payloadKey) => {
          action.payload.included[payloadKey].forEach(
            (parsedResource) =>
              (draftState.domain[payloadKey][parsedResource.id] =
                parsedResource),
          );
        });
        if (action.payload.included.lineManagers.length === 0) {
          draftState.domain.lineManagers = {};
        }
        draftState.application.pending.personnelFetch = false;
        break;
      case 'LOAD_TRAINING':
        draftState.domain.training = { data: {}, allDataIds: [] };
        draftState.domain.attachments = {};
        draftState.domain.coverImages = {};
        action.payload.collection.forEach((training) => {
          const trainingId = training.id;
          draftState.domain.training.data[trainingId] = training;
          draftState.domain.training.allDataIds.push(trainingId);
        });
        Object.keys(action.payload.included).forEach((payloadKey) => {
          action.payload.included[payloadKey].forEach(
            (parsedResource) =>
              (draftState.domain[payloadKey][parsedResource.id] =
                parsedResource),
          );
        });
        draftState.application.pending.trainingFetch = false;
        break;
      case 'LOAD_ASSIGNABLE_COMPANIES':
        draftState.domain.assignableCompanies = action.payload.collection; // array due to mixed resource types
        draftState.application.pending.assignableCompaniesFetch = false;
        break;
      case 'LOAD_ASSIGNABLE_ROLES':
        draftState.domain.assignableRoles = action.payload.collection;
        draftState.application.pending.assignableRolesFetch = false;
        break;
      case 'UPDATE_ASSIGNABLE_ROLES':
        draftState.domain.assignableRoles = [
          ...draftState.domain.assignableRoles,
          action.payload.role,
        ];
        break;
      case 'LOAD_PERSONNEL_EVENTS':
        draftState.domain.personnelEvents = action.payload.collection;
        draftState.domain.personnelEventsMeta = action.payload.meta;
        draftState.application.pending.personnelEventsFetch = false;
        break;
      case 'CREATE_TRAINING_REQUIREMENT':
        draftState.domain.userCourses[action.payload.userCourse.id] =
          action.payload.userCourse;
        if (action.payload.training) {
          const trainingId = action.payload.training.id;
          draftState.domain.training.data[trainingId] = action.payload.training;
          if (draftState.domain.training.allDataIds.indexOf(trainingId) === -1)
            draftState.domain.training.allDataIds.push(trainingId);
        }
        action.payload.attachments.forEach(
          (attachment) =>
            (draftState.domain.attachments[attachment.id] = attachment),
        );
        action.payload.coverImages.forEach(
          (coverImage) =>
            (draftState.domain.coverImages[coverImage.id] = coverImage),
        );
        if (action.payload.eLearningCourses) {
          action.payload.eLearningCourses.forEach(
            (eLearningCourse) =>
              (draftState.domain.eLearningCourses[eLearningCourse.id] =
                eLearningCourse),
          );
        }
        draftState.domain.courses[action.payload.course.id] =
          action.payload.course;
        if (action.payload.autoEnrolmentConfiguration)
          draftState.domain.autoEnrolmentConfigurations[
            action.payload.autoEnrolmentConfiguration.id
          ] = action.payload.autoEnrolmentConfiguration;
        draftState.domain.personnel.relationships.userCourses.data.push({
          id: action.payload.userCourse.id,
          type: 'userCourse',
        });
        break;
      case 'CREATE_USER_COURSE':
        draftState.domain.userCourses[action.payload.member.id] =
          action.payload.member;
        draftState.domain.personnel.relationships.userCourses.data.push({
          id: action.payload.member.id,
          type: 'userCourse',
        });
        draftState.domain.courses[action.payload.course.id] =
          action.payload.course;
        break;
      case 'UPDATE_USER_COURSE':
        draftState.domain.userCourses[action.payload.member.id] =
          action.payload.member;
        break;
      case 'DELETE_USER_COURSE':
        const userCourseIndex =
          draftState.domain.personnel.relationships.userCourses.data.findIndex(
            (userCourse) => userCourse.id === action.payload.member.id,
          );
        if (userCourseIndex > -1) {
          draftState.domain.personnel.relationships.userCourses.data.splice(
            userCourseIndex,
            1,
          );
        }
        delete draftState.domain.userCourses[action.payload.member.id];
        break;
      case 'CREATE_BOOKING':
        draftState.domain.bookings[action.payload.member.id] =
          action.payload.member;
        if (action.payload.booker)
          draftState.domain.bookers[action.payload.booker.id] =
            action.payload.booker;
        break;
      case 'UPDATE_BOOKING':
        draftState.domain.bookings[action.payload.member.id] =
          action.payload.member;
        break;
      case 'CREATE_E_LEARNING_BOOKING':
        draftState.domain.bookings[action.payload.member.id] =
          action.payload.member;
        draftState.domain.personnel.meta.eLearningBookingsCount += 1;
        break;
      case 'DELETE_BOOKING':
        delete draftState.domain.bookings[action.payload.member.id];
        break;
      case 'DELETE_E_LEARNING_BOOKING':
        delete draftState.domain.bookings[action.payload.member.id];
        draftState.domain.personnel.meta.eLearningBookingsCount -= 1;
        break;
      case 'CREATE_AUTO_ENROLMENT_EXCLUSION':
        draftState.domain.autoEnrolmentExclusions[action.payload.member.id] =
          action.payload.member;
        break;
      case 'DELETE_AUTO_ENROLMENT_EXCLUSION':
        delete draftState.domain.autoEnrolmentExclusions[
          action.payload.member.id
        ];
        break;
    }
  });
};
