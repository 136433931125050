import snakeCase from 'voca/snake_case';

export function resourceAsParams(resourceName, resourceValues) {
  const fieldValues = {};
  for (const key in resourceValues) {
    fieldValues[snakeCase(key)] = resourceValues[key];
  }
  return { [resourceName]: fieldValues };
}

export const paramsForResourcesDeletions = ({
  serverSideIds,
  frontendSideIds,
}) => {
  const idsToDelete = serverSideIds.filter((n) => !frontendSideIds.includes(n));
  return idsToDelete.map((id) => {
    return { id: id, _destroy: true };
  });
};
