import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

export default function OneTimePasswordSignInCard({
  inputRefs,
  otpCode,
  onSubmit,
  onInputKeyUp,
  onFormPasteCapture,
  onInputClick,
}) {
  useEffect(() => {
    if (otpCode.join('').length < 6) {
      return;
    }
    onSubmit(otpCode);
  }, [otpCode]);

  useEffect(() => {
    inputRefs[0].current.focus();
  }, []);

  return (
    <form onPasteCapture={onFormPasteCapture}>
      <div className='tw-flex tw-flex-col tw-space-18 tw-w-full'>
        <div className='tw-flex tw-flex-row tw-items-center tw-justify-between tw-mx-auto tw-w-full tw-max-w-xs'>
          {inputRefs.map((ref, i) => (
            <input
              key={i}
              type='text'
              className='otp tw-py-2.5 tw-w-10 tw-h-10 tw-flex tw-flex-col tw-items-center tw-justify-center tw-text-center tw-outline-none tw-rounded-xl tw-text-lg tw-bg-white tw-border-1 tw-border-solid tw-border-grey-300 focus:tw-border-blue-300 tw-caret-transparent'
              ref={ref}
              value={otpCode[i]}
              maxLength='1'
              onKeyUp={onInputKeyUp}
              onClick={(e) => onInputClick(e, i)}
              onChange={() => {}}
            />
          ))}
        </div>
      </div>
    </form>
  );
}

OneTimePasswordSignInCard.propTypes = {
  inputRefs: PropTypes.array.isRequired,
  otpCode: PropTypes.array.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onInputKeyUp: PropTypes.func.isRequired,
  onFormPasteCapture: PropTypes.func.isRequired,
  onInputClick: PropTypes.func.isRequired,
};
