import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { courseTrainingStatus } from 'components/helpers/resources/courses';
import { uniqueTrainingCollectionByCourse } from 'components/helpers/resources/training';

import Tabs from 'components/application/Tabs';
import PersonnelRoleTrainingStatusIndicator from 'components/training/PersonnelRoleTrainingStatusIndicator';
import RoleTrainingTab from 'components/training/RoleTrainingTab';
import TrainingEventsTab from 'components/trainingEvents/TrainingEventsTab';
import TrainingModalBox from 'components/training/TrainingModalBox';

import ClockIcon from '-!svg-react-loader?name=ClockIcon!icons/clock.svg';

export default function TrainingRecords(props) {
  const defaultModalBoxData = {
    training: null,
    course: null,
    loaded: false,
    attachments: [],
    context: 'notes',
  };

  const [modalBoxData, setModalBoxData] = useState(defaultModalBoxData);

  const isArchived = !!props.currentPersonnel.archivedAt;

  const individuallyRequiredCourses = props.userCourses.reduce(
    (acc, userCourse) => {
      if (userCourse.attributes.required)
        acc.push(props.courses[userCourse.relationships.course.data.id]);
      return acc;
    },
    [],
  );

  const uniqueTrainingCollection = uniqueTrainingCollectionByCourse({
    training: props.training,
  });

  const trainingTabs =
    props.roles.length === 0 ?
      [
        <RoleTrainingTab
          hasTrainingEditableAccess={props.hasTrainingEditableAccess}
          role={null}
          key='blankRoleTrainingTab'
          label='Training'
          isArchived={isArchived}
          courseTrainingStatus={courseTrainingStatus(
            individuallyRequiredCourses,
            uniqueTrainingCollection,
          )}
          training={props.training}
          bookers={props.bookers}
          attachments={props.attachments}
          courses={props.courses}
          autoEnrolmentConfigurations={props.autoEnrolmentConfigurations}
          autoEnrolmentExclusions={props.autoEnrolmentExclusions}
          eLearningCourses={props.eLearningCourses}
          eLearningAllowance={props.eLearningAllowance}
          courseCompanyRoles={props.courseCompanyRoles}
          roles={props.roles}
          userCourses={props.userCourses}
          coursesMeta={props.coursesMeta}
          onAddRole={props.onAddRole}
          currentPersonnel={props.currentPersonnel}
          currentBookings={props.currentBookings}
          currentRegistrations={props.currentRegistrations}
          creditInfoUnavailable={props.creditInfoUnavailable}
          onUpdateUserCourse={props.onUpdateUserCourse}
          onCreateUserCourse={props.onCreateUserCourse}
          onNewTrainingClick={props.onNewTrainingClick}
          onEditTrainingClick={props.onEditTrainingClick}
          onDeleteTrainingClick={props.onDeleteTrainingClick}
          onDeleteCourseClick={props.onDeleteCourseClick}
          onAddBooking={props.onAddBooking}
          onAddELearningBooking={props.onAddELearningBooking}
          onRemoveBooking={props.onRemoveBooking}
          onBookingReminderClick={props.onBookingReminderClick}
          onCourseSelectChange={props.onCourseSelectChange}
          onCourseSelectError={props.onCourseSelectError}
          setModalBoxData={setModalBoxData}
          trainingEvidenceProcessed={props.trainingEvidenceProcessed}
          onTrainingEvidenceProcessed={props.onTrainingEvidenceProcessed}
          personnelId={props.personnelId}
          onShowBooking={props.onShowBooking}
          onCreateAutoEnrolmentExclusion={props.onCreateAutoEnrolmentExclusion}
          onDeleteAutoEnrolmentExclusion={props.onDeleteAutoEnrolmentExclusion}
        />,
      ]
    : props.roles.map((companyRole) => {
        const tabCompanyRoleCourses =
          companyRole.relationships.courses.data.map(
            (companyRoleCourse) => props.courses[companyRoleCourse.id],
          );
        const requiredRoleCourses = tabCompanyRoleCourses.reduce(
          (acc, course) => {
            const currentCcr = props.courseCompanyRoles.find(
              (ccr) =>
                ccr.relationships.companyRole.data.id == companyRole.id &&
                ccr.relationships.course.data.id == course.id,
            );

            if (currentCcr.attributes.required) {
              acc.push(props.courses[currentCcr.relationships.course.data.id]);
            }
            return acc;
          },
          [],
        );

        const requiredCourses = [
          ...requiredRoleCourses,
          ...individuallyRequiredCourses,
        ];
        const roleTrainingStatus =
          isArchived ? 'archived' : (
            courseTrainingStatus(requiredCourses, uniqueTrainingCollection)
          );

        return (
          <div
            icon={
              props.roles.length > 1 ?
                <div className='flex m-r-6'>
                  <PersonnelRoleTrainingStatusIndicator
                    status={roleTrainingStatus}
                  />
                </div>
              : undefined
            }
            label={
              props.roles.length === 1 ?
                'Training'
              : companyRole.attributes.position
            }
            key={`companyRoleTrainingTab--${companyRole.id}`}
          >
            <RoleTrainingTab
              hasTrainingEditableAccess={props.hasTrainingEditableAccess}
              role={companyRole}
              isArchived={isArchived}
              courseTrainingStatus={roleTrainingStatus}
              training={props.training}
              bookers={props.bookers}
              attachments={props.attachments}
              courses={props.courses}
              autoEnrolmentConfigurations={props.autoEnrolmentConfigurations}
              autoEnrolmentExclusions={props.autoEnrolmentExclusions}
              eLearningCourses={props.eLearningCourses}
              eLearningAllowance={props.eLearningAllowance}
              courseCompanyRoles={props.courseCompanyRoles}
              roles={props.roles}
              userCourses={props.userCourses}
              coursesMeta={props.coursesMeta}
              currentPersonnel={props.currentPersonnel}
              currentBookings={props.currentBookings}
              currentRegistrations={props.currentRegistrations}
              creditInfoUnavailable={props.creditInfoUnavailable}
              onUpdateUserCourse={props.onUpdateUserCourse}
              onCreateUserCourse={props.onCreateUserCourse}
              onNewTrainingClick={props.onNewTrainingClick}
              onEditTrainingClick={props.onEditTrainingClick}
              onDeleteTrainingClick={props.onDeleteTrainingClick}
              onDeleteCourseClick={props.onDeleteCourseClick}
              onCourseSelectChange={props.onCourseSelectChange}
              onAddBooking={props.onAddBooking}
              onShowBooking={props.onShowBooking}
              onAddELearningBooking={props.onAddELearningBooking}
              onRemoveBooking={props.onRemoveBooking}
              onBookingReminderClick={props.onBookingReminderClick}
              setModalBoxData={setModalBoxData}
              trainingEvidenceProcessed={props.trainingEvidenceProcessed}
              onTrainingEvidenceProcessed={props.onTrainingEvidenceProcessed}
              onCourseSelectError={props.onCourseSelectError}
              personnelId={props.personnelId}
              onCreateAutoEnrolmentExclusion={
                props.onCreateAutoEnrolmentExclusion
              }
              onDeleteAutoEnrolmentExclusion={
                props.onDeleteAutoEnrolmentExclusion
              }
            />
          </div>
        );
      });

  if (props.hasPersonnelEventViewableAccess) {
    trainingTabs.push(
      <TrainingEventsTab
        label='activity'
        key='personnelEventsTab'
        disabled={props.personnelEvents.collection.length === 0}
        tooltipText={'No training activity'}
        personnelEvents={props.personnelEvents.collection}
        fetchPersonnelEvents={props.fetchPersonnelEvents}
        metaData={props.personnelEvents.meta}
        personnelId={props.personnelId}
        icon={
          <span>
            <ClockIcon
              width={16}
              height={16}
              className='[&_path]:tw-fill-grey-500'
            />
          </span>
        }
      />,
    );
  }

  return (
    <>
      <Tabs modifiers={['truncated']}>{trainingTabs}</Tabs>
      <TrainingModalBox data={modalBoxData} />
    </>
  );
}

TrainingRecords.propTypes = {
  roles: PropTypes.array.isRequired,
  training: PropTypes.array.isRequired,
  bookers: PropTypes.object,
  personnelEvents: PropTypes.object.isRequired,
  currentPersonnel: PropTypes.object.isRequired,
  currentBookings: PropTypes.array,
  currentRegistrations: PropTypes.array,
  attachments: PropTypes.array,
  courses: PropTypes.object,
  autoEnrolmentConfigurations: PropTypes.object,
  autoEnrolmentExclusions: PropTypes.object,
  eLearningCourses: PropTypes.object,
  eLearningAllowance: PropTypes.object,
  courseCompanyRoles: PropTypes.array,
  onBookingReminderClick: PropTypes.func.isRequired,
  hasTrainingEditableAccess: PropTypes.bool,
  onShowBooking: PropTypes.func.isRequired,
  onRemoveBooking: PropTypes.func.isRequired,
  onCreateAutoEnrolmentExclusion: PropTypes.func,
  onDeleteAutoEnrolmentExclusion: PropTypes.func,
  onAddBooking: PropTypes.func.isRequired,
  onAddELearningBooking: PropTypes.func.isRequired,
  onUpdateUserCourse: PropTypes.func.isRequired,
  onCreateUserCourse: PropTypes.func.isRequired,
  onNewTrainingClick: PropTypes.func.isRequired,
  onEditTrainingClick: PropTypes.func.isRequired,
  onDeleteTrainingClick: PropTypes.func.isRequired,
  onDeleteCourseClick: PropTypes.func.isRequired,
  onCourseSelectChange: PropTypes.func.isRequired,
  onCourseSelectError: PropTypes.func.isRequired,
  onTrainingEvidenceProcessed: PropTypes.func.isRequired,
  fetchPersonnelEvents: PropTypes.func.isRequired,
};

TrainingRecords.defaultProps = {
  personnelEvents: { collection: [], meta: {} },
  currentBookings: [],
  currentRegistrations: [],
};
