import { useState } from 'react';
import useRequestError from 'components/hooks/useRequestError';

export const defaultAccess = {
  administrator: false,
  projectApprover: false,
  contentEditor: false,
  personnelEditor: false,
  personnelViewer: false,
};

export const defaultFormUser = {
  firstName: '',
  lastName: '',
  email: '',
  position: '',
  isSelected: false,
  accessType: 'regular',
  accessControls: {},
  rolesAndCoursesEditor: false,
  ...defaultAccess,
};

const domainUserAsForm = (domainUser) => {
  if (!domainUser) {
    return null;
  }

  const seededUser = { ...defaultFormUser, ...{ isSelected: true } };
  [
    'firstName',
    'lastName',
    'email',
    'position',
    'accessType',
    'rolesAndCoursesEditor',
  ].forEach((attr) => {
    seededUser[attr] = domainUser.data.attributes[attr];
  });

  seededUser.accessControls = domainUser.included
    .filter((inclusion) => inclusion.type === 'accessControl')
    .reduce((acc, current, _index) => {
      const gatheredAttrs = (({
        administrator,
        projectApprover,
        contentEditor,
        personnelViewer,
        personnelEditor,
      }) => ({
        administrator,
        projectApprover,
        contentEditor,
        personnelViewer,
        personnelEditor,
      }))(current.attributes);
      acc[current.relationships.company.data.id] = gatheredAttrs;
      return acc;
    }, {});

  return seededUser;
};

export default function useUserForm({ seededUser, seedType }) {
  let seedUser;
  if (seededUser) {
    if (seedType === 'domain') {
      seedUser = domainUserAsForm(seededUser);
    } else if (seedType === 'form') {
      seedUser = { ...defaultFormUser, ...seededUser };
    }
  }
  const [formUser, setFormUser] = useState(seedUser || defaultFormUser);
  const [
    requestError,
    _submitDisabled,
    removeErrorStyling,
    resetRequestError,
    handleRequestError,
  ] = useRequestError();

  const resetUserForm = () => setFormUser(defaultFormUser);

  return {
    formUser,
    setFormUser,
    resetUserForm,
    requestError,
    handleRequestError,
    resetRequestError,
    removeErrorStyling,
  };
}
