import React from 'react';
import PropTypes from 'prop-types';
import NoResultsIcon from '-!svg-react-loader?name=NoResultsIcon!icons/noresults-xl.svg';
import AddToolboxIcon from '-!svg-react-loader?name=AddToolboxIcon!icons/addtoolbox-xl.svg';
import FilledButton from 'components/application/buttons/FilledButton';

export default function ResourceBlankNotice(props) {
  const {
    addButton,
    addMessage,
    addMessageClass,
    displayReadOnlyContents,
    icon,
    isSingularResource,
    onAdd,
    resource,
    totalCount,
  } = props;

  return totalCount > 0 ?
      <section className='section section--notice br-tl-0 br-tr-0 tw-border-grey-100 tw-bg-white'>
        <NoResultsIcon
          width={64}
          height={64}
          className='[&_path]:tw-fill-grey-300'
        />
        <h2 className='section__header tw-text-grey-900 tw-text-l tw-font-semibold'>{`No ${resource}s found`}</h2>
      </section>
    : <section className='section section--notice tw-border-grey-100 tw-bg-white'>
        {icon ?
          icon
        : <AddToolboxIcon
            width={64}
            height={64}
            className='[&_path]:tw-fill-grey-500'
          />
        }
        {isSingularResource ?
          <h2 className='section__header tw-text-grey-900 tw-text-l tw-font-semibold tw-mt-[14px]'>{`${resource}`}</h2>
        : <h2 className='section__header tw-text-grey-900 tw-text-l tw-font-semibold'>
            {displayReadOnlyContents ?
              `No ${resource}s yet`
            : `Add ${resource}s`}
          </h2>
        }
        <p
          className={`${addMessageClass ? addMessageClass : 'section__content section__content--md'}`}
        >
          {addMessage}
        </p>
        {!displayReadOnlyContents && addButton && (
          <FilledButton
            color='mint'
            onClick={onAdd}
          >{`Add new ${resource}`}</FilledButton>
        )}
      </section>;
}

ResourceBlankNotice.propTypes = {
  addMessage: PropTypes.string.isRequired,
  addMessageClass: PropTypes.string,
  displayReadOnlyContents: PropTypes.bool,
  isSingularResource: PropTypes.bool,
  resource: PropTypes.string.isRequired,
  totalCount: PropTypes.number.isRequired,
  onAdd: PropTypes.func,
  addButton: PropTypes.bool,
  icon: PropTypes.element,
};

ResourceBlankNotice.defaultProps = {
  addButton: true,
  displayReadOnlyContents: false,
};
