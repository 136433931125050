import React from 'react';
import PropTypes from 'prop-types';

import { getCoshhDocumentDetails } from 'components/helpers/coshhDocuments';

export default function CoshhShow({
  coshhDocument,
  secondaryFieldLabel,
  secondaryFieldDefaultValue,
}) {
  const coshhDocumentDetails = getCoshhDocumentDetails({
    coshhDocument,
    secondaryFieldLabel,
    secondaryFieldDefaultValue,
  });

  return (
    <div className={'popup__body-show tw-p-0'}>
      <>
        <div className='popup__body-content p-b-0'>
          {coshhDocumentDetails.map((documentDetail) => {
            return (
              <div className={'m-b-24'} key={documentDetail.label}>
                <div className='personal-info__label tw-font-medium m-b-4'>
                  {documentDetail.label}
                </div>
                <div
                  className={`personal-info__text${documentDetail.text ? '' : ' personal-info__text--not-added'}`}
                >
                  <span>{documentDetail.text || 'Not specified'}</span>
                </div>
              </div>
            );
          })}
        </div>
      </>
    </div>
  );
}

CoshhShow.propTypes = {
  coshhDocument: PropTypes.object.isRequired,
  secondaryFieldLabel: PropTypes.string.isRequired,
  secondaryFieldDefaultValue: PropTypes.string.isRequired,
};
