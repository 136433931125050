import React, { useState } from 'react';
import useToggle from 'components/hooks/useToggle';
import ModalBox from 'components/application/ModalBox';
import CheckboxField from 'components/application/CheckboxField';
import RadioField from 'components/application/RadioField';
import { useCurrentActor } from 'components/contexts/CurrentActor';
import { useTrainingRegisterResources } from 'components/contexts/TrainingRegisterResourceManagementContext';
import TextButton from 'components/application/buttons/TextButton';
import FilledButton from 'components/application/buttons/FilledButton';

export default function TrainingMatrixOptionsModal({ onCancel, onSubmit }) {
  const [includeEmployees, toggleIncludeEmployees] = useToggle(true);
  const [employeeScope, setEmployeeScope] = useState('all');
  const [includeSubcontractors, toggleIncludeSubContractors] = useToggle(true);
  const currentActor = useCurrentActor();
  const trainingRegisterResources = useTrainingRegisterResources();

  // calculated
  const includeEmployeeEditable = includeSubcontractors;
  const includeSubcontractorsEditable = includeEmployees;
  const showDivisionFilteringOptions =
    includeEmployees &&
    trainingRegisterResources.isMultiDivisionAccount &&
    currentActor.division.attributes.primary;

  return (
    <ModalBox
      mode='flexible'
      isOpen={true}
      onClose={onCancel}
      customFooter={
        <div className='modalbox__footer-actions modalbox__footer-actions--right'>
          <TextButton
            size='md'
            color='blue'
            className='m-r-12'
            onClick={onCancel}
          >
            Cancel
          </TextButton>
          <FilledButton
            color='mint'
            onClick={() =>
              onSubmit({
                includeEmployees,
                employeeScope,
                includeSubcontractors,
              })
            }
          >
            Download now
          </FilledButton>
        </div>
      }
    >
      <div className='p-32'>
        <h2 className='tw-text-grey-900 tw-text-l tw-font-semibold p-0 m-t-0 m-b-20'>
          Which personnel do you want to include in the training matrix?
        </h2>
        <div className={'m-b-16'}>
          <CheckboxField
            disabled={!includeEmployeeEditable}
            label='Employees'
            checked={includeEmployees}
            name='includeEmployees'
            value={'includeEmployees'}
            onChange={() => includeEmployeeEditable && toggleIncludeEmployees()}
            labelTextProps={{ className: 'tw-font-medium' }}
          />
        </div>
        <div className='m-l-28'>
          {showDivisionFilteringOptions && (
            <>
              <RadioField
                label={'From all divisions'}
                name={'employeeScope'}
                value={'all'}
                checked={employeeScope == 'all'}
                onChange={() => setEmployeeScope('all')}
              />
              <RadioField
                label={`From ${currentActor.division.attributes.name} only`}
                name={'employeeScope'}
                value={'division'}
                checked={employeeScope == 'division'}
                onChange={() => setEmployeeScope('division')}
              />
            </>
          )}
        </div>
        <CheckboxField
          disabled={!includeSubcontractorsEditable}
          label='Sub-contractors'
          checked={includeSubcontractors}
          name='includeSubcontractors'
          value={'includeSubcontractors'}
          onChange={() => includeEmployees && toggleIncludeSubContractors()}
          labelTextProps={{ className: 'tw-font-medium' }}
        />
      </div>
    </ModalBox>
  );
}
