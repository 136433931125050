import React from 'react';

import {
  SortableContainer,
  SortableElement,
  SortableHandle,
} from 'react-sortable-hoc';

import { toggleGrabbing } from 'components/helpers/document';

import DragIcon from '-!svg-react-loader?name=DragIcon!icons/ic-drag.svg';
import CollectionInput from 'components/collections/CollectionInput';

const DragHandle = SortableHandle((props) => (
  <DragIcon
    className={`drag-icon [&_path]:tw-fill-grey-300 drag-handle-${props.sortIndex}`}
    width={8}
    height={18}
  />
));

const SortableItem = SortableElement(
  ({ value, sortIndex, collectionProps }) => (
    <li className='sortable-collection__member-wrapper'>
      <div className='sortable-collection__member'>
        <DragHandle sortIndex={sortIndex} />
        <CollectionInput
          value={value.name}
          index={sortIndex}
          deleteAllowed={collectionProps.collection.length > 1}
          onDelete={collectionProps.onDelete}
          onChange={collectionProps.onListInputChange}
          collectionErrors={collectionProps.collectionErrors}
          submitDisabled={collectionProps.submitDisabled}
          removeErrorStyling={collectionProps.removeErrorStyling}
        />
      </div>
    </li>
  ),
);

const SortableList = SortableContainer(({ collection, collectionProps }) => {
  return (
    <ul className='sortable-collection'>
      {collection.map((value, index) => {
        return (
          <SortableItem
            key={`item-${index}`}
            index={index}
            sortIndex={index}
            value={value}
            collectionProps={collectionProps}
          />
        );
      })}
    </ul>
  );
});

function SortableCollection({ collection, collectionProps }) {
  function handleSortStart({ _node, _helper }) {
    toggleGrabbing(true);
  }

  function handleSortEnd({ oldIndex, newIndex }) {
    toggleGrabbing(false);
    collectionProps.onSortEnd({ oldIndex, newIndex });
  }

  return (
    <SortableList
      useDragHandle
      transitionDuration={400}
      lockToContainerEdges
      lockAxis='y'
      axis='y'
      helperClass='sortable-collection__member-wrapper--active'
      collection={collection}
      onSortStart={handleSortStart}
      onSortEnd={handleSortEnd}
      collectionProps={collectionProps}
    />
  );
}

export default SortableCollection;
