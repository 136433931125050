import React from 'react';

import AddCoshhIcon from '-!svg-react-loader?name=AddCoshhIcon!icons/ic-add-coshh.svg';

export default function ProjectActivityBlankNotice() {
  return (
    <section className='section section--notice section--notice--no-top-border-radius p-t-80 p-b-80 tw-border-grey-100 tw-bg-white'>
      <AddCoshhIcon
        className='[&_path]:tw-fill-grey-500'
        width={42}
        height={42}
      />
      <h2 className='section__header p-t-0 tw-text-grey-900 tw-text-l tw-font-semibold'>
        Add COSHH
      </h2>
      <p className='section__content m-t-8 m-b-0'>
        Add assessments for each hazardous substance you are using in your RAMS
      </p>
    </section>
  );
}
