import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import DragIcon from '-!svg-react-loader?name=DragIcon!icons/ic-drag.svg';
import { SortableHandle } from 'react-sortable-hoc';
import RowBar from 'components/application/RowBar';

const DragHandle = SortableHandle((props) => {
  return (
    <div
      className={classNames(
        'drag-handle drag-handle--padded',
        props.isOrdering && 'drag-handle--hidden',
      )}
    >
      <DragIcon
        className='drag-icon [&_path]:tw-fill-grey-300'
        width={8}
        height={18}
      />
    </div>
  );
});

export default function ToggleableRow({
  additionalClasses,
  checkboxField,
  toggleableField,
  modifiers,
  isOrdering,
}) {
  return (
    <RowBar
      additionalClasses={classNames(
        'drag-handle-container toggleable-row',
        modifiers.map((modifier) => `toggleable-row--${modifier}`).join(' '),
        additionalClasses,
      )}
      modifiers={['border-top-none']}
      content={
        <React.Fragment>
          <div className='toggleable-row__dragger'>
            <DragHandle isOrdering={isOrdering} />
          </div>
          <span className='toggleable-row__selector'>{checkboxField}</span>
          <div className='toggleable-row__text-wrapper'>{toggleableField}</div>
        </React.Fragment>
      }
    />
  );
}

ToggleableRow.defaultProps = {
  modifiers: [],
};

ToggleableRow.propTypes = {
  additionalClasses: PropTypes.string,
  checkboxField: PropTypes.node.isRequired,
  isOrdering: PropTypes.bool,
  modifiers: PropTypes.array,
  toggleableField: PropTypes.node.isRequired,
};
