import React from 'react';
import { SketchPicker } from 'react-color';

export default function ColourInput({
  name,
  colour,
  isOpen,
  onClick,
  onChange,
}) {
  const handlePickerChange = (e) => {
    onChange({ colour: e.hex });
  };

  return (
    <div className='colour-field'>
      <input
        name={name}
        onClick={onClick}
        className='colour-field__input tw-font-inter tw-text-m tw-font-normal tw-tracking-auto tw-border-grey-300 hover:tw-border-grey-400 focus-within:tw-border-blue-300 hover:focus-within:tw-border-blue-300 placeholder:tw-text-grey-300'
        value={colour}
        readOnly={true}
      />
      <span
        className='colour-field__swatch'
        style={{ background: colour }}
      ></span>
      {isOpen && (
        <div className='colour-field__picker-wrapper'>
          <div
            className='popup__backdrop popup__backdrop--low'
            onClick={onClick}
          />
          <SketchPicker color={colour} onChange={handlePickerChange} />
        </div>
      )}
    </div>
  );
}
