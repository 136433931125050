import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import ModalBox from 'components/application/ModalBox';
import TextButton from 'components/application/buttons/TextButton';
import FilledButton from 'components/application/buttons/FilledButton';

export default function UpdateConfirmationModal(props) {
  const { isOpen, setClose, destroyFieldValueCount, onUpdate } = props;

  const closeModalBox = () => {
    setClose();
  };

  const handleDeleteClick = () => {
    closeModalBox();
    onUpdate();
  };

  const customFooter = (
    <div className='modalbox__footer-actions modalbox__footer-actions--right'>
      <TextButton
        size='md'
        color='blue'
        className='m-r-12'
        onClick={closeModalBox}
      >
        Cancel
      </TextButton>
      <FilledButton color='red' onClick={handleDeleteClick}>
        {`Delete ${destroyFieldValueCount} record${destroyFieldValueCount > 1 ? 's' : ''}`}
      </FilledButton>
    </div>
  );

  useEffect(() => {
    document.body.style.overflow = 'hidden';
  }, [isOpen]);

  return (
    <ModalBox
      mode='flexible'
      isOpen={isOpen}
      onClose={closeModalBox}
      customFooter={customFooter}
    >
      <React.Fragment>
        <div className='modalbox-body'>
          <div className='tw-text-l tw-text-grey-900 tw-font-semibold m-b-16'>
            Are you sure you want to save changes and delete the existing data?
          </div>
          <div>
            This will permanently delete the data linked to the items removed
            from the dropdown list. This cannot be undone.
          </div>
        </div>
      </React.Fragment>
    </ModalBox>
  );
}

UpdateConfirmationModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  setClose: PropTypes.func.isRequired,
  destroyFieldValueCount: PropTypes.number.isRequired,
  onUpdate: PropTypes.func.isRequired,
};
