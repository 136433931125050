import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import { components } from 'react-select';
import Async from 'react-select/async';
import _ from 'lodash';

import {
  NoOptionsMessage,
  ClearIndicator,
  DropdownIndicator,
} from 'components/application/CollectionSelect';

export default function SearchELearningCoursesField({
  name,
  fieldError,
  removeErrorStyling,
  onSelect,
  onError,
}) {
  const loadELearningCourses = useCallback(
    _.debounce(
      (inputValue, callback) => {
        axios
          .get(`/e_learning_courses?search=${inputValue}`)
          .then((response) =>
            response.data.data.map((eLearningCourse) => ({
              label: eLearningCourse,
              value: eLearningCourse.id,
            })),
          )
          .then((options) => callback(options))
          .catch(() => onError());
      },
      500,
      { maxWait: 1000 },
    ),
    [],
  );

  return (
    <div
      className={`${fieldError.fieldHighlighted ? 'collection-select--invalid' : ''}`}
    >
      <label className='field__label field__required tw-font-medium'>
        Course name
      </label>
      <Async
        id={name}
        name={name}
        className='collection-select__select-container'
        classNamePrefix='collection-select'
        placeholder='Select course'
        value={null}
        cacheOptions={true}
        openMenuOnClick={false}
        onChange={onSelect}
        onFocus={() => removeErrorStyling({ target: { name } })}
        components={{
          NoOptionsMessage,
          ClearIndicator,
          DropdownIndicator,
          Option,
        }}
        loadOptions={loadELearningCourses}
      />
    </div>
  );
}

function Option(props) {
  const { name, duration } = props.data.label.attributes;

  return (
    <components.Option {...props}>
      <span className='l-if flex--vertically-centered'>
        <span>
          <div className='tw-text-grey-900'>{name}</div>
          <div className='tw-text-grey-600 tw-text-s tw-tracking-wide'>
            {duration} &bull; 1 credit per course
          </div>
        </span>
      </span>
    </components.Option>
  );
}

SearchELearningCoursesField.propTypes = {
  name: PropTypes.string.isRequired,
  fieldError: PropTypes.object,
  removeErrorStyling: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,
};

SearchELearningCoursesField.defaultProps = {
  fieldError: { fieldHighlighted: false },
};
