import React from 'react';
import PropTypes from 'prop-types';

import ModalBox from 'components/application/ModalBox';
import TextButton from 'components/application/buttons/TextButton';
import FilledButton from 'components/application/buttons/FilledButton';

export default function UpdateConfirmationModal(props) {
  const { isOpen, onClose, onSave, displayText } = props;

  const onSaveClick = () => {
    onClose();
    onSave();
  };

  const customFooter = (
    <div className='modalbox__footer-actions modalbox__footer-actions--right'>
      <TextButton size='md' color='blue' className='m-r-12' onClick={onClose}>
        Cancel
      </TextButton>
      <FilledButton color='mint' onClick={onSaveClick}>
        Save changes
      </FilledButton>
    </div>
  );

  return (
    <ModalBox
      mode='flexible'
      isOpen={isOpen}
      onClose={onClose}
      customFooter={customFooter}
    >
      <div className='p-32'>
        <h2 className='tw-text-l tw-text-grey-900 tw-font-semibold m-0 p-0'>
          Are you sure you want to save changes?
        </h2>
        <p className='tw-border-0 tw-rounded-lg tw-text-red-800 tw-bg-red-025 tw-p-3 m-0 m-t-20'>
          {displayText}
        </p>
      </div>
    </ModalBox>
  );
}

UpdateConfirmationModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  displayText: PropTypes.string.isRequired,
};
