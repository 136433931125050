import React from 'react';
import InfoBanner from 'components/application/InfoBanner';
import ExpandableList from 'components/application/ExpandableList';
import DivisionItem, {
  prepareDivisionItemProps,
} from 'components/riskRegister/sharedResourceNotices/shared/DivisionItem';
import PropTypes from 'prop-types';
import { resourceShape } from 'components/helpers/serialisableResources';

export default function MultiCategoriesMultiDivisionsNonEditable({
  riskAssessment,
  categories,
  divisions,
}) {
  return (
    <InfoBanner title='You can’t edit this risk assessment as it will affect other categories and other divisions you may not have access to'>
      {riskAssessment.attributes.name} is a risk assessment shared with:
      <ExpandableList
        collection={divisions}
        renderItem={(division) => (
          <li key={`divItem--${division.id}`}>
            <DivisionItem
              {...prepareDivisionItemProps(
                division,
                riskAssessment,
                categories,
              )}
            />
          </li>
        )}
      />
      <div className='m-t-4'>
        Some categories are shared across other divisions you do not have the
        correct permissions for. Please contact an account administrator to get
        access to {riskAssessment.attributes.name}.
      </div>
    </InfoBanner>
  );
}

MultiCategoriesMultiDivisionsNonEditable.propTypes = {
  riskAssessment: resourceShape('masterActivity').isRequired,
  divisions: PropTypes.arrayOf(resourceShape('company')).isRequired,
  categories: PropTypes.arrayOf(resourceShape('trade')).isRequired,
};
