import React from 'react';
import PropTypes from 'prop-types';

export default function ValidationErrorsMessages({
  validationErrors,
  className = '',
}) {
  return (
    <div
      className={`tw-border-0 tw-rounded-lg tw-text-red-800 tw-bg-red-025 tw-p-3 ${className}`}
    >
      <p className='tw-mb-2'>Please fix the following errors:</p>
      <ul className='tw-pl-6 tw-mb-0'>
        {Object.keys(validationErrors).map((key, errorIndex) => {
          return validationErrors[key].messages.map((message, messageIndex) => (
            <li key={`${errorIndex} ${messageIndex}`}>{message}</li>
          ));
        })}
      </ul>
    </div>
  );
}

ValidationErrorsMessages.propTypes = {
  validationErrors: PropTypes.object.isRequired,
};
