import React from 'react';
import PropTypes from 'prop-types';
import CardWrapper from './CardWrapper';
import OutlinedButton from 'components/application/buttons/OutlinedButton';

export default function SelectedCard({
  projectName,
  folderName,
  onEdit,
  onUnlink,
}) {
  return (
    <CardWrapper>
      <div className='tw-text-grey-900 tw-font-semibold'>{projectName}</div>
      <div>{folderName}</div>
      <div className='flex m-t-12'>
        <OutlinedButton
          className='m-r-16'
          size='sm'
          color='grey'
          onClick={onEdit}
        >
          Edit
        </OutlinedButton>
        <OutlinedButton size='sm' color='red' onClick={onUnlink}>
          Unlink
        </OutlinedButton>
      </div>
    </CardWrapper>
  );
}

SelectedCard.propTypes = {
  projectName: PropTypes.string.isRequired,
  folderName: PropTypes.string.isRequired,
  onEdit: PropTypes.func.isRequired,
  onUnlink: PropTypes.func.isRequired,
};
