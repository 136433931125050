import React from 'react';
import { QueryClientProvider } from '@tanstack/react-query';
import queryClient from 'react-query-settings/queryClient';
import AppWrapper from 'components/apps/AppWrapper';
import PersonnelShowPage from 'components/pages/PersonnelShowPage';

export default function PersonnelShowApp({ wrappedProps, contextProps }) {
  return (
    <QueryClientProvider client={queryClient}>
      <AppWrapper {...contextProps}>
        <PersonnelShowPage {...wrappedProps} />
      </AppWrapper>
    </QueryClientProvider>
  );
}
