import React from 'react';
import PropTypes from 'prop-types';
import CollectionSelect from 'components/application/CollectionSelect';
import { useCurrentActor } from 'components/contexts/CurrentActor';

export default function SelectDivision({
  team,
  sidePanelContext,
  assignableCompanies,
  onTeamCompanyOptionChange,
}) {
  const currentActor = useCurrentActor();
  const assignedDivision = assignableCompanies.find(
    (company) => company.id === `${team.companyId}`,
  );
  const assignableOptions = assignableCompanies.map((company) => ({
    value: company.id,
    label: company.attributes.name,
  }));
  const companyValue =
    assignedDivision ?
      { value: assignedDivision.id, label: assignedDivision.attributes.name }
    : '';
  const primaryDivision =
    currentActor && currentActor.division.attributes.primary;

  return (
    <div className={`tooltip-parent`}>
      <CollectionSelect
        label='Division'
        isDisabled={sidePanelContext === 'edit' || !primaryDivision}
        name='company'
        value={companyValue}
        isRequired={true}
        options={assignableOptions}
        onChange={(e) => onTeamCompanyOptionChange(e.value)}
        modifiers={['typeable-search', 'grey-disabled']}
      />
    </div>
  );
}

SelectDivision.propTypes = {
  team: PropTypes.object.isRequired,
  sidePanelContext: PropTypes.string.isRequired,
  assignableCompanies: PropTypes.array.isRequired,
  onTeamCompanyOptionChange: PropTypes.func.isRequired,
};
