import React, { Fragment, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import Download from 'components/attachments/components/Download';
import Display from 'components/attachments/components/Display';
import ModalBox from 'components/application/ModalBox';
import Stepper from 'components/application/Stepper';

export default function Previewer(props) {
  const { step, stepDown, stepUp, isOpen, attachments, onClose } = props;

  const [timestamp, setTimestamp] = useState(null);
  const selectedAttachment = attachments[step - 1];

  useEffect(() => {
    setTimestamp(moment().unix());
  }, [JSON.stringify(attachments)]);

  return (
    attachments.length !== 0 && (
      <ModalBox
        isOpen={isOpen}
        onClose={onClose}
        secondaryFooter={
          <Stepper
            totalSteps={attachments.length}
            currentStep={step}
            onStepUp={stepUp}
            onStepDown={stepDown}
          />
        }
      >
        {isOpen && selectedAttachment && timestamp && (
          <Fragment>
            <div className='modalbox-header tw-border-grey-100'>
              <div className='flex flex--justify-content__space-between flex--vertically-centered'>
                <h2 className='modalbox-header__title truncated-text-container m-r-16 tw-text-l tw-font-semibold'>
                  {selectedAttachment.filename}
                </h2>
                <Download
                  hashid={selectedAttachment.hashid}
                  filename={selectedAttachment.filename}
                  disabled={!selectedAttachment.attachedUrl}
                />
              </div>
            </div>
            <div
              className={`modalbox-body${selectedAttachment.recordType === 'pdf' ? ' p-0' : ''}`}
            >
              <Display
                recordType={selectedAttachment.recordType}
                attachedUrl={
                  selectedAttachment.attachedUrl ||
                  selectedAttachment.originalImageUrl
                }
                timestamp={timestamp}
              />
            </div>
          </Fragment>
        )}
      </ModalBox>
    )
  );
}

Previewer.propTypes = {
  step: PropTypes.number.isRequired,
  stepDown: PropTypes.func.isRequired,
  stepUp: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  attachments: PropTypes.array.isRequired,
  onClose: PropTypes.func.isRequired,
};
