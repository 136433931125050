import React from 'react';
import PropTypes from 'prop-types';

import { useCurrentActor } from 'components/contexts/CurrentActor';
import { personDisplayName } from 'components/helpers/users';
import { resourceShape } from 'components/helpers/serialisableResources';

import ContextSwitcher from 'components/application/ContextSwitcher';
import UserMenu from 'components/application/UserMenu';

export default function UserBar({
  currentPath,
  poweredByImgSrc,
  isDashboardAccessible,
  isContextSwitcherVisible,
  isImpersonatorInfoVisible,
  isPoweredByVisible,
  isProcoreSession,
  isTrainingProfileNavigationVisible,
  personnel,
  lineManager,
}) {
  const currentActor = useCurrentActor();

  return (
    <div className='tw-flex tw-items-center tw-bg-grey-700 tw-h-10'>
      {isContextSwitcherVisible && (
        <div className='tw-flex-none'>
          <ContextSwitcher
            currentPath={currentPath}
            isDashboardAccessible={isDashboardAccessible}
          />
        </div>
      )}
      <div className='tw-flex-auto tw-flex tw-items-center tw-justify-end'>
        {isImpersonatorInfoVisible && (
          <div className='tw-flex-auto tw-flex tw-items-center tw-justify-end tw-text-grey-100 tw-h-5'>
            You are currently acting on behalf of{' '}
            {personDisplayName(currentActor.user.attributes)}
          </div>
        )}
        {isPoweredByVisible && (
          <div className='tw-flex-none tw-flex tw-items-center tw-text-grey-100 tw-h-5 tw-ml-3'>
            <span className='tw-font-medium'>Powered by</span>
            <img className='tw-h-5 tw-ml-3' src={poweredByImgSrc} />
          </div>
        )}
        <div className='tw-flex-none tw-h-8 tw-ml-3'>
          <UserMenu
            isProcoreSession={isProcoreSession}
            isTrainingProfileNavigationVisible={
              isTrainingProfileNavigationVisible
            }
            personnel={personnel}
            lineManager={lineManager}
          />
        </div>
      </div>
    </div>
  );
}

UserBar.propTypes = {
  currentPath: PropTypes.string.isRequired,
  poweredByImgSrc: PropTypes.string.isRequired,
  isDashboardAccessible: PropTypes.bool.isRequired,
  isContextSwitcherVisible: PropTypes.bool.isRequired,
  isImpersonatorInfoVisible: PropTypes.bool.isRequired,
  isPoweredByVisible: PropTypes.bool.isRequired,
  isProcoreSession: PropTypes.bool.isRequired,
  isTrainingProfileNavigationVisible: PropTypes.bool.isRequired,
  personnel: resourceShape('simplePersonnel'),
  lineManager: resourceShape('simplePersonnel'),
};
