import { enableAllPlugins } from 'immer';
enableAllPlugins();
import { produce } from 'immer';
import { v4 as uuidv4 } from 'uuid';
import voca from 'voca';

import {
  baseStoreState,
  resourcesAsDomainData,
  populateResources,
  addResource,
  replaceResource,
  removeResource,
} from 'components/helpers/storeHelpers';

const newOptionUuid1 = uuidv4();
const newOptionUuid2 = uuidv4();

const defaultForm = {
  fieldSettingId: null,
  fieldAttributeId: null,
  name: '',
  fieldType: 'singleLineText',
  restricted: true,
  options: {
    data: {
      [newOptionUuid1]: { id: newOptionUuid1, value: '', isNew: true },
      [newOptionUuid2]: { id: newOptionUuid2, value: '', isNew: true },
    },
    allDataIds: [newOptionUuid1, newOptionUuid2],
  },
  requestError: { validationErrors: {}, isFallback: false },
  isSubmitDisabled: false,
};

const defaultSidePanel = { isOpen: false, context: null };

const initialState = {
  ...baseStoreState,
  domain: resourcesAsDomainData([
    'fieldSetting',
    'fieldAttribute',
    'fieldOption',
  ]),
  application: { form: defaultForm },
  ui: { sidePanel: defaultSidePanel },
};

const mapDispatch = (dispatch) => ({
  loadData: ({ data }) => dispatch({ type: 'LOAD DATA', payload: data }),
  openNewSidePanel: () => dispatch({ type: 'OPEN NEW SIDE PANEL' }),
  openEditSidePanel: ({ id }) =>
    dispatch({ type: 'OPEN EDIT SIDE PANEL', payload: { id } }),
  resetSidePanel: () => dispatch({ type: 'RESET SIDE PANEL' }),
  removeErrorStyling: ({ key }) =>
    dispatch({ type: 'REMOVE ERROR STYLING', payload: { key } }),
  updateFormValue: ({ key, value }) =>
    dispatch({ type: 'UPDATE FORM VALUE', payload: { key, value } }),
  updateFormOptionValue: ({ id, value }) =>
    dispatch({ type: 'UPDATE FORM OPTION VALUE', payload: { id, value } }),
  updateFormOptionOrder: ({ oldIndex, newIndex }) =>
    dispatch({
      type: 'UPDATE FORM OPTION ORDER',
      payload: { oldIndex, newIndex },
    }),
  appendFormOption: () => dispatch({ type: 'APPEND FORM OPTION' }),
  deleteFormOption: ({ id }) =>
    dispatch({ type: 'DELETE FORM OPTION', payload: { id } }),
  startSubmittingForm: () => dispatch({ type: 'START SUBMITTING FORM' }),
  stopSubmittingForm: () => dispatch({ type: 'STOP SUBMITTING FORM' }),
  appendFieldSetting: ({ data }) =>
    dispatch({ type: 'APPEND FIELD SETTING', payload: data }),
  deleteFieldSetting: ({ id }) =>
    dispatch({ type: 'DELETE FIELD SETTING', payload: { id } }),
  updateFieldSetting: ({ data }) =>
    dispatch({ type: 'UPDATE FIELD SETTING', payload: data }),
  reorderFieldSettings: ({ ids }) =>
    dispatch({ type: 'REORDER FIELD SETTINGS', payload: { ids } }),
  zeroFieldValueCount: ({ id }) =>
    dispatch({ type: 'ZERO FIELD VALUE COUNT', payload: { id } }),
  setValidationErrors: ({ data }) =>
    dispatch({ type: 'SET VALIDATION ERRORS', payload: data }),
  setFallbackError: () => dispatch({ type: 'SET FALLBACK ERROR' }),
});

function buildForm(draftState, { id }) {
  const fieldSetting = draftState.domain.fieldSettingCollection.data[id];
  const fieldAttribute =
    draftState.domain.fieldAttributeCollection.data[
      fieldSetting.relationships.fieldAttribute.data.id
    ];

  draftState.application.form = {
    fieldSettingId: id,
    fieldAttributeId: fieldAttribute.id,
    name: fieldAttribute.attributes.name,
    fieldType: fieldAttribute.attributes.formattedFieldType,
    restricted: fieldSetting.attributes.restricted,
    options: { data: {}, allDataIds: [] },
    requestError: { validationErrors: {}, isFallback: false },
    isSubmitDisabled: false,
  };

  fieldAttribute.relationships.fieldOptions.data
    .map((obj) => obj.id)
    .forEach((fieldOptionId) => {
      const fieldOption =
        draftState.domain.fieldOptionCollection.data[fieldOptionId];

      draftState.application.form.options.data[fieldOptionId] = {
        id: fieldOptionId,
        value: fieldOption.attributes.value,
        isNew: false,
      };
      draftState.application.form.options.allDataIds.push(fieldOptionId);
    });
}

function resetSidePanel(draftState) {
  draftState.application.form = defaultForm;
  draftState.ui.sidePanel = defaultSidePanel;
}

function removeErrorStyling(draftState, { key }) {
  const validationError =
    draftState.application.form.requestError.validationErrors[
      voca.camelCase(key)
    ];

  if (validationError && validationError.fieldHighlighted) {
    validationError.fieldHighlighted = false;
    draftState.application.form.isSubmitDisabled = false;
  }
}

function updateOrder(arr, { oldIndex, newIndex }) {
  if (newIndex >= arr.length) {
    const k = newIndex - arr.length + 1;
    while (k--) {
      arr.push(undefined);
    }
  }
  arr.splice(newIndex, 0, arr.splice(oldIndex, 1)[0]);
}

function appendFormOption(draftState) {
  const newOptionUuid = uuidv4();
  draftState.application.form.options.data[newOptionUuid] = {
    id: newOptionUuid,
    value: '',
    isNew: true,
  };
  draftState.application.form.options.allDataIds.push(newOptionUuid);
}

function deleteFormOption(draftState, payload) {
  const existingOptionIndex =
    draftState.application.form.options.allDataIds.findIndex(
      (id) => id === payload.id,
    );
  if (existingOptionIndex !== -1)
    draftState.application.form.options.allDataIds.splice(
      existingOptionIndex,
      1,
    );
}

function setIsSubmitDisabled(draftState, bool) {
  draftState.application.form.isSubmitDisabled = bool;
}

function setValidationErrors(draftState, payload) {
  if (payload.errors) {
    const validationErrors = payload.errors.reduce(function (map, obj) {
      if (obj.source) {
        const key = obj.source.attribute;
        map[voca.camelCase(key)] = { error: obj, fieldHighlighted: true };
        return map;
      }
    }, {});

    draftState.application.form.requestError = {
      validationErrors: validationErrors,
      isFallback: false,
    };
  }
}

function setFallbackError(draftState) {
  draftState.application.form.requestError = {
    validationErrors: {},
    isFallback: true,
  };
}

export { defaultForm, defaultSidePanel, initialState, mapDispatch };

export default function reducer(state, action) {
  return produce(state, (draftState) => {
    switch (action.type) {
      case 'LOAD DATA':
        populateResources(draftState, action.payload.data);
        populateResources(draftState, action.payload.included);
        break;
      case 'OPEN NEW SIDE PANEL':
        draftState.application.form = defaultForm;
        draftState.ui.sidePanel = { isOpen: true, context: 'new' };
        break;
      case 'OPEN EDIT SIDE PANEL':
        buildForm(draftState, action.payload);
        draftState.ui.sidePanel = { isOpen: true, context: 'edit' };
        break;
      case 'RESET SIDE PANEL':
        resetSidePanel(draftState);
        break;
      case 'REMOVE ERROR STYLING':
        removeErrorStyling(draftState, action.payload);
        break;
      case 'UPDATE FORM VALUE':
        draftState.application.form[action.payload.key] = action.payload.value;
        break;
      case 'UPDATE FORM OPTION VALUE':
        draftState.application.form.options.data[action.payload.id].value =
          action.payload.value;
        break;
      case 'UPDATE FORM OPTION ORDER':
        updateOrder(
          draftState.application.form.options.allDataIds,
          action.payload,
        );
        break;
      case 'APPEND FORM OPTION':
        appendFormOption(draftState);
        break;
      case 'DELETE FORM OPTION':
        deleteFormOption(draftState, action.payload);
        break;
      case 'START SUBMITTING FORM':
        setIsSubmitDisabled(draftState, true);
        break;
      case 'STOP SUBMITTING FORM':
        setIsSubmitDisabled(draftState, false);
        break;
      case 'APPEND FIELD SETTING':
        addResource(draftState, action.payload.data);
        populateResources(draftState, action.payload.included);
        resetSidePanel(draftState);
        break;
      case 'DELETE FIELD SETTING':
        removeResource(draftState, {
          type: 'fieldSetting',
          id: action.payload.id,
        });
        break;
      case 'UPDATE FIELD SETTING':
        replaceResource(draftState, action.payload.data);
        populateResources(draftState, action.payload.included);
        resetSidePanel(draftState);
        break;
      case 'REORDER FIELD SETTINGS':
        draftState.domain.fieldSettingCollection.allDataIds =
          action.payload.ids;
        break;
      case 'ZERO FIELD VALUE COUNT':
        draftState.domain.fieldAttributeCollection.data[
          action.payload.id
        ].attributes.fieldValueCount = 0;
        break;
      case 'SET VALIDATION ERRORS':
        setValidationErrors(draftState, action.payload);
        break;
      case 'SET FALLBACK ERROR':
        setFallbackError(draftState);
        setIsSubmitDisabled(draftState, false);
        break;
    }
  });
}
