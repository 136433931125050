import PropTypes from 'prop-types';
import React from 'react';
import Toggleable from 'components/application/Toggleable';
import SubmittableField from 'components/application/SubmittableField';

export default function ToggleableField(props) {
  return (
    <Toggleable
      isToggled={props.isToggled}
      setIsToggled={props.onToggle}
      primary={props.toggleableItem}
      secondary={
        <SubmittableField
          autoFocus={props.autoFocus}
          isSubmitDisabled={props.isSubmitDisabled}
          onCancel={props.onCancel}
          onChange={props.onChange}
          onSubmit={props.onSubmit}
          placeholderText={props.placeholderText}
          submitButtonText={props.submitButtonText}
          value={props.value}
        />
      }
    />
  );
}

ToggleableField.propTypes = {
  autoFocus: PropTypes.bool,
  isSubmitDisabled: PropTypes.bool,
  isToggled: PropTypes.bool,
  onCancel: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onToggle: PropTypes.func.isRequired,
  placeholderText: PropTypes.string,
  submitButtonText: PropTypes.string.isRequired,
  toggleableItem: PropTypes.node,
  value: PropTypes.string,
};
