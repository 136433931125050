import React from 'react';
import PropTypes from 'prop-types';

import { useBreadBoard } from 'components/contexts/Toaster';
import {
  usePersonnel,
  usePersonnelTraining,
} from 'components/mobileTrainingProfile/hooks/reactQuery';
import {
  formatResources,
  filterIncluded,
} from 'components/mobileTrainingProfile/helpers/requests';
import { getTrainingStatus } from 'components/helpers/resources/training';

import LeftArrowIcon from '-!svg-react-loader?name=LeftArrowIcon!icons/left-arrow-lg.svg';

import CombinedStatus from 'components/mobileTrainingProfile/components/courseDetailsScreen/CombinedStatus';
import BookingDetails from 'components/mobileTrainingProfile/components/courseDetailsScreen/BookingDetails';
import Evidence from 'components/mobileTrainingProfile/components/courseDetailsScreen/Evidence';

export default function CourseDetailsScreen({
  personnelId,
  courseId,
  isRequired,
  onViewHomeClick,
}) {
  const breadBoard = useBreadBoard();

  const {
    data: personnelQuery,
    isLoading: isPersonnelQueryLoading,
    isError: isPersonnelQueryError,
  } = usePersonnel({
    personnelId,
    select: personnelQuerySelector,
    onError: breadBoard.addInedibleToast,
  });

  const {
    data: personnelTrainingQuery,
    isLoading: isPersonnelTrainingQueryLoading,
    isError: isPersonnelTrainingQueryError,
  } = usePersonnelTraining({
    personnelId,
    select: personnelTrainingQuerySelector,
    onError: breadBoard.addInedibleToast,
  });

  if (
    isPersonnelQueryLoading ||
    isPersonnelQueryError ||
    isPersonnelTrainingQueryLoading ||
    isPersonnelTrainingQueryError
  )
    return null;

  const allCoursesIndexedById = {
    ...personnelQuery.courses.indexedById,
    ...personnelTrainingQuery.courses.indexedById,
  };

  const course = allCoursesIndexedById[courseId];
  const booking = personnelQuery.bookings.indexedByCourseId[course.id];
  const booker =
    booking ?
      personnelQuery.bookers.indexedById[booking.relationships.booker.data?.id]
    : null;
  const registration =
    personnelQuery.registrations.indexedByCourseId[course.id];
  const latestTraining =
    (personnelTrainingQuery.training.groupedByCourseId[course.id] &&
      personnelTrainingQuery.training.groupedByCourseId[course.id][0]) ||
    null;
  const attachments =
    personnelTrainingQuery.attachments.groupedByTrainingId[
      latestTraining?.id
    ] || [];

  const currentTrainingStatus = getTrainingStatus({
    course,
    training: latestTraining,
  });

  return (
    <>
      <div className='tw-sticky tw-top-0 tw-z-[999] tw-flex tw-items-center tw-bg-grey-900 tw-h-20 tw-w-full tw-p-3'>
        <div className='tw-flex-none tw-flex tw-items-center tw-border-solid tw-border-0 tw-border-r-1 tw-border-grey-500 tw-w-13 tw-h-full'>
          <LeftArrowIcon
            data-element-name='navigate-home-icon'
            className='tw-ml-0.5 tw-cursor-pointer'
            width={40}
            height={40}
            onClick={onViewHomeClick}
          />
        </div>
        <div className='tw-flex-auto tw-text-grey-025 tw-ml-4 tw-mr-4'>
          <h1 className='tw-font-mark-pro tw-text-xl tw-font-bold tw-tracking-tight tw-m-0'>
            Course details
          </h1>
        </div>
      </div>
      <div className='tw-p-3'>
        <div className='tw-border-solid tw-border-1 tw-rounded-lg tw-border-grey-100 tw-bg-white tw-p-4'>
          <div className='tw-mb-3'>
            <h2 className='tw-text-grey-900 tw-text-2xl tw-font-semibold tw-tracking-tighter tw-m-0'>
              {course.attributes.name}
            </h2>
            {!isRequired && (
              <span className='tw-text-grey-500 tw-text-m tw-font-normal tw-tracking-auto tw-mt-1'>
                Course is optional
              </span>
            )}
          </div>
          <CombinedStatus
            currentTrainingStatus={currentTrainingStatus}
            latestTraining={latestTraining}
            isRequired={isRequired}
          />
          {booking && (
            <div className='tw-mt-6'>
              <BookingDetails
                course={course}
                booking={booking}
                booker={booker}
                registration={registration}
              />
            </div>
          )}
        </div>
        <div className='tw-mt-3'>
          <Evidence
            attachments={attachments}
            coverImagesIndexedById={
              personnelTrainingQuery.coverImages.indexedById
            }
          />
        </div>
      </div>
    </>
  );
}

CourseDetailsScreen.propTypes = {
  personnelId: PropTypes.string.isRequired,
  courseId: PropTypes.string.isRequired,
  isRequired: PropTypes.bool,
  onViewHomeClick: PropTypes.func.isRequired,
};

const personnelQuerySelector = (response) => {
  const included = response.included;

  return {
    courses: formatResources({
      resources: filterIncluded({ included, type: 'course' }),
      options: { indexedById: { path: 'id' } },
    }),
    bookings: formatResources({
      resources: filterIncluded({ included, type: 'booking' }),
      options: { indexedByCourseId: { path: 'relationships.course.data.id' } },
    }),
    bookers: formatResources({
      resources: filterIncluded({ included, type: 'simpleUser' }),
      options: { indexedById: { path: 'id' } },
    }),
    registrations: formatResources({
      resources: filterIncluded({ included, type: 'registration' }),
      options: { indexedByCourseId: { path: 'relationships.course.data.id' } },
    }),
  };
};

const personnelTrainingQuerySelector = (response) => {
  const data = response.data;
  const included = response.included;

  return {
    training: formatResources({
      resources: data,
      options: { groupedByCourseId: { path: 'relationships.course.data.id' } },
    }),
    courses: formatResources({
      resources: filterIncluded({ included, type: 'course' }),
      options: { indexedById: { path: 'id' } },
    }),
    attachments: formatResources({
      resources: filterIncluded({ included, type: 'attachment' }),
      options: {
        groupedByTrainingId: { path: 'relationships.attachable.data.id' },
      },
    }),
    coverImages: formatResources({
      resources: filterIncluded({ included, type: 'coverImage' }),
      options: { indexedById: { path: 'id' } },
    }),
  };
};
