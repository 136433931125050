import React from 'react';
import PropTypes from 'prop-types';

import Options from 'components/application/Options';
import OptionChoice from '../application/OptionChoice';
import OutlinedButton from '../application/buttons/OutlinedButton';
import ChevronUpIcon from '-!svg-react-loader?name=ChevronUpIcon!icons/chevron-up.svg';
import ChevronDownIcon from '-!svg-react-loader?name=ChevronDownIcon!icons/chevron-down.svg';
import PersonIcon from '-!svg-react-loader?name=PersonIcon!icons/person.svg';
import Tooltip from '../application/Tooltip';

export default function SelectedMembersOptions({ member, onChange }) {
  return (
    <Options
      key={'memberStatus'}
      toggleStyle={'[&_.options\_\_toggle]:tw-h-6'}
      listStyle='[&_li]:tw-h-auto'
      buttonDisabled={!member.isStatusChangeEnabled}
      btnToggle={(isOpen) => {
        return (
          <div className='tooltip-parent tw-h-6'>
            <OutlinedButton
              className={`tw-h-6`}
              color='white'
              onClick={() => {}}
            >
              {member.isManager && <PersonIcon width={20} height={20} />}
              <span className='p-r-4 tw-text-s'>
                {member.isManager ? 'Manager' : 'Member'}
              </span>
              <ChevronUpIcon
                className='m-r--8'
                width={24}
                height={24}
                style={{ display: isOpen ? 'inline-block' : 'none' }}
              />
              <ChevronDownIcon
                className={`m-r--8${member.isStatusChangeEnabled ? '' : ' [&_polygon]:tw-fill-grey-300'}`}
                width={24}
                height={24}
                style={{ display: isOpen ? 'none' : 'inline-block' }}
              />
            </OutlinedButton>
            {!member.isStatusChangeEnabled && (
              <Tooltip
                placement='top'
                trigger='hover'
                tooltip={member.tooltip}
              />
            )}
          </div>
        );
      }}
    >
      <OptionChoice
        onClick={(setOptionsOpen) => {
          onChange({ member, isManager: false });
          setOptionsOpen(false);
        }}
      >
        <div className='tw-flex tw-flex-col tw-py-2.5 tw-px-3'>
          <span className='tw-text-m tw-text-grey-700'>Member</span>
          <span className='tw-text-s tw-text-grey-500'>
            No view or edit access
          </span>
        </div>
      </OptionChoice>
      <OptionChoice
        onClick={(setOptionsOpen) => {
          onChange({ member, isManager: true });
          setOptionsOpen(false);
        }}
      >
        <div className='tw-flex tw-flex-col tw-py-2.5 tw-px-3'>
          <span className='tw-text-m tw-text-grey-700'>Manager</span>
          <span className='tw-text-s tw-text-grey-500'>
            Can edit members already in team
          </span>
        </div>
      </OptionChoice>
      <div className='tw-text-s tw-text-grey-500 tw-border-t-grey-100 tw-bg-grey-025 tw-w-[284px] tw-px-2.5 tw-py-3'>
        <div>Only users with personnel editor permission</div>
        <div>
          can add members to teams &nbsp;
          <a
            target='_blank'
            className='tw-p-0 tw-w-0'
            href='https://intercom.help/hands-hq/en/articles/9158540-how-to-create-and-manage-teams'
          >
            Learn more
          </a>
          .
        </div>
      </div>
    </Options>
  );
}

SelectedMembersOptions.propTypes = {
  member: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
};
