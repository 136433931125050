import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { resourceShape } from 'components/helpers/serialisableResources';

import { useCurrentActor } from 'components/contexts/CurrentActor';
import {
  groupTrainingByCourseId,
  courseTrainingStatusOrdering,
} from 'components/helpers/resources/training';
import { getELearningCourseId } from 'components/helpers/resources/courses';
import { mappedAutoEnrolmentExclusionsByConfigurationId } from 'components/helpers/resources/autoEnrolments';

import Table from 'components/application/Table';
import CourseTraining from 'components/training/CourseTraining';

export default function AdditionalTrainingTable(props) {
  const currentActor = useCurrentActor();

  const trainingByCourseId = groupTrainingByCourseId({
    training: props.training,
  });
  // repeated function from RoleTrainingTab; refactor when state is lifted
  const allCompanyRoleCourseIds = props.roles.reduce((acc, role) => {
    const ids = role.relationships.courses.data.map(
      (roleCourse) => roleCourse.id,
    );
    return [...acc, ...ids];
  }, []);

  const courseTrainingCollections = props.courses.reduce((acc, course) => {
    const eLearningCourse =
      props.eLearningCourses[getELearningCourseId(course)];
    const autoEnrolmentConfiguration =
      props.autoEnrolmentConfigurations &&
      props.autoEnrolmentConfigurations[
        course.relationships?.autoEnrolmentConfiguration?.data?.id
      ];
    const autoEnrolmentExclusion =
      props.autoEnrolmentExclusions &&
      mappedAutoEnrolmentExclusionsByConfigurationId(
        props.autoEnrolmentExclusions,
      )[autoEnrolmentConfiguration?.id];
    const training = trainingByCourseId[course.id];
    const firstTrainingMember = training && training[0];
    const booking = props.currentBookings.find(
      (booking) => booking.relationships.course.data.id == course.id,
    );
    const booker =
      props.bookers && props.bookers[booking?.relationships?.booker?.data?.id];
    const registration = props.currentRegistrations.find(
      (registration) => registration.relationships.course.data.id == course.id,
    );
    const userCourse = props.userCourses.find(
      (userCourse) => userCourse.relationships.course.data.id === course.id,
    );

    const isRequired = !!(userCourse && userCourse.attributes.required);
    const isRequiredByAnotherRole = allCompanyRoleCourseIds.includes(course.id);
    const isRequiredByAnything = isRequired || isRequiredByAnotherRole;

    const component = (
      <CourseTraining
        key={`additionalCourseTraining--${course.id}`}
        isRequired={isRequired}
        isWithinRoleTable={false}
        isAdditional={true}
        isRequiredByAnything={isRequiredByAnything}
        isRequiredByAnotherRole={isRequiredByAnotherRole}
        hasTrainingEditableAccess={props.hasTrainingEditableAccess}
        course={course}
        autoEnrolmentConfiguration={autoEnrolmentConfiguration}
        autoEnrolmentExclusion={autoEnrolmentExclusion}
        eLearningCourse={eLearningCourse}
        eLearningAllowance={props.eLearningAllowance}
        training={training}
        booking={booking}
        booker={booker}
        registration={registration}
        userCourse={userCourse}
        attachments={props.attachments}
        currentPersonnel={props.currentPersonnel}
        onUpdateUserCourse={props.onUpdateUserCourse}
        onCreateUserCourse={props.onCreateUserCourse}
        creditInfoUnavailable={props.creditInfoUnavailable}
        onNewTrainingClick={props.onNewTrainingClick}
        onEditTrainingClick={props.onEditTrainingClick}
        onDeleteTrainingClick={props.onDeleteTrainingClick}
        onDeleteCourseClick={props.onDeleteCourseClick}
        onAddBooking={props.onAddBooking}
        onShowBooking={props.onShowBooking}
        onAddELearningBooking={props.onAddELearningBooking}
        onRemoveBooking={props.onRemoveBooking}
        onBookingReminderClick={props.onBookingReminderClick}
        setModalBoxData={props.setModalBoxData}
        personnelId={props.personnelId}
        onCreateAutoEnrolmentExclusion={props.onCreateAutoEnrolmentExclusion}
        onDeleteAutoEnrolmentExclusion={props.onDeleteAutoEnrolmentExclusion}
      />
    );

    const priorityStatus = courseTrainingStatusOrdering({
      course,
      isRequired,
      training: firstTrainingMember,
    });
    const row = {
      priorityStatus: priorityStatus,
      name: course.attributes.name,
      component: component,
    };
    const insertIndex = acc.findIndex(
      (obj) =>
        (obj.priorityStatus === priorityStatus &&
          obj.name > course.attributes.name) ||
        obj.priorityStatus > priorityStatus,
    );

    insertIndex === -1 ? acc.push(row) : acc.splice(insertIndex, 0, row);

    return acc;
  }, []);

  return (
    <Fragment>
      <Table
        headers={
          <tr>
            <th className='tw-text-s tw-text-grey-900 tw-font-medium tw-tracking-wide fw-66'></th>
            <th className='tw-text-s tw-text-grey-900 tw-font-medium tw-tracking-wide'>
              Name
            </th>
            <th className='tw-text-s tw-text-grey-900 tw-font-medium tw-tracking-wide fw-124'>
              Expiry date
            </th>
            <th className='tw-text-s tw-text-grey-900 tw-font-medium tw-tracking-wide fw-216'>
              Bookings
            </th>
            {currentActor.user.attributes.accessType !== 'personnel' && (
              <th className='tw-text-s tw-text-grey-900 tw-font-medium tw-tracking-wide fw-72'>
                Actions
              </th>
            )}
          </tr>
        }
        rows={courseTrainingCollections.map((row) => row.component)}
      />
    </Fragment>
  );
}

AdditionalTrainingTable.propTypes = {
  eLearningCourses: PropTypes.objectOf(resourceShape('eLearningCourse')),
  training: PropTypes.arrayOf(resourceShape('training')),
  bookers: PropTypes.objectOf(resourceShape('simpleUser')),
  userCourses: PropTypes.arrayOf(resourceShape('userCourse')),
  autoEnrolmentConfiguration: resourceShape('autoEnrolmentConfiguration'),
  autoEnrolmentExclusions: PropTypes.object,
  eLearningAllowance: PropTypes.object,
  currentBookings: PropTypes.arrayOf(resourceShape('booking')),
  currentRegistrations: PropTypes.array,
  creditInfoUnavailable: PropTypes.bool,
  onBookingReminderClick: PropTypes.func.isRequired,
  onShowBooking: PropTypes.func.isRequired,
  onRemoveBooking: PropTypes.func.isRequired,
  onCreateAutoEnrolmentExclusion: PropTypes.func,
  onDeleteAutoEnrolmentExclusion: PropTypes.func,
  roles: PropTypes.arrayOf(resourceShape('companyRole')),
  courses: PropTypes.arrayOf(resourceShape('course')),
  autoEnrolmentConfigurations: PropTypes.object,
  hasTrainingEditableAccess: PropTypes.bool.isRequired,
  attachments: PropTypes.array,
  currentPersonnel: PropTypes.object.isRequired,
  onUpdateUserCourse: PropTypes.func.isRequired,
  onCreateUserCourse: PropTypes.func.isRequired,
  onNewTrainingClick: PropTypes.func.isRequired,
  onEditTrainingClick: PropTypes.func.isRequired,
  onDeleteTrainingClick: PropTypes.func.isRequired,
  onDeleteCourseClick: PropTypes.func.isRequired,
  onAddBooking: PropTypes.func.isRequired,
  onAddELearningBooking: PropTypes.func.isRequired,
  onRemoveBooking: PropTypes.func.isRequired,
  setModalBoxData: PropTypes.func,
  personnelId: PropTypes.node,
};

AdditionalTrainingTable.defaultProps = {
  training: [],
  userCourses: [],
};
