import React from 'react';
import PropTypes from 'prop-types';
import ModalBox from 'components/application/ModalBox';
import TextButton from 'components/application/buttons/TextButton';
import FilledButton from 'components/application/buttons/FilledButton';

RemoveModal.propTypes = {
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
  strategy: PropTypes.string.isRequired,
  divisionName: PropTypes.string.isRequired,
  isTrainingRegisterAllowed: PropTypes.bool.isRequired,
  onRemove: PropTypes.func.isRequired,
  onRevoke: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default function RemoveModal({
  firstName,
  lastName,
  strategy,
  divisionName,
  isTrainingRegisterAllowed,
  onRemove,
  onRevoke,
  onClose,
}) {
  const isDeletion = strategy === 'delete';

  return (
    <ModalBox
      mode='letterbox'
      isOpen={true}
      usingStandardDimensions={true}
      onClose={onClose}
      customFooter={
        <div className='modalbox__footer-actions modalbox__footer-actions--right'>
          <TextButton
            size='md'
            color='blue'
            className='m-r-12'
            onClick={onClose}
          >
            Cancel
          </TextButton>
          <FilledButton color='red' onClick={isDeletion ? onRemove : onRevoke}>
            Remove
          </FilledButton>
        </div>
      }
    >
      <>
        <div className='modalbox-header tw-border-grey-100'>
          <h2 className='modalbox-header__title truncated-text-container tw-text-l tw-text-grey-900 tw-font-semibold'>
            {`Are you sure you want to remove ${firstName} ${lastName}${isDeletion ? '' : ` from ${divisionName}`}?`}
          </h2>
        </div>
        <div className='modalbox-body'>
          {isDeletion ?
            `${isTrainingRegisterAllowed ? 'They won’t be able to access HandsHQ or their training profile anymore.' : 'They will no longer be able to access HandsHQ.'} The personnel profile won’t be deleted.`
          : 'This won’t affect other divisions they might have access to. The personnel profile won’t be deleted.'
          }
        </div>
      </>
    </ModalBox>
  );
}
