import React, { useState, useRef } from 'react';
import { focusField } from 'components/helpers/refs';
import PropTypes from 'prop-types';
import { useTrainingRegisterResources } from 'components/contexts/TrainingRegisterResourceManagementContext';
import { resourceShape } from 'components/helpers/serialisableResources';
import PersonnelForm from 'components/personnel/PersonnelForm';
import PersonnelShow from 'components/personnel/PersonnelShow';
import PersonnelSidePanelTabs from 'components/personnel/PersonnelSidePanelTabs';
import FormFooter from 'components/sidepanels/FormFooter';
import ShowFooter from 'components/sidepanels/ShowFooter';
import SidePanel from 'components/application/SidePanel';
import PanelIcon from 'components/application/PanelIcon';
import PersonIcon from '-!svg-react-loader?name=PersonIcon!icons/person-lg.svg';
import ArchiveButton from 'components/archive/ArchiveButton';

export default function PersonnelSidePanel({
  allowShowFooterContents,
  assignableCompanies,
  assignableRoles,
  availableFields,
  availableFieldOptions,
  closeSidePanel,
  createPersonnel = () => void 0,
  currentPersonnel,
  user,
  currentSubcontractor,
  division,
  isSubcontractor,
  lineManager,
  lineManagers,
  onArchiveButtonClick,
  onCancel,
  onCompanyRolesOptionChange,
  onDateFieldChange,
  onToggle,
  onEditClick,
  onDeleteClick,
  onFieldOptionChange,
  onAssignableRoleAdd,
  onNewCompanyRoleAdd,
  onPersonnelDateChange,
  onPersonnelFieldChange,
  onPersonnelInputChange,
  onPersonnelOptionChange,
  onPersonnelUpdateSubmit,
  onRemoveRole,
  onSelectPrimary,
  onSubcontractorSelect,
  personnel,
  removeErrorStyling,
  requestError,
  roles,
  setCurrentPersonnel,
  setIsSubcontractor,
  sidePanelContext,
  sidePanelIsOpen,
  subcontractor,
  submitDisabled,
  teams,
}) {
  const trainingRegisterResourceManagementContext =
    useTrainingRegisterResources();
  const [scrolledAmount, setScrolledAmount] = useState(0);
  const sidePanelBodyRef = useRef();
  const sidePanelFieldRef = useRef();

  const iconHeaderContent = (
    <PanelIcon Icon={PersonIcon} scrolledAmount={scrolledAmount} />
  );

  const titleHeaderContent = (
    <div className='popup__title popup__title--tertiary'>
      <h1 className='tw-text-grey-900 tw-text-xl tw-font-semibold tw-tracking-tight'>
        {sidePanelContext === 'edit' ? 'Edit personnel' : 'Add new personnel'}
      </h1>
    </div>
  );

  const formBodyContent = (
    <>
      <PersonnelForm
        currentPersonnel={currentPersonnel}
        user={user}
        setCurrentPersonnel={setCurrentPersonnel}
        requestError={requestError}
        personnel={personnel}
        lineManagers={lineManagers}
        assignableCompanies={assignableCompanies}
        assignableRoles={assignableRoles}
        onAssignableRoleAdd={onAssignableRoleAdd}
        currentSubcontractor={currentSubcontractor}
        onSubcontractorSelect={onSubcontractorSelect}
        isSubcontractor={isSubcontractor}
        setIsSubcontractor={setIsSubcontractor}
        sidePanelIsOpen={sidePanelIsOpen}
        sidePanelContext={sidePanelContext}
        sidePanelBodyRef={sidePanelBodyRef}
        sidePanelFieldRef={sidePanelFieldRef}
        onCompanyRolesOptionChange={onCompanyRolesOptionChange}
        onPersonnelInputChange={onPersonnelInputChange}
        onPersonnelOptionChange={onPersonnelOptionChange}
        onPersonnelDateChange={onPersonnelDateChange}
        removeErrorStyling={removeErrorStyling}
        isCreatableCompanyRole={
          trainingRegisterResourceManagementContext.hasRoleEditableAccess
        }
        isEditableProfileAccess={
          trainingRegisterResourceManagementContext.hasProfileAccessEditableAccess
        }
        onNewCompanyRoleAdd={onNewCompanyRoleAdd}
        submitDisabled={submitDisabled}
        availableFields={availableFields}
        availableFieldOptions={availableFieldOptions}
        onPersonnelFieldChange={onPersonnelFieldChange}
        onFieldOptionChange={onFieldOptionChange}
        onDateFieldChange={onDateFieldChange}
        onToggle={onToggle}
        onSelectPrimary={onSelectPrimary}
        onRemoveRole={onRemoveRole}
      />
    </>
  );

  const showBodyContent = (
    <>
      <PersonnelShow
        currentPersonnel={currentPersonnel}
        division={division}
        subcontractor={subcontractor}
        personnel={personnel}
        assignableCompanies={assignableCompanies}
        roles={roles}
        availableFields={availableFields}
        availableFieldOptions={availableFieldOptions}
      />
      <hr className='tw-h-px tw-bg-grey-100 tw-m-0 tw-border-0' />
      <PersonnelSidePanelTabs
        currentPersonnel={currentPersonnel}
        defaultDetails={[
          { label: 'Personnel ID', text: currentPersonnel.externalId },
          { label: 'Email', text: currentPersonnel.email },
        ]}
        teams={teams}
        sidePanelIsOpen={sidePanelIsOpen}
        availableFields={availableFields}
        availableFieldOptions={availableFieldOptions}
        lineManager={lineManager}
        isCompact={false}
        key={`currentPersonnelId: ${currentPersonnel.id}`}
      />
    </>
  );

  const newFooterContent = (
    <FormFooter
      text='Add personnel'
      onCancel={onCancel || closeSidePanel}
      onSubmit={createPersonnel}
      submitButtonDisabled={submitDisabled}
    />
  );

  const showFooterContent = allowShowFooterContents && (
    <ShowFooter
      resource={currentPersonnel.firstName}
      onEditClick={onEditClick}
      onDeleteClick={onDeleteClick}
      requestError={requestError}
      isCompact={true}
      isEditButtonHidden={currentPersonnel.isArchived}
    >
      <ArchiveButton
        disabled={
          !currentPersonnel.isArchived &&
          currentPersonnel.isParticipatingInOngoingProjects
        }
        disabledTooltip="Personnel on live or future RAMS can't be archived"
        isCompact={true}
        isArchived={currentPersonnel.isArchived}
        onClick={onArchiveButtonClick}
      />
    </ShowFooter>
  );

  const editFooterContent = (
    <FormFooter
      text='Save changes'
      onCancel={onCancel || closeSidePanel}
      onSubmit={onPersonnelUpdateSubmit}
      submitButtonDisabled={submitDisabled}
    />
  );

  const footerContent = () => {
    switch (sidePanelContext) {
      case 'new':
        return newFooterContent;
      case 'show':
        return showFooterContent;
      case 'edit':
        return editFooterContent;
    }
  };

  const onBackdropClick = () => {
    const panelLocked = ['new', 'edit'].includes(sidePanelContext);
    if (!panelLocked) {
      closeSidePanel();
    }
  };

  return (
    <SidePanel
      color={sidePanelContext === 'show' ? 'secondary' : 'tertiary'}
      isOpen={sidePanelIsOpen}
      onOpen={() => sidePanelContext === 'new' && focusField(sidePanelFieldRef)}
      displayClose={sidePanelContext === 'show'}
      onBackdropClick={onBackdropClick}
      closeCallback={closeSidePanel}
      headerContent={
        sidePanelContext === 'show' ? iconHeaderContent : titleHeaderContent
      }
      bodyContent={
        sidePanelContext === 'show' ? showBodyContent : formBodyContent
      }
      bodyRef={sidePanelBodyRef}
      scrolledAmount={scrolledAmount}
      setScrolledAmount={setScrolledAmount}
      scrollHeader={sidePanelContext === 'show'}
      footerContent={footerContent()}
      contentContext={sidePanelContext}
      submitDisabled={submitDisabled}
    />
  );
}
PersonnelSidePanel.propTypes = {
  allowShowFooterContents: PropTypes.bool,
  availableFields: PropTypes.shape({
    loaded: PropTypes.bool,
    collection: PropTypes.array,
  }),
  availableFieldOptions: PropTypes.shape({
    loaded: PropTypes.bool,
    collection: PropTypes.array,
  }),
  assignableCompanies: PropTypes.arrayOf(resourceShape('assignableCompany')),
  assignableRoles: PropTypes.arrayOf(resourceShape('assignableCompanyRole')),
  closeSidePanel: PropTypes.func,
  createPersonnel: PropTypes.func,
  currentPersonnel: PropTypes.object,
  user: resourceShape('user'),
  currentSubcontractor: PropTypes.object,
  division: resourceShape(['company', 'assignableCompany']),
  isSubcontractor: PropTypes.bool,
  lineManager: resourceShape('lineManager', 'personnel'),
  lineManagers: PropTypes.arrayOf(resourceShape('lineManager')),
  onArchiveButtonClick: PropTypes.func,
  onCancel: PropTypes.func,
  onCompanyRolesOptionChange: PropTypes.func,
  onDateFieldChange: PropTypes.func,
  onToggle: PropTypes.func,
  onDeleteClick: PropTypes.func,
  onFieldOptionChange: PropTypes.func,
  onAssignableRoleAdd: PropTypes.func,
  onNewCompanyRoleAdd: PropTypes.func,
  onPersonnelDateChange: PropTypes.func,
  onPersonnelFieldChange: PropTypes.func,
  onPersonnelInputChange: PropTypes.func,
  onPersonnelOptionChange: PropTypes.func,
  onRemoveRole: PropTypes.func,
  onSelectPrimary: PropTypes.func,
  personnel: resourceShape('personnel'),
  removeErrorStyling: PropTypes.func,
  requestError: PropTypes.object,
  roles: PropTypes.arrayOf(resourceShape('companyRole')),
  onPersonnelUpdateSubmit: PropTypes.func,
  setCurrentPersonnel: PropTypes.func,
  setIsSubcontractor: PropTypes.func,
  onSubcontractorSelect: PropTypes.func,
  sidePanelContext: PropTypes.oneOf(['new', 'show', 'edit']),
  sidePanelIsOpen: PropTypes.bool,
  subcontractor: resourceShape(['subcontractor', 'assignableCompany']),
  submitDisabled: PropTypes.bool,
  teams: PropTypes.arrayOf(resourceShape('team')),
};
