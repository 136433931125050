import React, { Fragment } from 'react';

export default function ValidationErrorMessage(props) {
  const { filename, typeNotAccepted, maxSizeReached } = props;

  const errors = [
    {
      isActive: typeNotAccepted,
      message: 'The file is not a PNG, JPG, or PDF',
    },
    { isActive: maxSizeReached, message: 'The size is larger than 10MB' },
  ];

  return (
    <Fragment>
      <span className='tw-font-medium'>{filename}</span> was not uploaded
      because:
      <ul>
        {errors
          .filter((error) => error.isActive)
          .map((error, index) => (
            <li key={index}>
              <span>{error.message}</span>
            </li>
          ))}
      </ul>
    </Fragment>
  );
}
