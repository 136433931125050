import React, { useState } from 'react';
import PropTypes from 'prop-types';

import OutlinedButton from 'components/application/buttons/OutlinedButton';
import NavBackIcon from '-!svg-react-loader?name=NavBackIcon!icons/ic-nav-back.svg';
import ProgressIcon from '-!svg-react-loader?name=ProgressIcon!icons/progress.svg';

export default function MasterCoshhBackButton({
  projectId,
  projectDisplayName,
  coshhDocumentsChangeMade,
  addInedibleToast,
}) {
  const [loading, setLoading] = useState(false);

  const handleOnClick = () => {
    setLoading(true);

    if (coshhDocumentsChangeMade) {
      axios
        .post(`/projects/${projectId}/latest_history/versions`, {
          success_toast_message: 'COSHH assessments were successfully updated',
          failure_toast_message:
            'COSHH assessments did not update - please try again',
        })
        .then(() => {
          window.location.href = `/projects/${projectId}?switch_tab=coshh-tab`;
        })
        .catch(() => {
          addInedibleToast();
          setLoading(false);
        });
    } else {
      window.location.href = `/projects/${projectId}?switch_tab=coshh-tab`;
    }
  };

  return (
    <OutlinedButton
      className='nav-button no-back-warning'
      size='md'
      color='blue-grey'
      onDark={true}
      onClick={loading ? () => {} : handleOnClick}
    >
      <NavBackIcon className='m-r-4 p-t-2' width={12} height={12} />
      {`Back to ${projectDisplayName} `}
      <ProgressIcon
        className={`m-l-6 ${loading ? 'tw-inline-block' : 'tw-hidden'}`}
      />
    </OutlinedButton>
  );
}

MasterCoshhBackButton.propTypes = {
  projectId: PropTypes.number.isRequired,
  projectDisplayName: PropTypes.string.isRequired,
  coshhDocumentsChangeMade: PropTypes.bool.isRequired,
  addInedibleToast: PropTypes.func.isRequired,
};
