import React, { useReducer } from 'react';

import validationErrorsReducer, {
  initialValidationErrorsState,
  mapValidationErrorsDispatch,
} from 'components/validationErrors/reducers/validationErrorsReducer';
import { trainingStatusFilterSchema } from 'components/trainingRegisterReports/schemas/trainingStatusFilterSchema';
import {
  validateForm,
  buildFormValues,
} from 'components/trainingRegisterReports/helpers';

import { useCurrentActor } from 'components/contexts/CurrentActor';
import useReportForm, {
  initialTrainingStatusFilterState,
  initialEstimatesState,
  initialFiltersState,
} from 'components/trainingRegisterReports/hooks/useReportForm';
import useWindowStorage from 'components/hooks/useWindowStorage';

import Report from 'components/trainingRegisterReports/reports/Report';
import TrainingStatusFilter from 'components/trainingRegisterReports/components/TrainingStatusFilter';

const initialFormValues = {
  trainingStatusFilter: initialTrainingStatusFilterState,
  estimates: initialEstimatesState,
  filters: initialFiltersState,
};

const additionalValidators = [
  { schema: trainingStatusFilterSchema, key: 'trainingStatusFilter' },
];

export default function CurrentTrainingStatusReport() {
  const currentActor = useCurrentActor();

  const [getLocalStorageItem, setLocalStorageItem, removeLocalStorageItem] =
    useWindowStorage(
      `currentTrainingStatusReport|${currentActor.user.id}|${currentActor.division.id}`,
    );
  const {
    formValues,
    handleTrainingStatusFilterChange,
    handleEstimatesChange,
    handleFilterAddition,
    handleFilterRemoval,
    handleFilterSelection,
    handleFilterDeselection,
    handleFilterChange,
  } = useReportForm(
    'currentTrainingStatus',
    initialFormValues,
    (initialState) => {
      return buildFormValues({
        type: 'currentTrainingStatus',
        formValues: initialState,
        additionalValidators,
        getLocalStorageItem,
        removeLocalStorageItem,
      });
    },
  );

  const [validationErrors, validationErrorsDispatch] = useReducer(
    validationErrorsReducer,
    initialValidationErrorsState,
  );
  const validationErrorsHandlers = mapValidationErrorsDispatch(
    validationErrorsDispatch,
  );

  const handleValidate = () => {
    return validateForm({ formValues, additionalValidators, safe: true });
  };

  return (
    <Report
      type='currentTrainingStatus'
      title='Current training status report'
      tooltip='The current training status report only includes the last recorded training for each course and not any historical training'
      setLocalStorageItem={setLocalStorageItem}
      formValues={formValues}
      validationErrors={validationErrors}
      onValidationErrorsReset={
        validationErrorsHandlers.handleValidationErrorsReset
      }
      onValidationErrorsAssignment={
        validationErrorsHandlers.handleValidationErrorsAssignment
      }
      onValidationErrorsUnhighlight={
        validationErrorsHandlers.handleValidationErrorsUnhighlight
      }
      onEstimatesChange={handleEstimatesChange}
      onFilterAddition={handleFilterAddition}
      onFilterRemoval={handleFilterRemoval}
      onFilterSelection={handleFilterSelection}
      onFilterDeselection={handleFilterDeselection}
      onFilterChange={handleFilterChange}
      onValidate={handleValidate}
    >
      <label className='tw-font-medium tw-mb-3'>Training status</label>
      <div className='tw-mb-6'>
        <TrainingStatusFilter
          trainingStatusFilter={formValues.trainingStatusFilter}
          onChange={handleTrainingStatusFilterChange}
        />
      </div>
    </Report>
  );
}
