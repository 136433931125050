import React from 'react';
import PropTypes from 'prop-types';

export default function Display(props) {
  const { recordType, attachedUrl, timestamp } = props;

  return recordType === 'pdf' ?
      <div className='attachments__pdf-wrapper'>
        <iframe
          id='hqPdfViewer'
          src={`/pdfjs/web/viewer.html?file=${encodeURIComponent(attachedUrl)}&_=${timestamp}#zoom=auto`}
          width='100%'
          height='100%'
          frameBorder='0'
          scrolling='no'
        />
      </div>
    : <img
        className='attachment-img'
        src={`${attachedUrl}?timestamp=${timestamp}`}
      />;
}

Display.propTypes = {
  recordType: PropTypes.string.isRequired,
  attachedUrl: PropTypes.string.isRequired,
  timestamp: PropTypes.number,
};
