import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { resourceShape } from 'components/helpers/serialisableResources';
import Select, { components as reactSelectComponents } from 'react-select';
import CheckboxField from 'components/application/CheckboxField';
import {
  MultiValueContainer,
  DropdownIndicator,
} from 'components/application/CollectionSelect.jsx';

const ValueContainer = ({ children, ...props }) => {
  return (
    <reactSelectComponents.ValueContainer {...props}>
      <div className='tw-truncate tw-m-0 tw-h-5 collection-select__input-values'>
        {children}
      </div>
    </reactSelectComponents.ValueContainer>
  );
};

export default function NestedAttributesOptions(props) {
  const {
    personsAtRiskOptions,
    assignedPersonsAtRisk,
    nestedNameAttribute,
    shouldInitiallySelectDefaultRisk,
  } = props;

  const assignedPersonsAtRiskNames = assignedPersonsAtRisk.data.map(
    (personAtRisk) => personAtRisk.attributes.name,
  );
  const [currentPersonsAtRisk, setCurrentPersonsAtRisk] = useState(
    shouldInitiallySelectDefaultRisk ?
      [personsAtRiskOptions[0]]
    : assignedPersonsAtRiskNames,
  );
  const personsAtRiskOptionsAndAssignments = [
    ...new Set([...personsAtRiskOptions, ...assignedPersonsAtRiskNames]),
  ];
  const formattedPersonAtRiskOptions = personsAtRiskOptionsAndAssignments.map(
    (option) => ({ label: option, value: option, name: option }),
  );
  const formattedAssignedPersonAtRiskValues = currentPersonsAtRisk.map(
    (personAtRisk) => ({
      label: personAtRisk,
      value: personAtRisk,
      name: personAtRisk,
    }),
  );
  const assignedPersonsAtRiskToCreate = currentPersonsAtRisk.filter(
    (person_at_risk) => !assignedPersonsAtRiskNames.includes(person_at_risk),
  );
  const assignedPersonsAtRiskToDelete = assignedPersonsAtRiskNames.filter(
    (person_at_risk) => !currentPersonsAtRisk.includes(person_at_risk),
  );
  // Maintaining an index to ensure collections of hidden inputs used to send params to the server have unique index numbers and so do not overwrite each other on the backend
  let nestedAttributeIndex = 0;

  const findAssignedPersonsAtRiskId = (personAtRisk) => {
    return (
      assignedPersonsAtRisk.data &&
      assignedPersonsAtRisk.data.find(
        (assignedPerson) => assignedPerson.attributes.name == personAtRisk,
      ).id
    );
  };

  const handleOptionsChange = (_selected, actionMeta) => {
    const value = actionMeta.option.value;

    if (currentPersonsAtRisk.includes(value)) {
      const currentPersonsAtRiskWithoutValue = currentPersonsAtRisk.filter(
        (person_at_risk) => person_at_risk != value,
      );

      setCurrentPersonsAtRisk(currentPersonsAtRiskWithoutValue);
    } else {
      setCurrentPersonsAtRisk([...currentPersonsAtRisk, value]);
    }
  };

  const Option = (props) => {
    return (
      <reactSelectComponents.Option {...props} key={props.label}>
        <CheckboxField
          label={props.label}
          checked={props.isSelected}
          name={props.data.name}
          value={props.value}
          onChange={props.setValue}
          disabled={false}
          isInputLabelSibling={true}
          labelProps={{ className: 'tw-mb-0' }}
        />
      </reactSelectComponents.Option>
    );
  };

  return (
    <>
      <Select
        options={formattedPersonAtRiskOptions}
        value={formattedAssignedPersonAtRiskValues}
        onChange={handleOptionsChange}
        isMulti={true}
        isSearchable={false}
        isClearable={false}
        components={{
          Option,
          ValueContainer,
          MultiValueContainer,
          DropdownIndicator,
        }}
        className='collection-select__select-container'
        classNamePrefix='collection-select'
        closeMenuOnSelect={false}
        hideSelectedOptions={false}
        backspaceRemovesValue={false}
        placeholder='Select person(s)'
        maxMenuHeight={'unset'}
        customMargin='m-b-32'
        styles={{
          valueContainer: (provided, _state) => ({
            ...provided,
            padding: '9px 12px !important',
          }),
        }}
      />
      {assignedPersonsAtRiskToCreate.map((personAtRisk) => {
        const hiddenInputToCreateAssignedPersonsAtRisk = (
          <div key={nestedAttributeIndex}>
            <input
              type='hidden'
              name={`${nestedNameAttribute}[assigned_persons_at_risks_attributes][${nestedAttributeIndex}][name]`}
              value={personAtRisk}
            />
          </div>
        );

        nestedAttributeIndex++;

        return hiddenInputToCreateAssignedPersonsAtRisk;
      })}
      {assignedPersonsAtRiskToDelete.map((personAtRisk) => {
        const hiddenInputToDeleteAssignedPersonsAtRisk = (
          <div key={nestedAttributeIndex}>
            <input
              type='hidden'
              name={`${nestedNameAttribute}[assigned_persons_at_risks_attributes][${nestedAttributeIndex}][id]`}
              value={findAssignedPersonsAtRiskId(personAtRisk)}
            />
            <input
              type='hidden'
              name={`${nestedNameAttribute}[assigned_persons_at_risks_attributes][${nestedAttributeIndex}][_destroy]`}
              value={1}
            />
          </div>
        );

        nestedAttributeIndex++;

        return hiddenInputToDeleteAssignedPersonsAtRisk;
      })}
    </>
  );
}

NestedAttributesOptions.propTypes = {
  personsAtRiskOptions: PropTypes.array.isRequired,
  assignedPersonsAtRisk: PropTypes.shape({
    data: PropTypes.arrayOf(resourceShape('assignedPersonsAtRisk')),
  }),
  nestedNameAttribute: PropTypes.string.isRequired,
  shouldInitiallySelectDefaultRisk: PropTypes.bool.isRequired,
};
