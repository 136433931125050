import React from 'react';

import Tooltip from 'components/application/Tooltip';
import SmallStubPill from 'components/application/pills/SmallStubPill';

export default function ELearningCourseEnrolledPill() {
  return (
    <Tooltip placement='top' trigger='hover' tooltip='eLearning enrolled'>
      <SmallStubPill className='tw-align-middle tw-bg-green-025 tw-text-green-800'>
        eLearning enrolled
      </SmallStubPill>
    </Tooltip>
  );
}
