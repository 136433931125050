import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ModalBox from 'components/application/ModalBox';
import TextButton from 'components/application/buttons/TextButton';
import FilledButton from 'components/application/buttons/FilledButton';
import ProjectRowArchiveActions from 'components/ProjectRowArchiveActions';
import ProjectRowRegularActions from 'components/ProjectRowRegularActions';
import ProjectDuplicationManager from 'components/projectDuplication/ProjectDuplicationManager';

export default function ProjectRowActions(props) {
  const {
    projectID,
    projectName,
    projectArchived,
    projectTab,
    ramsSingularName,
    projectTableWidth,
    duplicationEnabled,
  } = props;

  const [duplicationBlocked, setDuplicationBlocked] = useState(true);
  const [outOfDateActivities, setOutOfDateActivities] = useState([]);
  const [
    canDuplicateWithOutOfDateActivities,
    setCanDuplicateWithOutOfDateActivities,
  ] = useState(false);
  const [duplicationRequested, setDuplicationRequested] = useState(false);
  const [deleteModalIsOpen, setDeleteModalIsOpen] = useState(false);

  const requestDuplication = () => setDuplicationRequested(true);
  const closeDuplicationProcess = () => setDuplicationRequested(false);
  const allowDuplicationWithOutOfDateActivities = () =>
    setCanDuplicateWithOutOfDateActivities(true);
  const projectPath = (path) => `/projects/${projectID}/${path}`;

  const fetchDuplicationStatus = () => {
    axios.get(projectPath('duplication_status')).then((response) => {
      const blocked = response.data.data.attributes.blocked;

      if (duplicationBlocked !== blocked) {
        setDuplicationBlocked(blocked);
      }
      if (!blocked) {
        fetchOutOfDateActivities();
      }
    });
  };

  const fetchOutOfDateActivities = () => {
    axios
      .get(projectPath('out_of_date_activities'))
      .then((response) => setOutOfDateActivities(response.data.data));
  };

  const handleRegularActionsClick = () => fetchDuplicationStatus();

  const renderArchiveActions = () => {
    let archiveActions;
    if (projectArchived) {
      archiveActions = <ProjectRowArchiveActions projectID={projectID} />;
    }
    return archiveActions;
  };

  const renderRegularActions = () => {
    return (
      <ProjectRowRegularActions
        projectID={projectID}
        projectArchived={projectArchived}
        duplicationBlocked={duplicationBlocked}
        projectTab={projectTab}
        ramsSingularName={ramsSingularName}
        duplicationEnabled={duplicationEnabled}
        onRegularActionsClick={handleRegularActionsClick}
        onDuplicateClick={requestDuplication}
        onDeleteClick={() => setDeleteModalIsOpen(true)}
      />
    );
  };

  const renderDeleteModal = () => {
    return (
      <ModalBox
        mode='flexible'
        isOpen={deleteModalIsOpen}
        onClose={() => setDeleteModalIsOpen(false)}
        customFooter={
          <div className='modalbox__footer-actions modalbox__footer-actions--right'>
            <TextButton
              size='md'
              color='blue'
              className='m-r-12'
              onClick={() => setDeleteModalIsOpen(false)}
            >
              Cancel
            </TextButton>
            <FilledButton
              color='red'
              className='confirm_project_delete'
              data-id={projectID}
              href='#0'
            >
              {`Yes, delete ${ramsSingularName}`}
            </FilledButton>
          </div>
        }
      >
        <div className='p-32'>
          <h2 className='tw-text-grey-900 tw-text-l tw-font-semibold m-0 p-0'>
            Delete this {ramsSingularName}?
          </h2>
          <p className='m-0'>
            Are you sure you want to delete this {ramsSingularName}?
          </p>
        </div>
      </ModalBox>
    );
  };

  const renderProjectDuplicationManager = () => {
    if (!duplicationBlocked && duplicationRequested) {
      return (
        <ProjectDuplicationManager
          originalProject={{ id: projectID, name: projectName }}
          ramsSingularName={ramsSingularName}
          projectTableWidth={projectTableWidth}
          closeDuplicationProcess={closeDuplicationProcess}
          outOfDateActivities={outOfDateActivities}
          canDuplicateWithOutOfDateActivities={
            canDuplicateWithOutOfDateActivities
          }
          allowDuplicationWithOutOfDateActivities={
            allowDuplicationWithOutOfDateActivities
          }
        />
      );
    }
  };

  return (
    <React.Fragment>
      {renderArchiveActions()}
      {renderRegularActions()}
      {renderDeleteModal()}
      {renderProjectDuplicationManager()}
    </React.Fragment>
  );
}

ProjectRowActions.defaultProps = {
  ramsSingularName: 'project',
};

ProjectRowActions.propTypes = {
  projectID: PropTypes.number.isRequired,
  projectName: PropTypes.string.isRequired,
  projectArchived: PropTypes.bool.isRequired,
  projectTab: PropTypes.string,
  ramsSingularName: PropTypes.string,
  projectTableWidth: PropTypes.number.isRequired,
  duplicationEnabled: PropTypes.bool.isRequired,
};
