import React from 'react';
import PropTypes from 'prop-types';

import { submitSAMLRequest } from 'components/saml/helpers';

export default function SamlInviteLandingPage(props) {
  const { logoSrc, accountName, identityProviderUuid, createPasswordLink } =
    props;

  return (
    <div className='form-container tw-bg-white tw-border-white'>
      {logoSrc && (
        <div className='invite-logo-container m-b-20'>
          <img src={logoSrc} alt='Logo' />
        </div>
      )}
      <p className='tw-text-l tw-font-normal tw-tracking-auto text-center text-center m-0'>
        <span>To get started, log in to your</span>
        <span className='tw-font-semibold'> {accountName} </span>
        <span>account.</span>
      </p>
      {identityProviderUuid && (
        <a
          className='tw-apply-filled-button tw-apply-filled-button--lg tw-apply-filled-button--h-auto tw-apply-filled-button--mint tw-apply-filled-button--on-light tw-justify-center tw-w-full m-t-20'
          onClick={() => submitSAMLRequest({ identityProviderUuid })}
        >
          <span className='tw-text-center tw-mt-[13px] tw-mb-[13px]'>
            Log in with single{' '}
            <span className='tw-whitespace-nowrap'>sign-on</span> (SSO)
          </span>
        </a>
      )}
      {createPasswordLink && (
        <div className='secondary-links secondary-links--centered tw-m-0 tw-mt-5 tw-text-grey-900'>
          <span className='m-r-8'>Or</span>
          <a
            className="tw-text-grey-900 tw-font-semibold before:tw-content-[''] before:tw-bg-mint-200 hover:tw-text-mint-200"
            href={createPasswordLink}
          >
            create password and log in
          </a>
        </div>
      )}
    </div>
  );
}

SamlInviteLandingPage.propTypes = {
  logoSrc: PropTypes.string,
  accountName: PropTypes.string.isRequired,
  identityProviderUuid: PropTypes.string,
  createPasswordLink: PropTypes.string,
};
