import classNames from 'classnames';
import React, { useState, useMemo } from 'react';
import ReactDOM from 'react-dom';
import { useSpring, animated } from 'react-spring';
import { v4 as uuidv4 } from 'uuid';

import { genericErrorMessage } from 'components/helpers/errors';

import useMeasure from 'components/hooks/useMeasure';
import usePrevious from 'components/hooks/usePrevious';

import AvailableStepList from 'containers/AvailableStepList';
import BranchingSubNode from '-!svg-react-loader?name=BranchingSubNode!icons/branching-sub-node.svg';
import CheckboxField from 'components/application/CheckboxField';
import FilledButton from 'components/application/buttons/FilledButton';
import ModalBox from 'components/application/ModalBox';
import OutlinedButton from 'components/application/buttons/OutlinedButton';
import RowBar from 'components/application/RowBar';
import SubmittableStepField from './SubmittableStepField';
import TextButton from 'components/application/buttons/TextButton';
import Toggleable from 'components/application/Toggleable';
import ToggleableField from 'components/application/ToggleableField';
import ToggleableRow from 'components/application/ToggleableRow';

// todo add testing for container and see if props can be reduced
export default function SequenceRow(props) {
  const {
    addError,
    containerRef,
    dispatch,
    existingSequence,
    isAddingStepName,
    isEditingSequenceToggle,
    isFocused,
    masterSequence,
    newStepNameKey,
    newStepText,
    projectId,
    projectTradeId,
    sequenceFieldText,
    sequenceNameKey,
    sequenceOrderingInProgress,
    stepIds,
    stepsOrderingInProgress,
    tradeOrderingInProgress,
  } = props;

  // derived
  const isSelected =
    existingSequence ? existingSequence.attributes.selected : false;
  const sequenceTemplate = existingSequence || masterSequence;
  const [visualOptions, setVisualOptions] = useState({
    confirmationModalOpen: false,
    checkboxDisabled: false,
  });
  const previousTradeOrdering = usePrevious(tradeOrderingInProgress);
  const previousSequenceOrdering = usePrevious(sequenceOrderingInProgress);
  const previousStepsOrdering = usePrevious(stepsOrderingInProgress);
  const [bind, { height: viewHeight }] = useMeasure();
  const memoisedPreviousTradeOrdering = useMemo(() => {
    return previousTradeOrdering;
  }, [tradeOrderingInProgress]);
  const memoisedPreviousSequenceOrdering = useMemo(() => {
    return previousSequenceOrdering;
  }, [sequenceOrderingInProgress]);
  const memoisedPreviousStepsOrderingSorting = useMemo(() => {
    return previousStepsOrdering;
  }, [stepsOrderingInProgress]);

  const hasSteps = stepIds?.length > 0;
  const shouldNotTransition =
    (!memoisedPreviousTradeOrdering && tradeOrderingInProgress) ||
    (!memoisedPreviousSequenceOrdering && sequenceOrderingInProgress) ||
    (!memoisedPreviousStepsOrderingSorting && stepsOrderingInProgress);

  const springProps = useSpring({
    transform:
      sequenceOrderingInProgress || tradeOrderingInProgress ?
        `translateY(-150px)`
      : 'none',
    height: isSelected ? viewHeight : 0,
    immediate: shouldNotTransition,
  });

  const requestSequenceCreation = (toggleOpen) => {
    axios
      .post(`/master_method_sequences/${masterSequence.id}/method_sequences`, {
        method_sequence: {
          project_id: projectId,
          master_method_sequence_id: masterSequence.id,
        },
      })
      .then((response) => {
        if (toggleOpen)
          dispatch({
            type: 'UI_TOGGLE',
            value: true,
            key: `editingSequenceName--${response.data.data.id}`,
          });
        dispatch({
          type: 'ADD_METHOD_SEQUENCE',
          payload: response.data,
          projectTradeId: projectTradeId,
        });
        setVisualOptions({ ...visualOptions, checkboxDisabled: false });
      })
      .catch(handleError);
  };

  const requestSequenceUpdate = (_event, fieldValue) => {
    axios
      .patch(`/method_sequences/${existingSequence.id}`, {
        method_sequence: { name: fieldValue },
      })
      .then((response) => {
        dispatch({ type: 'UPDATE_RESOURCE', payload: response.data });
        dispatch({ type: 'CLEAR_TOGGLE_CONTENT', key: sequenceNameKey });
      })
      .catch(handleError);
  };

  const requestCustomSequenceToggleUpdate = (event) => {
    setVisualOptions({ ...visualOptions, checkboxDisabled: true });

    axios
      .patch(`/method_sequences/${existingSequence.id}`, {
        method_sequence: { selected: event.target.checked },
      })
      .then((response) => {
        dispatch({
          type: 'UPDATE_RESOURCE',
          payload: response.data,
        });
        setVisualOptions({ ...visualOptions, checkboxDisabled: false });
      })
      .catch(handleError);
  };

  const requestSequenceDeletion = ({ withConfirmation = false } = {}) => {
    axios
      .delete(`/method_sequences/${existingSequence.id}`, {
        params: { confirmation: withConfirmation },
      })
      .then(
        (_resolved) => {
          dispatch({
            type: 'REMOVE_METHOD_SEQUENCE',
            payload: { id: existingSequence.id, type: 'methodSequence' },
            projectTradeId: projectTradeId,
          });
          setVisualOptions({
            ...visualOptions,
            confirmationModalOpen: false,
            checkboxDisabled: false,
          });
        },
        (rejection) => {
          if (rejection.response.status === 412) {
            setVisualOptions({ ...visualOptions, confirmationModalOpen: true });
          } else {
            return Promise.reject(rejection);
          }
        },
      )
      .catch((_error) => {
        setVisualOptions({
          ...visualOptions,
          checkboxDisabled: false,
          confirmationModalOpen: false,
        });
        handleError();
      });
  };

  const handleError = () =>
    addError(uuidv4(), { fullMessage: genericErrorMessage });

  const handleToggleClick = () => {
    if (!existingSequence) requestSequenceCreation(true);
  };

  const handleConfirmationClose = () => {
    setVisualOptions({
      ...visualOptions,
      confirmationModalOpen: false,
      checkboxDisabled: false,
    });
  };

  const handleMasterSequenceToggle = (event) => {
    setVisualOptions({ ...visualOptions, checkboxDisabled: true });
    event.target.checked ?
      requestSequenceCreation(false)
    : requestSequenceDeletion();
  };

  const handleNewStepSubmit = () => {
    axios
      .post(`/method_sequences/${existingSequence.id}/method_steps`, {
        method_step: { text: newStepText },
      })
      .then((response) => {
        dispatch({
          type: 'ADD_METHOD_STEP',
          payload: response.data,
          methodSequenceId: existingSequence.id,
        });
        dispatch({ type: 'CLEAR_TOGGLE_CONTENT', key: newStepNameKey });
      })
      .catch(handleError);
  };

  const handleSequenceNameChange = (e) => {
    dispatch({
      type: 'EDITING_CONTENT_CHANGE',
      key: sequenceNameKey,
      value: e.target.value,
    });
  };

  const handleNewStepTextChange = ({ content }) => {
    dispatch({
      key: newStepNameKey,
      type: 'EDITING_CONTENT_CHANGE',
      value: content,
    });
  };

  return (
    <div
      className={classNames(
        'draggable-row-wrapper',
        isFocused && !sequenceOrderingInProgress && 'tw-relative tw-z-[1]',
      )}
      onClick={() => {
        dispatch({
          key: 'projectTrade',
          type: 'SET_UI_FOCUSED',
          value: projectTradeId,
        });
        dispatch({
          key: 'sequenceRow',
          type: 'SET_UI_FOCUSED',
          value: sequenceNameKey,
        });
      }}
    >
      <ToggleableRow
        additionalClasses='tw-static'
        modifiers={[
          'border-top-none',
          `${isSelected && !sequenceOrderingInProgress ? 'open' : 'closed'}`,
          `${sequenceOrderingInProgress ? 'collapsed' : ''}`,
        ]}
        isOrdering={sequenceOrderingInProgress}
        checkboxField={
          <CheckboxField
            disabled={visualOptions.checkboxDisabled}
            height={'short'}
            value={sequenceTemplate.id}
            name='sequenceSelected'
            checked={Boolean(isSelected)}
            onChange={
              masterSequence ?
                handleMasterSequenceToggle
              : requestCustomSequenceToggleUpdate
            }
            labelTextProps={{ className: 'tw-font-medium' }}
          />
        }
        toggleableField={
          <ToggleableField
            autoFocus
            isSubmitDisabled={existingSequence ? false : true}
            isToggled={isEditingSequenceToggle}
            onCancel={() =>
              dispatch({ type: 'CLEAR_TOGGLE_CONTENT', key: sequenceNameKey })
            }
            onChange={handleSequenceNameChange}
            onSubmit={requestSequenceUpdate}
            onToggle={
              existingSequence ?
                (toggleValue) =>
                  dispatch({
                    type: 'UI_TOGGLE',
                    value: toggleValue,
                    key: sequenceNameKey,
                  })
              : () => {}
            }
            submitButtonText='Update'
            toggleableItem={
              <div className='toggleable-field__item-wrapper'>
                <span
                  className={classNames(
                    'toggleable-row__text tw-border-grey-500 tw-font-medium',
                    sequenceOrderingInProgress && 'truncated-text-container',
                  )}
                  onClick={handleToggleClick}
                >
                  {existingSequence ?
                    existingSequence.attributes.name
                  : sequenceTemplate.attributes.name}
                </span>
              </div>
            }
            value={sequenceFieldText}
          />
        }
      />
      {isSelected && !sequenceOrderingInProgress && hasSteps && (
        <div className='header-bar header-bar--border tw-text-s tw-text-grey-900 tw-font-medium tw-tracking-wide tw-border-grey-100 tw-bg-grey-050'>
          Step
        </div>
      )}
      <div
        className={classNames(
          'collapsable-list collapsable-list--nested',
          isFocused && 'tw-relative tw-z-[1]',
          sequenceOrderingInProgress ?
            'tw-h-0 tw-overflow-hidden'
          : 'tw-h-auto',
        )}
      >
        <animated.div
          style={{
            height: springProps.height,
            transform: springProps.transform,
          }}
        >
          <div className='tw-bg-white tw-border-grey-100'>
            <div {...bind}>
              {isSelected && (
                <React.Fragment>
                  <AvailableStepList
                    containerRef={containerRef}
                    methodSequenceId={existingSequence.id}
                    projectTradeId={projectTradeId}
                  />
                </React.Fragment>
              )}
            </div>
          </div>
        </animated.div>
      </div>

      {isSelected && !sequenceOrderingInProgress && (
        <RowBar
          additionalClasses='tw-static'
          modifiers={['secondary', 'border-top-none', 'enlarged']}
          content={
            <div className='toggleable-row__text-wrapper'>
              <Toggleable
                isToggled={isAddingStepName}
                primary={
                  <OutlinedButton color='grey' onClick={() => {}} size='sm'>
                    <BranchingSubNode
                      className='tw-mx-1'
                      width={24}
                      height={24}
                    />
                    <span>Add step</span>
                  </OutlinedButton>
                }
                secondary={
                  <SubmittableStepField
                    onCancel={() =>
                      dispatch({
                        type: 'CLEAR_TOGGLE_CONTENT',
                        key: newStepNameKey,
                      })
                    }
                    onChange={handleNewStepTextChange}
                    onSubmit={handleNewStepSubmit}
                    placeholder='Describe step'
                    submitButtonText='Add this step'
                    value={newStepText || ''}
                  />
                }
                setIsToggled={() =>
                  dispatch({
                    type: 'UI_TOGGLE',
                    value: true,
                    key: newStepNameKey,
                  })
                }
              />
            </div>
          }
        />
      )}

      {visualOptions.confirmationModalOpen && (
        <PortaledModal>
          <ModalBox
            mode='letterbox'
            isOpen={visualOptions.confirmationModalOpen}
            usingStandardDimensions={true}
            onClose={handleConfirmationClose}
            customFooter={
              <div className='modalbox__footer-actions modalbox__footer-actions--right'>
                <TextButton
                  size='md'
                  color='blue'
                  className='tw-mr-3'
                  onClick={handleConfirmationClose}
                >
                  Cancel
                </TextButton>
                <FilledButton
                  color='red'
                  onClick={() => {
                    requestSequenceDeletion({ withConfirmation: true });
                  }}
                >
                  Yes, remove task
                </FilledButton>
              </div>
            }
          >
            <React.Fragment>
              <div className='modalbox-header tw-border-grey-100'>
                <h2 className='modalbox-header__title truncated-text-container tw-text-l tw-text-grey-900 tw-font-semibold'>
                  Are you sure you want to remove this task?
                </h2>
              </div>
              <div className='modalbox-body'>
                Removing the task will update the content to the latest version.
                The current version will be deleted and this action cannot be
                undone.
              </div>
            </React.Fragment>
          </ModalBox>
        </PortaledModal>
      )}
    </div>
  );
}

const PortaledModal = (props) => {
  const modalLocation = document.getElementById('modalRoot');
  return ReactDOM.createPortal(props.children, modalLocation);
};
