import React from 'react';
import PropTypes from 'prop-types';
import CheckboxCollection from 'components/application/CheckboxCollection';
import { useCurrentActor } from 'components/contexts/CurrentActor';

export default function PersonnelTypeFilters(props) {
  const currentActor = useCurrentActor();

  const checkboxes =
    currentActor.isAllowedFeature('training_register_only') ?
      [
        {
          label: 'Employees',
          name: 'employeeFilter',
          checked: props.filters.employeeFilter,
        },
        {
          label: 'Sub-contractors',
          name: 'subFilter',
          checked: props.filters.subFilter,
        },
      ]
    : [
        {
          label: 'Employees',
          name: 'employeeFilter',
          checked: props.filters.employeeFilter,
        },
        {
          label: 'Active sub-contractors',
          name: 'activeSubFilter',
          checked: props.filters.activeSubFilter,
          tooltip: 'Sub-contractors on a live or future RAMS',
          tooltipAlignment: 'right',
        },
        {
          label: 'Inactive sub-contractors',
          name: 'inActiveSubFilter',
          checked: props.filters.inActiveSubFilter,
          tooltip: 'Sub-contractors not on a live or future RAMS',
          tooltipAlignment: 'right',
        },
      ];

  return (
    <CheckboxCollection
      checkBoxes={checkboxes}
      labelTextProps={{ className: 'tw-font-medium' }}
      onChange={props.onChange}
      mustSelect={true}
      isDisabled={props.isDisabled}
    />
  );
}

PersonnelTypeFilters.propTypes = {
  filters: PropTypes.object,
  onChange: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool,
};

PersonnelTypeFilters.defaultProps = {
  isDisabled: false,
};
