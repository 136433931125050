import React, { useState, useEffect } from 'react';
// components
import RolesBar from 'components/roles/RolesBar';
import RolesMain from 'components/roles/RolesMain';
import RolesSidePanel from 'components/roles/RolesSidePanel';
import ResourceChangedToast from 'components/application/ResourceChangedToast';
import DestroyModal from 'components/application/DestroyModal';
// hooks
import useForm from 'components/hooks/useForm';
import useDebounce from 'components/hooks/useDebounce';
import useRequestError from 'components/hooks/useRequestError';
import useValidatedStore from 'components/hooks/useValidatedStore';
import useWindowStorage from 'components/hooks/useWindowStorage';
// icons
import ResourceBlankNotice from 'components/application/ResourceBlankNotice';
//contexts
import { useBreadBoard } from 'components/contexts/Toaster';
import { useTrainingRegisterResources } from 'components/contexts/TrainingRegisterResourceManagementContext';
import { useCurrentActor } from 'components/contexts/CurrentActor';
// resources
import {
  paramsForCreate,
  paramsForUpdate,
} from 'components/helpers/resources/courseCompanyRoles';
import { z } from 'zod';

const initialTabStore = {
  currentSearch: '',
  page: 1,
  currentResourceId: '',
  currentResourceName: '',
  sidePanelIsOpen: false,
};

const tabSchema = z.object({
  currentResourceId: z.string().nullable(),
  currentResourceName: z.string(),
  sidePanelIsOpen: z.boolean(),
  page: z.number().or(z.null()),
  currentSearch: z.string(),
});

export default function RolesTab({ label }) {
  const defaultRole = {
    id: '',
    position: '',
    personnelCount: 0,
    isDeleteProhibited: false,
    courseCompanyRoles: [],
  };

  const currentActor = useCurrentActor();
  const breadBoard = useBreadBoard();
  const trainingRegisterResourceManagementContext =
    useTrainingRegisterResources();

  const [getStore, setStore] = useWindowStorage(`trainingRegister|${label}`, {
    store: window.sessionStorage,
  });
  const tabStore = useValidatedStore({
    getStore,
    initialStore: initialTabStore,
    schema: tabSchema,
  });
  const initialSidePanelIsOpen = tabStore.sidePanelIsOpen;
  const initialCurrentSearch = { rolesSearch: tabStore.currentSearch };

  const initialDefaultRole = {
    ...defaultRole,
    id: tabStore.currentResourceId,
    position: tabStore.currentResourceName,
  };

  const [roles, setRoles] = useState({ loaded: false, collection: [] });
  const [sidePanelContext, setSidePanelContext] = useState('show');
  const [sidePanelIsOpen, setSidePanelIsOpen] = useState(
    initialSidePanelIsOpen,
  );
  const [currentRole, setCurrentRole, handleRoleInputChange] =
    useForm(initialDefaultRole);
  const [courseCompanyRoles, setCourseCompanyRoles] = useState({
    loaded: false,
    collection: [],
  });
  const [assignableCourses, setAssignableCourses] = useState({
    loaded: false,
    collection: [],
  });
  const [
    requestError,
    submitDisabled,
    removeErrorStyling,
    resetRequestError,
    handleRequestError,
  ] = useRequestError();
  const [metaData, setMetaData] = useState({
    totalCount: 0,
    scopedCount: 0,
    currentPage: null,
    totalPages: null,
  });
  const [currentSearch, setCurrentSearch, handleSearchInputChange] =
    useForm(initialCurrentSearch);
  const [debouncedCurrentSearch, _resetDebouncedCurrentSearch] = useDebounce(
    currentSearch,
    250,
  );
  const [destroyModalIsOpen, setDestroyModalIsOpen] = useState(false);

  const openSidePanel = () => {
    setSidePanelIsOpen(true);
  };
  const closeSidePanel = () => {
    setSidePanelIsOpen(false);
  };

  const currentRoleParamsCreate = function () {
    return {
      company_role: {
        position: currentRole.position,
        course_company_roles_attributes: paramsForCreate({
          currentId: currentRole.id,
          frontendCourseCompanyRoles: currentRole.courseCompanyRoles,
          currentType: 'company_role',
        }),
      },
    };
  };

  const currentRoleParamsUpdate = function () {
    return {
      company_role: {
        position: currentRole.position,
        course_company_roles_attributes: paramsForUpdate({
          currentId: currentRole.id,
          frontendCourseCompanyRoles: currentRole.courseCompanyRoles,
          serverCourseCompanyRoles: courseCompanyRoles.collection,
          currentType: 'company_role',
        }),
      },
    };
  };

  const refreshRoles = (page = 1) => {
    axios
      .get(
        '/company_roles?with_personnel_count=true&with_course_connections=true',
        { params: { search: currentSearch.rolesSearch, page } },
      )
      .then((response) => {
        setRoles({ loaded: true, collection: response.data.data });
        setMetaData(response.data.meta);
      })
      .catch((_error) => breadBoard.addInedibleToast());
  };

  const updateRole = function () {
    axios
      .patch(`/company_roles/${currentRole.id}`, currentRoleParamsUpdate())
      .then((response) => {
        resetRoles();
        resetRequestError();
        breadBoard.addToast(
          <ResourceChangedToast
            resource={response.data.data.attributes.position}
            onBurnToast={breadBoard.handleBurnToast}
            status={'edited'}
          />,
        );
      })
      .catch(handleRequestError);
  };

  const createRole = function () {
    axios
      .post('/company_roles', currentRoleParamsCreate())
      .then((response) => {
        resetRoles();
        breadBoard.addToast(
          <ResourceChangedToast
            resource={response.data.data.attributes.position}
            onBurnToast={breadBoard.handleBurnToast}
            status={'added'}
          />,
        );
        resetRoles();
      })
      .catch(handleRequestError);
  };

  const destroyRole = function () {
    axios
      .delete(`/company_roles/${currentRole.id}`)
      .then((_response) => {
        resetRoles();
        breadBoard.addToast(
          <ResourceChangedToast
            resource={currentRole.position}
            onBurnToast={breadBoard.handleBurnToast}
            status={'deleted'}
          />,
        );
        resetRoles();
      })
      .catch((_error) => {
        closeSidePanel();
        setCurrentRole(defaultRole);
      });
  };

  const resetRoles = () => {
    closeSidePanel();
    refreshRoles();
    setCurrentRole(defaultRole);
  };

  const handleNewRole = function (event) {
    event.currentTarget.blur();
    setCurrentRole(defaultRole);
    resetRequestError();
    setSidePanelContext('new');
    openSidePanel();
  };

  const handlePageChange = (event) => {
    const page = event.target.getAttribute('data-page');
    refreshRoles(page);
  };

  const handleSearchReset = (_event) => {
    setCurrentSearch({ ...currentSearch, rolesSearch: '' });
  };

  const blankMessage =
    trainingRegisterResourceManagementContext.hasRoleEditableAccess ?
      'Create a list of the roles used in your company.'
    : 'Any roles that have been created will be listed here';

  const formatCourseCompanyRoles = (serverData) => {
    return serverData.map((ccr) => ({
      courseId: ccr.relationships.course.data.id,
      required: ccr.attributes.required,
    }));
  };

  // assignableCourses will now be the source of truth for course data, aka getting the name to display in the FE
  const fetchAssignableCourses = () => {
    axios
      .get('/assignable_courses')
      .then((response) => {
        setAssignableCourses({ loaded: true, collection: response.data.data });
      })
      .catch(breadBoard.addInedibleToast);
  };

  const fetchCourseCompanyRoles = function () {
    axios
      .get(`/company_roles/${currentRole.id}/course_company_roles`)
      .then((response) => {
        setCourseCompanyRoles({ loaded: true, collection: response.data.data });
        setCurrentRole({
          ...currentRole,
          courseCompanyRoles: formatCourseCompanyRoles(response.data.data),
        });
      })
      .catch(breadBoard.addInedibleToast);
  };

  const handleRoleOptionChange = (courseId) => {
    const newCourseCompanyRoles = [...currentRole.courseCompanyRoles];
    newCourseCompanyRoles.push({ courseId: courseId, required: true });
    setCurrentRole({
      ...currentRole,
      courseCompanyRoles: newCourseCompanyRoles,
    });
  };

  const handleCourseCompanyRoleRequiredChange = (courseId, value) => {
    const newCourseCompanyRoles = [...currentRole.courseCompanyRoles];
    const courseCompanyRoleIndex = newCourseCompanyRoles.findIndex(
      (ccr) => ccr.courseId == courseId,
    );
    newCourseCompanyRoles[courseCompanyRoleIndex] = {
      ...newCourseCompanyRoles[courseCompanyRoleIndex],
      required: value,
    };
    setCurrentRole({
      ...currentRole,
      courseCompanyRoles: newCourseCompanyRoles,
    });
  };

  const handleCourseCompanyRoleDelete = (courseId) => {
    const newCourseCompanyRoles = currentRole.courseCompanyRoles.filter(
      (ccr) => ccr.courseId != courseId,
    );
    setCurrentRole({
      ...currentRole,
      courseCompanyRoles: newCourseCompanyRoles,
    });
  };

  useEffect(() => {
    refreshRoles(tabStore.page);
  }, []);

  useEffect(() => {
    if (roles.loaded && debouncedCurrentSearch) {
      refreshRoles();
    }
  }, [debouncedCurrentSearch]);

  useEffect(() => {
    if (!sidePanelIsOpen) {
      return;
    }

    if (!assignableCourses.loaded) {
      fetchAssignableCourses();
    }

    if (sidePanelContext == 'new') {
      setCourseCompanyRoles({ loaded: false, collection: [] });
    } else if (currentActor.isAllowedFeature('training_register')) {
      fetchCourseCompanyRoles();
    }
  }, [sidePanelContext, sidePanelIsOpen]);

  useEffect(() => {
    setStore({
      page: metaData.currentPage,
      currentSearch: currentSearch.rolesSearch,
      currentResourceId: currentRole.id,
      currentResourceName: currentRole.position,
      sidePanelIsOpen: sidePanelIsOpen,
    });
  }, [
    metaData.currentPage,
    currentRole.id,
    currentRole.position,
    currentSearch.rolesSearch,
    sidePanelIsOpen,
  ]);

  return (
    <>
      {roles.loaded && (
        <>
          <div className={'col-sm-12 p-l-0 p-r-0'}>
            {metaData.totalCount > 0 && (
              <RolesBar
                actionButtonsVisible={
                  trainingRegisterResourceManagementContext.hasRoleEditableAccess
                }
                onNewRole={handleNewRole}
                rolesSearch={currentSearch.rolesSearch}
                onSearchInputChange={handleSearchInputChange}
                onSearchReset={handleSearchReset}
              />
            )}
            {roles.collection.length > 0 ?
              <RolesMain
                currentRole={currentRole}
                setCurrentRole={setCurrentRole}
                roles={roles.collection}
                metaData={metaData}
                sidePanelContext={sidePanelContext}
                setSidePanelContext={setSidePanelContext}
                openSidePanel={openSidePanel}
                onNewRole={handleNewRole}
                onPageChange={handlePageChange}
                resetRequestError={resetRequestError}
                destroyRole={destroyRole}
              />
            : <ResourceBlankNotice
                displayReadOnlyContents={
                  !trainingRegisterResourceManagementContext.hasRoleEditableAccess
                }
                totalCount={metaData.totalCount}
                onAdd={handleNewRole}
                resource={'role'}
                addMessage={blankMessage}
              />
            }
          </div>
          <RolesSidePanel
            allowShowFooterContents={
              trainingRegisterResourceManagementContext.hasRoleEditableAccess
            }
            currentRole={currentRole}
            assignableCourses={assignableCourses.collection}
            requestError={requestError}
            sidePanelIsOpen={sidePanelIsOpen}
            setSidePanelContext={setSidePanelContext}
            sidePanelContext={sidePanelContext}
            closeSidePanel={closeSidePanel}
            onRoleInputChange={handleRoleInputChange}
            onRoleOptionChange={handleRoleOptionChange}
            onDeleteClick={() => setDestroyModalIsOpen(true)}
            onCourseCompanyRoleRequiredChange={
              handleCourseCompanyRoleRequiredChange
            }
            onCourseCompanyRoleDelete={handleCourseCompanyRoleDelete}
            updateRole={updateRole}
            createRole={createRole}
            removeErrorStyling={removeErrorStyling}
            submitDisabled={submitDisabled}
          />
          {sidePanelContext === 'show' && (
            <DestroyModal
              isOpen={destroyModalIsOpen}
              displayText={`Are you sure you want to delete ${currentRole.position}?`}
              confirmationText={`Delete ${currentRole.position}`}
              additionalContent={
                trainingRegisterResourceManagementContext.isMultiDivisionAccount && (
                  <p className='tw-border-0 tw-rounded-lg tw-text-red-800 tw-bg-red-025 tw-p-3 m-0 m-t-20'>
                    You're about to make a change that could affect divisions
                    and personnel to which you may not have direct access.
                  </p>
                )
              }
              onClose={() => setDestroyModalIsOpen(false)}
              onDestroy={destroyRole}
            />
          )}
        </>
      )}
    </>
  );
}
