import React from 'react';
import PropTypes from 'prop-types';
import { PieChart, Pie, Cell } from 'recharts';

export default function StatusChart({
  totalCount,
  chartData,
  highlightedKey,
  onKeyHighlight,
  onKeyUnhighlight,
  onSegmentClick,
}) {
  const cellClassName = ({ entry }) => {
    return highlightedKey && entry.countKey !== highlightedKey ?
        'tw-fill-grey-100'
      : entry.cellClassName;
  };

  return (
    <div className='tw-relative'>
      <div className='tw-flex tw-justify-center tw-items-center tw-absolute tw-w-full tw-h-full'>
        <h4 className='tw-text-3xl tw-font-semibold tw-tracking-tight tw-text-grey-700 tw-m-0'>
          {totalCount}
        </h4>
      </div>
      <PieChart width={180} height={180}>
        <Pie
          nameKey='name'
          dataKey='count'
          margin={{ top: 0, left: 0, right: 0, bottom: 0 }}
          startAngle={90}
          endAngle={-270}
          animationBegin={150}
          animationDuration={600}
          animationEasing='ease-in-out'
          data={chartData}
          isAnimationActive={true}
          innerRadius='58%'
          outerRadius='104%'
          onMouseLeave={onKeyUnhighlight}
        >
          {chartData.map((entry) => (
            <Cell
              key={entry.countKey}
              cursor='pointer'
              strokeWidth={2}
              className={cellClassName({ entry })}
              onMouseEnter={() => onKeyHighlight(entry.countKey)}
              onClick={() => onSegmentClick({ entry })}
            />
          ))}
        </Pie>
      </PieChart>
    </div>
  );
}

StatusChart.propTypes = {
  totalCount: PropTypes.number.isRequired,
  chartData: PropTypes.array.isRequired,
  highlightedKey: PropTypes.string,
  onKeyHighlight: PropTypes.func.isRequired,
  onKeyUnhighlight: PropTypes.func.isRequired,
  onSegmentClick: PropTypes.func.isRequired,
};
