import React from 'react';
import PropTypes from 'prop-types';
import { resourceShape } from 'components/helpers/serialisableResources';

import Tooltip from 'components/application/Tooltip';
import TableCell from 'components/application/TableCell';

export default function TeamsRow({ team, company, managers, onTeamRowClick }) {
  const managerNamesList = managers
    .map(
      (manager) =>
        `${manager.attributes.firstName} ${manager.attributes.lastName}`,
    )
    .join(', ');
  return (
    <tr
      className='handshq__tr--clickable fh-49 hover:tw-bg-grey-025'
      onClick={() => onTeamRowClick(team.id)}
    >
      <TableCell>
        <span className='truncated-text-container tw-text-grey-900 tw-text-m tw-font-medium'>
          {team.attributes.name}
        </span>
      </TableCell>
      <TableCell>
        <div className='tooltip-parent truncated-text-container tw-text-m'>
          <span className='tw-text-grey-900 tw-text-m tw-font-medium'>
            {managerNamesList}
          </span>
          {managerNamesList.length > 42 && (
            <Tooltip
              placement='top'
              trigger='hover'
              tooltip={managerNamesList}
            />
          )}
        </div>
      </TableCell>
      <TableCell>
        <div className='tw-text-m'>{company.attributes.name}</div>
      </TableCell>
      <TableCell justifyContent='center'>
        <div className='tw-text-m'>{team.attributes.teamMembersCount}</div>
      </TableCell>
    </tr>
  );
}

TeamsRow.propTypes = {
  team: resourceShape('team').isRequired,
  company: resourceShape('company').isRequired,
  managers: PropTypes.arrayOf(resourceShape('simpleUser')),
  onTeamRowClick: PropTypes.func.isRequired,
};
