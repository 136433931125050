import React, { useState } from 'react';
import PropTypes from 'prop-types';
import VisibleIcon from '-!svg-react-loader?name=VisibleIcon!icons/ic-visible-16.svg';
import HiddenIcon from '-!svg-react-loader?name=HiddenIcon!icons/ic-restricted-16.svg';
import DefaultIcon from '-!svg-react-loader?name=DefaultIcon!icons/ic-check.svg';
import Select, { components } from 'react-select';
import { DropdownIndicator } from 'components/application/CollectionSelect';

const OptionValue = (props) => {
  const icon = {
    selected: <DefaultIcon width={16} height={16} />,
    visible: <VisibleIcon width={16} height={16} />,
    hidden: <HiddenIcon width={16} height={16} />,
  }[props.data.value];

  return (
    <div className='tw-flex tw-items-center'>
      {icon}
      <span className='tw-ml-2'>{props.children}</span>
    </div>
  );
};

const SingleValue = (props) => {
  return (
    <components.SingleValue {...props}>
      <OptionValue {...props} />
    </components.SingleValue>
  );
};

const Option = (props) => {
  return (
    <components.Option {...props}>
      <div className='collection-select__option_container'>
        <OptionValue {...props} />
      </div>
    </components.Option>
  );
};

const IconSelection = ({ icon, iconAllocation, index }) => {
  const initialState =
    iconAllocation ? iconAllocation.attributes.selectionState : 'visible';
  const [selectedState, setSelectedState] = useState(initialState);

  const options = [
    { label: 'Visible', value: 'visible' },
    { label: 'Selected', value: 'selected' },
    { label: 'Hidden', value: 'hidden' },
  ];

  const selectedOption = options.find(
    (option) => option.value === selectedState,
  );

  const handleChange = (selectedOption) => {
    setSelectedState(selectedOption.value);
  };

  const isPersisted = iconAllocation !== undefined;
  const isCreating = selectedState !== 'visible' && !isPersisted;
  const isUpdating = selectedState !== 'visible' && isPersisted;
  const isDeleting = selectedState === 'visible' && isPersisted;
  const hasIconChanged = selectedState !== initialState;

  const renderHiddenInputs = (() => {
    if (isCreating) {
      return (
        <>
          <input
            type='hidden'
            name={`project_configuration[icon_allocations_attributes][${index}][icon_id]`}
            value={icon.attributes.id}
          />
          <input
            type='hidden'
            name={`project_configuration[icon_allocations_attributes][${index}][selection_state]`}
            value={selectedState}
          />
        </>
      );
    } else if (isUpdating) {
      return (
        <>
          <input
            type='hidden'
            name={`project_configuration[icon_allocations_attributes][${index}][id]`}
            value={iconAllocation.id}
          />
          <input
            type='hidden'
            name={`project_configuration[icon_allocations_attributes][${index}][selection_state]`}
            value={selectedState}
          />
        </>
      );
    } else if (isDeleting) {
      return (
        <>
          <input
            type='hidden'
            name={`project_configuration[icon_allocations_attributes][${index}][id]`}
            value={iconAllocation.id}
          />
          <input
            type='hidden'
            name={`project_configuration[icon_allocations_attributes][${index}][_destroy]`}
            value='1'
          />
        </>
      );
    }
    return null;
  })();

  return (
    <div className='tw-flex tw-flex-col tw-items-center tw-p-2 tw-rounded-lg tw-border-1 tw-border-solid tw-border-grey-100'>
      <div className='tw-mt-2'>
        <img src={icon.attributes.imageAssetPath} alt={icon.attributes.name} />
      </div>
      <div className='tw-pt-2.5 tw-pb-2.5 tw-text-center tw-align-top tw-h-full'>
        {icon.attributes.name}
      </div>
      <div className='tw-w-full tw-mb-2 tw-h-10'>
        <Select
          value={selectedOption}
          options={options}
          onChange={handleChange}
          components={{ SingleValue, Option, DropdownIndicator }}
          className='collection-select__select-container '
          classNamePrefix='collection-select'
        />
      </div>
      {/* Render hidden inputs for create, update, or destroy only if there's been a change */}
      {hasIconChanged && renderHiddenInputs}
    </div>
  );
};

IconSelection.propTypes = {
  icon: PropTypes.object.isRequired,
  iconAllocation: PropTypes.object, // This might be null/undefined if no allocation exists
  index: PropTypes.number.isRequired,
};

export default IconSelection;
