import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import Options from 'components/application/Options';
import Tooltip from 'components/application/Tooltip';

export default function ProjectDuplicationSuccessRow(props) {
  const {
    name,
    status,
    statusDisplayText,
    includeClient,
    client,
    reference,
    modified,
    riskRegister,
    activitiesIndicatorCssClass,
    activitiesTextCssClass,
    activitiesDisplayText,
    approval,
    approvalStateCssClass,
    approvalDisplayText,
    resourceId,
  } = props;

  const viewProject = () => (window.location.href = `/projects/${resourceId}`);

  const handleActionClick = (e) => e.stopPropagation();

  return (
    <tr
      className='fh-49 project-row tw-group/row tw-cursor-pointer hover:tw-bg-grey-025'
      onClick={viewProject}
    >
      <td className='tw-border-grey-100 live'>
        <div className='fh-32 flex flex--vertically-centered flex--justify-content__center'>
          <div
            className={`tw-inline-flex tw-h-2.5 tw-w-2.5 tw-rounded-full ${status} hover-popover`}
            data-content={statusDisplayText}
            data-placement='top'
          ></div>
        </div>
      </td>
      <td className='tw-border-grey-100 project tw-font-medium tw-text-grey-900'>
        <div className='fh-32 flex flex--vertically-centered'>
          <div className='truncated-text-container truncated-text-container--auto'>
            {name}
          </div>
        </div>
      </td>
      {includeClient && (
        <td className='tw-border-grey-100 project__client'>
          <div className='fh-32 flex flex--vertically-centered'>
            <div className='truncated-text-container truncated-text-container--auto'>
              {client}
            </div>
          </div>
        </td>
      )}
      <td className='tw-border-grey-100 reference'>
        <div className='fh-32 flex flex--vertically-centered'>
          <div className='truncated-text-container truncated-text-container--auto'>
            {reference}
          </div>
        </div>
      </td>
      <td className='tw-border-grey-100 modified'>
        <div className='fh-32 flex flex--vertically-centered'>
          {`${moment.parseZone(modified).format('DD MMM YY [at] HH:mm')}`}
        </div>
      </td>
      {riskRegister && (
        <td className='tw-border-grey-100 risk_assessments master-activity__version'>
          <div className='fh-32 flex flex--vertically-centered'>
            <div
              className={`tw-inline-flex tw-h-2.5 tw-w-2.5 tw-rounded-full tw-mr-1 ${activitiesIndicatorCssClass}`}
            ></div>
            <span
              className={`${activitiesTextCssClass} tw-text-s tw-font-medium tw-tracking-wide`}
            >
              {activitiesDisplayText}
            </span>
          </div>
        </td>
      )}
      {approval && (
        <td className='tw-border-grey-100 status'>
          <div className='fh-32 flex flex--vertically-centered'>
            <div
              className={`tw-border-0 tw-rounded-lg tw-px-2 tw-py-1 tw-text-s tw-font-medium tw-tracking-wide ${approvalStateCssClass}`}
            >
              {approvalDisplayText}
            </div>
          </div>
        </td>
      )}
      <td className='tw-border-grey-100 actions'>
        <div className='fh-32 flex flex--vertically-centered flex--justify-content__flex-end'>
          <div
            className='dropdown__container pull-right regular-actions'
            onClick={handleActionClick}
          >
            <div className='dropdown'>
              <div className='tooltip-parent'>
                <Options disabled={true} />
                <Tooltip
                  placement='top'
                  trigger='hover'
                  tooltip="This RAMS can't be duplicated or deleted until the page is refreshed"
                />
              </div>
            </div>
          </div>
        </div>
      </td>
    </tr>
  );
}

ProjectDuplicationSuccessRow.propTypes = {
  name: PropTypes.string.isRequired,
  status: PropTypes.string,
  client: PropTypes.string,
  reference: PropTypes.string,
  modified: PropTypes.string,
  activitiesStatus: PropTypes.string,
  activitiesDisplayText: PropTypes.string,
  approvalStateCssClass: PropTypes.string,
  approvalDisplayText: PropTypes.string,
};
