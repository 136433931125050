import React, { useState } from 'react';
import Table from 'components/application/Table';
import ProjectPersonnelRow from 'components/projectPersonnel/ProjectPersonnelRow';
import PersonnelModalBox from 'components/personnel/PersonnelModalBox';
import ReviewModalBox from 'components/reviews/ReviewModalBox';
import { useCurrentActor } from 'components/contexts/CurrentActor';
import { useBreadBoard } from 'components/contexts/Toaster';
import { sortRolesByPrimaryAndPosition } from 'components/helpers/personnel';

export default function ProjectPersonnelTable(props) {
  const currentActor = useCurrentActor();
  const breadBoard = useBreadBoard();

  const defaultCurrentPersonnel = {
    loaded: false,
    member: {},
    fieldValues: {},
    includedRoles: [],
    includedLineManager: null,
    includedCompany: null,
    includedSubcontractor: null,
  };

  const defaultCurrentReview = {
    loaded: false,
    member: {},
    includedPersonnel: null,
    includedSignature: null,
    includedPhoto: null,
    includedReviewedVersion: null,
  };

  const [currentPersonnel, setCurrentPersonnel] = useState(
    defaultCurrentPersonnel,
  );
  const [currentReview, setCurrentReview] = useState(defaultCurrentReview);

  function showPersonnel(personnelId) {
    new Promise((_resolve) => {
      setCurrentPersonnel(defaultCurrentPersonnel);
    }).then(
      axios
        .get(`/projects/${props.projectId}/personnel/${personnelId}`)
        .then((response) => {
          const fieldValues = response.data.included.reduce(
            (object, resource) => {
              if (resource.type == 'fieldValue') {
                object[resource.id] = resource;
              }
              return object;
            },
            {},
          );

          const formattedFieldValues = Object.values(fieldValues).reduce(
            (object, value) => {
              object[value.relationships.fieldAttribute.data.id] = {
                id: value.id,
                value: value.attributes.value,
                valueType: value.attributes.valueType,
                fieldAttributeId: value.relationships.fieldAttribute.data.id,
                fieldOptionId:
                  value.relationships.fieldOption.data &&
                  value.relationships.fieldOption.data.id,
                personnelId: value.relationships.entity.data.id,
              };

              return object;
            },
            {},
          );

          const includedRoles = response.data.included.filter(
            (object) => object.type === 'companyRole',
          );
          const includedUserCompanyRoles = response.data.included.filter(
            (object) => object.type === 'userCompanyRole',
          );
          const sortedRoles = sortRolesByPrimaryAndPosition(
            includedUserCompanyRoles,
            includedRoles,
          );

          setCurrentPersonnel({
            loaded: true,
            member: response.data.data,
            fieldValues: formattedFieldValues,
            includedRoles: sortedRoles,
            includedLineManager: response.data.included.find(
              (object) => object.type === 'lineManager',
            ),
            includedCompany: response.data.included.find(
              (object) => object.type === 'company',
            ),
            includedSubcontractor: response.data.included.find(
              (object) => object.type === 'subcontractor',
            ),
          });
        })
        .catch(breadBoard.addInedibleToast),
    );
  }

  const showAcceptedReview = (reviewId) => {
    new Promise((_resolve) => {
      setCurrentReview(defaultCurrentReview);
    }).then(
      axios
        .get(`/accepted_reviews/${reviewId}`)
        .then((response) => {
          setCurrentReview({
            loaded: true,
            member: response.data.data,
            includedPersonnel: response.data.included.find(
              (object) => object.type === 'personnel',
            ),
            includedSignature: response.data.included.find(
              (object) => object.type === 'signature',
            ),
            includedPhoto: response.data.included.find(
              (object) => object.type === 'photo',
            ),
            includedReviewedVersion: response.data.included.find(
              (object) => object.type === 'reviewedVersion',
            ),
          });
        })
        .catch(breadBoard.addInedibleToast),
    );
  };

  const showRejectedReview = (reviewId) => {
    new Promise((_resolve) => {
      setCurrentReview(defaultCurrentReview);
    }).then(
      axios
        .get(`/rejected_reviews/${reviewId}`)
        .then((response) => {
          setCurrentReview({
            loaded: true,
            member: response.data.data,
            includedPersonnel: response.data.included.find(
              (object) => object.type === 'personnel',
            ),
            includedReviewedVersion: response.data.included.find(
              (object) => object.type === 'reviewedVersion',
            ),
          });
        })
        .catch(breadBoard.addInedibleToast),
    );
  };

  const formattedProjectPersonnel = props.projectPersonnel.map(
    (projectPersonnel, _index) => {
      const personnel = props.personnel.find(
        (personnel) =>
          personnel.id === String(projectPersonnel.attributes.personnelId),
      );
      const trainingStatus = projectPersonnel.attributes.trainingStatus;
      const companyRole = props.companyRoles.find(
        (companyRole) =>
          companyRole.id === String(projectPersonnel.attributes.companyRoleId),
      );
      const personnelCompanyRoleIds =
        personnel.relationships.companyRoles.data.map(
          (companyRole) => companyRole.id,
        );
      const assignableCompanyRoles = props.companyRoles.filter((companyRole) =>
        personnelCompanyRoleIds.includes(companyRole.id),
      );
      const review = props.reviews.find(
        (review) =>
          String(review.attributes.projectPersonnelId) ===
          String(projectPersonnel.id),
      );
      const reviewedVersion =
        review &&
        props.reviewedVersions.find(
          (reviewedVersion) =>
            String(reviewedVersion.id) ===
            String(review.attributes.reviewedVersionId),
        );

      return {
        id: projectPersonnel.id,
        personnel: personnel,
        trainingStatus: trainingStatus,
        companyRole: companyRole,
        review: review,
        reviewedVersion: reviewedVersion,
        assignableCompanyRoles: assignableCompanyRoles,
        handleShowPersonnel: showPersonnel,
        handleShowAcceptedReview: showAcceptedReview,
        handleShowRejectedReview: showRejectedReview,
      };
    },
  );

  return (
    <React.Fragment>
      <Table
        headers={
          <tr>
            <th className='w-25 tw-text-s tw-text-grey-900 tw-font-medium tw-tracking-wide'>
              Name
            </th>
            <th className='w-25 tw-text-s tw-text-grey-900 tw-font-medium tw-tracking-wide'>
              <span className='m-l-1 p-l-10 tw-text-s tw-text-grey-900 tw-font-medium tw-tracking-wide'>
                Role
              </span>
            </th>
            {currentActor.isAllowedFeature('training_register') && (
              <th className='w-16 tw-text-s tw-text-grey-900 tw-font-medium tw-tracking-wide'>
                {'Training status'}
              </th>
            )}
            <th className='w-15 tw-text-center tw-text-s tw-text-grey-900 tw-font-medium tw-tracking-wide'>
              {currentActor.isAllowedFeature('digital_signatures') &&
                'Signature status'}
            </th>
            <th className='w-10 tw-text-right tw-text-s tw-text-grey-900 tw-font-medium tw-tracking-wide'>
              Actions
            </th>
          </tr>
        }
        rows={formattedProjectPersonnel.map((formattedProjectPersonnel) => (
          <ProjectPersonnelRow
            key={formattedProjectPersonnel.id}
            formattedProjectPersonnel={formattedProjectPersonnel}
            fetchProjectPersonnel={props.fetchProjectPersonnel}
            replaceProjectPersonnel={props.replaceProjectPersonnel}
            latestVersion={props.latestVersion}
            destroyProjectPersonnel={props.destroyProjectPersonnel}
          />
        ))}
      />
      <PersonnelModalBox
        personnel={currentPersonnel}
        availableFields={props.availableFields}
        availableFieldOptions={props.availableFieldOptions}
      />
      {currentActor.isAllowedFeature('digital_signatures') && (
        <ReviewModalBox review={currentReview} />
      )}
    </React.Fragment>
  );
}
