import React from 'react';
import ActivityTableHeaders from 'components/projectActivity/ActivityTableHeaders';

export default function ProjectActivityBlankNotice(props) {
  return (
    <>
      <table
        className={
          'table handshq__table handshq__table--spaced handshq__table--middled handshq__table--no-top-border handshq__table--no-bottom-border tw-border-grey-100'
        }
      >
        <thead className='handshq__thead'>
          <ActivityTableHeaders
            showVersionNumberColumn={props.showVersionNumberColumn}
          />
        </thead>
      </table>
      <section className='section section--notice p-t-80 p-b-80 tw-border-grey-100 tw-bg-white'>
        <h2 className='section__header p-t-0 tw-text-grey-900 tw-text-l tw-font-semibold'>
          RAMS creation process was not completed
        </h2>
        <p className='section__content section__content-link m-t-8 m-b-0'>
          Please{' '}
          <a
            className='app-link tw-align-baseline tw-text-blue-500 hover:tw-text-blue-300 active:tw-text-blue-300'
            href={props.editProject}
          >
            edit your RAMS
          </a>{' '}
          and click 'Save and exit' to create version 0
        </p>
      </section>
    </>
  );
}
