export const fetchProcorePermissions = ({ companyID, projectID }) =>
  axios.get(`/procore/bouncer?company_id=${companyID}&project_id=${projectID}`);

export const fetchProcoreCompanyProject = ({ companyID, projectID }) =>
  axios.get(`/procore/companies/${companyID}/projects/${projectID}`);

export const fetchProcoreCompanyProjects = ({ companyID }) =>
  axios.get(`/procore/companies/${companyID}/projects`);

export const fetchProcoreDocument = ({ projectID, folderID, companyID }) =>
  axios.get(
    `/procore/companies/${companyID}/projects/${projectID}/folders/${folderID}`,
  );

export const fetchProcoreDocuments = ({ projectID, companyID }) =>
  axios.get(`/procore/companies/${companyID}/projects/${projectID}/documents`);

export const fetchProcoreCompanies = () => axios.get('/procore/companies');
