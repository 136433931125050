import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import moment from 'moment';

import { resourceShape } from 'components/helpers/serialisableResources';

import StatusIndicator from 'components/application/indicators/StatusIndicator';

export default function CombinedStatus({
  currentTrainingStatus,
  latestTraining,
  isRequired,
}) {
  const expiryDate = latestTraining?.attributes?.expiryDate;
  const formattedExpiryDate = (
    expiryDate ?
      moment.parseZone(expiryDate)
    : null)?.format('D MMM YYYY');

  const trainingStatusOptions = {
    valid: {
      status: 'low',
      text: expiryDate ? `Expires ${formattedExpiryDate}` : 'Does not expire',
    },
    expiryDateMissing: {
      status: isRequired ? 'high' : 'grey',
      text: 'Expiry date is missing',
    },
    expiring: {
      status: isRequired ? 'medium' : 'low',
      text: `Expires ${formattedExpiryDate}`,
    },
    expired: {
      status: isRequired ? 'high' : 'grey',
      text: `Expired ${formattedExpiryDate}`,
    },
    invalid: {
      status: isRequired ? 'high' : 'grey',
      text: 'Expiry date not set',
    },
    missing: {
      status: isRequired ? 'high' : 'grey',
      text: 'Missing',
    },
  };

  const colourOptions = {
    low: { className: 'tw-bg-green-025 tw-text-green-800' },
    medium: { className: 'tw-bg-amber-025 tw-text-amber-800' },
    high: { className: 'tw-bg-red-025 tw-text-red-800' },
    grey: { className: 'tw-bg-grey-050 tw-text-grey-700' },
  };

  const status = trainingStatusOptions[currentTrainingStatus]['status'];
  const text = trainingStatusOptions[currentTrainingStatus]['text'];
  const colourClassName = colourOptions[status]['className'];

  return (
    <div
      className={classNames(
        'tw-inline-flex tw-items-center tw-rounded-full tw-h-8 tw-px-3',
        colourClassName,
      )}
    >
      <StatusIndicator status={status} />
      <span className='tw-ml-2'>{text}</span>
    </div>
  );
}

CombinedStatus.propTypes = {
  currentTrainingStatus: PropTypes.string.isRequired,
  latestTraining: resourceShape('training'),
  isRequired: PropTypes.bool,
};
