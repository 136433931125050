import React from 'react';
import PropTypes from 'prop-types';
import useEscapeKey from 'components/hooks/useEscapeKey';
import useBodyOverflowToggle from 'components/hooks/useBodyOverflowToggle';
import TextButton from 'components/application/buttons/TextButton';

export default function ModalBox(props) {
  const {
    children,
    mode,
    usingStandardDimensions,
    isOpen,
    secondaryFooter,
    customFooter,
    onClose,
    usingSlimDimensions,
    withFooter,
  } = props;

  const openCloseClassName = isOpen ? 'modalbox--open' : 'modalbox--close';

  useBodyOverflowToggle(isOpen);

  const modeClassName = {
    landscape: 'modalbox__box--landscape',
    portrait: 'modalbox__box--portrait',
    letterbox: 'modalbox__box--letterbox',
    flexible: 'modalbox__box--flexible',
  }[mode];

  const defaultFooter =
    mode === 'portrait' ?
      <TextButton size='md' color='blue' onClick={onClose}>
        Close
      </TextButton>
    : <React.Fragment>
        {secondaryFooter && (
          <div className='modalbox__footer-actions modalbox__footer-actions--left'>
            {secondaryFooter}
          </div>
        )}
        <div className='modalbox__footer-actions modalbox__footer-actions--right'>
          <TextButton size='md' color='blue' onClick={onClose}>
            Close
          </TextButton>
        </div>
      </React.Fragment>;

  const handleWrapperClick = (event) => {
    if (event.target.classList.contains('modalbox__box-wrapper')) {
      onClose();
    }
  };

  useEscapeKey(onClose, [isOpen]);

  return (
    <div
      data-element-name='modalbox'
      className={`modalbox ${openCloseClassName}`}
    >
      <div className='modalbox__scroller'>
        <div className='popup__backdrop tw-bg-grey-900 tw-opacity-75'></div>
        <div className='modalbox__box-wrapper' onClick={handleWrapperClick}>
          <div
            className={`modalbox__box tw-bg-white ${modeClassName}${usingStandardDimensions ? ' modalbox__box--standard-dimensions' : ''}${usingSlimDimensions ? ' modalbox__box--slim-dimensions' : ''}`}
          >
            <section className='modalbox__children'>{children}</section>
            {withFooter && (
              <section className='modalbox__footer tw-border-t-grey-100 tw-border-b-white'>
                {customFooter || defaultFooter}
              </section>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

ModalBox.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

ModalBox.defaultProps = {
  mode: 'landscape',
  secondaryFooter: null,
  customFooter: null,
  usingSlimDimensions: false,
  withFooter: true,
};
