import _ from 'lodash';
import { AdvancedMarker, Map, Pin, useMap } from '@vis.gl/react-google-maps';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { mapConfigurationShape } from './helpers';
import { resourceShape } from 'components/helpers/serialisableResources';
import HandshqApp from 'handshq-app/handshq-app';
import tailwindConfig from 'stylesheets/tailwind.config.js';

const colors = tailwindConfig.theme.colors;

export default function MedicalLocationMap({
  domainMapConfiguration,
  mapConfiguration,
  medicalLocation,
  onCameraChanged,
  siteCoordinates,
}) {
  const medicalLocationCoordinates = {
    lat: medicalLocation.attributes.latitude,
    lng: medicalLocation.attributes.longitude,
  };

  const defaultCenter =
    !!mapConfiguration.centerLatitude && !!mapConfiguration.centerLongitude ?
      {
        lat: mapConfiguration.centerLatitude,
        lng: mapConfiguration.centerLongitude,
      }
    : medicalLocationCoordinates;

  const map = useMap();
  const markerCoordinates = [medicalLocationCoordinates];
  if (!!siteCoordinates) markerCoordinates.push(siteCoordinates);
  const zoom =
    !!mapConfiguration.zoomLevel ? parseInt(mapConfiguration.zoomLevel) : 12;

  useEffect(() => {
    if (!map) return;

    // Don't change bounds when loading domain map configuration for the first time
    if (
      medicalLocation.type === 'medicalLocation' &&
      !!domainMapConfiguration &&
      _.isEqual(
        {
          centerLatitude: domainMapConfiguration.attributes.centerLatitude,
          centerLongitude: domainMapConfiguration.attributes.centerLongitude,
          id: domainMapConfiguration.id,
          zoomLevel: domainMapConfiguration.attributes.zoomLevel,
        },
        mapConfiguration,
      )
    ) {
      return;
    }

    if (markerCoordinates.length === 1) {
      map.setCenter(markerCoordinates[0]);
      map.setZoom(zoom);
    } else {
      const bounds = new google.maps.LatLngBounds();
      markerCoordinates.forEach((coordinates) => bounds.extend(coordinates));
      map.fitBounds(bounds);
    }
  }, [map, markerCoordinates.length]);

  return (
    <Map
      defaultCenter={defaultCenter}
      defaultZoom={zoom}
      fullscreenControl={false}
      keyboardShortcuts={false}
      mapId={HandshqApp.rails.googleMapsMapId}
      mapTypeControl={false}
      onCameraChanged={onCameraChanged}
      streetViewControl={false}
      zoomControl={false}
    >
      <AdvancedMarker position={medicalLocationCoordinates}>
        <Pin
          background='#EA5D52'
          borderColor='#D7352E'
          glyphColor={colors.white}
        >
          <p className='tw-font-bold tw-mb-0 tw-mt-0.5 tw-text-m'>H</p>
        </Pin>
      </AdvancedMarker>
      {!!siteCoordinates && <AdvancedMarker position={siteCoordinates} />}
    </Map>
  );
}

MedicalLocationMap.propTypes = {
  domainMapConfiguration: resourceShape('medicalLocationMapConfiguration'),
  mapConfiguration: PropTypes.shape(mapConfigurationShape).isRequired,
  medicalLocation: PropTypes.oneOfType([
    resourceShape('assignableMedicalLocation'),
    resourceShape('medicalLocation'),
  ]).isRequired,
  onCameraChanged: PropTypes.func.isRequired,
  siteCoordinates: PropTypes.shape({
    lat: PropTypes.number,
    lng: PropTypes.number,
  }),
};
