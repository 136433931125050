import React from 'react';
import Select, { components } from 'react-select';
import {
  DropdownIndicator,
  Option,
} from 'components/application/CollectionSelect';

import FilterIcon from '-!svg-react-loader?name=FilterIcon!icons/ic-filter.svg';

const defaultDivisionSelectionOption = { label: 'All divisions', value: '' };

const SingleValue = ({ ...props }) => (
  <components.SingleValue {...props}>
    <div
      className='collection-select__custom-value-container'
      style={{ display: props.selectProps.menuIsOpen ? 'none' : 'flex' }}
    >
      <div className='collection-select__filter-icon-wrapper m-r-8'>
        <FilterIcon width={10} height={20} />
      </div>
      <div className='collection-select__auto-content-wrapper'>
        <span className='truncated-text-container'>{props.children}</span>
      </div>
    </div>
  </components.SingleValue>
);

export default function DivisionFilter({
  divisions,
  selectedDivisionId,
  onChange,
}) {
  const selectDivisionOptions = [defaultDivisionSelectionOption];
  divisions.forEach((division) =>
    selectDivisionOptions.push({
      label: division.attributes.name,
      value: division.id,
    }),
  );
  const selectedDivision =
    selectDivisionOptions.find(
      (option) => option.value === selectedDivisionId,
    ) || defaultDivisionSelectionOption;

  return (
    <div className='collection-select collection-select--inline-form'>
      <div className='form-group'>
        <Select
          className='collection-select__select-container'
          classNamePrefix='collection-select'
          name='divisionFilter'
          controlShouldRenderValue={true}
          isSearchable={true}
          autoFocus={false}
          components={{ SingleValue, DropdownIndicator, Option }}
          value={selectedDivision}
          options={selectDivisionOptions}
          onChange={onChange}
        />
      </div>
    </div>
  );
}
