import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Editor } from '@tinymce/tinymce-react';
import { v4 as uuidv4 } from 'uuid';
import 'style-loader!tinymce/skins/handshq/skin.min.css';
import { initOptions } from 'helpers/tinymce';

export default function RichTextEditor({ name, onChange, options, value }) {
  const uuid = useMemo(() => uuidv4(), []);

  const handleEditorChange = (content) => {
    onChange({ content, target: { name } });
  };

  return (
    <Editor
      apiKey={process.env.TINYMCE_API_KEY}
      id={uuid}
      init={initOptions(options)}
      onEditorChange={handleEditorChange}
      value={value}
    />
  );
}

RichTextEditor.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.exact({
    additionalContentStyleString: PropTypes.string,
    autoFocus: PropTypes.bool,
    height: PropTypes.number,
    inline: PropTypes.bool,
    minHeight: PropTypes.number,
    onInit: PropTypes.func,
    onSetup: PropTypes.func,
    placeholder: PropTypes.string,
    selector: PropTypes.string,
    toolbar: PropTypes.string.isRequired,
    useSpellChecker: PropTypes.bool,
  }).isRequired,
  value: PropTypes.string.isRequired,
};
