import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { resourceShape } from 'components/helpers/serialisableResources';
import { getTrainingStatus } from 'components/helpers/resources/training';

import AttachmentIcon from '-!svg-react-loader?name=AttachmentIcon!icons/attachment.svg';

import TrainingStatus from 'components/training/TrainingStatus';
import TrainingRowBookingPill from 'components/mobileTrainingProfile/components/homeScreen/TrainingRowBookingPill';

export default function TrainingRow({
  course,
  latestTraining,
  attachments,
  booking,
  registration,
  isRequired,
  requiredSource,
  onViewCourseDetailsClick,
}) {
  const currentTrainingStatus = getTrainingStatus({
    course,
    training: latestTraining,
  });

  const handleRowClick = () => {
    onViewCourseDetailsClick({ id: course.id, isRequired });
  };

  return (
    <tr
      className='fh-49 tw-cursor-pointer hover:tw-bg-grey-025'
      onClick={handleRowClick}
    >
      <td className='tw-border-grey-100 tw-px-4'>
        <div className='tw-flex tw-items-center tw-h-8'>
          <div className='tw-flex-none'>
            <TrainingStatus
              currentTrainingStatus={currentTrainingStatus}
              isRequired={isRequired}
              requiredSource={requiredSource}
            />
          </div>
          <div
            className={classNames(
              'truncated-text-container truncated-text-container--auto',
              booking && 'tw-flex-auto',
            )}
          >
            <span className='tw-text-m tw-text-grey-900 tw-font-medium tw-ml-2.5'>
              {course.attributes.name}
            </span>
          </div>
          {booking && (
            <div className='tw-flex-none tw-ml-2'>
              <TrainingRowBookingPill
                course={course}
                booking={booking}
                registration={registration}
              />
            </div>
          )}
          {!booking && attachments?.length > 0 && (
            <div
              data-element-name='evidence-count'
              className='tw-flex-none tw-flex tw-items-center tw-h-6 tw-px-1 tw-py-0.75 tw-border-1 tw-border-solid tw-border-grey-100 tw-rounded-md tw-ml-2'
            >
              <AttachmentIcon width={16} height={16} />
              <span className='m-l-6 tw-text-xs tw-font-semibold tw-tracking-wide'>
                {attachments.length}
              </span>
            </div>
          )}
        </div>
      </td>
    </tr>
  );
}

TrainingRow.propTypes = {
  course: resourceShape('course').isRequired,
  latestTraining: resourceShape('training'),
  attachments: PropTypes.arrayOf(resourceShape('attachment')),
  booking: resourceShape('booking'),
  registration: resourceShape('registration'),
  isRequired: PropTypes.bool,
  requiredSource: PropTypes.string,
  onViewCourseDetailsClick: PropTypes.func.isRequired,
};
