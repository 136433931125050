import React, { useState, useEffect, useRef } from 'react';
import { focusField } from 'components/helpers/refs';
import PropTypes from 'prop-types';
import { useBreadBoard } from 'components/contexts/Toaster';
import { useTrainingRegisterResources } from 'components/contexts/TrainingRegisterResourceManagementContext';
import SidePanel from 'components/application/SidePanel';
import TeamsForm from './TeamsForm';
import TeamSidePanelShow from './TeamSidePanelShow';
import ShowFooter from 'components/sidepanels/ShowFooter';
import FormFooter from 'components/sidepanels/FormFooter';
import PanelIcon from 'components/application/PanelIcon';
import TeamsIcon from '-!svg-react-loader?name=TeamsIcon!icons/icon-teams.svg';

const defaultMembers = {
  loaded: false,
  collection: [],
  meta: { currentPage: 1, isLastPage: false },
};

export default function TeamsSidePanel({
  team,
  teamCompanyName,
  onTeamNameInputChange,
  onTeamCompanyOptionChange,
  onTeamMemberSelect,
  onTeamMemberStatusChange,
  onTeamMemberDelete,
  onTeamMemberFetch,
  onCreateClick,
  onUpdateClick,
  sidePanelIsOpen,
  sidePanelContext,
  closeCallback,
  onDeleteClick,
  onEditClick,
  submitDisabled,
  requestError,
  removeErrorStyling,
}) {
  const trainingRegisterResourceManagementContext =
    useTrainingRegisterResources();
  const breadBoard = useBreadBoard();
  const sidePanelFieldRef = useRef();

  const [teamMembers, setTeamMembers] = useState(defaultMembers);
  const [isLoadMoreMembersActive, setIsLoadMoreMembersActive] = useState(false);
  const [isFetchingTeamMembers, setIsFetchingTeamMembers] = useState(false);
  const [scrolledAmount, setScrolledAmount] = useState(0);
  const canLoadMoreTeamMembers =
    teamMembers.loaded &&
    isLoadMoreMembersActive &&
    !teamMembers.meta.isLastPage;

  function fetchTeamMembers({ page }) {
    setIsLoadMoreMembersActive(false);
    setIsFetchingTeamMembers(true);
    axios
      .get(`/teams/${team.id}/members`, { params: { page } })
      .then((response) => {
        setTeamMembers((prevMembers) => ({
          loaded: true,
          collection:
            page === 'all' ?
              response.data.data
            : prevMembers.collection.concat(response.data.data),
          meta: response.data.meta,
        }));
        onTeamMemberFetch({ members: response.data.data, replace: true });
        setIsLoadMoreMembersActive(true);
        setIsFetchingTeamMembers(false);
      })
      .catch(breadBoard.addInedibleToast)
      .finally(() => setIsFetchingTeamMembers(false));
  }

  function handleEditClick() {
    fetchTeamMembers({ page: 'all' });
    onEditClick();
  }

  function onBackdropClick() {
    const panelLocked = ['new', 'edit'].includes(sidePanelContext);
    if (!panelLocked) {
      closeCallback();
    }
  }

  const titleHeaderContent = (
    <div className='popup__title popup__title--tertiary'>
      <h1 className='tw-text-grey-900 tw-text-xl tw-font-semibold tw-tracking-tight'>
        {sidePanelContext === 'edit' ? 'Edit team' : 'Add new team'}
      </h1>
    </div>
  );

  function handleLoadMoreMembers(inView) {
    if (inView)
      fetchTeamMembers({ page: parseInt(teamMembers.meta.currentPage) + 1 });
  }

  useEffect(() => {
    if (team.id && sidePanelIsOpen) {
      fetchTeamMembers({ page: parseInt(teamMembers.meta.currentPage) });
    }

    if (!sidePanelIsOpen) {
      setTeamMembers(defaultMembers);
      setIsLoadMoreMembersActive(false);
    }
  }, [sidePanelIsOpen]);

  const showBodyContent = (
    <TeamSidePanelShow
      team={team}
      teamCompanyName={teamCompanyName}
      teamMembers={teamMembers}
      sidePanelIsOpen={sidePanelIsOpen}
      canLoadMoreTeamMembers={canLoadMoreTeamMembers}
      onLoadMoreTeamMembers={handleLoadMoreMembers}
      isFetchingTeamMembers={isFetchingTeamMembers}
    />
  );

  const footerContent = (() => {
    switch (sidePanelContext) {
      case 'new':
        return (
          <FormFooter
            text='Add team'
            submitButtonDisabled={submitDisabled}
            onCancel={closeCallback}
            onSubmit={onCreateClick}
          />
        );
      case 'show':
        return trainingRegisterResourceManagementContext.hasTeamEditableAccess ?
            <ShowFooter
              type='team'
              onEditClick={handleEditClick}
              onDeleteClick={onDeleteClick}
            />
          : null;
      case 'edit':
        return (
          <FormFooter
            text='Save changes'
            submitButtonDisabled={submitDisabled}
            onCancel={closeCallback}
            onSubmit={onUpdateClick}
          />
        );
    }
  })();

  const formBodyContent = sidePanelIsOpen && (
    <TeamsForm
      team={team}
      teamMembers={teamMembers}
      onTeamNameInputChange={onTeamNameInputChange}
      onTeamCompanyOptionChange={onTeamCompanyOptionChange}
      onTeamMemberSelect={onTeamMemberSelect}
      onTeamMemberStatusChange={onTeamMemberStatusChange}
      onTeamMemberDelete={onTeamMemberDelete}
      requestError={requestError}
      removeErrorStyling={removeErrorStyling}
      sidePanelFieldRef={sidePanelFieldRef}
      sidePanelContext={sidePanelContext}
      isFetchingTeamMembers={isFetchingTeamMembers}
    />
  );

  const iconHeaderContent = (
    <PanelIcon Icon={TeamsIcon} scrolledAmount={scrolledAmount} />
  );

  return (
    <SidePanel
      color={sidePanelContext === 'show' ? 'secondary' : 'tertiary'}
      isOpen={sidePanelIsOpen}
      onOpen={() => sidePanelContext === 'new' && focusField(sidePanelFieldRef)}
      displayClose={sidePanelContext === 'show'}
      onBackdropClick={onBackdropClick}
      closeCallback={closeCallback}
      scrolledAmount={scrolledAmount}
      setScrolledAmount={setScrolledAmount}
      scrollHeader={sidePanelContext === 'show'}
      headerContent={
        sidePanelContext === 'show' ? iconHeaderContent : titleHeaderContent
      }
      bodyContent={
        sidePanelContext === 'show' ? showBodyContent : formBodyContent
      }
      footerContent={footerContent}
      contentContext={sidePanelContext}
    />
  );
}

TeamsSidePanel.propTypes = {
  team: PropTypes.object.isRequired,
  teamCompanyName: PropTypes.string.isRequired,
  onTeamNameInputChange: PropTypes.func.isRequired,
  onTeamCompanyOptionChange: PropTypes.func.isRequired,
  onTeamMemberSelect: PropTypes.func.isRequired,
  onTeamMemberStatusChange: PropTypes.func.isRequired,
  onTeamMemberDelete: PropTypes.func.isRequired,
  onCreateClick: PropTypes.func.isRequired,
  onUpdateClick: PropTypes.func.isRequired,
  sidePanelIsOpen: PropTypes.bool.isRequired,
  sidePanelContext: PropTypes.string.isRequired,
  closeCallback: PropTypes.func.isRequired,
  onDeleteClick: PropTypes.func.isRequired,
  submitDisabled: PropTypes.bool.isRequired,
  requestError: PropTypes.object.isRequired,
  removeErrorStyling: PropTypes.func.isRequired,
};
