import React from 'react';
import PropTypes from 'prop-types';

import { useCurrentActor } from 'components/contexts/CurrentActor';
import { resourceShape } from 'components/helpers/serialisableResources';

import Tabs from 'components/application/Tabs';
import BookingSidePanelDetailsTab from 'components/training/BookingSidePanelDetailsTab';
import BookingSidePanelCourseTab from 'components/training/BookingSidePanelCourseTab';

export default function BookingSidePanelShow({
  booker,
  bookingGroupInfoBanner,
  course,
  domainBookingSource,
  domainBookingSourcePersonnelCollection,
  endOfListRef,
  isFetching,
  sidePanelIsOpen,
  totalReadableBookingCount,
  shouldShowPersonnel,
}) {
  const currentActor = useCurrentActor();

  const courseHasELearningCourse =
    course && !!course.relationships.eLearningCourse.data;

  return (
    <Tabs
      modifiers={['panelled']}
      navClassName='tw-bg-grey-050'
      activeTabIsReset={!sidePanelIsOpen}
    >
      <BookingSidePanelDetailsTab
        key='detailsTab'
        label={`${courseHasELearningCourse ? 'Enrolment' : 'Booking'} details`}
        domainBookingSource={domainBookingSource}
        course={course}
        booker={booker ? booker : null}
        shouldShowPersonnel={shouldShowPersonnel}
        domainBookingSourcePersonnelCollection={
          domainBookingSourcePersonnelCollection
        }
        totalReadableBookingCount={totalReadableBookingCount}
        bookingGroupInfoBanner={bookingGroupInfoBanner}
        endOfListRef={endOfListRef}
        isFetching={isFetching}
      />
      {currentActor.user.attributes.accessType !== 'personnel' && (
        <BookingSidePanelCourseTab
          key='coursesTab'
          label='Course details'
          course={course}
        />
      )}
    </Tabs>
  );
}

BookingSidePanelShow.propTypes = {
  booker: resourceShape('simpleUser'),
  bookingGroupInfoBanner: PropTypes.node,
  course: resourceShape('course'),
  domainBookingSource: resourceShape(['booking', 'bookingGroup']),
  endOfListRef: PropTypes.func,
  isFetching: PropTypes.bool,
  domainBookingSourcePersonnelCollection: PropTypes.arrayOf(
    resourceShape('personnel'),
  ),
  shouldShowPersonnel: PropTypes.bool,
  sidePanelIsOpen: PropTypes.bool,
  totalReadableBookingCount: PropTypes.number,
};
