import React from 'react';

import OutlinedButton from 'components/application/buttons/OutlinedButton';
import NoteIcon from '-!svg-react-loader?name=NoteIcon!icons/note-sm.svg';

export default function TrainingNotesIcon(props) {
  const { onNotesToggleClick } = props;

  return (
    <OutlinedButton size='xs' color='grey' onClick={onNotesToggleClick}>
      <NoteIcon width={16} height={16} />
    </OutlinedButton>
  );
}
